// import {  Modal } from "react-bootstrap";
import { useNotifications } from "reapop";
import sytle from './NewService.css';
import { MdOutlineLaunch } from "react-icons/md";
import Modal from "../Modal/Modal";
import Button from "../../Elements/Button/Button";
import { BiErrorCircle, BiPencil } from "react-icons/bi";
import Select from "../../V1/Select/Select";
import { useRef, useState } from "react";

const NewService = ({show,title, logo, description,users=[], className, ...props }) => {


    const serviceNameRef = useRef(null)
    const [seletedUser, setSelectedUser] = useState({}) 

    const onSelectUser = (item)=>{
        setSelectedUser(item)
    }

    const onSaveService = ()=>{
        props.onSave(serviceNameRef.current.value, seletedUser)
    }

    //console.log({props})
    return (
        <>
            <Modal show={show} style={{ width: "514px" }}>
                <Modal.Title>
                    <div className="content-padding">

                        <div className="headingDiv">
                            <div className='logoos'>
                                <BiPencil/>
                            </div>
                            <div className='header'>
                                <span className="mainHeading">
                                    {title}
                                </span>
                                <span className="subHeading">
                                    {description}
                                </span>
                            </div>
                        </div>
                    </div>
                </Modal.Title>
                <Modal.Body>
                    <div>
                        <div className="Content">
                            <div className="formControls">
                                <label htmlFor="lname">Service Name</label><span className="hint"><BiErrorCircle color="rgba(255, 153, 0, 0.40)"/></span><br />
                                <input ref={serviceNameRef} type="text" id="lname" name="lname" />
                            </div>
                            <div className="formControls" style={{marginTop:'20px'}}>
                                <Select label={"Owner"}  options={users} onChange={onSelectUser} />
                            </div>
                        </div>
                        <div style={{ float: "right", marginTop: "40px" }}>
                            <Button type="danger" ButtonStyle="transparent" className="mx-3" onClick={() => props.onHide()}>Cancel</Button>
                            <Button onClick={onSaveService} >Save</Button>

                        </div>


                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default NewService;