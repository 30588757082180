export const inputNumberOnly = (e)=>{
    if(isNaN(e.key) && e.keyCode != 8){
        e.preventDefault()
    }
}

export const inputDecimalOnly = (e)=>{
    
    let hasDot = e.target.value?.includes(".") ?? false
    console.log({code : e.keyCode})
    console.log({code1 : isNaN(e.key)})
    console.log({code2 : e.keyCode != 8})
    console.log({code3 : e.keyCode != 190})
    console.log({code4 :hasDot})
    
    if(isNaN(e.key) && (e.keyCode != 8 && (e.keyCode != 190 || hasDot))){
        e.preventDefault()
    }
}


