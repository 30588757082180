import "./Timeline.css"

const Timeline = ({className, timelines, stage, ...props})=>{

    const step = stage - 1
    return (
        <div  {...props}>
            <div className={`timelines ${className}`}>
               
                 {timelines.map((item,index)=>{
                        return(
                            <div
                                key={index}
                                className={`timeline ${
                                index < 1 ? 'complete' : index == 1 ? 'active' : 'pending'
                                }`}
                            >
                                <span className="title">{item}</span>
                                <div className="timeline-line-container">
                                <span className="timeline-dot"></span>
                                <span className="timeline-line"></span>
                                </div>
                            </div>
                        )
                })}
                
            </div>
        </div>
    )
}

export default Timeline