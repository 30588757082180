
import { useEffect, useState } from "react"
import style from "./Select.module.css"
import arrowDown from "./assets/arrow-down.svg"
import arrowUp from "./assets/arrow-up.svg"
import { BiSearch } from "react-icons/bi"
import arrowDisabled from "./assets/arrow-down-disabled.svg"
import { BiEdit } from "react-icons/bi"

import TooltipView from "../../Tooltip/Tooltip"
const SelectwithSearch = ({hint,label, data, placeholder, onChange, defaultValue, size, muliple, serverSideSearch, onServerSideSearch, ...otherProps})=>{


    const [items, setItems] = useState([])
    const [showDropdown, setShowDropdown] = useState(false)

    const [disabled, setDisabled] = useState(false)
    const [editable, setEditable] = useState(false)

    const makeEditable= (value) => {
        setEditable(true)
        setDisabled(false)
    }

    const [selectedItem, setSelectedItem] = useState({})
    const [defaultVal, setDefaultVal] = useState("")
    const [dropdownIcon, setDropdownIcon] = useState(arrowDown)

    const onItemSelect = (index)=>{
        
    }

    const showedOptions =(value)=>{
        setShowDropdown(value)
    }

    const handleSelect = (obj) => {
        if(obj !== undefined && obj[otherProps.showKey] !== undefined){
            setDefaultVal(obj[otherProps.showKey])
            setDisabled(true)
            setEditable(false)
            onChange(obj)
        }
    }
    const handleSearch = (search) => {
        if (!serverSideSearch){
            var filtered = data.filter((obj)=> obj[otherProps.showKey].includes(search))
            setItems(filtered)
        }else{
            onServerSideSearch(search)
        }
    }


    useEffect(()=>{
        setDefaultVal(defaultValue)
    },[defaultValue])

    useEffect(()=>{
        setItems(data)
    },[data])

   
    return(
        <>  
            <TooltipView trigger={"click"} position={"bottom"} padding={"0px"} distance={0} onShown={showedOptions}  disabled={disabled} 
                width={`${otherProps.width}px`}
            body= {
                
                <div className={`${style.selectSearchContainer}}`}  >
                   
                    <div className={style.selectSearchInput}>
                        <div className="row" style={{height:"100%", width: "100%"}}>
                            <div className="col-2 " style={{
                                padding: "0px"
                            }}>
                                <BiSearch style={{fontSize: "20px", marginTop:"10px", marginLeft:"10px"}}/>
                            </div>
                            <div className="col-10" style={{
                                padding: "0px"
                            }}>
                                <input className={style.selectTextInput}  style={{ height: "100%", width: "100%", border: "0px"}} placeholder="search" onChange={(ev)=> {handleSearch(ev.target.value)}}/>
                            </div>
                        </div>
                        
                    </div>
                    <div className={style.selectSearchOptions}>
                    {
                        items && items.length > 0 && items.map((item)=> {
                        return <div className={style.selectSearchRow} onClick={()=>{handleSelect(item)}}>
                            {item[otherProps.showKey]}
                        </div>
                        })
                    }
                    {
                        (!items || items.length ==0) && (
                            <div className="pt-4">
                                No items to display
                            </div>
                        )
                    }
                    </div>
                </div>
            }>
                {label != "" && <label className={style.selectInputLabel}>{label}</label> }
                <div className={style.selectSearchSelectInput} style={{width: `${otherProps.width-35}px`, border: (showDropdown)? "1px solid #6B53AE": "none"}}>
                   
                   {
                    (defaultVal == "" ) && (
                        <div className={style.selectInputContainer}>
                            <div className={style.selectInputContainerText}>{placeholder}</div>
                            <div> <img src={arrowDisabled} /></div>
                        </div>
                    ) }

                    {
                    (defaultVal != "" && !editable) && (
                        <div className={style.selectInputContainer}>
                            <div className={style.selectInputContainerText}>{defaultVal}</div>
                            <div onClick={()=>{makeEditable(true)}}> <BiEdit /></div>
                        </div>
                    ) }

{
                    (defaultVal != "" && editable) && (
                        <div className={style.selectInputContainer}>
                            <div className={style.selectInputContainerText}>{defaultVal}</div>
                            <div onClick={()=>{makeEditable(true)}}><BiEdit /></div>
                        </div>
                    ) }
                </div>
            </TooltipView>
            
        </>
    )
}


SelectwithSearch.defaultProps = {
    label:"",
    placeholder: "Select Item",
    muliple: false,
    defaultValue: "",
    valueKey: "value",
    showKey: "value",
    size: "default",
    width: 100,
    data: [],
    onChange: ()=>{},
    hint:false,
    serverSideSearch: false,
    onServerSideSearch: ()=>{}
}

export default SelectwithSearch