import { FaBeer } from 'react-icons/fa';
import OrganisationSettings from "../sections/Organisation/Settings/Settings"
import Organisation from "sections/Organisation/Organisation"
import CreateHostOrganisationLandscape from '../sections/Organisation/CreateHostOrganisationLandscape';
import Index from '../sections/Sources/Index';
import Azure from '../sections/Integration/Azure/Azure';
import Overview2 from '../sections/Overview/Overview';
import GitRepo from '../sections/Integration/GitRepo/GitRepo';
import APM from '../sections/Integration/APM/APM';
import Service from '../sections/Service/Service';
import ServiceDetails from '../sections/Service/ServiceDetails/ServiceDetails';
import ServiceFix from '../sections/Service/ServiceDetails/ServiceFix';
import Resources from '../sections/Resource/Resources';
import ResourceDetails from '../sections/Resource/ResourceDetails/ResourceDetails';
import Alerts from '../sections/Alerts/Alerts';
import Reports from '../sections/Reports/Reports';
import ResolvedIndex from '../sections/Resource/Fix/Index';
import Unresolved from '../sections/Resource/Fix/Unresolved/Unresolved';
import ServiceSuccess from '../sections/Service/ServiceDetails/ServiceSuccess';
import ResourceCompliances from '../sections/Resource/Compliances/ResourceFix';
import Reportlist from '../sections/Reports/ReportList/ReportList';
import NewIntegration from 'sections/Integration/NewIntegration/NewIntegration';
import Changes from 'sections/Changes/Changes';
import SettingsPlan from 'sections/SettingsPlan/SettingsPlan';
import Onboarding from 'pages/onboarding/Onboarding';
   


export const routes = [

   

   
    {
        path: "/onboarding",
        name: "OnBoarding",
        component: <Onboarding/> ,
        icon: <FaBeer />,
        isPrivate: false
    },
    
    {
        path: "/overview",
        name: "Overview",
        component: < Overview2 />,
        icon: <FaBeer />,
        isPrivate: false
    },

    {
        path: "/neworg",
        name: "New Org",
        component: <CreateHostOrganisationLandscape />,
        icon: <FaBeer />,
        isPrivate: false
    },

    {
        path: "/alerts",
        name: "Alerts",
        component: <Alerts/>,
        icon: <FaBeer />,
        isPrivate: false
    },
    {
        path: "/settings",
        name: "Organisation Settings",
        component: <OrganisationSettings/> ,
        icon: <FaBeer />,
        isPrivate: false
    },

    {
        path: "/integrations",
        name: "Integrations",
        key: "integrations",
        component: <Index/>,
        icon: <FaBeer />,
        isPrivate: false
    },
    {
        path: "/services",
        name: "Services",
        component: < Service />,
        icon: <FaBeer />,
        isPrivate: false
    },
    {
        path: "/changes",
        name: "Changes",
        component: < Changes />,
        icon: <FaBeer />,
        isPrivate: false
    },
   

    {
        path: "/organisations",
        name: "Organisations",
        component: < Organisation />,
        icon: <FaBeer />,
        isPrivate: false
    },

    {
        path: "/create/organisation",
        name: "organisations",
        component: < Organisation />,
        icon: <FaBeer />,
        isPrivate: false
    },
   
    {
        path: "/services/:serviceId/details",
        name: "Services",
        component: <ServiceDetails/> ,
        icon: <FaBeer />,
        isPrivate: false
    },
    {
        path: "/services/:serviceId/fix",
        name: "Service Fix",
        component: <ServiceFix/> ,
        icon: <FaBeer />,
        isPrivate: false
    },
    {
        path: "/services/:serviceId/fix-success",
        name: "Service Success",
        component: <ServiceSuccess/> ,
        icon: <FaBeer />,
        isPrivate: false
    },
    {
        path: "/organisation/settings",
        name: "Organisation Settings",
        component: <OrganisationSettings/> ,
        icon: <FaBeer />,
        isPrivate: false
    },
    {
        path: "/resources",
        name: "Resources",
        component: <Resources/> ,
        icon: <FaBeer />,
        isPrivate: false
    },
    {
        path: "/resources/resolve",
        name: "Overview",
        component: <ResolvedIndex/> ,
        icon: <FaBeer />,
        isPrivate: false
    },

    {
        path: "/resources/resolve/:platformId/details",
        name: "Overview",
        component: <Unresolved/>,
        icon: <FaBeer />,
        isPrivate: false
    },

    {
        path: "/resources/compliances/details",
        name: "Overview",
        component: <ResourceCompliances/>,
        icon: <FaBeer />,
        isPrivate: false
    },
   

    {
        path: "/reports",
        name: "Report",
        component: <Reports/> ,
        icon: <FaBeer />,
        isPrivate: false
    },
    {
        path: "/reports/:reportId/details",
        name: "Reports",
        component: <Reportlist />,
        icon: <FaBeer />,
        isPrivate: false
    },
    {
        path: "/changeyourplan",
        name: "ChangePlan",
        component: <SettingsPlan />,
        icon: <FaBeer />,
        isPrivate: false
    },

    
    


    
   
  ];