import React from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "../config/routes";
import { useId } from "react";
import Overview from "../sections/Overview/Overview";
import ResourceDetails from "sections/Resource/ResourceDetails/ResourceDetails";
import NewIntegration from "sections/Integration/NewIntegration/NewIntegration";
import Azure from "sections/Integration/Azure/Azure";
import APM from "sections/Integration/APM/APM";


const RoutesList = () => {

    

    return (
        <Routes>
                {
                routes
                .filter((route) => !route.isPrivate)
                .map((route, index) => {
                    return (
                        <Route
                            path={route.path}
                            element={route.component}
                            key={route.key ? route.key : "route-"+index }
                        />
                )})
                }


                <Route path="/resources/:resourcesId/details" element={<ResourceDetails/>} >
                    <Route path="edit" element={<></>} />
                </Route>
                <Route path="/integrations/new/sources" element={ <NewIntegration/> } >
                    <Route path=":sourceId/add" element={<></>} />
                </Route>

                <Route path="/integrations/azure/:id/details" element={<Azure/> } >
                    <Route path="action/:action" element={<></>} />
                </Route>
                <Route path="/integrations/aws/:id/details" element={ <Azure/> } >
                    <Route path="action/:action" element={<></>} />
                </Route>

                <Route path="/integrations/github/:id/details" element={ <APM/> } >
                    <Route path="action/:action" element={<></>} />
                </Route>
                <Route path="/integrations/bitbucket/:id/details" element={ <APM/> } >
                    <Route path="action/:action" element={<></>} />
                </Route>

                <Route path="/integrations/apm/:id/details" element={ <APM/> } >
                    <Route path="action/:action" element={<></>} />
                </Route>
               
                

                <Route
                    path="*"
                    element={<Overview />}
                    key={"overview"}
                />
        </Routes>

    )
}

export default RoutesList;