
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import DashboardBody from "pages/newlayout/DashboardBody";
import IncidentTable from "components/incidentTable/IncidentTable";
import "./Alerts.css";
import { useEffect, useState } from "react";
import GetService from "services/GetServices";
import { API_URL } from "config/config";
import { useSearchParams } from "react-router-dom";
import { getAlertBaseData, parseDataForFilter } from "./AlterUtil";
import Calendar from "components/Calender/Calender";
import AppSettingStore from "stores/NotificationSystem/AppSettingStore";


const Alerts = ()=>{

    const appSettings = AppSettingStore()
    const [alerts, setAlerts] = useState([])
    const [tableInfo, setTableInfo] = useState({ currentPage: 1, totalRows:0, perPage: 10, loading: true , search: "", sort: [], filter:{}, fromTimestamp: appSettings.calender.fromTimestamp, toTimestamp: appSettings.calender.toTimestamp,  }) 
    const [tableFilterData, setTableFilterData] = useState([])
    const [searchParams] = useSearchParams();
  

    const SEVERITY_VALUES = [[10,9,8],[7,6],[5,4],[3,2,1]]

    const parseFilterData = async ()=>{
        let data = await getAlertBaseData()
        setTableFilterData(parseDataForFilter(data))
    }

  
    const getAlerts = (page = 1, search = "", sort= [], filter = {}, fromDate = appSettings.calender.fromTimestamp, toDate = appSettings.calender.toTimestamp)=>{
        let apiFilter = filter
        let apiSearch = [{key: "incident_title", value :search}]
        let service_id = searchParams.get("service")
        if(searchParams.get("active") == 1){
            apiFilter["platform_status"] = [0]
        }

        if(searchParams.get("active") == 0){
            apiFilter["platform_status"] = [1]
        }

        if(service_id != null){
            apiFilter["config_items.service_id"] = [service_id]
        }

    
        if(apiFilter['severity']){
            let values = [];

            apiFilter['severity']?.map(item=>{
                values = [...values, ...SEVERITY_VALUES[item]]
            })

            apiSearch.push({
                key: 'severity',
                condition: 'in',
                value: values
            })
            delete apiFilter['severity']
        }

        let searchJSON = JSON.stringify(apiSearch)
        let filterJSON = JSON.stringify(apiFilter)
        let sortJSON = JSON.stringify(sort)



        console.log({filterJSON, searchJSON, sortJSON})


        let urlParams = `current_page=${page}&search=${searchJSON}&filter=${filterJSON}&sort=${sortJSON}&startTime=${fromDate}&endTime=${toDate}`
        console.log({urlParams})
        GetService(API_URL + `/api/v1/incidents?${urlParams}`).then(response=>{
            let data = response.data.data;
            setAlerts(data.items);
            setTableInfo({...tableInfo, ...{loading: false, totalRows: data.total_count, currentPage: page, search: search, sort: sort, filter: filter }})

        })
    }



    const onRefresh = ()=>{
        setTableInfo({ currentPage: 1, totalRows:0, perPage: 10, loading: true , search: "", sort: [], filter:{}})
        getAlerts();
    }
    const onFilterApply = (data)=>{
        setTableInfo({...tableInfo, ...{filter: data}})
        getAlerts(1, "", [], data)
    }


    const onSearch = (keyword)=>{
        getAlerts(1, keyword)
    }
    

    const onPageChange = (page) => {
        setTableInfo({...tableInfo, ...{loading: true}})
        getAlerts(page, tableInfo.search, tableInfo.sort, tableInfo.filter)
    }

    const onSort = (key, direction) =>{
        setTableInfo({...tableInfo, ...{sort: [{key: key, order:direction}]}})
        getAlerts(tableInfo.currentPage, tableInfo.search, [{key: key, order:direction}], tableInfo.filter)
    }

    

    const onDateChange = (fromDate, toDate, fromTimestamp, toTimestamp)=>{
        appSettings.setCalender(fromDate, toDate, fromTimestamp, toTimestamp)
        setTableInfo({...tableInfo, ...{fromTimestamp: fromTimestamp, toTimestamp: toTimestamp}})
        getAlerts(1, tableInfo.search, tableInfo.sort, tableInfo.filter, fromTimestamp, toTimestamp)
    }

    useEffect(()=>{
        parseFilterData()
        getAlerts()
    },[])
   

  

    return(

        <>
            <Breadcrumbs section={["Alerts"]} />
            <DashboardBody>
                <div className='overViewCalender'>
                   <div>
                      <Calendar onClick={onDateChange} fromDate={appSettings.calender.fromDate} toDate={appSettings.calender.toDate}  />
                   </div>
                </div>
                <IncidentTable 
                    data={alerts} 
                    currentPage={tableInfo.currentPage} 
                    totalRows={tableInfo.totalRows} 
                    perPage={tableInfo.perPage} 
                    onChangePage={onPageChange}
                    onRefresh={onRefresh}
                    onSearch={onSearch}
                    filterData={tableFilterData}
                    onFilterApply={onFilterApply}
                    onSort={onSort}

    
                />
            </DashboardBody>
        </>
    )
}

export default Alerts