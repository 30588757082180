import { useEffect, useState } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import DashboardBody from '../../pages/newlayout/DashboardBody';
import './Reports.css';
import { RiDonutChartFill, RiFileChartLine, RiFilePaper2Line, RiPercentLine } from 'react-icons/ri';
import Card from '../../components/Card/Card';
import * as echarts from 'echarts';
import Table from '../../components/Table/Table';
import ReactECharts from 'echarts-for-react';
import { Link } from 'react-router-dom';
import GetService from 'services/GetServices';
import { API_URL } from 'config/config';
import Avatar from 'components/Avatar/Avatar';
import { getProgressOption, getServiceDistributionOption } from './ReportUtil';
import { CHART_COLORS } from 'config/constants';
import SimpleToolTip from 'components/Tooltip/SimpleToolTip';

function Reports() {

    const [appliedPolicies, setAppliedPolicies] = useState([])
    const [appliedPoliciesTableInfo, setAppliedPoliciesTableInfo] = useState({totalRows: 0 , currentPage: 1, loading: true, search: "", sort:[], filter :{}})
    const [policyDistribution, setPolicyDistribution] = useState({compliant: 0, total: 0})
    const [ciDistribution, setCiDistribution] = useState({compliant: 0, total: 0, percentage: 0})
    const [serviceChartOptions, setServiceChartOptions] = useState({})

    



    const getAppliedPolicyList = (page = 1, search = "", sort = [])=>{
        // let searchJSON = JSON.stringify([{key:"plaform_policy.policy_mapping_name", value:search}])
        let searchJSON = JSON.stringify([])
        GetService(API_URL + `/api/v1/policy/applied/paginated/list?current_page=${page}&search=${searchJSON}`).then(response=>{
            let data = response.data.data;
            setAppliedPolicies(data.items)
            setAppliedPoliciesTableInfo({...appliedPoliciesTableInfo, ...{loading: false, totalRows: data.total_count, currentPage: data.current_page }})
        })
    }

    const onPageChage = (page)=>{
        getAppliedPolicyList(page, appliedPoliciesTableInfo.search, appliedPoliciesTableInfo.sort)
    }

    const onSearch = (search)=>{
        getAppliedPolicyList(1, search, appliedPoliciesTableInfo.search, appliedPoliciesTableInfo.sort)
        setAppliedPoliciesTableInfo({...appliedPoliciesTableInfo, ...{search: search}})

    }

   
    useEffect(() => {
        // Initialize ECharts instance
        let myChart = null
        GetService(API_URL + "/api/v1/policy/applied/overview").then(response=>{
            let data = response.data.data
           
            setPolicyDistribution({
                compliant: data.policy_distribution?.compliant,
                total: data.policy_distribution?.total,
            })

            setCiDistribution({
                compliant: data.ci_distribution?.compliant,
                total: data.ci_distribution?.total_count,
                percentage: ((data.ci_distribution?.compliant / data.ci_distribution?.total_count) * 100).toFixed(2)
            })
            let option = getProgressOption(data.ci_distribution?.compliant, data.ci_distribution?.total_count)
            let progressbar = document.getElementById('progressbar')
            
            if( progressbar){
                myChart = echarts.init(progressbar);
                myChart.setOption(option);
            }
            



            let tempServiceResources = []
            let charIndex = 0
            for (let key in data.service_distribution) {
                tempServiceResources.push({
                    value: data.service_distribution[key], 
                    name: key,
                    itemStyle: {
                        color: CHART_COLORS[charIndex]
                    },
                })
                charIndex++
                
            }
            console.log({tempServiceResources})
            let serviceOptions = getServiceDistributionOption(tempServiceResources)
            setServiceChartOptions(serviceOptions)

         
        })

        getAppliedPolicyList()
      
        // Clean up the chart when the component unmounts
        return () => {
            myChart?.dispose();
        };
    }, []);

    const columns = [
        {
            name: 'POLICY',
            sortable: true,
            selector: (row) => (
                        
                        <Link to={`/reports/${row.policy_platform_id}/details`} style={{textDecoration: "none"}}>
                            <span className='table-span ops-common-hover'>
                                    {row.plaform_policy?.policy_mapping_name}
                            </span>
                        </Link>
                    
            ),
            width: "300px",
        },
        {
            name: 'OWNER',
            selector: row=>{
                return(
                    row.owner.first_name != "" ? <Avatar username={`${row.owner.first_name} ${row.owner.last_name}`} /> : <span className="no-value-span">Not assigned</span> 
                )
            },
            width: "250px",
        },
        {
            name: 'PLATFORMS',
            width: "180px",
            selector: row => {
                return(
                        <>
                        {
                            <div className='d-flex'>
                                <img src={`/images/connector-images/${row.plaform?.platform_type?.icon}`} className='br-img-rounded' style={{width: "24px", height: "24px", marginRight: "10px"}}/>
                                <span className='integration-table-name'>{row.plaform?.name}</span>
                            </div> 

                        }
                            
                        </>
                )
            }
        },
        {
            name: 'COMPLIANCE',
            selector: row =>  (row.compliant_count != 0 ?  ((row.compliant_count/row.total_cis)*100 ).toFixed().toString() + "%": "0%"),
            sortable: true,
            center: true,
            width: "180px",
        },
        {
            name: 'DESCRIPTION',
            selector: row => ( <SimpleToolTip tooltip={row.plaform_policy?.policy_mapping_description}>{row.plaform_policy?.policy_mapping_description}</SimpleToolTip> )
        },
    ];





    



    return (
        <>
            <Breadcrumbs section={["Reports"]} />
            <DashboardBody style={{ background: "#F4F6FC" }}>
                <Card title={<span> <RiFilePaper2Line fontSize={24} color='#6B53AE' style={{ marginRight: "8.2px" }} /> Policy  Overview</span>} type="full">

                    {policyDistribution.total > 0 && <>
                        
                        <div className="row" style={{ marginLeft: "9px", marginRight: "9px", marginBottom: "43px", marginTop: "20px" }}>
                        <div className="col-md-4 ">
                            <Card type={'full'} className={"cardCss "}>
                                <Card.CardBody >
                                    <div className="op_d-flex flex-row gap-2 align-items-center reportHeader">
                                        <div className="">
                                            <RiPercentLine fontSize={24} color='#6B53AE' />
                                        </div>
                                        <div className="pt-2">
                                            <h2 className='title'>Overall Compliance</h2>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: "36px" }}>
                                        <span className='loaderPercentage'>{ciDistribution.percentage}%</span>
                                        <div id="progressbar" style={{ width: '100%', height: '40px' }}></div>
                                        <span className='loaderPercentagesmall'>{ciDistribution.compliant} out of {ciDistribution.total}</span>
                                    </div>
                                </Card.CardBody>
                            </Card>
                        </div>
                        <div className="col-md-4">
                            <Card type={'full'} className={"cardCss"}>
                                <Card.CardBody>
                                    <div className="op_d-flex flex-column">
                                        <div className="op_d-flex flex-row gap-2 align-items-center reportHeader">
                                            <div className="">
                                                <RiDonutChartFill fontSize={24} color='#6B53AE' />
                                            </div>
                                            <div className="pt-2">
                                                <h2 className='title'>Complaint resources by Service</h2>
                                            </div>
                                        </div>
                                        <div className="row" style={{ paddingTop: "24px" }}>
                                            <div className="col">
                                                <ReactECharts
                                                    option={serviceChartOptions}
                                                    style={{ height: '100px' }}
                                                />
                                            </div>
                                            <div className="col op_d-flex flex-column" style={{ marginTop: "0px" }}>

                                                {serviceChartOptions?.series && serviceChartOptions?.series[0]?.data?.map(item=>{
                                                    return(
                                                        <div>
                                                            <div className='coloindication mt-16'>
                                                            <span className='colo2' style={{ backgroundColor: item.itemStyle.color }}></span>
                                                            <div style={{ minWidth: '100px' }}>
                                                                <span className='text-left'>{item.name}</span>
                                                            </div>
                                                            {/* <span><img src={Expand} /></span> */}
                                                    </div>
                                                </div>
                                                    )
                                                })}

                                            </div>
                                        </div>
                                    </div>
                                </Card.CardBody>
                            </Card>

                        </div>
                        <div className="col-md-4">
                            <Card type={'full'} className={"cardCss"}>
                                <Card.CardBody>
                                    <div className="op_d-flex flex-row gap-2 align-items-center reportHeader">
                                        <div className="">
                                            <RiFileChartLine fontSize={24} color='#6B53AE' />
                                        </div>
                                        <div className="pt-2">
                                            <h2 className='title'>Overall Compliance</h2>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: "45px" }}>
                                        <div className='outofPer'>
                                            <span className='largetext mr-10'>{policyDistribution.compliant}</span><span className='smalltext mr-10'>out of</span><span className='largetext'>{policyDistribution.total}</span>
                                        </div>
                                    </div>
                                </Card.CardBody>
                            </Card>
                        </div>
                    </div>
                    
                    </>}
                   
                    

                    <div>
                        <Table 
                            containerStyle={{border: "0px"}} 
                            tableStyle={{marginTop: policyDistribution.total > 0 ? "20px": "0px"}}
                            loading={appliedPoliciesTableInfo.loading} 
                            control={false}
                            columns={columns} 
                            data={appliedPolicies} 
                            totalRows={appliedPoliciesTableInfo.totalRows}
                            currentPage={appliedPoliciesTableInfo.currentPage}
                            onPageChage={onPageChage}
                            onSearch={onSearch}
                            pagination={true}/>
                    </div>

                </Card>
            </DashboardBody>
        </>
    );
}

export default Reports;
