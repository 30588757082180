import React, {Component, useEffect, useState} from "react";
import Sidebar from "./Sidebar"
import Header from "./Header"
import style from "./Dashboard.module.css"
import leftArrow from "../../assets/icons/arrows/arrow-left-s-line.svg"
import rightArrow from "../../assets/icons/arrows/arrow-right-s-line.svg"
import RoutesList from "../../routes/SubRoutes";
import GetService from '../../services/GetServices'
import { API_URL } from '../../config/config'
import { Navigate, useLocation } from "react-router-dom";
import WaitingList from "../../sections/WaitingList/WaitingList";
import EmptySerivce from "../../sections/Service/EmptyService";
import GeneralLayout from "../general-layout/GeneralLayout";
import NewOrganisation from "sections/Organisation/NewOrganisation";
import TagManager from "react-gtm-module";
import Onboarding from "pages/onboarding/Onboarding";
import AppSettingStore from "stores/NotificationSystem/AppSettingStore";
import { datadogRum } from "@datadog/browser-rum";



const Dashboard = ()=>{

    const [expand, setExpand] = useState(false)
    const [organizations, setOrganizations] = useState([])

    const [currentOrg, setCurrentOrg] = useState({name:"no organization"})
    const [organizationStatus, setOrganizationStatus] = useState(8)


    const [timelineStage, setTimelimeStage] = useState(2)
    const [organizationWaitTitle, setOrganizationWaitTitle] = useState("Pending for verification")
    const [organizationMessage, setOrganizationMessage] = useState("")
    const [organizationErrorMessage, setOrganizationErrorMessage] = useState("")
    const [organizationhasError, setOrganizationHasError] = useState(false)
    const [services, setServices] = useState([])
    const [pageLoaded, setPageLoaded] = useState(false)
    const [user, setUser] = useState({})


    const location = useLocation()
    const appSettings = AppSettingStore()
    
   
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
    }
    window.dataLayer = window.dataLayer || [];
    


       
    if(process.env.NODE_ENV === "production" && process.env.REACT_APP_ENABLE_DATADOG == "true"){
        datadogRum.init({
            applicationId:  process.env.REACT_APP_DD_APP_ID,
            clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
            // `site` refers to the Datadog site parameter of your organization
            // see https://docs.datadoghq.com/getting_started/site/
            site:  process.env.REACT_APP_DD_SITE,
            service:  process.env.REACT_APP_DD_SERVICE,
            env: process.env.NODE_ENV,
            // Specify a version number to identify the deployed version of your application in Datadog
            // version: '1.0.0', 
            sessionSampleRate: 100,
            sessionReplaySampleRate: 20,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
        })
    }
    
    const updateNavDisable = (status, hideSidebar)=>{
        setOrganizationStatus(status)
        setPageLoaded(true)
    }

    const GetUserInfo = () => {
        GetService(API_URL + `/api/v1/base/user/info`).then(response=>{
            if (response.data?.code == 200 ){
                let user = response.data.data?.user
                let organisation = response.data.data?.organisations ?? []
                setUser({
                    user: user,
                    loggedIn: true
                })
                appSettings.setUser(user.first_name, user.last_name, response.data.data?.org_role_id)


                if(process.env.NODE_ENV === "production" && process.env.REACT_APP_ENABLE_DATADOG == "true"){
                    window.dataLayer.push({
                            userName: `${user.first_name} ${user.last_name}`,
                            userID: user.user_id,
                            userEmail: user.user_email,
                            
                    });

                    datadogRum.setUser({
                        id: user.user_id,
                        name: `${user.first_name} ${user.last_name}`,
                        email: user.user_email,
                    })
                    
                    window.userName = `${user.first_name} ${user.last_name}`
                    window.userID = user.user_id
                    window.userEmail = user.user_email
                    
                    if(process.env.REACT_APP_ENABLE_GTM == "true"){
                        TagManager.initialize(tagManagerArgs)
                    }

                }

                
                setOrganizations(organisation)
               
                if(organisation.length == 0){
                    console.log("no organisation")
                    setOrganizationStatus(0)
                    return
                }
                
                getActiveOrg()
            }
        })
    }


    const getActiveOrg = ()=>{
        GetService(API_URL + `/api/v1/base/active/organisation`).then(response=>{
            let  org = response.data?.data?.organisation
            let  orgStatus = response.data?.data?.organisation?.status ?? 0
            setCurrentOrg(org)
            console.log({activeOrg: orgStatus})
                // 0 -  pending for approval  1
                // 1  -  error                4
                // 2  -  approved             1
                // 3  -  deployment start     1
                // 4  -  ready                2
                // 5  -  disabled             3
                // 6  -  rejected             3
               


                // frontend values
                //  0 - no organisation
                //  1 - pending / deployment
                //  2 - error
                //  3 - ready
                //  5 - disabled
                //  6 - rejected
            if( [0,2,3].includes(orgStatus)){
                
                setOrganizationWaitTitle("Creating Your Organization")
                setOrganizationMessage("We're in the process of creating your organization. You can wait a few minutes, or we'll notify you via email once it's complete.")

                switch (orgStatus) {
                    case 0:
                        break;
                    case 2:
                        setTimelimeStage(2)
                        break;
                    default:
                        setTimelimeStage(2)
                        break;
                }

                setOrganizationStatus(1)
                setPageLoaded(true)
                return 
            }



            if(orgStatus  == 1){
                setOrganizationWaitTitle("Organization Creation failed")
                setOrganizationMessage("An error occurred during the organization creation, and we've informed the OnePane team. They'll reach out to you shortly.")
                setOrganizationHasError(true)
                setOrganizationStatus(2)
                setOrganizationErrorMessage(org.message)
                setPageLoaded(true)
                return 
            }

            if(orgStatus  == 10){
                setOrganizationWaitTitle("Organization Disabled")
                setOrganizationMessage("This organization has beed disabled")
                setOrganizationHasError(true)
                setOrganizationStatus(5)
                setPageLoaded(true)
                return 
            }

            if(orgStatus == 6){
                setOrganizationWaitTitle("Organization Rejected")
                setOrganizationMessage("Admin rejected the organization")
                setOrganizationHasError(true)
                setOrganizationStatus(6)
                setPageLoaded(true)
                return 
            }


            if( orgStatus == 4 || orgStatus == 5){
                setOrganizationStatus(3)
            }


            GetService(API_URL + `/api/v1/service/list`).then(response=>{
                setServices(response.data?.data?.items ?? [])
                setPageLoaded(true)
            })

            // setPageLoaded(true)
            // GetService(API_URL + `/api/v1/base/organisation/${org.org_id}/select`).then(()=>{
            //     setPageLoaded(true)
            //     GetService(API_URL + `/api/v1/service/list`).then(response=>{
            //         setServices(response.data?.data?.items ?? [])
                    
            //     })
            // })
        })
    }





    useEffect(()=>{
        console.log({location})
        GetUserInfo()
    },[])


   




    const expandMenu = ()=>{
        localStorage.setItem("menu-expand", !expand)
        setExpand(!expand)
    }

    useEffect(()=>{
        console.log({kk:localStorage.getItem("menu-expand")})
        //setExpand(localStorage.getItem("menu-expand") ?? false)
    },[])





    console.log({organizationStatus, pageLoaded})

    if (organizationStatus == 0){
        return(
            <Onboarding user={user.user} />
        )
    }


    if (location.pathname == "/create/organisation"){
        return(
            <NewOrganisation  user={user.user} org={currentOrg} orgList={organizations} updateNavDisable={updateNavDisable} />
        )    
    }

   

return(
        <>

        {([1,2,5,6].includes(organizationStatus) && !(location.pathname.includes("/integrations") || location.pathname.includes("/settings") || location.pathname.includes("/organisations")) && pageLoaded == true) && <>
            <GeneralLayout showProfile={true} user={user.user} org={currentOrg} orgList={organizations}>
                <WaitingList imgUrl={organizationhasError ? "/images/icons/error.png": "/images/icons/warning-check.png"} text={organizationWaitTitle} timelineStage={timelineStage} hasError={organizationhasError} errorMessage={organizationErrorMessage} message={organizationMessage} /> 
            </GeneralLayout>
        </>
        }
        {[1,3].includes(organizationStatus) && <>
            <div className={style.dashboardLayout}>
                <div className={`${style.dashboardLeft} ${ expand ? style.expand : style.collapse}`}>
                    <div className={style.sidebarActionContainer} onClick={expandMenu}>
                        <img src={expand ? leftArrow : rightArrow} className={style.expandCollapseIcon} />
                    </div>
                    <Sidebar key={"sidebar"} expand={expand}/>
                </div>
                <div className={style.dashboardRight}>
                    <Header user={user.user} org={currentOrg} orgList={organizations} />
                    {/* {JSON.stringify(services)} 
                    ------
                    {JSON.stringify(pageLoaded)} */}
                    <div className="flex-grow-1">
                       
                    {([1,3].includes(organizationStatus) && services.filter(item=>item.name != 'shared_resources').length == 0 && location.pathname.includes("/integrations")) && pageLoaded == true &&  <RoutesList /> }
                    {([1,3].includes(organizationStatus) && services.filter(item=>item.name != 'shared_resources').length == 0 && location.pathname.includes("/settings")) && pageLoaded == true &&  <RoutesList /> }
                    {([1,3].includes(organizationStatus) && services.filter(item=>item.name != 'shared_resources').length == 0 && location.pathname.includes("/organisations")) && pageLoaded == true &&  <RoutesList /> }
                    {([3].includes(organizationStatus) && services.filter(item=>item.name != 'shared_resources').length == 0 ) && pageLoaded == true && !location.pathname.includes("/integrations") && <EmptySerivce username={user.user?.first_name} />}  
                    { [1,3].includes(organizationStatus)&& services.filter(item=>item.name != 'shared_resources').length > 0 && <RoutesList />}
                    {location.pathname == "/" && <Navigate replace to="/overview" />}  
                    {/* <RoutesList /> */}
                    </div>
                </div>
            </div>
        </>}
    </>
    )
}

export default Dashboard