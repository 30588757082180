


import { Row,Col } from "react-bootstrap"
import "./WaitingList.css"
import Timeline from "../../components/Timeline/Timeline"

const WaitingList = ({imgUrl, hasError, text, errorMessage, timelineStage, message = ""})=>{
    return(
        <div className="waiting-list">
            <Row>
                <Col>
                    <div className="content">
                        <div className="">
                            <img src={imgUrl}/>
                        </div>
                        
                        <div>
                            <h1 className="message">{text}</h1>
                            <p className="wait-list-message">{message}</p>
                        </div>
                        { hasError == false && (<>
                            {process.env.REACT_APP_SAAS_MODE == 1 && <Timeline className="line" timelines={["Created", "Provisioning", "Ready"]} stage={timelineStage} style={{width: "380px",margin: "41px auto auto calc(50% - 137px)"}} /> }
                            {process.env.REACT_APP_SAAS_MODE == 0 && <Timeline className="line" timelines={["Creating", "Deploying", "Ready"]} stage={timelineStage}  style={{width: "380px",margin: "41px auto auto calc(50% - 137px)"}}  /> }
                            </> )
                        }
                        
                        {/* { hasError == true && <p className="wait-list-error">{errorMessage}</p> } */}
                        
                    </div>
                </Col>
            </Row>
        </div>
    )
}


WaitingList.defaultProps = {
    imgUrl : "/images/icons/warning-check.png",
    hasError: false,
    text: "You request received",
    errorMessage: "",
    timelineStage: 2,
}

export default WaitingList