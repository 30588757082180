import style from "./SearchControl.module.css"
import filterIcon from "./assets/filter.svg"
import refreshIcon from "./assets/refresh.svg"
import sortIcon from "./assets/sort.svg"

const Button = ({ icon, children, ...props }) => {
    return (
        <>
            <button className={style.button} {...props}>
                <span className={style.buttonIcon}>
                    <img src={icon} />
                </span>
                {children}
            </button>
        </>
    )
}

const SearchControl = ({actions, showFilter, showSort, showRefresh , onRefresh, onSearch}) => {

    let timeout = null;

    const onSearchChange = (e)=>{
        console.log({timeout})
        clearTimeout(timeout)
        timeout = setTimeout(()=>{
           onSearch(e.target.value)
        },1000)
    }

    return (
        <div className={`${style.searchControlBox}`}>
            <div className={`${style.searchControlContainer}`}>
                <div style={{flexGrow: 1}}>
                    <div className={style.opInputGroup}>
                        <input type="text" onChange={onSearchChange} className={`${style.searchInput}`} placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" />
                    </div>
                </div>
                {actions && <>
                    <div className={`${style.searchControlAction}`}>
                        <div>
                           {showRefresh &&  <Button icon={refreshIcon} onClick={onRefresh}>Refresh</Button>} 
                        </div>
                        <div>
                            {showSort &&  <Button icon={sortIcon}>Sort</Button> }
                        </div>
                        <div>
                            {showFilter &&  <Button icon={filterIcon}>Filter</Button> }
                        </div>
                    </div>
                </>}
            </div>

        </div>
    )
}

SearchControl.defaultProps = {
    actions: true,
    showFilter : true,
    showSort: true,
    showRefresh: true,
    onSearch:()=>{},
    onRefresh: ()=>{},
    onSort: ()=>{},
    onFilter: ()=>{}
}

export default SearchControl