import React, { useState, useEffect } from 'react';
import { DtPicker } from 'react-calendar-datetime-picker';
import 'react-calendar-datetime-picker/dist/style.css';
import calender from './Calender.module.css';
import "./Calendercustom.css";
import calenderImg from "./Icons/calicon.svg";
import Button from 'components/Elements/Button/Button';


const Calendar = ({ fromDate, toDate, dateTimeInterval, onClick }) => {
  const [date, setDate] = useState(null);
  
 

  const calDefaultValue = {
    from: { year: fromDate.getFullYear() , month: fromDate.getMonth() + 1 , day: fromDate.getDate() },
    to: { year: toDate.getFullYear() , month: toDate.getMonth() + 1 , day: toDate.getDate() },
  }



  const handleAction = () => {
   
    if (date && date.from && date.to) {

      let fDate = new Date(date.from.year, date.from.month - 1, date.from.day, date.from.hour ?? 0, date.from.minute ?? 0)
      let tDate = new Date(date.to.year, date.to.month - 1, date.to.day, date.to.hour ?? 0, date.to.minute ?? 0)
      let fTimestamp = fDate.getTime()
      let tTimestamp = tDate.getTime()
      onClick(fDate, tDate, fTimestamp, tTimestamp)
    } else {
      console.error("Please select a valid date range before clicking Apply.");
    }
  };

 
  return (
    <>
      <div className={calender["main_cal"]}>
        <div>
          <div>
            <DtPicker
              onChange={setDate}
              initValue={calDefaultValue}
              showWeekend
              isRequired
              fromLabel="FROM"
              toLabel="TO"
              type="range"
              inputClass={`${calender["custom_input"]} ${calender["custom_inputheader"]}`}
              headerClass={`${calender["custom_header"]}`}
              monthsClass={`${calender["custom_month"]}`}
              withTime
              showTimeInput
              daysClass={`${calender["custom_day"]}`}
              yearListStyle={`${calender["test"]}`}
            />
          </div>
        </div>
        <div className={calender.icon_calender}>
          <img src={calenderImg} alt='iconcalender' />
        </div>
        <div>
          <Button onClick={handleAction}>Apply</Button>
        </div>
      </div>
    </>
  );
};


Calendar.defaultProps = {
  dateTimeInterval: 24,
  fromDate: new Date(new Date().getTime() - (24 * 60 * 60 * 1000)),
  toDate: new Date(),
  onClick: () => { }
};

export default Calendar;
