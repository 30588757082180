
import "./Button.css"

const Button = (props)=>{

    const { icon, size, buttonType, ButtonStyle, className, type, children, ...otherProps } = props

    return(
        <button type={buttonType} className={`op-button ${size} ${ButtonStyle} ${type} ${className} ${otherProps.disabled == true ? "disabled":""}` } {...otherProps} onClick={ otherProps.disable == true ? ()=>{} : otherProps.onClick}>
           {children} {icon != "" && <span className="op-button-icon">{icon}</span> }
        </button>
    )
}

Button.defaultProps = {
    icon: "",
    type : "primary",
    ButtonStyle : "solid",
    size: "",
    buttonType: "submit"

}


export default Button