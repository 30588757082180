// import {  Modal } from "react-bootstrap";
import { useNotifications } from "reapop";
import './EditMapRepo.css';
import { MdOutlineLaunch } from "react-icons/md";
import Modal from "../Modal/Modal";
import Button from "../../Elements/Button/Button";
import { BiErrorCircle, BiPencil } from "react-icons/bi";
import Select from "../../V1/Select/Select";

const EditMapRepo = ({show,title="Edit", logo, description="Altering this will have no impact on your cloud tenant whatsoever", className, ...props }) => {



    //console.log({props})
    return (
        <>
            <Modal show={show} style={{ width: "514px" }}>
                <Modal.Title>
                    <div className="content-padding">

                        <div className="headingDiv">
                            <div className='logoos'>
                                <BiPencil/>
                            </div>
                            <div className='header'>
                                <span className="mainHeading">
                                    {title}
                                </span>
                                <span className="subHeading">
                                    {description}
                                </span>
                            </div>
                        </div>
                    </div>
                </Modal.Title>
                <Modal.Body>
                    <div>
                        <div className="Content">


                            <div className="formControls">
                                <label htmlFor="lname">Resource</label><span className="hint"><BiErrorCircle color="rgba(255, 153, 0, 0.40)"/></span><br />
                                <input type="text" id="lname" name="lname" defaultValue={props.language} disabled/>
                            </div>
                            <div className="formControls">
                                <label htmlFor="lname">Original Type</label><span className="hint"><BiErrorCircle color="rgba(255, 153, 0, 0.40)"/></span><br />
                                <input type="text" id="lname" name="lname" defaultValue={props.originalType} disabled/>
                            </div>

                            <div className="formControls">
                                <label htmlFor="lname">Onepane Type</label><span className="hint"><BiErrorCircle color="rgba(255, 153, 0, 0.40)"/></span><br />
                                <input type="text" id="lname" name="lname" defaultValue={props.onepaneType} disabled/>
                            </div>
                            <div className="formControls">
                                <label htmlFor="lname">Service</label><span className="hint"><BiErrorCircle color="rgba(255, 153, 0, 0.40)"/></span><br />
                                <input type="text" id="lname" name="lname" defaultValue={props.service} disabled/>
                            </div>
                            <div className="formControls" style={{marginTop:'20px'}}>
                                <Select label={"Resource"} placeholder={"vm-onepane-demo-eastus-001"} hint={true} />
                            </div>

                        </div>
                        <div style={{ float: "right", marginTop: "40px" }}>
                            <Button type="danger" ButtonStyle="transparent" className="mx-3" onClick={() => props.onHide()}>Cancel</Button>
                            <Button onClick={() => props.onSave()} >Save</Button>

                        </div>


                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditMapRepo;