import { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import launchIcon from "assets/icons/util/launch.svg";
import { useParams } from "react-router-dom";
import Table from "components/Table/Table";
import GetService from "services/GetServices";
import { API_URL } from "config/config";
import SimpleToolTip from "components/Tooltip/SimpleToolTip";
import { getAlertBaseData, parseDataForFilter } from "sections/Alerts/AlterUtil";

const Alerts = ({startTime, endTime}) => {
  const [resourceAlerts, setResourceAlerts] = useState([]);
  const [tableInfo, setTableInfo] = useState({ currentPage: 1, totalRows:0, perPage: 10, loading: true , search: "", sort: [], filter:{}})
  const [tableFilterData, setTableFilterData] = useState([])

  const { resourcesId } = useParams();

  const SEVERITY_VALUES = [[10,9,8],[7,6],[5,4],[3,2,1]]



  const parseFilterData = async ()=>{
    let data = await getAlertBaseData()
    setTableFilterData(parseDataForFilter(data))
}

  const getResourceAlerts = (page = 1, search = "", sort= [], filter = {}) => {

    let apiFilter = filter
    let apiSearch = [{key: "incident_title", value :search}]
  

    if(apiFilter['severity']){
        let values = [];

        apiFilter['severity']?.map(item=>{
            values = [...values, ...SEVERITY_VALUES[item]]
        })

        apiSearch.push({
            key: 'severity',
            condition: 'in',
            value: values
        })
        delete apiFilter['severity']
    }

    let searchJSON = JSON.stringify(apiSearch)
    let filterJSON = JSON.stringify(apiFilter)
    let sortJSON = JSON.stringify(sort)


    let urlParams = `current_page=${page}&search=${searchJSON}&filter=${filterJSON}&sort=${sortJSON}&startTime=${startTime}&endTime=${endTime}`
    GetService(API_URL + `/api/v1/resource/${resourcesId}/incidents?${urlParams}`).then(response=>{
      const data = response.data.data;
      setResourceAlerts(data.items);
      setTableInfo({...tableInfo, ...{loading: false, totalRows: data.total_count, currentPage: page, search: search, sort: sort, filter: filter }})
    })


  };

  useEffect(() => {
    parseFilterData()
  }, []);


  useEffect(() => {
    getResourceAlerts();
  }, [startTime, endTime]);



  const onPageChange = (page)=>{
    setTableInfo({...tableInfo, ...{loading: true}})
    getResourceAlerts(page, tableInfo.search, tableInfo.sort, tableInfo.filter)
}



const onRefresh = ()=>{
    setTableInfo({ currentPage: 1, totalRows:0, perPage: 10, loading: true , search: "", sort: [], filter:{}})
    getResourceAlerts();
}
const onFilterApply = (data)=>{
    setTableInfo({...tableInfo, ...{filter: data}})
    getResourceAlerts(1, tableInfo.search, tableInfo.sort, data)
}


const onSearch = (keyword)=>{
  getResourceAlerts(1, keyword, tableInfo.sort, tableInfo.filter)
}


const onSort = (selectedColumn, sortDirection, sortedRows) =>{
    setTableInfo({...tableInfo, ...{sort: [{key: selectedColumn.sortField, order:sortDirection}]}})
    getResourceAlerts(tableInfo.currentPage, tableInfo.search, [{key: selectedColumn.sortField, order:sortDirection}], tableInfo.filter)
}


  const columns = [
    {
      name: "ALERT ID",
      selector: (row) => (
        <span style={{ fontWeight: 700 }} className="ops-common-hover">
          <a
            style={{ color: "#9BA0AF", textDecoration: "none" }}
            href={row.link}
          >
            #OP{row.incident_id}
          </a>
        </span>
      ),
      sortable: true,
      sortField: "incident_id",
      width: "160px",
    },
    {
      name: "ALERT",
      selector: (row) => (
        <div className="ops-common-hover p-1">
          <a style={{ color: "black", textDecoration: "none" }} href={row.incident_link}>
            {row.incident_title}
          </a>
        </div>
      ),
      sortable: true,
      sortField: "incident_title",
    },
    {
      name: "SOURCE",
      selector: (row) => (
        <>
          <SimpleToolTip tooltip={row.config_item?.plaform?.name}>
            <div className="flex">
              <span className="">
                <img
                  src={`/images/connector-images/${row.config_item?.plaform?.platform_type?.icon}`}
                  className="br-img-rounded"
                  style={{ width: "26px", height: "26px", marginRight: "10px" }}
                />
              </span>
            </div>
          </SimpleToolTip>
        </>
      ),
      width: "250px",
    },
    {
      name: "TIME",
      selector: (row) => (
        <span style={{ color: "#9BA0AF" }}>
          {moment(row.time).format("YYYY-MM-DD HH:mm:ss")}
        </span>
      ),
      sortable: true,
      width: "180px",
      sortField: "time",
    },
    {
      name: "ACTION",
      selector: () => (
        <Link to={"/alerts"}>
          <img src={launchIcon} alt="Launch" />
        </Link>
      ),
      sortable: true,
      width: "180px",
      center: true,
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        data={resourceAlerts}
        containerStyle={{ border: "0px" }}
        pagination={true}
        loading={tableInfo.loading}
        totalRows={tableInfo.totalRows}
        onPageChage={onPageChange}
        onSearch={onSearch}
        onRefresh={onRefresh}
        dataTableProps={{sortServer: true, onSort: onSort}}
        filterData={tableFilterData}
        onFilterApply={onFilterApply}
      />
    </>
  );
};

export default Alerts;
