import { RiArrowLeftSLine } from "react-icons/ri"
import style from "./Breadcrumbs.module.css"
import { Link } from "react-router-dom"

const Breadcrumbs = ({section, actions, showHome, showUpgradeMessage})=>{


    return(
        <>
            <div className={style.dashboardBreadcrumbs} >
                <div className={style.breadcrumbsList}>
                    {showHome && <>  <Link to={"/overview"} style={{textDecoration: "none"}}>
                            <div className={style.breadcrumb} key={"-1"} >
                                <span className={`${style.breadcrumbIcon} `}><RiArrowLeftSLine/> </span>
                                <span className={`${style.breadcrumbText} `}>{'Overview'}</span>
                            </div>
                        </Link>
                    </>}

                    {section?.map((item,index)=>{
                        if(typeof item == 'string'){
                            return(
                                <div className={style.breadcrumb} key={index}>
                                    <span className={`${style.breadcrumbIcon} ${index == section.length - 1 ? style.breadcrumbIconCurrent : ""}`}><RiArrowLeftSLine/> </span>
                                    <span className={`${style.breadcrumbText} ${index == section.length - 1 ? style.breadcrumbTextCurrent : ""}`}>{item}</span>
                                </div>
                            )
                        }else{
                            return(
                                <Link to={item.path} style={{textDecoration: "none"}}>
                                    <div className={style.breadcrumb} key={index} >
                                        <span className={`${style.breadcrumbIcon} ${index == section.length - 1 ? style.breadcrumbIconCurrent : ""}`}><RiArrowLeftSLine/> </span>
                                        <span className={`${style.breadcrumbText} ${index == section.length - 1 ? style.breadcrumbTextCurrent : ""}`}>{item.name}</span>
                                    </div>
                                </Link>
                            )
                        }
                    })}
                </div>
                <div className={style.dashboardBreadcrumbAction}>
                    {actions}
                </div>
            </div>
            
        </>
    )
}

Breadcrumbs.defaultProps = {
    section : [],
    actions: <></>,
    showHome: true,
}

export default Breadcrumbs