
import pencilIcon from "assets/icons/services/connector-edit-pencil.svg"
import KeyValueLabel from "components/KeyValueLabel/KeyValueLabel"
import Tag from "components/Elements/Tag/Tag"
import moment from "moment"
import { Col, Row } from "react-bootstrap"
import Avatar from "components/Avatar/Avatar"
import SimpleToolTip from "components/Tooltip/SimpleToolTip"
import { Tooltip } from "react-tippy"
import { getAlternativeName, getCriticalityStatus } from "utils/util"
import { getOnepaneTypeCIName } from "utils/OnepaneType"
import launch from "assets/icons/util/launch.svg"
import { Link } from "react-router-dom"

const Overview = ({ resourceData, onEditPlatform, OnResourceCrossLaunchClick }) => {


    const getPlatformByCategory = (data, categories = [], placeholder = <span className="connector-not-detected">Not Detected</span>, ) => {
        let platform = []
        let tooltip = []
        
    
        data?.map((item)=>{
            if( categories.includes(item.platform.platform_type.category)){
                platform.push(<img src={`/images/connector-images/${item.platform.platform_type.icon}`} className="resource-connector-img" />)
                tooltip.push(
                    <div className="platform-container">
                        <div className="platform-img "><img src={`/images/connector-images/${item.platform.platform_type.icon}`} className="resource-connector-img"/></div>
                        <div className="platform-name span-ellipsis">{item.platform.name}</div>
                        { item.platform.platform_type.category != 1 && <div> <img className="ops-cursor" onClick={()=>onEditPlatform(item.platform.platform_id)} src={pencilIcon} /></div> }
                        <div> <img className="ops-cursor" onClick={()=>OnResourceCrossLaunchClick(item.platform.platform_id, item.identifier)} src={launch} /></div>
                    </div>
                )
            }
        })
        if (platform.length == 0){
            return placeholder
        }
        return (
            <>
                <Tooltip 
                    interactive
                    html={(
                        <div className="resource-platform-container">
                           {tooltip}
                        </div>
                    )}
                >
                    {platform}
                    {data?.length > 3 && <span className="platform-see-more">+ {data?.length - 3} more</span>}
                </Tooltip>
            
            </>
        )
    }


    const getAlterName = (tags, type)=>{
        let othername = getAlternativeName(tags, type)
         

        return (
             <>
               { othername != "" && <KeyValueLabel label={"Alternate Name"} value={<>
                    <SimpleToolTip tooltip={othername}>
                        <span className="span-ellipsis" style={{ color: "#222127", width: "187px", display: "block" }}>{othername}</span>
                    </SimpleToolTip>
                </> }/>
                }
             </>           
        )
    }





    return (
        <>
            <div className="service-overview">

                <div>
                    <div className="service-details">

                        <Row>
                            <Col md={4}>
                                <div>
                                    <KeyValueLabel label={"Resource Name"} value={<>
                                        <SimpleToolTip tooltip={resourceData?.name}>
                                            <span className="span-ellipsis" style={{ color: "#222127", width: "187px", display: "block" }}>{resourceData?.name}</span>
                                        </SimpleToolTip>
                                    </> }/>

                                    <KeyValueLabel label={"Environment"} value={
                                  <span style={{ color: "#222127" }}>
                                  {resourceData?.tags?.Env ?? <span className="no-value-span">no-env</span>}
                                </span>
                                
                                    } />

                                    <KeyValueLabel label={"Owner"} value={resourceData.tags?.Owner ? <Avatar username={resourceData.tags?.Owner} imageUrl="" customText="Not assigned" /> : <span className="no-value-span">Not assigned</span>} />

                                    <KeyValueLabel label={"Service"} value={ <Link to={`/services/${resourceData?.service?.service_id}/details`}><Tag type={"success"}>{resourceData?.service?.name}</Tag></Link> } />

                                    <KeyValueLabel label={"Criticality"} value={getCriticalityStatus(resourceData?.tags?.Criticality)} />

                                    <KeyValueLabel label={"Total Alerts"} value={<span style={{ color: "#222127" }}>{resourceData?.total_alerts ?? 0}</span>} />
                                </div>
                            </Col>

                            <Col md={4}>
                                <div>
                                    {getAlterName(resourceData?.tags, resourceData?.plaform?.type)}
                                    <KeyValueLabel label={"Last Change"} value={<span style={{ color: "#A4A3A9" }}>{moment(resourceData?.updated_at).fromNow()}</span>} />
                                    <KeyValueLabel label={"Health"} value={<>
                                        <Tag type="success">{resourceData?.tags?.Criticality ?? <span>no-health</span>} </Tag>
                                    </>} />
                                    <KeyValueLabel label={"Cloud Service"} value={<>
                                        <Tag type={"primary"}>{getOnepaneTypeCIName(resourceData.onepane_type?.toString())}</Tag>
                                    </>} />
                                    <KeyValueLabel label={"Cloud Tenant"} value={<>
                                        {getPlatformByCategory(resourceData?.config_mapping, [1])}
                                    </>} />

                                    <KeyValueLabel label={"APM"} value={<>
                                        {getPlatformByCategory(resourceData?.config_mapping, [2])}
                                    </>} />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div>
                                    <KeyValueLabel label={"Type"} value={
                                        <SimpleToolTip tooltip={resourceData?.original_type}>
                                            <span style={{ color: "#222127", width: "190px" }} className="span-ellipsis">{resourceData?.original_type}</span>
                                        </SimpleToolTip>
                                    }/>
                                    <KeyValueLabel label={"Monitoring"} value={<>
                                        {getPlatformByCategory(resourceData?.config_mapping, [1,2], "")}
                                  
                                    </>} />
                                    <KeyValueLabel label={"Github"} value={<>
                                        {getPlatformByCategory(resourceData?.config_mapping, [3])}
                                    </>} />
                                    <KeyValueLabel label={"DevOps"} value={<>
                                        {getPlatformByCategory(resourceData?.config_mapping, [4])}
                                    </>} />
                                    <KeyValueLabel label={"Estimated Cost"} value={<>
                                        {(resourceData.pricing_details && resourceData.pricing_details?.length > 0) ? <>
                                            <span style={{color: "#2DA077",fontWeight: "700"}}>$
                                                <span style={{color: "#4D4C52"}}>
                                                    {parseFloat(resourceData.pricing_details?.[0]?.total_cost).toFixed(2)}
                                                </span>
                                            </span>
                                            <span style={{fontWeight: "400"}}>/month</span>
                                        </> : <>
                                            <span className="no-value-span">Not Estimated</span>
                                        </> }
                                       
                                    </>} />
                                    
                                </div>
                            </Col>
                        </Row>

                    </div>
                </div>
            </div>


            
            {/* <UserServiceAssign show={showUserAssignModal} serviceId={resource?.service_id} onHide={()=>setShowUserAssignModal(false)}/> */}
        </>


    )


}

export default Overview