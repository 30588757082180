import { Tooltip } from "react-tippy"
import style from "./SimpleToolTip.module.css"



const SimpleToolTip = ({tooltip, disabled = false, children})=>{

    return(
        <Tooltip html={<><span style={{padding: "0px 10px"}}>{tooltip}</span></>} disabled={disabled}>
            {children}
        </Tooltip>
    )

}

export default SimpleToolTip