import React, { useEffect, useState } from 'react';
import "./Notification.css";
import { RiFlag2Line, RiNotification3Line } from 'react-icons/ri';
import NotificationMessage from '../Elements/NotificationMessage/NotificationMessage';
import { Link } from 'react-router-dom';
import activity from '../../../src/assets/icons/message/activities-avatar.svg';
import newnotification from "assets/icons/message/newnotification.svg"
import moment from 'moment';
function Notification(props) {

  const [notificationList, setNotificationList] = useState([]);
  
  useEffect(()=>{
    setNotificationList(props.notifications)
  },[props])


  return (
    <>
      <div className="notifications-box">
        <div className="col-12">
          <div className="row">
            <div className="col-2">
              {props.icon}
            </div>
            <div className="col-10 p-0">
              <h3 className='notification-headings'>{props.title}</h3>
            </div>
          </div>

          {notificationList.map((message, index) => (
            
            <NotificationMessage
                key={index}
                message={message.message}
                seen = {message.time}
                messageicon={newnotification} 
            />
            
        ))}
          <div className="row">
            <div className="col-12">
              <Link to={""}><p className='more'>More</p></Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Notification.defaultProps = {
  icon: <RiNotification3Line fontSize={24} color='#6B53AE' />,
  title: 'Notifications',
  notifications: [],
  messageicon:""
}

export default Notification;
