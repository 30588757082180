import Avatar from "react-avatar";
import Table from "components/Table/Table";
import Tag from "components/Elements/Tag/Tag";
import { RiPencilLine } from "react-icons/ri";
import Button from "components/Elements/Button/Button";
import { useEffect, useState } from "react";
import GetService from "services/GetServices";
import { API_URL } from "config/config";
import moment from "moment";
import { AiOutlineUserAdd } from "react-icons/ai";
import SettingInviteUser from "components/modals/SettingInviteUser/SettingInviteUser";
import { useNotifications } from "reapop";
import PostService from "services/PostServices";
import ConfirmDialog from "utils/ConfirmDialog";
import { CapitalizeEachWord } from "utils/util";
import RBACWrapper from "components/RBACWrapper/RBACWrapper";

const Users = () => {
    const [shoInviteModal, setShowInviteModal] = useState(false);
    const [members, setMembers] = useState([]);
    const [modalData, setModalData] = useState({});
    const [selectRole, setSelectRole] = useState(1)
    const [formData, setFormData] = useState({ account: "", email: "", role: 1 });

    const { notify, dismissNotification } = useNotifications();

    const [tableInfo, setTableInfo] = useState({totalRows: 0 , currentPage: 1, loading: true, search: "", sort:[], filter :{}})
    let timeout;
    
    const showNotification = (text, status = "success") => {
        notify(text, status, {
            dismissAfter: 1500
        });
    }


    const getMembers = (page = 1, search = "", sort = [], filter = {})=>{
        
        let searchJSON = JSON.stringify([{key:"first_name", value:search}, {key:"last_name", value:search}])
        let sortJSON = JSON.stringify(sort)
        let filterJSON = JSON.stringify(filter)

        GetService(API_URL + `/api/v1/base/organisation/user/list?current_page=${page}&search=${searchJSON}&sort=${sortJSON}&filter=${filterJSON}`).then(response => {
            let  data = response.data.data
            setMembers(data.items);
            setTableInfo({...tableInfo, ...{currentPage: page, loading: false, totalRows: data.total_count, sort:sort, filter: filter }})
        })
    }


    const onPageChange = (page)=>{
        setTableInfo({...tableInfo, ...{loading: true, currentPage: page}})
        getMembers(page, tableInfo.search, tableInfo.sort, tableInfo.filter)
    }
   
    const onSearch = (keyword)=>{
        setTableInfo({...tableInfo, ...{search: keyword}})
        getMembers(1, keyword, tableInfo.sort, tableInfo.filter)
    }

    const sortTable = (selectedColumn, sortDirection, sortedRows)=>{
        getMembers(1, "", [{key: selectedColumn.sortField, order: sortDirection}], tableInfo.filter)
        setTableInfo({...tableInfo, ...{sort: [{key: selectedColumn.sortField, order: sortDirection}]}})
    }

    const refreshResource = ()=>{
        getMembers()
    }

    const onFilter = (data)=>{
        // console.log({data})
        setTableInfo({...tableInfo, ...{filter: data.value}})
        getMembers(1,"", tableInfo.sort, data)
    }


    const columns = [
        {
            name: 'NAME',
            selector: row => {
                let userName = CapitalizeEachWord(row.first_name + " " + row.last_name) 
                return (
                    <>
                        <Avatar name={userName} size="28" round={true} />
                        <span style={{ marginLeft: "5px" }}>{userName}</span>
                    </>
                )
            },
            sortable: true,
        },
        {
            name: 'MAIL ID',
            selector: row => row.user_email,
            sortable: true,
            // width: "250px",
        },
        {
            name: 'ROLE',
            selector: row => <p className="roleValues">
               {row?.org_mappings &&  row.org_mappings[0] && row.org_mappings[0].role == 1 && 'Admin'}
               {row?.org_mappings &&  row.org_mappings[0] && row.org_mappings[0].role == 2 && 'Contributor'}
               {row?.org_mappings &&  row.org_mappings[0] && row.org_mappings[0].role == 3 && 'Reader'}
            </p>,
            sortable: true,
            width: "150px",
            center: true
        },
        {
            name: 'VERIFIED',
            selector: row => <Tag type={row.IsVerified === true ? "success" : "warning"}>{row.IsVerified === true ? "Yes" : "No"}</Tag>,
            sortable: true,
            width: "160px",
            center: true
        },
        {
            name: 'STATUS',
            selector: row =>{
               
                let today =  moment();
                let lastLogin = moment(row.last_login, "YYYY-MM-DD");
                let days = today.diff(lastLogin, "days")
               
                return (
                        <Tag type={days < 90 ? "success" : "warning"}>{days < 90 ? "Active" : "Inactive"}</Tag>
                )
            },
            sortable: true,
            width: "150px",
            center: true
        },
        
    ];

    if (RBACWrapper.hasPermission("SETTINGS_EDIT_MEMBER")) {
        columns.push({
            name: 'ACTION',
            selector: row => {
                return (
                    
                    <span className="member-edit-icon" onClick={() => editUser(row)}>
                        <RiPencilLine style={{ marginBottom: "4px" }} />
                    </span>
                )
            },
            width: "120px",
            center: true,
        });
    }

   

    useEffect(() => {
        getMembers()
    }, []);

    const inviteUserClick = () => {
        setModalData({
            title: "Invite User",
            subtitle: "Invite a user to this organization",
            action: 1,
            data: {first_name: "", last_name: "", user_email: ""}
        })
        setShowInviteModal(true);
    }

    const editUser = (data) => {
        const action = data.IsVerified === true ? 2 : 3;
        setModalData({
            title: "Change Role",
            subtitle: "Changing roles could result in reduced privileges and limited access to certain screens",
            action: action,
            data: data,
        })
        setShowInviteModal(true);
    }

    const sendInvite = (data, action) => {
        console.log({data, action})
        let postForm = new FormData()
        
        if (action === 1) {
            postForm.append("email", data.email)
            postForm.append("name", data.name)
            postForm.append("role", data.role)
            PostService(API_URL + `/api/v1/base/organisation/user/invite`, postForm).then(response=>{
                if(response.data.success == true){
                    setShowInviteModal(false);
                    showNotification("User Invited Successfully");
                    getMembers()
                }
            })
        }
        else if (action === 2) {
            PostService(API_URL + `/api/v1/base/organisation/user/update?user_id=${data.user_id}&role_id=${data.role}`, postForm).then(response=>{
                if(response.data.success == true){
                    setShowInviteModal(false);
                    showNotification("User Updated Successfully");
                    getMembers()
                }
            })
        }
    }

    const deleteUser = (user_id)=>{
        ConfirmDialog(<p>Are you sure you want to remove this user? <br/>This action is irreversible</p>, "Remove", ()=>{
            PostService(API_URL + `/api/v1/base/organisation/user/delete?user_id=${user_id}`).then(response=>{
                showNotification("User removed Successfully")
                setShowInviteModal(false);
                getMembers()
            })
        }, { confirmButton:{ className:`settings-member-reject-btn`}, cancelButton:{className:`settings-member-reject-cancel-btn`, label: "Cancel"}})
    }

    const extraAction = (
        <div className="d-inline-block">
            <Button onClick={inviteUserClick}>
                <AiOutlineUserAdd size={18} style={{ marginRight: "2px", marginBottom: "3px" }} /> Invite Member
            </Button>
        </div>
    );

    return (
        <div className="h-100">
            <Table
                border={false}
                containerStyle={{ border: "0px"}}
                columns={columns}
                data={members}
                customAction={RBACWrapper.hasPermission("SETTINGS_INVITE_MEMBER") ? extraAction: "" }
                loading={tableInfo.loading}
                pagination={true}
                totalRows={tableInfo.totalRows}
                onPageChage={onPageChange}
                onSearch={onSearch}
                onRefresh={refreshResource}
                dataTableProps={{sortServer: true, onSort: sortTable}}
                filterData={[{label: "Type", key:"user_organisation_mappings.role", values:[ {label: "Admin", value: 1 }, {label: "Contributor", value: 2 }, {label: "Reader", value: 3 }] }]}
                onFilterApply={onFilter}

            />
            <SettingInviteUser
                onSave={(formData, action) => sendInvite(formData, action)}
                data={modalData.data}
                show={shoInviteModal}
                title={modalData.title}
                action={modalData.action}
                description={modalData.subtitle}
                onHide={() => setShowInviteModal(false)}
                onDelete={deleteUser}
            />

        </div>
    );
}

Users.defaultProps = {};

export default Users;
