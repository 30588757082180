

import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Card from "components/Card/Card";
import ResolvedUnresolvedTimeLine from "components/ResolvedUnresolvedTimeLine/ResolvedUnresolvedTimeLine";
import DashboardBody from "pages/newlayout/DashboardBody";
import Button from "components/Elements/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import Table from "components/Table/Table";
import { useEffect, useState } from "react";
import Avatar from "components/Avatar/Avatar";
import SucessfullySolvedComponent from "components/TableEmptySuccess/TableEmptySuccess";
import HowToLink from "components/V1/HowToLink/HowToLink";
import SelectwithSearch from "components/V1/SelectwithSearch/Select";
import SimpleToolTip from "components/Tooltip/SimpleToolTip";
import { getResourceBaseData, loadResources, parseDataForFilter } from "../ResourceUtil";
import { getCriticalityStatus, getENVStatus, getUserListForOption } from "utils/util";
import PostService from "services/PostServices";
import { API_URL } from "config/config";

const ResourceFix = ()=> {

    const [state, setState] = useState(0)
    const [resources, setResources] = useState([])
    const [compaliceFixes, setCompaliceFixes] = useState({})
    const [confirmTableData, setConfirmTableData] = useState([])
    const [tableInfo, setTableInfo] = useState({totalRows: 0 , currentPage: 1, loading: true, search: "", sort:[], filter :{}})
    const [dataForFilter, setDataForFilter] = useState([])
    const [dataForDropDown, setDataForDropDown] = useState([])

    let timeout;


    const navigate = useNavigate()

    const onCancel = ()=>{
        navigate(`/resources`)
      }

    const parseFilterData = async ()=>{
        let filterData = await getResourceBaseData()
        let users = await getUserListForOption()
        filterData["users"] = users
        setDataForDropDown(filterData)
        setDataForFilter(parseDataForFilter(filterData))
    }

    const getResources = (page = 1, search = "", sort=[], filter={})=>{
        loadResources(page, search, sort, filter).then(response=>{
            let data = response.data.data
            setResources(data.items)
            setTableInfo({...tableInfo, ...{loading: false, totalRows: data.total_count, currentPage: page , search,sort, filter}})
        })
        parseFilterData()
    }

    const onPageChange = (page)=>{
        setTableInfo({...tableInfo, ...{loading: true}})
        getResources(page, tableInfo.search, tableInfo.sort, tableInfo.filter)
    }
   
    const onSearch = (keyword)=>{
        setTableInfo({...tableInfo, ...{search: keyword}})
        getResources(1, keyword, tableInfo.sort, tableInfo.filter)
    }

    const sortTable = (selectedColumn, sortDirection, sortedRows)=>{
        getResources(1, tableInfo.search, [{key: selectedColumn.sortField, order: sortDirection}])
        setTableInfo({...tableInfo, ...{sort: [{key: selectedColumn.sortField, order: sortDirection}]}})
    }

    const refreshResource = ()=>{
        getResources()
    }

    const onFilter = (data)=>{
        setTableInfo({...tableInfo, ...{filter: data}})
        getResources(1, tableInfo.search, tableInfo.sort, data)
    }

    const onUpdateComplaince = (data, key, value)=>{
        let ciId = data.ci_id
        let tempCompaliceFixes = JSON.parse(JSON.stringify(compaliceFixes))
        if(!tempCompaliceFixes[ciId]){
            tempCompaliceFixes[ciId] = {}
            tempCompaliceFixes[ciId]["ci_id"] = ciId;
            tempCompaliceFixes[ciId]["name"] = data.name;
            tempCompaliceFixes[ciId]["plaform"] = data.plaform;
            tempCompaliceFixes[ciId]["original_type"] = data.original_type;
            tempCompaliceFixes[ciId]["service_name"] = data.service.name;
            tempCompaliceFixes[ciId]["env"] = data.tags?.Env;
            tempCompaliceFixes[ciId]["owner"] = data.tags?.Owner;
            tempCompaliceFixes[ciId]["criticality"] = data.tags?.Criticality;
        }
        tempCompaliceFixes[ciId][key] = value
        setCompaliceFixes(tempCompaliceFixes)
    }

    const onConfirmComplaince = ()=>{
        let tempConfirmTableData = []
        for (const key in compaliceFixes) {
            tempConfirmTableData.push(compaliceFixes[key])
        }
        setConfirmTableData(tempConfirmTableData)
        console.log({compaliceFixes})
        setState(1)
    }

    const onSaveComplaince = ()=>{
        PostService(API_URL + `/api/v1/resource/tagging/bulk/fix`,{changes: confirmTableData}).then(response=>{
            console.log({resources})
            setState(2)
        })
    }

    useEffect(()=>{
        getResources();
    },[])
    


    const tableColumns = [
        {
            name: 'RESOURCE NAME',
            selector: row => {
                return ( 
                    <>
                        <Link to={`/resources/${row.ci_id}/details`} className="table-link-title">
                            <SimpleToolTip tooltip={row.name}>
                                <span className="table-span ops-common-hover" style={{marginLeft: "5px"}}>{row.name}</span>
                            </SimpleToolTip>
                        </Link>
                    </>
                )
            },
            sortable: true,
            width: "250px",
        },
        {
            name: 'CLOUD',
            selector: row => {
                return (
                    <SimpleToolTip tooltip={row.plaform?.name}>
                        <span><img src={`/images/connector-images/${row.plaform.platform_type.icon}`} style={{width:"30px", height:"30px"}} className="br-img-rounded"/></span>
                    </SimpleToolTip>
                )
            },
            width: "100px",
        },
        {
            name: 'TYPE',
            selector: row => <SimpleToolTip tooltip={row.original_type}>{row.original_type}</SimpleToolTip> ,
            width: "200px"
        },
        {
            name: 'SERVICES',
            width: "200px",
            selector: row =>{
               return(
                <>
                  { state == 0 && <><SelectwithSearch  width={200} data={dataForDropDown.services} showKey={"label"} placeholder={"Choose"}  defaultValue={ compaliceFixes[row.ci_id] ? compaliceFixes[row.ci_id]["service_name"] : row.service.name} onChange={(data)=> { onUpdateComplaince(row, "service_name", data.label)}}/></> }
                  { state == 1 && <><span className="table-text">{row.service_name}</span></> }
                
                </>
               )
            }
        },
        {
            name: 'ENV',
            selector: row => {
                return(
                    <>
                    { state == 0 && <><SelectwithSearch   width={200} data={dataForDropDown.envs} showKey={"label"} placeholder={"Choose"} defaultValue={compaliceFixes[row.ci_id] ? compaliceFixes[row.ci_id]["env"] : row?.tags?.Env} onChange={(data)=> { onUpdateComplaince(row, "env", data.value)}} /> </> }
                    { state == 1 && <>  {getENVStatus(row.env)} </> }
                    </>
            )},
            width: "200px",
        },
        {
            name: 'OWNER',
            selector: row => {
                console.log({fd: row.owner})
                return(
                    <>
                    { state == 0 && <> <SelectwithSearch  width={200} data={dataForDropDown.users} placeholder={"Choose"} defaultValue={ compaliceFixes[row.ci_id] ? compaliceFixes[row.ci_id]["owner"] : row?.tags?.Owner} showKey={"label"} onChange={(data)=> { onUpdateComplaince(row, "owner", data.label)}} /></> }
                    { state == 1 && <> {row.owner != undefined ? <Avatar username={row.owner} imageUrl="" /> : <span className="no-value-span">Not assigned</span> } </>  }
                    </>
            )},
            width: "200px",
        },
        {
            name: 'CRITICALITY',
            selector: row =>  {
            return(
                <>
                    { state == 0 && <> <SelectwithSearch width={200} data={dataForDropDown.criticality} placeholder={"Choose"} showKey={"label"} defaultValue={ compaliceFixes[row.ci_id] ? compaliceFixes[row.ci_id]["criticality"] : row?.tags?.Criticality} onChange={(data)=> { onUpdateComplaince(row, "criticality", data.value)}}/> </> }
                    { state == 1 && <> {getCriticalityStatus(row.criticality)} </> }
                </>
            )},
            width: "200px",
        },
        
    ]


   
    return(
        <>
            <Breadcrumbs section={[{name:"Resource", path:"/resources"}, "Fix Compliance"]} />
            <DashboardBody>

                <ResolvedUnresolvedTimeLine
                    stage1="Fix resource compilance"
                    isStage2Completed={state == 1 || state == 2  ? true: false} 
                    isStage2Successful={state == 2 ? true: false}
                />
            
                <div style={{marginTop: "20px"}}>
                    {state == 0 && (<Table
                        columns={tableColumns}
                        data={resources}
                        loading={tableInfo.loading}
                        totalRows={tableInfo.totalRows}
                        onPageChage={onPageChange}
                        onSearch={onSearch}
                        onRefresh={refreshResource}
                        dataTableProps={{sortServer: true, onSort: sortTable}}
                        filterData={dataForFilter}
                        onFilterApply={onFilter}
                        pagination={true}
                    />)}

                 {state == 1 && (<Table
                    control={false}
                    columns={tableColumns}
                    data={confirmTableData}
                    loading={false}
                    pagination={true}
                    paginationServer={false}
                />)}
                
                {state == 2 && (<Table
                    columns={tableColumns}
                    data={[]}
                    loading={false}
                    pagination={true}
                    noDataComponent={<SucessfullySolvedComponent heading={"Compliance is changed"} subheading={"Changes made will be reflected in few minutes."}  buttonText={"Back to resource list"} link={"/resources"} />}
                    
                />)}
            </div>

            { (state == 0 || state == 1) && (<div style={{marginTop: "20px"}}>
                <Card type={"full"}>
                    <Card.CardBody>
                        <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                            <HowToLink />
                        </div>
                        <div className="">
                            <Button type={"danger"} ButtonStyle="transparent" onClick={onCancel}>Cancel</Button>
                            {state == 1 && <Button  ButtonStyle="transparent" onClick={()=>setState(0)}>Previous</Button>} 
                            {state == 0 && <Button  onClick={onConfirmComplaince}>Next</Button>} 
                            {state == 1 && <Button  onClick={onSaveComplaince}>Save</Button>} 
                        </div>
                    </div>
                    </Card.CardBody>
                </Card>
            </div>)}

            </DashboardBody>
        </>
    )

}

export default ResourceFix