
import React, { forwardRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import SelectSearch from 'react-select-search';
import ReactTip from '@jswork/react-tip'
import { AiOutlineInfoCircle } from "react-icons/ai";

import './SelectComponent.css';
import { RiArrowDropDownLine } from 'react-icons/ri';



const SelectComponent = forwardRef(({ label, selectStyle, style, ...props }, ref) => {
  

  const colourStylesRow = {
    dropdownIndicator: styles => ({
      ...styles,
      color: '#FFAE12',
    })
  }


  return (
    <>
      {selectStyle == "normal" && <>
        <div className='op-select'>
          <label className="label-main">{label}</label>
          {props.hint != "" && <>
            <ReactTip title={props.hint} placement={'up-right'}>
              <AiOutlineInfoCircle color='rgba(255, 153, 0, 0.40)' style={{ marginLeft: "2px", cursor: "pointer" }} />
            </ReactTip>

          </>}
       <div className="position-relative">
       <SelectSearch styles={colourStylesRow} ref={ref} style={{ width: "100% !important", ...style }} {...props} /><div className='dropDownArrow'><RiArrowDropDownLine  size={30} color={"kightgreu"}/></div>
       </div>
        </div>
      </>}
      {selectStyle == "inline" && <>
        <div className='op-inline-select'>
          <Col lg={"12"} className={"ops-input-select float-end mx-1"}>
            <div className='select-container'>
              <div className='select-label'>{label ? <span lg={props.sizeL} className={"f-12px fc-font-mild-grey fw-normal"}>{label}:</span> : ""}</div>
              <div className='select-control'>
                <select ref={props.ref} className="form-select b-0 f-12px" style={{ padding: "0px" }} aria-label="Default select example" defaultValue="{}" onChange={props.onChange}>
                  {
                    props?.options?.map((item, index) => {
                      return (
                        <option value={`${item.value}`} key={index}>{item.label}</option>
                      )
                    })
                  }

                </select>
              </div>
            </div>
          </Col>
        </div>

      </>}

    </>
  );
})

SelectComponent.defaultProps = {
  selectStyle: "normal",
  hint: ""
}

export default SelectComponent;


