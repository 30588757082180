import React from "react"
import price from "./Plancard.module.css"
import { BiCheck } from "react-icons/bi";
import Button from "../Elements/Button/Button";
import { RiArrowRightLine, RiPhoneLine } from "react-icons/ri";



function Plancard({ contactusButton, deployLink, choosePlanButton, planFor, plan, description, planPrice, features, showFeatures, isMonthly, discount }) {
    return (
        <><div className={price.Pricelist}>
            <div className={price.forStartup}>
                <div className={price.square}></div>
                <div className={price.content}>
                    <div className={price.planFor}>
                        {planFor}
                    </div>
                    <div className={price.plan}>
                        {plan}
                    </div>
                </div>
            </div>
            <div className={price.description}>
                {description}
            </div>
            <div className={price.Plancost}>
                <div className={`${price.planPrice}`}>{"$" + planPrice ? planPrice : ""}</div>
                <div className={`${price.duration}`}>{isMonthly ? "/monthly" : ""}</div>
            </div>



            <div className={`${price.features}`}>
                <div className={`${price.whatsInclude}`}>
                    What’s included
                </div>

                {features.map((feature, i) => (
                    <div className={`${price.fetureslist}`} key={i}>
                        <div className={`${price.tickMArk}`}>
                            <BiCheck size={26} />
                        </div>
                        <div className={`${price.Featurepoints}`}>
                            {feature.name}
                        </div>
                    </div>
                ))}
            </div>

            {deployLink ? <div className={`${price.deployLink}`}>

                <span> Hosted Deployment </span> <RiArrowRightLine size={28} style={{ paddingLeft: '10px' }} />
            </div> : null}


            {choosePlanButton ? <div className={`${price.chooseThePlan}`}>
                <Button style={{ backgroundColor: '#F4F4F5', height: '43px', color: '#6B53AE', width: '185px', }}>Choose This Plan</Button>
            </div> : null}

            {contactusButton ? <div>
                <Button style={{ padding: '4px 15px',height: '43px',margin:' 0px 0px '}}><RiPhoneLine size={20} style={{ marginRight: '10px' }} />Contact Us</Button>
            </div> : null}

        </div>




        </>
    )
}

Plancard.defaultProps = {
    planFor: "For Startups",
    plan: "Basic",
    description: "Show social proof notifications to increase leads and sales.",
    monthlyPrice: 0,
    YearlyPrice: 0,
    isMonthly: true,
    features: [],
    showFeatures: true,
    planPrice: "",
    choosePlanButton: "",
    deployLink: false,
    contactusButton: false
}

export default Plancard