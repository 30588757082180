import Avatar from "react-avatar";
import Table from "components/Table/Table";
import Tag from "components/Elements/Tag/Tag";
import { RiPencilLine } from "react-icons/ri";
import Button from "components/Elements/Button/Button";
import { useEffect, useState } from "react";
import GetService from "services/GetServices";
import { API_URL } from "config/config";
import moment from "moment";
import { AiOutlineUserAdd } from "react-icons/ai";
import SettingInviteUser from "components/modals/SettingInviteUser/SettingInviteUser";
import { useNotifications } from "reapop";
import PostService from "services/PostServices";
import ConfirmDialog from "utils/ConfirmDialog";
import { CapitalizeEachWord } from "utils/util";
import RBACWrapper from "components/RBACWrapper/RBACWrapper";

const Invites = () => {
    const [shoInviteModal, setShowInviteModal] = useState(false);
    const [members, setMembers] = useState([]);
    const [modalData, setModalData] = useState({});
    const [selectRole, setSelectRole] = useState(1)
    const [formData, setFormData] = useState({ account: "", email: "", role: 1 });

    const { notify, dismissNotification } = useNotifications();

    const [tableInfo, setTableInfo] = useState({totalRows: 0 , currentPage: 1, loading: true, search: "", sort:[], filter :{}})
    let timeout;
    
    const showNotification = (text, status = "success") => {
        notify(text, status, {
            dismissAfter: 1500
        });
    }


    const getInvitedMembers = (page = 1, search = "", sort = [], filter = {})=>{
        
        let searchJSON = JSON.stringify([{key:"name", value:search}])
        let sortJSON = JSON.stringify(sort)
        let filterJSON = JSON.stringify(filter)

        GetService(API_URL + `/api/v1/base/organisation/user/invite/list?current_page=${page}&search=${searchJSON}&sort=${sortJSON}&filter=${filterJSON}`).then(response => {
            let  data = response.data.data
            setMembers(data.items ?? []);
            setTableInfo({...tableInfo, ...{currentPage: page, loading: false, totalRows: data.total_count, sort:sort, filter: filter }})
        })
    }


    const onPageChange = (page)=>{
        setTableInfo({...tableInfo, ...{loading: true, currentPage: page}})
        getInvitedMembers(page, tableInfo.search, tableInfo.sort, tableInfo.filter)
    }
   
    const onSearch = (keyword)=>{
        setTableInfo({...tableInfo, ...{search: keyword}})
        getInvitedMembers(1, keyword, tableInfo.sort, tableInfo.filter)
    }

    const sortTable = (selectedColumn, sortDirection, sortedRows)=>{
        getInvitedMembers(1, "", [{key: selectedColumn.sortField, order: sortDirection}], tableInfo.filter)
        setTableInfo({...tableInfo, ...{sort: [{key: selectedColumn.sortField, order: sortDirection}]}})
    }

    const refreshResource = ()=>{
        getInvitedMembers()
    }

    const onFilter = (data)=>{
        // console.log({data})
        setTableInfo({...tableInfo, ...{filter: data.value}})
        getInvitedMembers(1,"", tableInfo.sort, data)
    }


    const columns = [
        {
            name: 'NAME',
            selector: row => {
                let userName = row.Name[0].toUpperCase() + row.Name.substr(1).toLowerCase()
                return (
                    <>
                        <Avatar name={userName} size="28" round={true} />
                        <span style={{ marginLeft: "5px" }}>{userName}</span>
                    </>
                )
            },
            sortable: true,
        },
        {
            name: 'MAIL ID',
            selector: row => row.UserEmail,
            sortable: true,
            // width: "250px",
        },
        {
            name: 'ROLE',
            selector: row => <p className="roleValues">
               {row.Role == 1 && 'Admin'}
               {row.Role == 2 && 'Contributor'}
               {row.Role == 3 && 'Reader'}
            </p>,
            sortable: true,
            width: "150px",
            center: true
        },
        {
            name: 'INVITED BY',
            selector: row => {
                let userName = CapitalizeEachWord(row.inviter?.first_name + " " + row.inviter?.last_name) 
                return (
                    <>
                        <Avatar name={userName} size="28" round={true} />
                        <span style={{ marginLeft: "5px" }}>{userName}</span>
                    </>
                )
            },
        },
        {
            name: 'INVITATIONS',
            selector: row => {
                return(
                    <>
                        {row.Status == 0 && <Tag type="warning">Pending</Tag>}
                        {row.Status == 1 && <Tag type="success">Accepted</Tag>}
                        {row.Status == 2 && <Tag type="danger">Rejected</Tag>}
                    </>
                    
                )
            },
            sortable: true,
            width: "160px",
            center: true
        },
    ];

    if (RBACWrapper.hasPermission("SETTINGS_EDIT_INVITE")) {
        columns.push({
            name: 'ACTION',
            selector: row => {
                return (
                    <>
                        {row.Status == 0 && <>
                            <span className="member-edit-icon" onClick={() => editUser(row)}>
                                <RiPencilLine style={{ marginBottom: "4px" }} />
                            </span>
                        </>}
                    </>
                )
            },
            width: "120px",
            center: true,
        });
    }

   

    useEffect(() => {
        getInvitedMembers()
    }, []);

  
    const editUser = (data) => {
        let userData = {
            first_name: data.Name,
            last_name: "",
            user_email: data.UserEmail,
            invite_id: data.invite_id,
            org_mappings: [
                { role: data.Role }
            ]
        }
        setModalData({
            title: "Change Role",
            subtitle: "Changing roles could result in reduced privileges and limited access to certain screens",
            action: 3,
            data: userData,
        })
        setShowInviteModal(true);
    }

    const sendInvite = (data, action) => {
        console.log({data, action})
        let postForm = new FormData()
        
        if (action === 1) {
            postForm.append("email", data.email)
            postForm.append("name", data.name)
            postForm.append("role", data.role)
            PostService(API_URL + `/api/v1/base/organisation/user/invite`, postForm).then(response=>{
                if(response.data.success == true){
                    setShowInviteModal(false);
                    showNotification("User Invited Successfully");
                    getInvitedMembers()
                }
            })
        }
        else if (action === 2) {
            PostService(API_URL + `/api/v1/base/organisation/user/update?user_id=${data.user_id}&role_id=${data.role}`, postForm).then(response=>{
                if(response.data.success == true){
                    setShowInviteModal(false);
                    showNotification("User Updated Successfully");
                    getInvitedMembers()
                }
            })
        }
    }
   

  

    const revokeUser = (invite_id)=>{
       
        ConfirmDialog(<p>Are you sure you want to revoke this user invitation? <br/>This action is irreversible</p>, "Revoke", ()=>{
            PostService(API_URL + `/api/v1/base/organisation/user/invite/delete?invite_id=${invite_id}`).then(response=>{
                showNotification("User Invitation Revoked Successfully")
                setShowInviteModal(false);
                getInvitedMembers()
            })
        }, { confirmButton:{ className:`settings-member-reject-btn`}, cancelButton:{className:`settings-member-reject-cancel-btn`, label: "Cancel"}})
    }

  
    return (
        <div className="h-100">
            <Table
                border={false}
                containerStyle={{ border: "0px"}}
                columns={columns}
                data={members}
                loading={tableInfo.loading}
                pagination={true}
                totalRows={tableInfo.totalRows}
                onPageChage={onPageChange}
                onSearch={onSearch}
                onRefresh={refreshResource}
                dataTableProps={{sortServer: true, onSort: sortTable}}
                filterData={[
                        {
                            label: "Type", 
                            key:"Role", 
                            values:[ {label: "Admin", value: 1 }, {label: "Contributor", value: 2 }, {label: "Reader", value: 3 }] 
                        },
                        {
                            label: "Status", 
                            key:"status", 
                            values:[ {label: "Pending", value: 0 }, {label: "Accepted", value: 1 }, {label: "Rejected", value: 2 }] 
                        }
                        ]}
                onFilterApply={onFilter}

            />
            <SettingInviteUser
                onSave={(formData, action) => sendInvite(formData, action)}
                data={modalData.data}
                show={shoInviteModal}
                title={modalData.title}
                action={modalData.action}
                description={modalData.subtitle}
                onHide={() => setShowInviteModal(false)}
                onRevoke={revokeUser}
            />

        </div>
    );
}

Invites.defaultProps = {};

export default Invites;
