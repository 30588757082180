
export const sideMenuIconProps = { color:"#79787D", size:"24px" }

export const connectorRoute = ["", "/integrations/azure","/integrations/apm","", "","","", "/integrations/github", "/integrations/bitbucket", "", "/integrations/aws" ]

export const CRITICALITY_STATUS = {"Low":"success", "Medium":"primary", "High":"warning"}
export const ENV_TYPE = {"Prod":"success", "Dev": "warning", "danger": "primary"}
export const POLICY_STATUS = [ {status:"primary", label: "Creating"}, {status:"success", label: "Applied"}, {status:"danger", label: "Deleting"}, {status:"primary", label: "New"}]

export const CRITICALITIES_FOR_OPTION = [{label: "Critical",value: "Critical"}, {label: "High",value: "High"}, { label: "Medium",value: "Medium" }, {label: "Low",value: "Low"}]

export const CHART_COLORS = ["#6B53AE","#EC8B5E","#2DA077", "#D94F4F","#FDBC3E","#3FA7F2","#3F46F2","#B34CE4","#D478D","#43B2D4","#B2B534"]
export const MONTH_FROM_NUMBER = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]


export const TERMS_OF_SERVICE_URL = "https://www.onepane.ai/terms-of-use";
export const TERMS_AND_CONDITION_URL = "https://www.onepane.ai/terms-of-use";
export const DOC_URL = "https://www.onepane.ai/docs";
export const COOKIE_POLICY_URL = "https://www.onepane.ai/cookie-policy";
export const HELP_CENTER_URL = "https://www.onepane.ai/docs";
export const PRIVACY_POLICY_URL = "https://www.onepane.ai/privacy-policy"
export const LINKEDIN_URL = "https://www.linkedin.com/company/onepaneai";
export const TWITTER_URL = "https://twitter.com/onepaneai";
export const GITHUB_URL = "https://github.com/onepaneai";


