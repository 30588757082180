
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import DashboardBody from "pages/newlayout/DashboardBody";
import "./Changes.css";
import { useEffect, useState } from "react";
import GetService from "services/GetServices";
import { API_URL } from "config/config";
import ChangesTable from "components/ChangesTable/ChangesTable";
import { getChangeBaseData, parseDataForFilter } from "sections/Changes/ChangesUtil";
import { useSearchParams } from "react-router-dom";
import AppSettingStore from "stores/NotificationSystem/AppSettingStore";
import Calendar from "components/Calender/Calender";


const Changes = ()=>{

    const appSettings = AppSettingStore()
    const [changes, setChanges] = useState([])
    const [tableInfo, setTableInfo] = useState({ currentPage: 1, totalRows:0, perPage: 10, loading: true , search: "",  sort: [], filter:{}, fromTimestamp: appSettings.calender.fromTimestamp, toTimestamp: appSettings.calender.toTimestamp, })
    const [tableFilterData, setTableFilterData] = useState([])

    const [searchParams] = useSearchParams();


    const parseFilterData = async ()=>{
        let data = await getChangeBaseData()
        setTableFilterData(parseDataForFilter(data))
    }

    const getChanges = (page = 1, search = "", sort=[], filter = {}, fromDate = appSettings.calender.fromTimestamp, toDate = appSettings.calender.toTimestamp)=>{

        let apiFilter = filter
        let  service_id = searchParams.get("service")
        let apiSearch = [{key: "message", value :search}]

        if( service_id != null){
            apiFilter["config_items.service_id"] = [service_id]
        }

        let searchJSON = JSON.stringify(apiSearch)
        let filterJSON = JSON.stringify(filter)
        let sortJSON = JSON.stringify(sort)

        let urlParams = `current_page=${page}&search=${searchJSON}&filter=${filterJSON}&sort=${sortJSON}&startTime=${fromDate}&endTime=${toDate}`
        GetService(API_URL + `/api/v1/events?${urlParams}`).then(response=>{
            let data = response.data.data;
            setChanges(data.items);
            setTableInfo({...tableInfo, ...{search: search, currentPage: page, loading: false, totalRows: data.total_count, currentPage: page, sort: sort, filter: filter}})

        })
    }




    const onRefresh = ()=>{
        setTableInfo({ currentPage: 1, totalRows:0, perPage: 10, loading: true , search: "", sort: [], filter:{}})
        getChanges();
    }

    const onFilterApply = (data)=>{
        setTableInfo({...tableInfo, ...{filter: data}})
        getChanges(1, "", [], data)
    }

    const onSearch = (keyword)=>{
        getChanges(1, keyword)
    }

    const onPageChange = (page) => {
        setTableInfo({...tableInfo, ...{loading: true}})
        getChanges(page, tableInfo.search, tableInfo.sort, tableInfo.filter)
    }

    const onSort = (key, direction) =>{
        setTableInfo({...tableInfo, ...{sort: [{key: key, order:direction}]}})
        getChanges(tableInfo.currentPage, tableInfo.search, [{key: key, order:direction}], tableInfo.filter)
    }

    const onDateChange = (fromDate, toDate, fromTimestamp, toTimestamp)=>{
        appSettings.setCalender(fromDate, toDate, fromTimestamp, toTimestamp)
        setTableInfo({...tableInfo, ...{fromTimestamp: fromTimestamp, toTimestamp: toTimestamp}})
        getChanges(1, tableInfo.search, tableInfo.sort, tableInfo.filter, fromTimestamp, toTimestamp)
    }

    useEffect(()=>{
        console.log({appSettings})
        getChanges();
        parseFilterData()
    },[])

    

    return(

        <>
            <Breadcrumbs section={["Changes"]} />
            <DashboardBody>
                <div className='overViewCalender'>
                   <div>
                      <Calendar onClick={onDateChange} fromDate={appSettings.calender.fromDate} toDate={appSettings.calender.toDate}  />
                   </div>
                </div>
                <ChangesTable
                    data={changes} 
                    currentPage={tableInfo.currentPage} 
                    totalRows={tableInfo.totalRows} 
                    perPage={tableInfo.perPage} 
                    onChangePage={onPageChange}
                    onRefresh={onRefresh}
                    onSearch={onSearch}
                    filterData={tableFilterData}
                    onFilterApply={onFilterApply}
                    onSort={onSort}
                />
            </DashboardBody>
        </>
    )
}

export default Changes