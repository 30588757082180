export const getProgressOption = (value, total)=>{

    const progressLineLength = 500;
    const maxValue = total;
    console.log({value, maxValue})
    const option = {
        graphic: {
            elements: [
                {
                    type: 'group',
                    left: 'center',
                    top: 'center',
                    children: [
                        {
                            type: 'rect',
                            shape: {
                                x: 0,
                                y: 0,
                                width: progressLineLength,
                                height: 22,
                            },
                            style: {
                                fill: '#E5E4E9  ',
                            },
                        },
                        {
                            type: 'rect',
                            shape: {
                                x: 0,
                                y: 0,
                                width: (progressLineLength * value) / maxValue,
                                height: 22,
                            },
                            style: {
                                fill: '#6B53AE',
                            },
                            keyframeAnimation: {
                                duration: 1000,
                                loop: false,
                                keyframes: [
                                    {
                                        percent: 0,
                                        scaleX: 0,
                                    },
                                    {
                                        percent: 1,
                                        scaleX: 1,
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
        },
    };

    return option

}


export const getServiceDistributionOption = (data)=>{

    const pieOptions = {
        // legend: {
        //     position: "right" // 'bottom', 'left', 'top'
        // },
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)',
            position: ["50%", "50%"]
        },
        series: [
            {
                name: 'Complaint Resources Count',
                type: 'pie',
                radius: ['50%', '90%'],
                avoidLabelOverlap: true,
                label: {
                    show: false,
                },
                emphasis: {
                    label: {
                        show: false,
                    },
                },
                data: data
            },
        ],
    };

    return pieOptions

}