import { forwardRef, useEffect, useRef, useState } from "react";
import style from "./SelectList.module.css"
import filterIcon from "./assets/filter.svg"
import itemIcon from "./assets/item.svg"
import addIcon from "./assets/add.svg"
import removeIcon from "./assets/remove.svg"
import TableFilter from "components/Table/TableFilter";

const SelectList = forwardRef((props, ref)=>{

    const { selectedOptions, removedOptions, selctedFilter, selectedActionComponent, removedFilter, removedActionComponent , selectDescription, removeDescription, onItemRemoved, onItemSelected, selectedFilterData, removedFilterData} = props

    const [selectedItems, setSelectedItems] = useState([]);
    const [removedItems, setRemovedItems] = useState([]);
    const [fullItems, setFullItems] = useState([])
    
    const [showSelectedFilter, setShowSelectedFilter] = useState(false) 
    const [showRemovedFilter, setShowRemovedFilter] = useState(false) 
    const [searchSelectedItems, setSearchSelectedItems] = useState([])
    const [seachRemovedItems, setSeachRemovedItems] = useState([])
    
    const selectSearchInputRef = useRef(null)
    const removeSearchInputRef = useRef(null)

    const [selectedFilteredData, setSelectedFilteredData] = useState({})
    const [removedFilteredData, setRemovedFilteredData] = useState({})

    const allowDrop =  (ev) => {
        ev.preventDefault();
    }

    const getParent = (id)=>{
       return fullItems.filter((item=>{
            return id == item.id
        }))
    }


    const onClear = (type = 1)=>{
        if(type == 1 ){
            setShowSelectedFilter(false)
            setSelectedFilteredData({})
            filterSelectItem({})
        }else{
            setShowRemovedFilter(false)
            setRemovedFilteredData({})
            filterRemovedItem({})
        }
        
    }

    const onApplyClick = (type = 1)=>{
        if(type == 1) {
            let fData = JSON.parse(JSON.stringify(selectedFilteredData))
            setShowSelectedFilter(false)
            setSelectedFilteredData(fData)
            filterSelectItem(fData)
        }else{
            let fData = JSON.parse(JSON.stringify(removedFilteredData))
            setShowRemovedFilter(false)
            setRemovedFilteredData(fData)
            filterRemovedItem(fData)
        }
    
    }

    const onSelectedCheckBoxClick = (e, filterVal)=>{
        let tempFilter = JSON.parse(JSON.stringify(selectedFilteredData))
        console.log({tempFilter1: tempFilter, filterVal})
       
        if(e.target.checked){
            if(!tempFilter[filterVal.key]){
                tempFilter[filterVal.key] = []
            }
            tempFilter[filterVal.key].push(filterVal.value) 
        }else{
            if(tempFilter[filterVal.key]){
                let dataIndex = tempFilter[filterVal.key].indexOf(filterVal.value)
                tempFilter[filterVal.key].splice(dataIndex, 1)
            }
        }
       
        if(tempFilter[filterVal.key].length == 0){
            delete tempFilter[filterVal.key]
        }
        console.log({tempFilter})
        setSelectedFilteredData(tempFilter)
    }

    const onRemovedCheckBoxClick = (e, filterVal)=>{
        let tempFilter = JSON.parse(JSON.stringify(removedFilteredData))
       
        console.log({filterVal})
        if(e.target.checked){
            if(!tempFilter[filterVal.key]){
                tempFilter[filterVal.key] = []
            }
            tempFilter[filterVal.key].push(filterVal.value) 
        }else{
            let dataIndex = tempFilter[filterVal.key].indexOf(filterVal.value)
            tempFilter[filterVal.key].splice(dataIndex, 1)
        }
       
        if(tempFilter[filterVal.key].length == 0){
            delete tempFilter[filterVal.key]
        }
        setRemovedFilteredData(tempFilter)
    }
   

    const onItemDragStart = (e, item, index)=>{
        e.dataTransfer.setData("item", JSON.stringify( {item, index} ))
    }

    const addToRemovedItems = (item)=>{
        let newItem = item
        if(item.hasParent){
           let parent = removedItems.filter(ele=>{
                return item.parentId == ele.id;
           })
           if( parent.length == 0){
                let p = getParent(item.parentId)
                let tempRemovedItems = [...removedItems,...[
                    {
                        id: p[0].id,
                        label: p[0].label,
                        value :[item]
                    }
                ]]
                setRemovedItems(tempRemovedItems);
                setSeachRemovedItems(tempRemovedItems)
                onItemRemoved(selectedItems, tempRemovedItems)
           }else{
                let tempRemovedItems = removedItems.map(ele=>{
                    if(ele.id == item.parentId){
                        ele.value.push(item)
                    }
                    return ele
                })
                setRemovedItems(tempRemovedItems);
                setSeachRemovedItems(tempRemovedItems)
                onItemRemoved(selectedItems, tempRemovedItems)
           }
           
        }else{
            let tempRemovedItems = [...removedItems,...[newItem]]
            let tempSelectedItems = [] ;
            for(let sItem of selectedItems) {
                if(sItem.id != item.id){
                    tempSelectedItems.push(sItem)
                }
            }
            setRemovedItems(tempRemovedItems);
            setSeachRemovedItems(tempRemovedItems)
            setSelectedItems(tempSelectedItems)
            setSearchSelectedItems(tempSelectedItems)
            onItemRemoved(tempSelectedItems, tempRemovedItems)
        }        
    }

    const addToSelectedItems = (item)=>{

        let newItem = item
        if(item.hasParent){
           let parent = selectedItems.filter(ele=>{
                return item.parentId == ele.id;
           })
           if( parent.length == 0){
                let p = getParent(item.parentId)
                let tempSelectedItems = [...selectedItems,...[
                    {
                        id: p[0].id,
                        label: p[0].label,
                        value :[item]
                    }
                ]]
                setSelectedItems(tempSelectedItems)
                setSearchSelectedItems(tempSelectedItems)
                onItemSelected(tempSelectedItems, removedItems)
           }else{
                let tempSelectedItems = selectedItems.map(ele=>{
                    if(ele.id == item.parentId){
                        ele.value.push(item)
                    }
                    return ele
                })
                setSelectedItems(tempSelectedItems)
                setSearchSelectedItems(tempSelectedItems)
                onItemSelected(tempSelectedItems, removedItems)
           }
           
        }else{
            let tempSelectedItems = [...selectedItems,...[item]]
            let tempRemovedItems = [];

            for(let rItem of removedItems) {
                if(rItem.id != item.id){
                    tempRemovedItems.push(rItem)
                }
            }

            setSelectedItems(tempSelectedItems)
            setSearchSelectedItems(tempSelectedItems)
            setRemovedItems(tempRemovedItems)
            setSeachRemovedItems(tempRemovedItems)
            onItemSelected(tempSelectedItems, tempRemovedItems)
        }



        
    }

    const removeFromRemovedItems = (index, hasParent, parentId)=>{
        if(hasParent){
            let tempRemovedItems =  removedItems.map(item=>{
                 if(item.id == parentId){
                     item.value.splice(index, 1)
                 }
                 return item
             })
                 
             setRemovedItems(tempRemovedItems)
             setSeachRemovedItems(tempRemovedItems)
         }else{
            removedItems.splice(index, 1)
            setRemovedItems(removedItems)
            setSeachRemovedItems(removedItems)
         }


    }
    const removeFromSelectedItems = (index, hasParent, parentId)=>{
        if(hasParent){
           let tempSelectItems =  selectedItems.map(item=>{
                if(item.id == parentId){
                    item.value.splice(index, 1)
                }
                return item
            })
                
            setSelectedItems(tempSelectItems)
            setSearchSelectedItems(tempSelectItems)
        }else{
            selectedItems.splice(index, 1)
            setSelectedItems(selectedItems)
            setSearchSelectedItems(selectedItems)
        }
       
        
    }



    const ItemDropOnRemoved = (e, item = null, index = null)=>{
        e.preventDefault();
        let dropItem = null
        let itemIndex = null
        if(item != null && index != null){
             dropItem = item
             itemIndex = index
        }else{
            let drapItem = JSON.parse(e.dataTransfer.getData("item"))
            dropItem = drapItem.item;
            itemIndex = drapItem.index;
        }
        addToRemovedItems(dropItem)
        removeFromSelectedItems(itemIndex, dropItem.hasParent ? true: false, dropItem.parentId)
        
    }

    const ItemDropOnSelected = (e, item = null, index = null)=>{
        e.preventDefault();
        let dropItem = null
        let itemIndex = null
        if(item != null && index != null){
             dropItem = item
             itemIndex = index
        }else{
            let drapItem = JSON.parse(e.dataTransfer.getData("item"))
            dropItem = drapItem.item;
            itemIndex = drapItem.index;
        }
        addToSelectedItems(dropItem)
        removeFromRemovedItems(itemIndex, dropItem.hasParent ? true: false, dropItem.parentId)
        
    }

    const searchItemsInList = async (items, keyword)=>{
        let searchedItems = []
        
        for (const item of items) {
            let label =  item.label;
            if(item.value instanceof Array) {
                label =  await item.value.filter((iitem, iindex)=>{
                    return iitem.label.toLowerCase().includes(keyword)
                })
            }
            item.value = label
            searchedItems.push(item)
          }
        return searchedItems
    }

    const filterItemsInList = async(items, keywords)=>{
        let searchedItems = []
        for (const item of items) {
            let label =  item.value;
            let containsInLabel = false;
            for(const keywordItem in keywords) {
                let key = keywords[keywordItem][0].toLowerCase()
                console.log({i: item, ll: item.label.toLowerCase(), k: item.label.toLowerCase().includes(key), kk: key})
                if(item.label.toLowerCase().includes(key)){
                    containsInLabel = true
                    break;
                } 
            }

          
            item.value = label
            if(containsInLabel == true && label.length > 0){
                searchedItems.push(item)
            }
          }
          console.log({searchedItems})
        return searchedItems
    }


    const selectItemSearch = async () => {
        let search = selectSearchInputRef.current.value
        let searchedItems = await searchItemsInList(JSON.parse(JSON.stringify(selectedItems)), search)
        setSeachRemovedItems(searchedItems)
    }
    const removeItemSearch = async () => {
        let search = removeSearchInputRef.current.value
        let searchedItems = await searchItemsInList(JSON.parse(JSON.stringify(removedItems)), search)
        setSeachRemovedItems(searchedItems)
    }

    const filterSelectItem = async (data) => {
        if(Object.keys(data).length > 0){
            let searchedItems = await filterItemsInList(JSON.parse(JSON.stringify(selectedItems)), data)
            setSearchSelectedItems(searchedItems)
        }else{
            setSearchSelectedItems(JSON.parse(JSON.stringify(selectedItems)))
        }
       
    }

    const filterRemovedItem = async (data) => {
        if(Object.keys(data).length > 0){
            let searchedItems = await filterItemsInList(JSON.parse(JSON.stringify(removedItems)), data)
            setSeachRemovedItems(searchedItems)
        }else{
            setSeachRemovedItems(JSON.parse(JSON.stringify(removedItems)))
        }
       
    }

    const RenderItem = (item, index, removeAction, icon)=>{
       
        return(
            <div key={index} className={style.SelectItem} draggable={true} onDragStart={(e)=>onItemDragStart(e, item, index)}>
                <div className={style.SelectItemDragIcon}><img src={itemIcon} /></div>
                <div className={style.SelectItemText}>{item.label}</div>
                <div className={style.SelectItemRemoveIcon} onClick={(e)=>removeAction(e, item, index)}><img src={icon} /></div>
            </div>
        )
    }

    useEffect(()=>{
        selectItemSearch()
        removeItemSearch()
    },[selectedItems, removedItems])
    
    

    useEffect(()=>{
        let id = 0

      
        let tempSelectedOptions = selectedOptions.map((item,index)=>{
            id = index + 1;
            let value =  item.value;
            if(item.value instanceof Array) {
                value =  item.value.map((iitem, iindex)=>{
                    return(
                        {
                            label: iitem.label,
                            value: iitem.value,
                            hasParent: true,
                            parentId: "s"+id
                        }
                    )
                })
            }
            return(
                {
                    id: "s"+id,
                    label: item.label,
                    value: value
                }
            )
        })
        setSelectedItems(tempSelectedOptions);


        let tempRemovedOptions = removedOptions.map((item,index)=>{
            id = index + 1;
            let value =  item.value;
            if(item.value instanceof Array) {
                value =  item.value.map((iitem, iindex)=>{
                    return(
                        {
                            label: iitem.label,
                            value: iitem.value,
                            hasParent: true,
                            parentId: "r"+id
                        }
                    )
                })
            }
            return(
                {
                    id: "r"+id,
                    label: item.label,
                    value: value
                }
            )
        })
        setRemovedItems(tempRemovedOptions)


        // setFullSelectedItems(JSON.parse(JSON.stringify(tempSelectedOptions)))
        // setFullRemovedItems(JSON.parse(JSON.stringify(tempRemovedOptions)))
        setSearchSelectedItems(JSON.parse(JSON.stringify(tempSelectedOptions)))
        setSeachRemovedItems(JSON.parse(JSON.stringify(tempRemovedOptions)))
        setFullItems(tempSelectedOptions)
        
    },[selectedOptions, removedOptions])

    return(
        <div className={style.SelectListContainer}>
            <div className={style.ListGroup}>
                <div className={style.ListAction}>
                    <span className={style.ListTitle}>Selected list</span>
                    <span className={style.ListSubTitle}>{selectDescription}</span>
                    <div className={style.SearchContainer}>
                        <div style={{flexGrow: 1}}>
                            <input ref={selectSearchInputRef} className={style.SearchControl} placeholder="Search" onChange={selectItemSearch} />
                        </div>
                        <div>
                           {selctedFilter  && (<div className={style.Filter} onClick={()=>setShowSelectedFilter(!showSelectedFilter)}>
                                <img src={filterIcon}/> <span className={style.FilterLabel}>Filter</span>
                              
                            </div>)}
                            {!selctedFilter && selectedActionComponent}
                        </div>
                       
                    </div>
                    {showSelectedFilter && <TableFilter  hasGroup={false} filteredData={selectedFilteredData} filterData={selectedFilterData} onApply={()=>onApplyClick(1)} onClear={()=>onClear(1)} onChecked={onSelectedCheckBoxClick}  />}
                </div>
                <div className={style.Divider}></div>
                <div className={style.ListContainer} onDragOver={allowDrop} onDrop={ItemDropOnSelected}>

                    {
                        searchSelectedItems?.map((item, index)=>{
                            return(
                                <>
                                    { item.value instanceof Array && <>
                                        {item.value?.length > 0 && <span className={style.ListGroupTitle}>{item.label}</span>}
                                        {
                                             item.value?.map((item, index)=> RenderItem(item, index, ItemDropOnRemoved, removeIcon))
                                        }
                                    </> }
                                    { typeof(item.value) === "string" && <> {RenderItem(item, index, ItemDropOnRemoved, removeIcon)}  </> }
                                </>
                            )
                           
                            
                        })
                    }
                </div>
            </div>

            <div className={style.ListGroup}>
                <div className={style.ListAction}>
                    <span className={style.ListTitle}>Removed list</span>
                    <span className={style.ListSubTitle}>{removeDescription}</span>
                    <div className={style.SearchContainer}>
                        <div style={{flexGrow: 1}}>
                            <input ref={removeSearchInputRef} className={style.SearchControl} placeholder="Search" onChange={removeItemSearch} />
                        </div>
                        <div>
                            {removedFilter  && (<div className={style.Filter} onClick={()=>setShowRemovedFilter(!showRemovedFilter)}>
                                <img src={filterIcon} /> <span className={style.FilterLabel}>Filter</span>
                            </div>)}
                            {!removedFilter && removedActionComponent}
                        </div>
                       
                    </div>
                    {showRemovedFilter && <TableFilter hasGroup={false} filteredData={removedFilteredData} filterData={removedFilterData} onApply={()=>onApplyClick(2)} onClear={()=>onClear(2)} onChecked={onRemovedCheckBoxClick}  />}
                </div>
                <div className={style.Divider}></div>
                <div className={style.ListContainer} onDragOver={allowDrop} onDrop={ItemDropOnRemoved}>

                    {
                        seachRemovedItems?.map((item, index)=>{
                            return(
                                <>
                                     { item.value instanceof Array && <>
                                        {item.value?.length > 0 && <span className={style.ListGroupTitle}>{item.label}</span>}
                                        {
                                             item.value?.map((item, index)=> RenderItem(item, index, ItemDropOnSelected, addIcon))
                                        }
                                    </> }
                                    { typeof(item.value) === "string" && <> {RenderItem(item, index, ItemDropOnSelected, addIcon)}  </> }
                                </>
                            )
                           
                            
                        })
                    }
                </div>
            </div>
            
        </div>
    )


})


SelectList.defaultProps = {

    selctedFilter:true,
    selectedActionComponent:<></>,
    removedFilter:true,
    removedActionComponent:<></>,

    selectedOptions: [],
    removedOptions:[],
    onItemRemoved: ()=>{},
    onItemSelected : ()=>{},

    selectedFilterData: [],
    removedFilterData: []


}


export default SelectList