import React, { useEffect, useState } from 'react';
import GetService from '../../../services/GetServices';
import SelectSearch from 'react-select-search';
import codeData from "./data"
import 'react-select-search/style.css'
import './TelComponent.css';


const TelComponent = React.forwardRef((props, ref) => {

    const [code, setCode] = useState("")
    const [shortName, setShortName] = useState("")
    const [flagCode, setFlagCode] = useState("")


    let options = []
    let tempCode = []
    codeData.map(item=>{
        if(!tempCode.includes(item.dial_code)){
            options.push({
                name: item.emoji + " " +item.name,
                flag: item.emoji,
                name: item.dial_code,
                value: item.dial_code,
                short_name: item.code                
            })
        }
        tempCode.push(item.dial_code)
    })

    const inputRender = (valueProps, snapshot, className)=>{
        // search: '', focus: false
        // console.log(snapshot)
        // if ((snapshot.search != "" || snapshot.search == "") && snapshot.focus == true){
        //     console.log("ddd")
        //     setShortName(snapshot.option?.short_name ??  shortName)
        // }
        // let value = snapshot.search == "" ? snapshot?.value : snapshot.search
        // if (snapshot.focus == false && snapshot.option && snapshot?.value  != null){
        //     setShortName(snapshot.option?.short_name ??  shortName)
        //     console.log(snapshot)
        // }
        // console.log({value})
        // console.log(valueProps)
        
        
        return <input {...valueProps}  className={className}/>
    }


    const onPhoneCodeChange = (e)=>{
        // console.log({e})
        setCode(e)
        let f =  options.find((item)=>{
            return e == item.value;
        })
        
        if(f){
            console.log({f})
            setShortName(f.short_name)
            setFlagCode(f.short_name)
            props.onCodeChange(f.value)
        }
    
    }


    useEffect(()=>{
        // GetService("https://ipapi.co/json",{},false, false, false).then(response=>{
        //     console.log({response})
        //     setCode(response.data?.country_calling_code)
        //     setShortName(response.data?.country)
        //     setFlagCode(response.data?.country)
        //     //https://flagcdn.com/32x24/in.png
        // })

        setCode('+1')
        setShortName('US')
        setFlagCode('US')
    },[])

  return (
        <>
            <label className="label-main">{props.label}</label>
            <div className='tel-flex'>
                <div className='tel-flag tel-gap'>
                    <img src={`https://flagcdn.com/32x24/${flagCode?.toLowerCase()}.png`} className='tel-flag-image'/> <span style={{ marginTop: "8px", marginLeft: "5px" }}>{shortName}</span>
                </div>
                {/* renderValue={inputRender} */}
                <SelectSearch options={options} search={true}  name="phone_code" onChange={onPhoneCodeChange}  style={{width: "113px"}} defaultValue={code} />
                <div style={{position: "relative", width: "100%"}}>
                <input ref={ref} type={"text"} style={{width: "100%"}} className={`${props.className} ops-input-text tel-number tel-gap`} {...props.rules} defaultValue={props.defaultValue} />
                    <span className="op-error-icon"  aria-label={props.error}></span>
                    <div className="error-popover">
                        <p>{props.error}</p>
                        <div className="popover__nub"></div>
                    </div>
                </div>
                
            </div>
            {/* <div style={{marginTop: "4px"}}>{props.error}</div> */}
           
        </>
    );
})

TelComponent.defaultProps = {

    id: "",
    type: "text",
    required: false,
    style: {},
    className: "",
    error: "",
    rules: {},
    onCodeChange: ()=>{}
}


export default TelComponent;