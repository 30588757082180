
import { useState } from "react"
import InputSearchComponent from "../form/InputSearchComponent/InputSearchComponent"
import "./Tab.css"


const Tab = ({tabs, panelClass, defaultTab, ...props})=>{

    const [selectedTab, setSelectedTab] = useState(0)

    return(
        <>
            <div className="op-tab" style={props.tabStyle}>
                <div className="tab-menus">
                    <InputSearchComponent />
                    <div className="tab-menu-list">
                        <ul>
                            {tabs.map((item, index)=>{
                                return  <li key={index} className={`${defaultTab == index || selectedTab == index ? 'active': ""} ${item.disabled == true ?'disabled': ""} ` } onClick={()=> item.disabled != true ? setSelectedTab(index) : ""}>{item.label}</li>
                            })}
                        </ul>
                    </div>
                </div>
                <div className={`tab-panels`}>
                            {tabs.map((item, index)=>{
                                return(
                                    <div key={index} className={`panel ${(index == selectedTab || defaultTab == index) ? 'show': ''}`}>
                                        { item.children }
                                    </div>
                                )
                            })}
                    
                </div>
            </div>
        </>
    )
}

Tab.defaultProps = {
    tabs: [],
    tabStyle: {}
}

export default Tab