import { useEffect, useRef, useState } from "react";
import {  Col, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useNotifications } from "reapop";
import { TagsInput } from "react-tag-input-component";
import RoundIconComponent from "../../RoundIconComponent/RoundIconComponent";
import InputComponent from "../../form/InputComponent/InputComponent";
import {RiUserAddLine} from "react-icons/ri"
import SelectComponent from "../../form/SelectComponent/SelectComponent";
import { API_URL } from "../../../config/config";
import { getCookie } from "../../../utils/util";
import GetService from "../../../services/GetServices";
import PostService from "../../../services/PostServices";


const  UserServiceAssign= ({className, ...props}) =>{

    const {notify} = useNotifications()
    const [users, setUsers] = useState([])
    const [selectUser, setSelectedUser] = useState(0)
   const onModalShow = ()=>{
        document.querySelector(".fade.modal-backdrop.show")?.classList.add("op-modal-fade")
        
        // GetService(API_URL + `/api/v1/base/organisation/${getCookie("onepane_org")}/user/list`).then(res=>{
        //     let tempUser = [];
        //     res.data.data?.items?.map(item=>{
        //         tempUser.push({
        //             name: item.first_name + " " + item.last_name,
        //             value: item.user_id
        //         })
        //     })
        //     if(tempUser.length > 0 ){
        //         setSelectedUser(tempUser[0].value)
        //     }
        //     setUsers(tempUser)
        // })

   }
   

   const onModalClose = ()=>{
    document.querySelector(".fade.modal-backdrop.show")?.classList.remove("op-modal-fade")
   }




  if (props.show === false) {
    return null;
  }

  console.log({props})

  const userChange = (e)=>{
    setSelectedUser(e)
  }
  const submitForm = ()=> {
    // console.log(selectUserRef)
    GetService(API_URL + `/api/v1/service/${props.serviceId}/assign?user_id=${selectUser}`)
  }

  return(
      <>
      <Modal show={props.show} onShow={onModalShow} onHide={onModalClose} className={`op-modal op-modal-content ${className}`} size={"lg"} backdrop={"static"} >
        <Modal.Body className="op-modal-body">
          {(props.show) && 
              <>
                <div className="op-modal-title">
                    <div>
                        <div className="op-header-icon">
                            <RiUserAddLine color="#6B53AE" size={30}/>
                        </div>
                    </div>
                    <div>
                        <div className="op-header-heading">
                            <h6 className="op-header-text">Add User</h6>
                            <p className="op-header-subtext">Add user to service</p>
                        </div>
                       
                    </div>
                </div>
                <div className="op-modal-content">
                    <Row>
                        <Col>
                            <SelectComponent  label={"Select Users"} hint="as" options={users} onChange={userChange} />
                        </Col>
                    </Row>
                </div>

                <Row>
                    <Col lg={12} className={"d-flex justify-content-end"} style={{ position:"absolute", bottom: "10px", right: "3px"}}>

                        <button className="ops-btn ops-btn-curved-outline-red f-14px col-2 py-2 ms-3" style={{marginRight: "19px"}} onClick={()=>{
                            props.onHide()
                        }}>
                            Cancel
                        </button>
                        <button className="ops-btn ops-btn-primary f-14px py-2" onClick={submitForm}>
                            Add User
                        </button>
                    </Col>
                </Row>

               
                
              </>
         
        }
        </Modal.Body>
      </Modal>
      </>
    ) 
}

export default UserServiceAssign;