
import Card from "../../../components/Card/Card";
import { RiDatabase2Line } from "react-icons/ri";
import Table from "../../../components/Table/Table";
import { HiPencil } from "react-icons/hi";
import Button from "../../../components/Elements/Button/Button";
import { useEffect, useState } from "react";
import EditMapRepo from "../../../components/modals/EditMapReepo-APM/EditMapRepo";
import SelectwithSearch from "../../../components/V1/SelectwithSearch/Select";
import { useNotifications } from "reapop";
import HowToLink from "../../../components/V1/HowToLink/HowToLink";
import CenterModal from "../../../components/modals/CenterModal/CenterModal";
import SearchControl from "../../../components/SearchControl/SearchControl";
import GetService from "services/GetServices";
import { API_URL } from "config/config";
import PostService from "services/PostServices";
import { RiCloseCircleLine } from "react-icons/ri";
import { Tooltip } from "react-tippy";
import { getOnepaneTypeCIName } from "utils/OnepaneType";
import Tag from "components/Elements/Tag/Tag";
import SimpleToolTip from "components/Tooltip/SimpleToolTip";
import RBACWrapper from "components/RBACWrapper/RBACWrapper";

const  EditApp = ({platformCategory, platformId, status, resources, onExpand})=>{

    const [showEditModal, setShowEditModal] = useState(false)
    
    const [resourceList, setResourcesList] = useState([])
    const [ showConfirmModal, setShowConfirmModal ] = useState(false)
    const [configItems, setConfigItem] = useState([])
    const [expandResource, setExpandResource] = useState(false)

    const { notify } = useNotifications()
    var searchTimer ;
    


    const ExpandPanel = (value)=>{
        setExpandResource(value)
        onExpand(value)
    }

   
    const ResourceCardTitle = <>
        <div className="d-flex align-items-center">
            <span className="flex-grow-1"> 
                <span> <RiDatabase2Line  color="#6B53AE" size={18} style={{marginRight: "8px"}}/> 
                
                {platformCategory == 2 ? 'Resource List' : 'Repository Mapping'}
            </span>
            </span>
            <span>
                <span>
                    <Tooltip disabled={!(status < 3)} html={<><span style={{padding: "0px 10px"}}>this will only enable after select applications</span></>}>
                        <Button ButtonStyle={"transparent"} type={expandResource ? "danger" : "primary"} size={"small"}  disabled={ RBACWrapper.hasPermission("INTEGRATION_EDIT") ?  status < 4 ? true : false : false}  onClick={()=>ExpandPanel(!expandResource)}> 
                            {expandResource == true ? <><RiCloseCircleLine size={"16"} style={{marginRight: "3px", marginTop: "-3px"}} /> Cancel</> : <><HiPencil size={"16"} style={{marginRight: "3px", marginTop: "-3px"}} /> Edit</>}
                            </Button>
                    </Tooltip>
                </span>
            </span>
        </div>
    </>



    const showNotification = (text, status="success")=>{
        notify( text, status,{
            dismissAfter: 1500
        })
    }


    const confirmSave = ()=>{

        let tempResourceList = [];
        resourceList.map((item)=>{
            if (item.mapped_id != -1){
                tempResourceList.push({
                    ci_id: item.config_id,
                    onepane_ci: item.mapped_id
                })
            }
        })
        console.log({tempResourceList})
        PostService(API_URL + `/api/v1/platform/${platformId}/step/final`,{
            mapped_resources: tempResourceList
        }).then(response=>{
            setShowConfirmModal(false)
            setExpandResource(false)
            showNotification("Save Successfully")
        })




    }

   
    const onMappingResourceSelect = (selectItem, row)=>{
        let tempResourceList = JSON.parse(JSON.stringify(resourceList))
        tempResourceList.map(item=>{
            if(item.config_id == row.config_id){
                item.mapped_id = selectItem.value
                item.mapped_name = selectItem.label
            }
        })

        console.log({tempResourceList})
        setResourcesList(tempResourceList)
    }
    

    const getConfigItem = (page = 1, search = "")=>{
        
        GetService(API_URL + `/api/v1/resource/list?current_page=${page}&search=${JSON.stringify([{key:"name", value:search}])}`,
        {}, false, false, false).then(response=>{
            let data = response.data.data
            let tempConfig = []
            data.items.map((item=>{
                tempConfig.push({
                    label: item.name,
                    value: item.ci_id
                })
            }))
            setConfigItem(tempConfig)
        })
    }


    const searchConfigItems = (search)=>{
        clearTimeout(searchTimer)
        searchTimer = setTimeout(()=>{
            getConfigItem(1, search)
        },2000)

        
    }


    const columns = [
        {
            name: platformCategory == 2 ? 'RESOURCE' : 'REPOSITORY',
            selector: row => {
                return ( 
                    <>
                        <SimpleToolTip tooltip={row.name}>
                            <div className="git-repo">
                                <span><RiDatabase2Line color="#2DA077" size={22} /></span>
                                <span className="repo-name">{row.name}</span>
                            {platformCategory == 3 && <Tag type={row.visibility == "private" ? "warning" : "primary"}>{row.visibility}</Tag>} 
                            </div>
                        </SimpleToolTip>
                    </>
                )
            },
            
            sortable: true,
        },
        {
            name: platformCategory == 2 ? 'ORIGINAL TYPE' : 'LANGUAGE',
            selector: row => {
                if(platformCategory == 2){
                    return <span className="apm-col">{row.original_type}</span>
                }else{
                    return(
                        <>
                            <div className="git-lang">
                                <span className="">
                                    <div className="git-lang-indicator"></div>
                                </span>
                                <span className="git-lang-label">{row.language == "" ? <span className="no-value-span">Not Detected</span> : row.language}</span>
                            </div>
                        </>
                    )
                }

            },
            width: platformCategory == 2 ? 'auto' : '200px',
            sortable: true,
        },
        {
            name: platformCategory == 2 ? 'ONEPANE TYPE' : 'TYPE',
            selector: row => {
               return platformCategory == 2 ? 
                    (<span className="apm-col">{getOnepaneTypeCIName(row.onepane_type)}</span>)
                : (<span className="table-span">{"Code"}</span>)
            },
            width: platformCategory == 2 ? 'auto' : '150px',
            sortable: true,
        },
        {
            name: platformCategory == 2 ? 'SERVICE' : 'BRANCH',
            selector: row => {
                console.log({row})
                return platformCategory == 2 ? 
                    (<span className="apm-col">{row.service == "" ? <span className="no-value-span">no-service</span> : row.service}</span>)
                : ( <span className="table-span">{row.branch}</span>)
            },
            sortable: true,
            width: platformCategory == 2 ? 'auto' : '180px'
        },
        {
            name: 'RESOURCE MAPPING',
            selector: row => {
                return(
                    <SelectwithSearch 
                        label={""} size={"small"} defaultValue={row.mapped_name} 
                        placeholder={row.mapped_name == "" ? "Select Resource" : row.mapped_name} 
                        data={configItems} 
                        style={{width: "160px"}} 
                        width={232} 
                        showKey={"label"} 
                        serverSideSearch={true}
                        onServerSideSearch={searchConfigItems}
                        onChange={(item)=>onMappingResourceSelect(item, row)} />
                  
                )
            },
            width: platformCategory == 2 ? 'auto' : '250px',
            sortable: true,
        },
    ]

    const parseResources = (resourceList)=>{
        let tempResources = []
        if(platformCategory == 2){
            for (const item of resourceList) {
                tempResources.push({
                    config_id: item.ci_id,
                    name: item.name,
                    original_type: item.original_type,
                    onepane_type: item.onepane_type,
                    service: item.onepane_config_item?.service?.name,
                    selected: item.selected,
                    mapped_id: item.onepane_ci == 0 ? -1 : item.onepane_ci,
                    mapped_name: item.onepane_config_item?.name ? item.onepane_config_item?.name : ""
                })
            }
        }else{
            for (const item of resourceList) {
                tempResources.push({
                    config_id: item.ci_id,
                    name: item.name,
                    code: "Code",
                    branch: item.tags?.["repository.branch"],
                    language: item.tags?.["repository.language"],
                    visibility: item.tags?.["repository.visibility"],
                    selected: item.selected,
                    mapped_id: item.onepane_ci == 0 ? -1 : item.onepane_ci,
                    mapped_name: item.onepane_config_item?.name ? item.onepane_config_item?.name : "",

                })
            }
        }

       return tempResources
    }

    useEffect(()=>{
        let tempResources = parseResources(resources)
        setResourcesList(tempResources)
        getConfigItem()
    }, [resources])

    
    return(
        <>
        <div style={{marginTop: "20px"}} >
                <Card title={ResourceCardTitle} type="full" expand={expandResource} >
                    <Table 
                        containerStyle={{border: "0px"}}
                        columns={columns} 
                        data={resourceList}
                        loading={false}
                        tableStyle={{border: "0px"}}
                        pagination={true}
                        paginationServer={false}
                    />
                     <div>
                        <Card.CardBody>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                    <HowToLink text={"How to fix"} />
                                </div>
                                <div className="">
                                    <Button  type="danger" ButtonStyle="transparent" onClick={()=>ExpandPanel(false)} >Cancel</Button>
                                    <Button  onClick={()=> setShowConfirmModal(true) }>Save</Button>
                                </div>
                            </div>
                        </Card.CardBody>
                
                    </div>
                </Card>

               

        </div>


       


        <EditMapRepo title={"Modify"} description={"Altering this will have no impact on your cloud tenant whatsoever"} show={showEditModal} onHide={()=>setShowEditModal(false)}  onSave={()=>setShowEditModal(false)} />
        
        <CenterModal show={showConfirmModal}>
                <Card
                    title={<span> <RiDatabase2Line color="#6B53AE" size={25} style={{marginRight: "7px"}} />Changed Resource List </span>}
                    type={"full"}
                >

                    <Card.CardBody>
                        <SearchControl actions={false} />
                      
                        <div style={{marginTop: "20px"}}>
                            <div style={{borderRadius: "8px 8px 0px 0px", border: "1px solid #EDE9F7", background: "#F4F6FC", padding: "11px 21px", overflow: "scroll", maxHeight: "500px"}}>
                                {
                                    resourceList.map(item=>{
                                        if(item.mapped_id != -1){
                                        return(
                                            <div className="d-flex align-items-center" style={{
                                                borderRadius: "8px",
                                                border: "1px solid #EDE9F7",
                                                background: "#FCFCFD",
                                                padding: "21px",
                                                margin: "10px 0px"
                                            }}>
                                                <div className="flex-grow-1" style={{width: "25%"}}>
                                                    <span className="change-confirm-span span-ellipsis" style={{display: "inline", marginRight: "10px"}}> 
                                                        <RiDatabase2Line color="#2DA077" size={25} style={{marginRight: "7px"}} />{item.name}
                                                    </span> 
                                                    {platformCategory == 3 && <Tag type={item.visibility == "private" ? "warning" : "primary"}>{item.visibility}</Tag>}
                                                </div>
                                                <div className="flex-grow-1" style={{width: "12%"}}> 
                                                    {platformCategory == 2 && <span className="change-confirm-span">{item.original_type}</span> }
                                                    {platformCategory == 3 && <div className="git-lang">
                                                        <span className="">
                                                            <div className="git-lang-indicator"></div>
                                                        </span>
                                                        <span className="git-lang-label">{item.language}</span>
                                                    </div>}
                                                </div>
                                                
                                                
                                                <div className="flex-grow-1" style={{width: "10%"}}>
                                                {platformCategory == 2 && <span className="change-confirm-span">{ getOnepaneTypeCIName(item.onepane_type)}</span>}
                                                {platformCategory == 3 && <span className="change-confirm-span">{ "Code"}</span>}
                                                </div>
                                                <div className="flex-grow-1" style={{width: "10%"}}>
                                                    {platformCategory == 2 && <span className="change-confirm-span">
                                                            {item.service == "" ? <span className="no-value-span">no-service</span> : item.service}
                                                        </span> 
                                                    }
                                                    {platformCategory == 3 && <span className="change-confirm-span">{item.branch}</span>}
                                                </div>
                                                <div className="flex-grow-1" style={{width: "15%"}}>
                                                    <span className="change-confirm-span span-ellipsis">{item.mapped_name}</span> 
                                                </div>
                                            </div>
                                        )}
                                    })
                                    
                                }
                            </div>
                            <div style={{
                                borderRadius: "0px 0px 8px 8px",
                                border:" 1px solid #EDE9F7",
                                background: "#FCFCFD",
                                borderTop: "0px",
                            }}>
                                <div className="text-right" style={{padding:"10px 21px"}}>
                                    <Button type="danger" ButtonStyle="transparent" onClick={()=>setShowConfirmModal(false)}>Cancel</Button>
                                    <Button onClick={confirmSave}>Confirm</Button>
                                </div>
                            </div>
                        </div>

                    </Card.CardBody>
                </Card>
        </CenterModal>
        
        
        </>

    )

}


export default EditApp