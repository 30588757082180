import CenterModal  from "components/modals/CenterModal/CenterModal"
import Button from "components/Elements/Button/Button"

const ConfirmModel = ({show, inputRef,modalInfo, onCancel, ...props})=>{
        return(
            <CenterModal show={show}>
                <div className="integration-delete-disable-modal">
                    <div className={`integration-icon-cover ${modalInfo.iconClass}`}>{modalInfo.icon}</div>
                    <h6 className="integration-delete-disable-modal-heading">{modalInfo.heading}</h6>
                    <p className="integration-delete-disable-modal-subheading">
                        {modalInfo.subheading}
                    </p>
                    <h6 className="integration-delete-disable-modal-message">{modalInfo.message}</h6>
                    <input ref={inputRef} className="integration-delete-disable-modal-input"/>
                    <div className="d-flex" style={{marginTop: "20px", gap: "20px"}}>
                        <div className="flex-grow-1 text-right">
                            <Button onClick={onCancel} type="danger" ButtonStyle="transparent" style={{width: "162px"}}>Cancel</Button>
                        </div>
                        <div className="flex-grow-1 text-left">
                            <Button type={props.actionButtonType ? props.actionButtonType : "danger"}style={{width: "162px"}} onClick={modalInfo.onAction} >{modalInfo.actionButtonText}</Button>
                        </div>
                    </div>
                </div>
                    
            </CenterModal>
        )
}

ConfirmModel.defaultProps = {
    show: false,
    onCancel: ()=>{},
    inputRef: null,
    modalInfo: {},
}

export default ConfirmModel