import './ServiceCard.css'
import IconTag from '../IconTag/IconTag'
import { RiCheckboxCircleLine, RiLightbulbFlashFill } from 'react-icons/ri'
import Expand from "../../assets/icons/arrows/expand-icon.svg"
import { Link } from 'react-router-dom'
import TooltipView from '../Tooltip/Tooltip'
import { getServiceStatus } from 'utils/util'



function ServiceCard(props) {
    return (
        <>


            <div className="NewServiceCard">
                <div>
                    <div className="row servicecard">

                        <div className="col-10">
                            <div className="d-flex align-items-center">
                                <div>
                                    <h3 className='servicename'>{props.servicename}</h3>
                                </div>
                                <div>
                                    <span className='ml-6 '> {getServiceStatus(props.active)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-1">
                            <div>
                                <Link to={props.link}> <img src={Expand} alt='expand' width={18} /></Link>
                            </div>
                        </div>
                    </div>

                </div>
                    
                <div className="d-flex align-content-center" style={{ gap: "16px", paddingTop: "20px" }}>
                    <TooltipView body={`${props.active}  active issues`} style={{fontSize: "13px" , padding: "5px 8px" }} >
                        <Link to={`/alerts?&active=1&service=${props.serviceId}`} className='link-decoration-none'>
                            <IconTag  iconBgcolor={"#FDBC3E"} icon={<RiLightbulbFlashFill color="#FCFCFD" size={18} />} text={`${props.active} active`} style={{ backgroundColor: "#FCEFD7", fontWeight: '700' }} className={"active"} />
                        </Link>
                    </TooltipView>
                    <TooltipView body={`${props.resolve}  resolved issues`} style={{fontSize: "13px" , padding: "5px 8px" }}>
                        <Link to={`/alerts?&active=0&service=${props.serviceId}`} className='link-decoration-none'>
                            <IconTag iconBgcolor={"#2DA077"} icon={<RiCheckboxCircleLine color="#FCFCFD" size={18} />} text={`${props.resolve} resolved`} style={{ backgroundColor: "#D3EAE2", fontWeight: '700' }} />
                        </Link>
                    </TooltipView>
                </div>
                <div className="d-flex align-content-center" style={{ gap: "5px", paddingTop: "12px" }}>
                    <Link to={`/changes?&service=${props.serviceId}`} className='link-decoration-none'>
                        <IconTag
                            className={'serviceChangeResourceTag'}
                            iconBgcolor={"#6B53AE"}
                            iconClass={'numberOval'}
                            icon={<span style={{ color: "#FCFCFD" }}>{props.changes}</span>}
                            text={"Changes"}
                        />
                     </Link>
                    <Link to={`/resources?&service=${props.serviceId}`} className='link-decoration-none'>
                        <IconTag
                            className={'serviceChangeResourceTag'}
                            iconBgcolor={"#6B53AE"}
                            iconClass={'numberOval'}
                            icon={<span style={{ color: "#FCFCFD" }}>{props.resources}</span>}
                            text={"Resources"}
                        />
                    </Link>
                </div>
            </div>



        </>
    )
}

ServiceCard.defaultProps = {
    servicename: 'noservice',
    active: '0',
    resolve: '0',
    resources: '0',
    changes: '0',
    link: '0',
}

export default ServiceCard