

import { RiUserSettingsLine, RiFunctionLine, RiBarChart2Line, RiDatabase2Line, RiFilePaper2Line, RiGitMergeLine, RiHeadphoneLine, RiSettings3Line, RiLogoutCircleLine, RiRefreshLine } from "react-icons/ri"

import { sideMenuIconProps } from "../../config/constants"

const SideBarMenu = [
    {
        title: "Overview",
        url: "/overview",
        icon: <RiFunctionLine {...sideMenuIconProps} />,
        disabled: false
    },
    {
        title: "Services",
        url: "/services",
        icon: <RiUserSettingsLine {...sideMenuIconProps} />,
        disabled: false
    },
    {
        title: "Resources",
        url: "/resources",
        icon: <RiDatabase2Line {...sideMenuIconProps} />,
        disabled: false
    },
    {
        title: "Alerts",
        url: "/alerts",
        icon: <RiBarChart2Line  {...sideMenuIconProps} />,
        disabled: false
    },
    {
        title: "Changes",
        url: "/changes",
        icon: <RiRefreshLine  {...sideMenuIconProps} />,
        disabled: false
    },

  
   
    // {
    //     title    : "Service Map",
    //     url     : "/service-map",
    //     icon   : <RiUserSettingsLine  color="#D0CFD4" size="24px"  />,
    //     disabled : true
    // },
    // {
    //     title    : "Reports",
    //     url     : "/reports",
    //     icon   : <RiFilePaper2Line  color="#D0CFD4" size="24px"  />,
    //     disabled : true
    // },
    {
        title: "Reports",
        url: "/reports",
        icon: <RiFilePaper2Line  {...sideMenuIconProps} />,
        disabled: false
    },
    {
        title: "Integrations",
        url: "/integrations",
        icon: <RiGitMergeLine {...sideMenuIconProps} />,
        disabled: false

    }
]


const SideBarBottomMenu = [
    // {
    //     title    : "Support",
    //     url     : "/support",
    //     icon   : <RiHeadphoneLine {...sideMenuIconProps} />
    // },
    {
        title: "Settings",
        url: "/settings",
        icon: <RiSettings3Line {...sideMenuIconProps} />,
    },
    // {
    //     title: "Logout",
    //     url: "",
    //     icon: <RiLogoutCircleLine color="#6B53AE" size="24px" />,
    //     // icon   : <RiLogoutCircleLine color="#6B53AE" {...sideMenuIconProps} />,
    //     onClick: () => {
    //         window.location = "/iam/wso2/logout"
    //     }
    // }
]


export { SideBarMenu, SideBarBottomMenu }