import { useEffect, useState } from "react"
import style from "./CenterModal.module.css"
import { dashboardFade } from "../../../utils/util"


const CenterModal = ({show , children})=> {
    const [showModal, setShowModal] = useState(false)

    useEffect(()=>{
        console.log({show})
        if(show){
            dashboardFade()
            setShowModal(show)
        }else{
            dashboardFade(false)
            setShowModal(show)
        }
    },[show])


    return(
        <>
            
                <div className={`${style.certalModal} ${showModal == true ? style.certalModalShow : ""}`}>
                    {children}
                </div>
        </>
    )
}


export default CenterModal