import 'tippy.js/dist/tippy.css';
import 'react-tippy/dist/tippy.css';
import './Tooltip.css';
import { Tooltip } from 'react-tippy';



const TooltipView = (props,ref) => {
    return(

            <Tooltip 
                interactive
                title={props.message} 
                position={props.position} 
                trigger={props.trigger}
                hideOnClick={true}
                sticky={true}
                disabled={props.disabled}
                // disabled={!props.open}
                distance={props.distance}
                offset={props.offset}
                onShow= {()=>{props.onShown(true)}}
                onHidden= {()=>{props.onShown(false)}}
                size= {"big"}
                arrow={props.arrow}
                theme= {"light"}
                html={ 
                    <div style={props.style}>
                        {props.body}
                    </div> 
                }
                
            >
                
                    {props.children}
            </Tooltip>
    )
}

TooltipView.defaultProps = {
    title: '',
    position: 'top',
    trigger: 'mouseenter',
    distance: 10,
    offset: 0,
    width: 'auto',
    onShown: ()=>{},
    padding: "10px",
    collapse: false,
    arrow: false,
    disabled: false,
    border: "none",
    backgroundColor: "white",
    borderRadius: "0px",
    fontSize: "12px"
}

export default TooltipView;