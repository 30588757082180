import GeneralLayout from 'pages/general-layout/GeneralLayout'
import React from 'react'
import { Row } from 'react-bootstrap'
import styles from './Onboarding.module.css'
import coverImage from "../../../src/assets/images/Rectangle 34624126.svg"
import loadingImage from "../../../src/assets/images/loadingImage.svg"
import { RiLoginBoxLine } from 'react-icons/ri'
import PostService from 'services/PostServices'
import { useNotifications } from "reapop";
import { API_URL } from 'config/config'

const Onboarding = ({user}) => {


    const {notify} = useNotifications()

    const showNotification = (text, status="success")=>{
        notify( text, status,{
            dismissAfter: 1500
        })
    }


    const createOrg = (loadDummyData)=>{
        var formData = new FormData();

        formData.append("type",  loadDummyData? "5": "4")
        PostService(API_URL + "/api/v1/base/organisation/select/kind", formData).then(response=>{
            // window.location.href = "/overview"
            let data = response.data
            if(data.success == true){
                window.location.href = "/overview"
            }else{
                showNotification("Error while loading dummy data")
            }
        })
    }




    return (

        <>
            <GeneralLayout header={true} footer={true} showProfile={true} user={user} enableOrganisation={false}>
                <div className={`${styles.main} op-scrollbar`}>
                    <div className={`${styles.onboardHeading}`}>Connect Your Cloud or Experiment with Sample Data</div>
                    <div className={`${styles.onboardSubheading}`}>Begin using Onepane with a swift 5-minute connection setup to your cloud account. Alternatively, if you'd like to familiarize yourself with the tool using sample data, select the 'Load Sample Data' option. You have the flexibility to clear this sample data and start fresh at any time</div>

                </div>
                <div className={styles.onBoardMarginFull}>
                    <div className="row">
                        <div className="col-6">
                            <div className={styles.OnboardWidth}>
                                <div className={styles.Heading}>
                                    <span className={styles.OnboardMainTitle}>EXPLORE</span>
                                    <div className={styles.OnboardSubTitle}>Use Sample Data</div>
                                </div>
                                <a href='#'>
                             <div className={`${styles.OnboardmainDiv}`}>
                                    <div className={styles.onBoardCoverImage}>
                                        <div>
                                            <img src={coverImage} alt='img' />
                                        </div>
                                    </div>
                                    <div className={styles.onBoardcoverBtn}>
                                        <button onClick={()=>createOrg(true)} className={styles.onBoardcoverBtn}><span>Load Data</span><span><RiLoginBoxLine size={24} /></span></button>
                                    </div>
                                </div>
                             </a>
                            </div>

                        </div>

                        <div className={`${styles.borderleft} col-6`}>

                          <div className={styles.OnboardWidth}>
                                <div className={styles.Heading}>
                                    <span className={styles.OnboardMainTitle}>CONFIGURE</span>
                                    <div className={styles.OnboardSubTitle}>Getting Started</div>
                                </div>                          
                                <a href='#'>
                                <div className={`${styles.OnboardmainDiv}`}>
                                    <div className={styles.coverImage}>
                                        <div>
                                            <img src={loadingImage} alt='img' />
                                        </div>
                                    </div>
                                    <div className={styles.onBoardcoverBtn}>
                                        <button onClick={()=>createOrg(false)} className={styles.onBoardcoverBtn}><span>Start Empty</span><span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                            <path d="M21.463 13.5005L17.5637 10.0644H7.43633L3.53703 13.5005L3 12.9165L7.12808 9.27832H17.8719L22 12.9728L21.463 13.5005Z" fill="#FCFCFD" stroke="#FCFCFD" />
                                            <path d="M20.8125 20.2288H4.1875C3.53259 20.2288 3 19.6962 3 19.0413V13.3018H9.33333V13.6976C9.33333 14.6799 10.1323 15.4788 11.1146 15.4788H13.8854C14.8677 15.4788 15.6667 14.6799 15.6667 13.6976V13.3018H22V19.0413C22 19.6962 21.4674 20.2288 20.8125 20.2288ZM3.79167 14.0934V19.0413C3.79167 19.2596 3.9692 19.4372 4.1875 19.4372H20.8125C21.0308 19.4372 21.2083 19.2596 21.2083 19.0413V14.0934H16.4281C16.2371 15.3251 15.1695 16.2705 13.8854 16.2705H11.1146C9.8305 16.2705 8.76294 15.3251 8.57195 14.0934H3.79167Z" fill="#FCFCFD" stroke="#FCFCFD" />
                                            <path d="M10.0632 6.55895L8.67773 4.38184L8.00981 4.80688L9.39524 6.984L10.0632 6.55895Z" fill="#FCFCFD" stroke="#FCFCFD" />
                                            <path d="M17.1884 4.80485L16.5205 4.37988L15.1353 6.557L15.8033 6.98196L17.1884 4.80485Z" fill="#FCFCFD" stroke="#FCFCFD" />
                                            <path d="M12.8957 4H12.104V6.57292H12.8957V4Z" fill="#FCFCFD" stroke="#FCFCFD" />
                                        </svg></span></button>
                                    </div>
                                </div>
                                </a>
                            </div>
                      

                        </div>
                    </div>
                </div>
            </GeneralLayout>

        </>
    )
}

export default Onboarding