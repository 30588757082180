import { RiAddCircleFill } from "react-icons/ri"
import "./IconTag.css"

const IconTag = ({iconBgcolor,textColor,className, type,  icon, text, ...props})=>{



    return(
        <>
            <div className={`icon-tag ${type} ${className}`} {...props}>
                <div className={`icon-tag-icon-container ${type} ${props.iconClass}`} style={{backgroundColor:iconBgcolor,...props.iconStyle}}>
                    {icon}
                </div>
                <span className={`icon-tag-text-container`} style={text === "" ? { minWidth: "33px", color: textColor } : { color: textColor }}>
  {text}
</span>

            </div>
        </>
    )
}

IconTag.defaultProps = {
    icon: <RiAddCircleFill color="#6B53AE" size={28}/>,
    text: "Add User",
    iconBgcolor:"transparent",
    textColor:"#222127",
    type:"primary",
    className: "",
    iconStyle: {},
    iconClass:""
    
}


export default IconTag