import React, { useEffect, useRef, useState } from 'react';
import ReactECharts from 'echarts-for-react';

function LineGraph({ data, lineColor, dotColor }) {
   const [lineChartOption, setChartOption] = useState({})

   useEffect(() => {
        const option = {
            xAxis: {
                type: 'category',
                data: data.map(item => item.month),
            },
            yAxis: {
                type: 'value',
            },
            series: [
                {
                    data: data.map(item => item.cost),
                    type: 'line',
                    smooth: true,
                    lineStyle: {
                        color: lineColor || '#1890FF',
                    },
                    itemStyle: {
                        color: dotColor || '#1890FF', // Set the dot color, default to '#1890FF' if dotColor is not provided
                    },
                },
            ],
        };
        setChartOption(option)
    }, [data]);

    return(
        <>
             <ReactECharts
                option={lineChartOption}
                // style={{ height: '100px' }}
            />
        </>
    )

    // return <div ref={costMonthGraphRef} style={{ width: '100%', height: '265px' }} />;
}

export default LineGraph;
