
import style from "./Table.module.css"

const TableButton = ({icon, className, children, ...props})=> {


    return(
        <>
            <button className={`${style.opTableButton} ${className}`} {...props}>
                <span style={{marginRight: "10px"}}>{icon}</span>
                {children}
            </button>
        </>
    )
}

TableButton.defaultProps = {
    icon: "",
}


export default TableButton