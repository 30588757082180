import { useNotifications } from "reapop";
import './SettingInviteUser.css';
import { MdOutlineLaunch } from "react-icons/md";
import Modal from "../Modal/Modal";
import Button from "../../Elements/Button/Button";
import { BiErrorCircle, BiPencil } from "react-icons/bi";
import Select from "../../V1/Select/Select";
import UserImage from "../../modals/SettingInviteUser/assets/user-add-line.svg";
import { color } from "echarts";
import { useForm } from "react-hook-form";
import InputComponent from "components/form/InputComponent/InputComponent";
import { useState } from "react";
import SelectComponent from "components/form/SelectComponent/SelectComponent";
import { getErrorClass, validateBussinessEmail, validateEmail } from "utils/util";
import GetService from "services/GetServices";
import { API_URL } from "config/config";


const SettingInviteUser = ({ data ={} , action, show, title = "Invite User", logo, description = "Azure connector gathers Azure resources, configurations, usage, and size data for streamlined management", className, ...props }, ref) => {


    const [selectedRole, setSelectedRole] = useState(2)


    const [formSubmiting, setFormSubmiting] = useState(false)
    const { register, handleSubmit, watch, formState, getValues} = useForm({
        mode: "onTouched",
    });

    const { errors, isValid, isSubmitting, touchedFields, submitCount } = formState


    const rules = {
        name: {
            required: {
                value: true,
                message: "first name required"
            },
            pattern: {
                value: /^[a-zA-Z ]+$/,
                message: "first name should not contain special characters "
            },
            minLength: {
                value: 3,
                message: "first name must be mininum 3 characters"
            }
        },
        
        email: {
            required: {
                value: true,
                message: "email  required"
            },
            validate: async val=>{
                if(!validateBussinessEmail(val)){
                    return "invalid business email address"
                }
            },
        },
    };



    const onFormSubmit = (formData) => {
        formData["role"] =  selectedRole
        formData["user_id"] = data.user_id ?? 0
        props.onSave(formData, action);
    };


    const roleChange = (e)=>{
        setSelectedRole(e)
    }

    return (
        <>
            <Modal show={show} style={{ width: "514px" }} ref={ref}>
                <Modal.Title>
                    <div className="content-padding">
                        <div className="headingDiv">
                            <div className='logoos'>
                                <img src={UserImage} alt="User" />
                            </div>
                            <div className='header'>
                                <span className="mainHeading">
                                    {title}
                                </span>
                                <span className="subHeading">
                                    {description}
                                </span>
                            </div>
                        </div>
                    </div>
                </Modal.Title>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onFormSubmit)}>
                       
                        <div className="Content">
                                <InputComponent 
                                    label={"Name"}
                                    defaultValue={`${data?.first_name ?? ""} ${data?.last_name ?? ""}`}
                                    disabled={action != 1 ? true : false}
                                    className={getErrorClass(errors, touchedFields, "name")} 
                                    error={errors.name?.message} 
                                    { ...(action == 1 ?  register("name", rules.name) : {})}
                                /> 
                               <div className="form-control-seperator"/>
                              
                                <InputComponent 
                                    label={"Email"}  
                                    defaultValue={data?.user_email}
                                    disabled={action != 1 ? true : false}
                                    className={getErrorClass(errors, touchedFields, "email")} 
                                    error={errors.email?.message} 
                                    {...(action ===1 ? register("email", rules.email) : {})}
                                /> 
                                <div className="form-control-seperator"/>
                            
                                <SelectComponent 
                                    // value={data?.org_mappings &&  data.org_mappings[0] && data.org_mappings[0].role}
                                    defaultValue={data?.org_mappings &&  data.org_mappings[0] && data.org_mappings[0].role}
                                    label={"Role"} 
                                    disabled={action == 3 ? true : false}
                                    options={[{name:"Contributor", value:2},{ name: "Reader", value:3}]} 
                                    onChange={roleChange} 
                                />
                                {action === 2 && (
                                    <div className="removeBox" style={{ marginTop: '30px' }}>
                                        <div>
                                            <span className="titleHead">Remove</span><br />
                                            <span className="titlesubHead">Remove user permanently</span>
                                        </div>
                                        <div>
                                            <Button buttonType="button" style={{ border: "none", background: "#D94F4F", color: "#FCFCFD" }} onClick={()=>{props.onDelete(data.user_id)}}>Remove</Button>
                                        </div>
                                    </div>
                                )}
                            
                                {action === 3 && (
                                    <div className="revokeBox" style={{ marginTop: '30px' }}>
                                        <div>
                                            <div className="titleHead">
                                                <span>Revoke Invite</span>
                                            </div>
                                            <div>
                                                <span className="revoketitlesubHead">The invitation request will become invalid, user won't be able to join</span>
                                            </div>
                                        </div>
                                        <div>
                                            <Button style={{ background: "transparent", color: "#FDBC3E", borderColor: "#FDBC3E" }} onClick={()=>{props.onRevoke(data.invite_id)}}>Revoke</Button>
                                        </div>
                                    </div>
                                )}
                        </div>
                        <div style={{ float: "right", marginTop: "40px" }}>
                            <Button type="danger" ButtonStyle="transparent" className="mx-3" onClick={() => props.onHide()}> {action !== 1 ? "Close" : "Cancel"}</Button>
                            <Button>
                                    {action === 1 ? "Invite" : action === 2 ? "Change" : "ResendInvite"}
                            </Button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default SettingInviteUser;
