import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { API_URL } from 'config/config';
import GetService from 'services/GetServices';
import Table from 'components/Table/Table';
import Tag from 'components/Elements/Tag/Tag';
import settingsIcon from "assets/icons/util/settings.svg";
import deploying from "assets/gif/deploying.gif";
import './Sources.css';
import { connectorRoute } from 'config/constants';
const Sources = (props) =>{

  
    const [tableInfo, setTableInfo] = useState({totalRows: 0 , currentPage: 1, loading: true, search: "", sort:[], filter :{}})
    

    const [sources ,setSources] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    
    var timeout = useRef();

    const filterData = [
        {
            label: "Status",
            key: "status",
            values: [
                    {label: 'Deploying', value: 0},
                    {label: 'Ready to Configure', value: 2},
                    {label: 'Configuring', value: 3},
                    {label: 'Initial Configuration Done', value: 4},
                    {label: 'Scanning', value: 5},
                    {label: 'Running', value: 6},
                    {label: 'Error', value: 1},
                    
                ]
        }
    ]

    const columns = [
        {
            name: 'NAME',
            selector: row => {
                return(
                        <>
                          
                          <Link to={`${connectorRoute[row.platform_type.platform_type_id]}/${row.platform_id}/details`} style={{textDecoration:"none"}}>
                            <div className='d-flex'>
                                    <img src={`/images/connector-images/${row.platform_type.icon}`} className='br-img-rounded' style={{width: "24px", height: "24px", marginRight: "10px"}}/>
                                    <span className='integration-table-name ops-common-hover'>{row.name}</span>
                                </div> 
                         </Link>
                        </>
                )
            },
            width: "400px",
            sortable: true,
            sortField: "name",
        },
        {
            name: 'TYPE',
            selector: row => {
                return(
                    <>
                    <div className='d-flex'>
                        <img src={`/images/connector-images/${row.platform_type.icon}`} className='br-img-rounded' style={{width: "24px", height: "24px", marginRight: "10px"}}/>
                        <span>{row.platform_type.name}</span>
                    </div>   
                    </>
                )
            },
            // width: "200px"
        },
        {
            name: 'TELEMETRY',
            selector: row => {
                return(
                    [2,3]?.map((item, index)=>{
                        return(<Tag type={"primary"} style={{marginRight: "7px"}} key={index}>{TELEMETRY[parseInt(item) - 1]}</Tag>)
                    })
                )
            },
            width: "200px"
        },
        {
            name: 'METHOD',
            selector: row => {
                return ( 
                    <>
                        <Tag>Cloud</Tag>
                    </>
                )
            },
            center: true,
            width: "200px"
        },
        {
            name: 'STATUS',
            selector: row => {
                return ( 
                    <>
                        { row.status == 0 && <><Tag type={"warning"}>Deploying</Tag> <img src={deploying} style={{width:"30px"}}/></>}
                        { row.status == 1 && <Tag type={"danger"}>Error</Tag>}
                        { row.status == 2 && <Tag type={"warning"}>Ready to Configure</Tag>}
                        { row.status == 3 && <Tag type={"warning"}>Configuring</Tag>}
                        { row.status == 4 && <Tag type={"warning"}>Initial Configuration Done</Tag>}
                        { row.status == 5 && <Tag type={"warning"}>Scanning</Tag>}
                        { row.status == 6 && <><Tag type={"success"} >Running</Tag></>}
                        { row.status == 7 && <><Tag type={"warning"} >Deleting</Tag></>}
                        { row.status == 8 && <><Tag type={"danger"} >Deleted</Tag></>}
                        { row.status == 9 && <><Tag type={"warning"} >Changing State</Tag></>}
                        { row.status == 10 && <><Tag type={"warning"} >Disabled</Tag></>}
                    </>
                )
            },
            // center: true,
            width: "200px",
            sortable: true,
            sortField: "status",
        },
        {
            name: 'Action',
            selector: row =>  <Link to={`${connectorRoute[row.platform_type.platform_type_id]}/${row.platform_id}/details`} ><img src={settingsIcon} /></Link> ,
            width: "100px"
        }
        
    ]



    const loadSources = (currentPage = 1, search = "", sort=[], filter={}, loader = true)=>{
        console.log("API Call")
        let searchJSON = JSON.stringify([{key:"name", value:search}])
        let sortJSON = JSON.stringify(sort)
        let filterJSON = JSON.stringify(filter)
        
        GetService( API_URL + `/api/v1/platform/active/sources?current_page=${currentPage}&per_page=10&search=${searchJSON}&sort=${sortJSON}&filter=${filterJSON}`,
        {}, false, false, loader).then((response)=>{
            let respData = response.data.data
            setSources(respData.items)
            setTableInfo({...tableInfo, ...{loading: false, totalRows: respData.total_count }})
        })
    }

  

    const onPageChange = (page)=>{
        let newTableInfo = {...tableInfo, ...{loading: true, currentPage: page}}
        setTableInfo(newTableInfo)
        loadSources(page, tableInfo.search, tableInfo.sort, tableInfo.filter)
        refreshPage(newTableInfo)

    }
   
    const onSearch = (keyword)=>{
        let newTableInfo = {...tableInfo, ...{search: keyword}}
        setTableInfo(newTableInfo)
        loadSources(1, keyword, tableInfo.sort, tableInfo.filter)
        refreshPage(newTableInfo)
    }

    const sortTable = (selectedColumn, sortDirection, sortedRows)=>{
        
        loadSources(1, tableInfo.search, [{key: selectedColumn.sortField, order: sortDirection}])
        let newTableInfo = {...tableInfo, ...{sort: [{key: selectedColumn.sortField, order: sortDirection}]}}
        setTableInfo(newTableInfo)
        refreshPage(newTableInfo)
    }

    const refreshSources = ()=>{
        loadSources()
        refreshPage()
    }




    const onFilter = (data)=>{
        loadSources(tableInfo.currentPage, tableInfo.search, tableInfo.sort, data)
        let newTableInfo = {...tableInfo, ...{filter: data}}
        setTableInfo(newTableInfo)
        refreshPage(newTableInfo)
        
        
    }

    const refreshPage = (tableInfo = null )=>{

        clearInterval(timeout.current)
      
        timeout.current = setInterval(() => {
            if(tableInfo == null){
                loadSources()
            }else{
                loadSources(tableInfo.currentPage, tableInfo.search, tableInfo.sort, tableInfo.filter, false)
            }
        }, 10000);
    }

    useEffect(()=>{
        loadSources(1)
        refreshPage(tableInfo)
        return ()=>{
            clearInterval(timeout.current)
        }
    },[])


    


    const TELEMETRY = ["Metric", "Log", "Event"];

 


    return (
        <>
            
            <Table 
                columns={columns} 
                data={sources} 
                border={false} 
                currentPage={tableInfo.currentPage} 
                totalRows={tableInfo.totalRows} 
                onPageChage={onPageChange} 
                pagination={true} 
                loading={tableInfo.loading}
                containerStyle={{border: "0px"}}
                onRefresh={refreshSources}
                onSearch={onSearch}
                dataTableProps={{sortServer: true, onSort: sortTable}}
                filterData={filterData}
                onFilterApply={onFilter}
            />
        </>
    )
}

export default Sources;