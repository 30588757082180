import { confirmAlert } from 'react-confirm-alert';

const ConfirmDialogDefaultConfig = {
  confirmButton: {
    label: "Yes",
    callBackFunc: () => { },
    className: "confirm-button"
  }
  ,
  cancelButton: {
    label: "Cancel",
    callBackFunc: () => { },
    className: "cancel-button"
  },
  className: "op-confirm-dialog-box",
}



const ConfirmDialog = (title, confirmText, confirmCallback = () => { }, config ={}) => {


  const mergedJSON = { ...ConfirmDialogDefaultConfig, ...config };

  console.log({mergedJSON, config});

  const options = {
    title: "",
    message: title,
    buttons: [
      {
        label: confirmText,
        onClick: () => confirmCallback(),
        className: mergedJSON.confirmButton.className
      },
      {
        label: mergedJSON.cancelButton.label,
        onClick: mergedJSON.cancelButton.callBackFunc,
        className: mergedJSON.cancelButton.className
      }
    ],
    cancelText: "op-confirm-dialog-box"
  };

  confirmAlert(options)

}


const MessageBox = (message = () => { }, confirmText, confirmCallback = () => { }) => {

  const options = {
    title: "",
    childrenElement: message,
    buttons: [
      {
        label: confirmText,
        onClick: () => confirmCallback(),
        className: 'confirm-button'
      },
    ],
    overlayClassName: "op-confirm-dialog-box"
  };

  confirmAlert(options)

}


export default ConfirmDialog

export { MessageBox }