


import { Col, Container, Row } from "react-bootstrap"
import onepaneLogo from "assets/logo/onepane-logo.svg"
import PriceBadge from "../../components/PriceBadge/PriceBadge"
import Timeline from "../../components/Timeline/Timeline"
import InputComponent from "../../components/form/InputComponent/InputComponent"
import TelComponent from "../../components/form/TelComponent/TelComponent"
import SelectComponent from "../../components/form/SelectComponent/SelectComponent"
import countries from "./countries.json"
import { useCreditCardValidator, images } from 'react-creditcard-validator'
import { useState, useRef, useEffect } from "react"
import { useForm } from "react-hook-form"
import BillingRules from "./BillingValidator"
import { API_URL } from "../../config/config"
import PostService from "../../services/PostServices"
import cardIcon from "../../assets/icons/credit-card/card.svg"
import cvvIcon from "../../assets/icons/credit-card/cvv.svg"
import calendarIcon from "../../assets/icons/credit-card/calendar.svg"
import darkLeftArrow from "../../assets/icons/arrows/dark-arrow-left-s-line.svg"
import whiteRightArrow from "../../assets/icons/arrows/white-arrow-right-s-line.svg"
import { useNotifications } from "reapop"
import "./Organisation.css"
import GetService from "services/GetServices"


const getClass = (errors, touchedFields , field)=>{
    if(errors[field]){
        return "ops-input-text-invalid"
    }
    if (!errors[field] && touchedFields[field]){
        return "ops-input-text-valid"
    }
}

const SectionHeader = ({stage, subHeading})=> {
    return(
        <div className="create-organisation-status-header">
            <img className="logo" src={onepaneLogo} />
            <h2 className="heading">Create Organisation</h2>
            <Timeline timelines={["Choose a plan", "Billing Form", "Payment"]} stage={stage} style={{margin: "50px auto auto calc(50% - 148px)", marginTop: "50px", width: "380px"}} />
            
            {subHeading && <h3 className="sub-heading">{subHeading}</h3>}
        </div>
    )
}


const PriceSection = ({onPlanChoose})=>{

    const [active, setActive] = useState(0)
    const [pricings, setPricing] = useState([])

    const getPricings = ()=>{
        GetService(API_URL + "/api/v1/base/plan/details").then(response=>{
            setPricing(response.data.data.plans)
        })
    }


    useEffect(()=>{
        getPricings()
    },[])

    return(
        <div className="plan-section">
            <SectionHeader stage={1} subHeading={"Choose your plan"}/>
            <div className="price-list">
                {pricings?.map((item, index)=>{
                    return(
                        <PriceBadge disabled={!item.is_active} active={index == active} id={item.plan_id} name={item.plan_name}
                            vendor={item.plan_details.vendor} 
                            description={item.plan_details.description} 
                            price={item.plan_details.price} 
                            onChooseClick={onPlanChoose} 
                            onClick={()=>setActive(index)}  
                            freatures={item.plan_details.freatures} />
                    )
                })}
            </div>
        </div>
    )
}

const BillingAddress = ({formData, selectedPlan, onPeviousClick, onNextClick, formSubmitted} )=>{

    
    const [formSubmiting, setFormSubmiting] = useState(false)
    const { register, handleSubmit, watch, setError, formState } = useForm({
        mode: "onTouched",
    });
   
    const { errors, isValid, isSubmitting, touchedFields, submitCount, } = formState


   
    const [states, setStates] = useState([])
    const [selectedState, setSelectedState] = useState(null)
    const [selectedCountry, setSelectedCountry] = useState(null)
    const [selectedCountryCode, setSelectedCountryCode] = useState('+1')

    let countryList = countries.map(item=>{
        return { name:item.country, value:item.country }
    })
    let stateList = countries[0].states?.map(item=>{
        return { name:item, value:item }
    })
    
    const onCountryChange = (e)=>{
        setSelectedCountry(e)
        let tempStates = countries.find(item=>{
            if(item.country == e){
                return item.states
            }
            return false
        })
        let stateList = tempStates?.states?.map(item=>{
            console.log(item)
            return { name:item, value:item }
        })
        setStates(stateList)
    }

    const onStateChange = (e)=>{
        console.log({state:e})
        setSelectedState(e)
    }

    const onFormSubmit = (data)=>{
        data["country"] = selectedState
        data["state"] = selectedCountry
        data["phone_code"] = selectedCountryCode
        if(`${selectedCountryCode}-${data["phone"]}`.length > 15){
            setError("phone", { type: "focus", message: `Number should be less than ${15 - selectedCountryCode.length}` });
            return 
        }
        onNextClick(data)
       
    }

    console.log({formState})

    useEffect(()=>{
        setStates(stateList)
        setSelectedCountry(countryList[0].value)
        setSelectedState(stateList[0].value)
    },[])


    return(
        <div className="billing-section">
            <Container fluid className="create-org-container">
                <Row>
                    <Col lg={5} style={{backgroundColor: "#F9FAFF"}}>
                        <div className="plan-details">
                            <div className="header">
                                <h2 className="heading">Get started with free plan</h2>
                                <span className="sub-heading">The Starter plan is a good option for small businesses with up to 10 users.</span>
                            </div>
                            <div>
                                <PriceBadge type={"white"} style={{margin:"auto", marginTop: "30px"}} id={selectedPlan.id} name={selectedPlan.name} vendor={selectedPlan.vendor} description={selectedPlan.description} price={selectedPlan.price} freatures={selectedPlan.freatures}  />
                            </div>
                        </div>
                    </Col>
                    <Col lg={7} className="full-height" style={{paddingTop: "40px"}}>
                        <SectionHeader stage={2}/>
                        <div  style={{margin: "50px 120px 50px 120px"}}>
                            <form onSubmit={handleSubmit(onFormSubmit)} className={`op-form ${formState.isSubmitted === true ? "form-validated": ""}`}>
                                <Row>
                                    <Col>
                                        <InputComponent label="Organisation Name" defaultValue={formData.organisationName} className={getClass(errors, touchedFields, "organisationName")} error={errors.organisationName?.message} {...register("organisationName", BillingRules.OrganisationName)}  />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{marginTop: "7px"}}><span style={{color: "#061237",fontSize: "16px", fontWeight: "600"}}>Billing address</span></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <InputComponent label="Full Name" defaultValue={formData.fullname} className={getClass(errors, touchedFields, "fullname")} error={errors.fullname?.message} {...register("fullname", BillingRules.FullName)}  />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <InputComponent label="Email" defaultValue={formData.email} className={getClass(errors, touchedFields, "email")} error={errors.email?.message} {...register("email", BillingRules.email)} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <TelComponent  id="phone"  label="Phone" defaultValue={formData.phone} onCodeChange={(value)=>setSelectedCountryCode(value)} className={getClass(errors,touchedFields, "phone")}  error={errors.phone?.message} rules={{...register("phone", BillingRules.phone)}}  />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                       <InputComponent label={"Address 1"} defaultValue={formData.address1} className={getClass(errors, touchedFields, "address1")} error={errors.address1?.message} {...register("address1", BillingRules.address1)}  />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                       <InputComponent label={"Address 2"} defaultValue={formData.address2} className={getClass(errors,touchedFields, "address2")}  error={errors.address2?.message} {...register("address2", {})}  />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        
                                        <SelectComponent label={"Country"} search={true} options={countryList}  onChange={onCountryChange} defaultValue={(selectedCountry ?? formData.country )} hint=""   />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <SelectComponent label={"State"} search={true} options={states}  onChange={onStateChange} defaultValue={( selectedState ?? formData.state )}  hint="" />
                                    </Col>
                                    <Col>
                                       <InputComponent label={"Zip Code"} defaultValue={formData.zipcode} className={getClass(errors, touchedFields, "zipcode")} error={errors.zipcode?.message} {...register("zipcode", BillingRules.zipCode)}  />
                                    </Col>
                                </Row>
                                <Row style={{marginTop: '31px'}}>
                                    <Col lg={6}>
                                        <button className={`back-button`} style={{padding: "10px 0px"}} onClick={onPeviousClick} >
                                            <img src={darkLeftArrow}/>
                                            Back
                                        </button>
                                    </Col>
                                    <Col lg={6}>
                                        <button className={`ops-btn ops-btn-main  ops-singup-btn ${(isValid == false || formSubmiting == true || formSubmitted == true) ? 'ops-singup-btn-disabled' :""}`} style={{width: "140px", padding: "10px 0px", float: "right"}}  >
                                           {selectedPlan.id == 1 ? 'Procced' : <>
                                                Continue
                                                <img src={whiteRightArrow}/>
                                           </>}
                                        </button>

                                    </Col>
                                </Row>
                            </form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

const CardSection = ({formData, selectedPlan, billingDetails, onPeviousClick, onProceedClick})=>{

    const [formSubmiting, setFormSubmiting] = useState(false)
    const { register, handleSubmit, watch, formState, getValues} = useForm({
        mode: "onTouched",
    });

    const cardRef = useRef(null);
    const expiryRef = useRef(null);
    const cvvRef = useRef(null);

    const { errors, isValid, isSubmitting, touchedFields, submitCount } = formState

    const numberOnly = (e, length = 2)=>{
        if(!(e.keyCode >= 48 && e.keyCode <= 57) && ![8,9].includes(e.keyCode)){
            e.preventDefault()
        }
        if (e.target.value.length >= length && ![8,9].includes(e.keyCode)){
            e.preventDefault()
        }
        
       
    }

    function expDateValidate(month, year) {
        let shortYear = new Date().getFullYear() - 2000
        if (Number(year) <= shortYear) {
          return 'Expiry Date Year cannot be less than ' + shortYear.toString();
        }
        return;
    }

    // onCardNumbe

    const {
        getCardNumberProps,
        getCardImageProps,
        getCVCProps,
        getExpiryDateProps,
        meta: { erroredInputs }
      } = useCreditCardValidator({ expiryDateValidator: expDateValidate });
    

      console.log({erroredInputs})
      console.log({formState})
      const onBack = ()=>{
        let data = [];
        data["cardHolderName"] = getValues("cardHolderName")
        data["cardnumber"] = getCardNumberProps().ref.current.value;
        data["month"] = getValues("month")
        data["year"] = getValues("year")
        onPeviousClick()
      }

      const formSubmit = (data)=>{
       
        data["month"] = data.month < 10 ? "0"+data.month : data.month
        data["cardnumber"] = getCardNumberProps().ref.current.value
        onProceedClick(data)
        
      }

    return(
        <div className="card-section">
            <Container fluid className="create-org-container">
                <Row>
                    <Col lg={5} className="" style={{backgroundColor: "#F9FAFF"}}>
                        <div className="billing-details">
                            <div>
                                <span className="payment-summary">Payment Summary</span>
                            </div>
                            <div>
                                <div className="plan">
                                    <div className="plan-icon">
                                        <div className="icon-outer">
                                            <div className="icon-inner"></div>
                                        </div>
                                    </div>
                                    <div style={{marginLeft: '10px'}}>
                                        <span className="plan-for">{selectedPlan.vendor}</span>
                                        <span className="plan-name">{selectedPlan.name}</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span className="billing-address-header section-header">Billing Address</span>
                                    <p className="billing-address">
                                        {billingDetails.fullname}<br/>
                                        {billingDetails.email},<br/>
                                        {billingDetails.phone},<br/>
                                        {billingDetails.address1}<br/>
                                        {billingDetails.address2  && (<>{billingDetails.address2} <br/></>)}
                                        {billingDetails.country}, {billingDetails.state}<br/>
                                        {billingDetails.zipCode}
                                    </p>
                                </div>
                            </div>
                            <div className="tax-amount">
                                <span className="tax-amount-header section-header">Tax</span>
                                <span className="tax-amount-price">$0</span>
                            </div>
                            <div className="total-amount">
                                <span className="total-amount-header section-header">Total Amount</span>
                                <span className="total-amount-price">${selectedPlan.price}</span>
                            </div>
                        </div>
                    </Col>
                    <Col lg={7} className="card-details-section ">
                        <SectionHeader stage={3}/>
                        <div  style={{margin: "50px 120px 20px 120px"}}>
                            <form onSubmit={handleSubmit(formSubmit)} className={`${formState.isSubmitted === true ? "form-validated": ""} op-form`}>
                                <Row>
                                    <Col>
                                        <InputComponent label="Card Holder" className={getClass(errors, touchedFields, "cardHolderName")} error={errors.cardHolderName?.message} {...register("cardHolderName", BillingRules.CardHolderName)} defaultValue={formData.cardHolderName} />
                                    </Col>
                                </Row>
                                
                                <Row>
                                    <Col>
                                        <InputComponent label="Credit Card Number" 
                                            {...getCardNumberProps()} 
                                            beforeIcon={<svg {...getCardImageProps({ images })} width={"23px"} />} 
                                            afterIcon={<img src={cardIcon}/>} 
                                            showErrorIcon={false}
                                            className={erroredInputs["cardNumber"] ? "ops-input-text-invalid": "ops-input-text-valid"}
                                            defaultValue={formData.cardnumber}
                                            // className={getClass(errors, touchedFields, "cardNumber")} error={errors.cardNumber?.message} {...register("cardNumber", BillingRules.CardNumber)} 
                                            />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <div className="d-flex ">
                                            <div>
                                                <InputComponent label="Expiry Date"
                                                    placeholder={"MM"}
                                                    className={getClass(errors, touchedFields, "month")} 
                                                    {...register("month", BillingRules.month)}
                                                    showErrorIcon={true} 
                                                    onKeyDown={numberOnly}
                                                    defaultValue={formData.month}
                                                    error={errors.month?.message}
                                                />
                                            </div>
                                            <div className="month-year-seperate">
                                                /
                                            </div>
                                            <div>
                                            <InputComponent 
                                                label= "‎"
                                                placeholder={"YY"}
                                                className={getClass(errors, touchedFields, "year")} 
                                                {...register("year", BillingRules.year)}
                                                showErrorIcon={false}
                                                afterIcon={<img src={calendarIcon}/>}
                                                onKeyDown={numberOnly}
                                                defaultValue={formData.year}
                                                error={errors.year?.message}
                                            />
                                            </div>
                                        </div>
                                        
                                         
                                    </Col>
                                    <Col>
                                        <InputComponent 
                                        type = "password"
                                        label="CVV" 
                                        // placeholder={"CVV"}
                                        className={getClass(errors, touchedFields, "cvv")} 
                                        {...register("cvv", BillingRules.CVV)}
                                        afterIcon={<img src={cvvIcon}/>}
                                        onKeyDown={(e)=>numberOnly(e,3)}
                                        error={errors.cvv?.message}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-30">
                                    <Col lg={6}>
                                        <button className={`back-button`} style={{padding: "10px 0px"}} onClick={onBack} >
                                                <img src={darkLeftArrow}/>
                                                Back
                                        </button>
                                    </Col>
                                    <Col lg={6}>
                                        <button className={`ops-btn ops-btn-main  ops-singup-btn ${(erroredInputs["cardNumber"] || isValid == false || isSubmitting == true) ? 'ops-singup-btn-disabled' :""}`} style={{width: "140px", padding: "10px 0px", float: "right"}}  >
                                            Procced
                                        </button>
                                    </Col>
                                </Row>
                               <Row>
                                <Col>
                                    <p className="credit-card-info">
                                        We will only use your credit card information to process payments for things you buy from us.
                                        We won't share your credit card information with anyone else without your permission.
                                    </p>
                                </Col>
                               </Row>
                            </form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
 

const CreateOrganisationLandscape = (props)=>{

    const [step, setStep] = useState(1)
    const [selectedPlan, setSelectedPlan] = useState({})
    const [billingDetails, setBillingDetails] = useState({})
    const [cardDetails, setCardDetails] = useState({})
    const [formSubmitted, setFormSubmitted] = useState(false)
    
    const { notify, dismissNotification } = useNotifications()

    const onPlanChoose = (data)=>{
        setSelectedPlan(data)
        setStep(2)
    }

    const OnBillingClick = (data)=>{
        console.log({BillingDetails:data})
        let billData = {selectedPlan, ...data}
        setBillingDetails(data)
        if(selectedPlan.id == 1){
            onProceedClick(billData)
        }else{
            setStep(3)
        }
        
    }

    const onPaymentBack = (cardDetails)=>{
        setCardDetails(cardDetails) 
        setStep(1)
    }

    const onProceedClick = (cardDetails)=>{
        console.log({selectedPlan})
        console.log({billingDetails})
        console.log({cardDetails})
        setCardDetails(cardDetails) 
        setFormSubmitted(true)
        let reqBody  = {}
        
        reqBody['plan_id'] = cardDetails.selectedPlan.id.toString();
        reqBody['org_name'] = cardDetails.organisationName;
        reqBody['full_name'] = cardDetails.fullname;
        reqBody['email'] = cardDetails.email;
        reqBody['phone'] = cardDetails.phone_code.toString() + "-" + cardDetails.phone.toString();
        reqBody['primary_address'] = cardDetails.address1;
        reqBody['secondary_address'] = cardDetails.address2;
        reqBody['country'] = cardDetails.country;
        reqBody['state'] = cardDetails.state;
        reqBody['zip_code'] = cardDetails.zipcode.toString();
        if (cardDetails.selectedPlan.id != 1){
            reqBody['card_expiry'] = cardDetails.month + "/" + cardDetails.year;
            reqBody['card_cvv'] = cardDetails.cvv;
            reqBody['card_number'] = cardDetails.cardnumber.replaceAll(" ","");
            reqBody['card_holder_name'] = cardDetails.cardHolderName;
        }
        console.log({reqBody})
        PostService(API_URL + "/api/v1/base/organisation/create", reqBody, true, true, false).then(response=>{
            let resData = response.data;
            console.log({resData})
            if(resData.success == false){
                setFormSubmitted(false)
                if(resData.validation?.length > 0){
                    notify(resData.validation[0].Message?.replaceAll("_", " "),{dismissAfter: 2500 })
                }else{
                    notify(resData.description,{dismissAfter: 2500 })
                }
            }else{
                console.log("create")
                window.location.href = '/overview'
                //props.navDisableUpdate(1, true)
            }


           
        })
    }
    return(
        <>
            <div className="organisation-creation--landscape">
                {step == 1 && <PriceSection onPlanChoose={onPlanChoose} />}
                {step == 2 && <BillingAddress selectedPlan={selectedPlan} formData={billingDetails}  onPeviousClick={onPaymentBack} onNextClick={OnBillingClick} formSubmitted={formSubmitted} />}
                {step == 3 && <CardSection selectedPlan={selectedPlan} formData={cardDetails} billingDetails={billingDetails}  onPeviousClick={()=>setStep(2)} onProceedClick={onProceedClick} />}
            </div>
        </>
        
    )


}



export default CreateOrganisationLandscape