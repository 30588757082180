import AppSettingStore from "stores/NotificationSystem/AppSettingStore"


const PERMISSIONS = {
    "1": {
        "routes":[],
        "allow":[
            "PROFILE_ORGANISATION",
            "ORGANISATION_SWICTH",
            "ORGANISATION_LIST",
            "ORGANISATION_CREATE",

            "SETTINGS_DELETE_ORGANISATION",
            "SETTINGS_MEMBER",
            "SETTINGS_DELETE_USER",
            "SETTINGS_INVITE_MEMBER",
            "SETTINGS_EDIT_MEMBER",
            "SETTINGS_EDIT_INVITE",
            "SETTINGS_BILLING",
            "SETTINGS_USAGE",

            "INTEGRATION_ADD",
			"INTEGRATION_EDIT",
			"INTEGRATION_VIEW_CONFIG",

            "SERVICE_EDIT",
            "RESOURECE_EDIT",
        ],
        "deny": []
    },
    "2": {
        "routes":[],
        "allow":[
            "PROFILE_ORGANISATION",
            "ORGANISATION_SWICTH",
            "ORGANISATION_LIST",
            "ORGANISATION_CREATE",


            "SETTINGS_MEMBER",
            "SETTINGS_DELETE_USER",
            "SETTINGS_BILLING",
            "SETTINGS_USAGE",

            "INTEGRATION_ADD",
			"INTEGRATION_EDIT",
			"INTEGRATION_VIEW_CONFIG",

            "SERVICE_EDIT",
            "RESOURECE_EDIT",
        ],
        "deny": []
    },
    "3": {
        "routes":[],
        "allow":[
            "PROFILE_ORGANISATION",
            "ORGANISATION_SWICTH",
            "ORGANISATION_LIST",
            "ORGANISATION_CREATE",
            
            "SETTINGS_DELETE_USER"
        ],
        "deny": []
    },
    "4": {
        "routes":[],
        "allow":[],
        "deny": []
    },
    "5": {
        "routes":[],
        "deny":[],
        "allow": [
            "DEMO_USER"
        ]
    },

}


const RBACWrapper = ({children, role, action})=>{


    const appSettings = AppSettingStore()

    return(
        <>
            <></>
            {
                PERMISSIONS[appSettings.user.role]?.["allow"]?.includes(action) && <>
                    {children}
                </>
            }
        </>
    )
}

RBACWrapper.hasPermission = (action)=>{
    const appSettings = AppSettingStore()
    return PERMISSIONS[appSettings.user.role]?.["allow"]?.includes(action)
} 

RBACWrapper.defaultProps = {
    role: 1,
    action: ""
}

export default RBACWrapper