import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import costIcon from "assets/icons/services/cost.svg"
import { HiOutlineChartPie } from 'react-icons/hi'
import { useState } from 'react'
import Card from 'components/Card/Card'
import { RiExternalLinkLine } from 'react-icons/ri'
import Overviewchart from 'components/OverviewChart/Overviewchart'
import { getRandomColor } from 'utils/util'
import LineGraph from 'components/LineGraph/LineGraph'
import { CHART_COLORS, MONTH_FROM_NUMBER } from 'config/constants'
import GetService from 'services/GetServices'
import { API_URL } from 'config/config'


const HeadingTopComponent = ({ Heading, link, icon, count, total, showall }) => {

    const [showLink, setshowlink] = useState(showall)

    return (
        <><div className='op-heading'>
            <div style={{ flexGrow: "4" }}>
                <span className='resource-chart-container-title'> {icon} {Heading}</span>
            </div>
        </div>
            <div className="subHeadingMArginTOP">
                <div style={{ display: "flex" }}>
                    <span className='costCount'>
                        {count}
                    </span>
                    <span className='totalCostHeading'>
                        {total}
                    </span>
                </div>

                {showLink ? (<div>
                   <Link to={link}> <span className='showallText'>Show all <RiExternalLinkLine size={12} /></span></Link>
                </div>) : (null)}


            </div></>
    )
}

HeadingTopComponent.defaultProps = {
    Heading: "",
    icon: {},
    count: "",
    total: "",
    showall: false,
}


const ChartDescription = ({title, count})=>{
    return(
        <>
            <div className="d-flex service-margin-for-graph">
                <div className="col-sm-1 servive-env-count pr-0">{count}</div>
                <div className="col-sm-9 service-env-title">{title}</div>
            </div>
        
        </>
    )
}




const Cost = (props) => {
    const [chartOpt, setChartOpt] = useState({})

    const [highestUtilizedResources, setHighestUtilizedResources] = useState([])
    const [costPerEnv, setCostPerEnv] = useState([])
    const [costPerOwner, setCostPerOwner] = useState([])
    const [costPerMonth, setCostPerMonth] = useState([])
    const {serviceId } = useParams()


    const costDistribution = ()=>{
        GetService(API_URL + `/api/v1/service/${serviceId}/cost_distribution`).then(response=>{
            console.log({response})
            let data = response.data.data;

            let tempCostDistributionResource = []
            data?.cost_distribution_resource?.slice(0,5)?.map(item=>{
                tempCostDistributionResource.push({
                    resourceName: item.name,
                    cost: parseFloat(item.total_cost).toFixed(2) ?? 0.00
                })
            })
            setHighestUtilizedResources(tempCostDistributionResource)

            let tempCosPerEnv = []
            data?.cost_distribution_env?.map((item, index)=>{
                tempCosPerEnv.push({
                    value: parseFloat(item.total_cost).toFixed(2) ?? 0.00,
                    name: item.env,
                    itemStyle: {
                        color: CHART_COLORS[index],
                    },
                })
            })
            setCostPerEnv(tempCosPerEnv)

            let tempCostPerOwner = []
            data?.cost_distribution_owner?.map((item, index)=>{
                tempCostPerOwner.push({
                    value: parseFloat(item.total_cost).toFixed(2) ?? 0.00,
                    name: item.owner,
                    itemStyle: {
                        color: CHART_COLORS[index],
                    },
                })
            })
            setCostPerOwner(tempCostPerOwner)

            let tempCostPerMonth = []
            data?.cost_time_line?.map((item, index)=>{
                tempCostPerMonth.push({
                    cost: parseFloat(item.total_cost).toFixed(2) ?? 0.00 ,
                    month: MONTH_FROM_NUMBER[item.month] + item.year
                })
            })
            setCostPerMonth(tempCostPerMonth)
        })
    }


    useEffect(()=>{
        costDistribution()
    },[])

    return (
        <>
            <div className="service-title-container">
                <div style={{ flexGrow: "1" }}>
                    <div style={{ flexGrow: "1" }}>
                        <div className="service-overview-title">
                            <div>
                                <img src={costIcon} style={{ marginTop: "0px" }} /> <span className="service-title">Cost Details</span>
                            </div>
                            <div className="service-overview-title-more-info">
                               {props.titleInfo}
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div>
                    {props.serviceTitleMenu}
                </div>
            </div>
            <div className='row op-mt-14 op-mx-10'>
                <div className="col-md-4" >
                    <Card type="full" style={{height: "100%"}}>
                        <Card.CardBody style={{ backgroundColor: "#FCFCFD" }}>
                            <HeadingTopComponent showall={true} link={`/resources?&service=${props?.serviceData?.service_id}`} total={"Highest Utilized Resource"} count={highestUtilizedResources?.length} icon={<HiOutlineChartPie fontSize={24} color='#6B53AE' style={{ marginRight: "3px" }} />} Heading={"Highest Utilized Resources "} />
                            {highestUtilizedResources.map((item, index) => {
                                return (
                                    <div className='utilizeStoreMarginTop' key={index}>
                                        <div className='service-Name span-ellipsis'>
                                            {item.resourceName}
                                        </div>
                                        <div className='service-Cost'>
                                            <span className='greenCOlor'>$</span><span> { parseFloat(item.cost).toFixed(2)}</span>
                                        </div>
                                    </div>
                                );
                            })}
                            {highestUtilizedResources?.length == 0 && <span className='span-no-value' style={{display: "block", margin: "25% 0% 0% 33%", color: "#4d4c53"}}>no data available</span>}
                        </Card.CardBody>
                    </Card>
                </div>
                <div className="col-md-4">
                    <Overviewchart description={<ChartDescription title={"Total Environment"} count={costPerEnv.length} />}  title="Cost per Environment" data={costPerEnv} shouldRound launch={false} alignment={"vertical"} showPercentage={true} />
                </div>
                <div className="col-md-4">
                    <Overviewchart description={<ChartDescription title={"Total Owners"} count={costPerOwner.length} />}  showEnviornmentCount={true} title="Cost per Owner" data={costPerOwner} shouldRound launch={false} alignment={"vertical"} showPercentage={true} />
                </div>
                {/* <div className="col-md-8">
                    <Card type="full">
                        <Card.CardBody style={{ backgroundColor: "#FCFCFD" }}>
                            <HeadingTopComponent showall={true} total={"Total Recommendations"} count={6} icon={<HiOutlineChartPie fontSize={24} color='#6B53AE' style={{ marginRight: "3px" }} />} Heading={"Recommendations "} />

                            {RecommendationsAlerts.map((item, index) => (
                                <div key={index}>
                                    <div className='alertmainDiv' style={{ backgroundColor: item.bgcolor, borderLeft: `4px solid ${item.bordercolor}` }}>
                                        {item.message}
                                    </div>
                                </div>
                            ))}

                        </Card.CardBody>
                    </Card>
                </div> */}
            </div>
            <div className="row op-mt-14 op-mx-10 op-mb-20">
               
                <div className="col-md-4">
                    <Card type="full" style={{ margin: "0px" }}>
                        <Card.CardBody style={{ backgroundColor: "#FCFCFD" }}>
                            <HeadingTopComponent total={"Total Cost"} count={"$" + parseFloat(costPerMonth.reduce((sum, item) => sum + item.cost, 0)).toFixed(2)} icon={<HiOutlineChartPie fontSize={24} color='#6B53AE' style={{ marginRight: "3px" }} />} Heading={"Cost per Month"} />
                        </Card.CardBody>
                        <div style={{ width: "100%", height: "265px" }}>
                            <LineGraph data={costPerMonth} lineColor={"#FDBC3E"} />
                        </div>
                    </Card>
                </div>
               
            </div>
        </>
    )
}

export default Cost