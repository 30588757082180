import { useEffect, useState } from "react"
import collapseIcon from "./assets/card-collapse-button.svg"
import exandIcon from "./assets/card-expand-button.svg"
import "./Card.css"

const CardBody = ({ children, ...props }) => {
    return (
        <div className="op-inner-card" {...props}>
            {children}
        </div>
    )
}




const Card = ({ title, type, children, className, collapseButton, expand, ...props }) => {

    const [cardCollapse, setCardCollapse] = useState(false)

    useEffect(()=>{
        setCardCollapse(!expand)
    },[expand])

    return (
        <div className={`op-card ${cardCollapse == true ? 'op-collapse' : ''} ${type} ${className}`} {...props}>
            {title !== "" && (
                <div className="op-card-title-container">
                    <div className="op-card-title">{title}</div>
                    {collapseButton && <><img src={cardCollapse == true ? exandIcon : collapseIcon} className="op-card-collapse-icon" onClick={() => setCardCollapse(!cardCollapse)} /></>}
                </div>
            )}
            {children}
        </div>
    )

}

Card.CardBody = CardBody

Card.defaultProps = {
    title: '',
    children: '',
    className: '',
    type: "",
    collapseButton: false,
    expand: true
}

export default Card