import  GeneralLayout from "pages/general-layout/GeneralLayout"

import CreateOrganisationLandscape from "sections/Organisation/CreateOrganisationLandscape";
import CreateHostOrganisationLandscape from "sections/Organisation/CreateHostOrganisationLandscape";

const NewOrganisation = ({user, currentOrg, organizations, updateNavDisable})=>{


    return(
         <GeneralLayout showProfile={true} user={user} org={currentOrg} orgList={organizations}>
            {process.env.REACT_APP_SAAS_MODE == 1 && <CreateOrganisationLandscape navDisableUpdate={updateNavDisable}/>}
            {process.env.REACT_APP_SAAS_MODE == 0 && <CreateHostOrganisationLandscape navDisableUpdate={updateNavDisable}/>}
        </GeneralLayout>
    )
}

export default NewOrganisation