import React from 'react';
import "./ResolvedUnresolvedTimeLine.css";

function ResolvedUnresolvedTimeLine({ isStage2Completed, isStage2Successful, stage1, stage2 }) {
  return (
    <>
      <div className="ResolvedTimedDiv">
        <div className={`stages ${isStage2Successful ? 'successful' : ''}`}>
          <div className={`stageCount ${isStage2Completed ? 'completed' : ''}`}>1</div>
          <div className={`stageTitle ${isStage2Completed ? 'completed' : ''}`}>{stage1}</div>
        </div>
        <div className={`processLine ${isStage2Completed ? 'completed' : ''}`}>
          <div></div>
        </div>
        <div className={`unstages ${isStage2Successful ? 'successful' : ''}`}>
          <div className={`stageCount ${isStage2Completed ? 'completed' : ''}`}>2</div>
          <div className={`stageTitle ${isStage2Completed ? 'completed' : ''}`}>{stage2}</div>
        </div>
      </div>
    </>
  );
}


ResolvedUnresolvedTimeLine.defaultProps = {
  isStage2Completed: false,
  isStage2Successful: false,
  stage1: "Fix unresolved",
  stage2: "Review changes"
  
};

export default ResolvedUnresolvedTimeLine;
