import HorizontalTab from "components/Tab/HorizontalTab";
import { RiUserLine } from "react-icons/ri";
import Users from "./Members/Users";
import Invites from "./Members/Invites";
import Card from "components/Card/Card";


const Members = () => {
    

    const cardTitle = <>
        <div className='billingHeader padding_X' style={{ marginTop: "0px", borderTop: "none", paddingTop: "0px" }}>
            <div className='TitleDiv'>
                <span><RiUserLine size={18} color='#6B53AE' /></span><span className='tileHeader'>User List and Invites</span>
            </div>
        </div>
    </>

    return (
        <>
            <div className="org-basic-panel org-member-panel">
                <Card title={cardTitle} >
                    <HorizontalTab
                        tabClass={"member-user-invite-tab-container"}
                        panelClass={"member-user-invite-tab-panel"}
                        tabs={[
                            {
                                title: <span>User List </span>,
                                children: <Users />
                            },
                            {
                                title: <span>Invites </span>,
                                children: <Invites />,
                            },
                        ]}

                        tabStyle="line" />
                </Card>
            </div>
        </>
    );
}

Members.defaultProps = {};

export default Members;
