
import { Col, Row } from "react-bootstrap";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Card from "components/Card/Card";
import ResolvedUnresolvedTimeLine from "components/ResolvedUnresolvedTimeLine/ResolvedUnresolvedTimeLine";
import InputComponent from "components/form/InputComponent/InputComponent";
import DashboardBody from "pages/newlayout/DashboardBody";

import SucessfullySolvedComponent from "components/TableEmptySuccess/TableEmptySuccess";
import { GrConfigure } from "react-icons/gr";
import Select from "components/V1/Select/Select";
import Button from "components/Elements/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import HowToLink from "components/V1/HowToLink/HowToLink";
import { useEffect, useState } from "react";
import GetService from "services/GetServices";
import { API_URL } from "config/config";
import { CRITICALITIES_FOR_OPTION } from "config/constants";
import { getCriticalityStatus, getCriticalityStringByNumber, getUserListForOption } from "utils/util";
import CardList from "components/CardList/CardList";
import Checkbox from "components/V1/Checkbox/Checkbox";
import { RiDatabase2Line } from "react-icons/ri";
import Table from "components/Table/Table";
import Avatar from "components/Avatar/Avatar";
import PostService from "services/PostServices";
import { useNotifications } from "reapop";
import { inputDecimalOnly, inputNumberOnly } from "utils/eventUtil";

const ServiceFix = ()=> {

    const {serviceId } = useParams()
    const [userList, setUserList] = useState()
    const [serviceDetails, setServiceDetails] = useState({})
    const [serviceResources, setServiceResources] = useState([])
    const [resourceList, setResourceList] = useState([])
    const [nonSelectedResourceList, setNonSelectedResourceList] = useState([])
    const [serviceUpdateDetails, setServiceUpdateDetails] = useState({})
    const [updatedServiceName, setUpdatedServiceName] = useState("")
    const [serviceResourceTableInfo, setServiceResourceTableInfo] = useState({totalRows: 0 , currentPage: 1, loading: true, search: "", sort:[], filter: {}})
    const [serviceNonSelectedResourceTableInfo, setServiceNonSelectedResourceTableInfo] = useState({totalRows: 0 , currentPage: 1, loading: true, search: "", sort:[], filter: {}})
    const [confirmTableData, setConfirmTableData] = useState([])
    const [stage, setStage] = useState(1)
    const [selectAllResources, setSelectAllResources] = useState(true)
    const [displayResource, setDisplayResource] = useState(false)

    // var seletedResourceIdsForCard = []
    // var removedResourceIdsForCard = []
    // var removedResourceDataForCard = []


    var [selectedResourceIds, setSeletedResourceIds] = useState([])
    var [removedResourceIds,  setRemovedResourceIds] = useState([])
    var [removedResourceData, setRemovedResourceData] = useState([])

    
  
    const { notify } = useNotifications()
    const navigate = useNavigate()

    const onCancel = ()=>{
        navigate(`/services/${serviceId}/details`)
    }

    const showNotification = (text, status="success")=>{
        notify( text, status,{
            dismissAfter: 1500
        })
    }

    const onServiceNameChange = (e)=>{
        setServiceUpdateDetails({...serviceUpdateDetails,...{name: e.target.value}})
    }

    const onOwnerUpdate = (selectedItem)=>{
        setServiceUpdateDetails({...serviceUpdateDetails,...{owner_name: selectedItem.label, owner_id: selectedItem.value}})
    }

    const onCriticalityUpdate = (selectedItem)=>{
        setServiceUpdateDetails({...serviceUpdateDetails,...{criticality: selectedItem.label}})
    }
    const onBudgetUpdate = (e)=>{
        setServiceUpdateDetails({...serviceUpdateDetails,...{budget: e.target.value}})
    }



    const reviewColumns = [
        {
            name: 'RESOURCE NAME',
            selector: row => {
                return ( 
                    <>
                       <div className="git-repo" >
                            <span><RiDatabase2Line color="#2DA077" size={20} /> </span>
                            <span className="repo-name ops-common-hover">{row.name}</span>
                        </div>
                    </>
                )
            },
            sortable: true,
        },
        {
            name: 'SERVICE',
            selector: row => <span className="table-span">{serviceUpdateDetails.name}</span>,
            sortable: true,
        },
        {
            name: 'OWNER',
            selector: row => serviceUpdateDetails.owner_name?.replaceAll(" ","") != "" ? <Avatar username={serviceUpdateDetails.owner_name} imageUrl="" /> : <span className="no-value-span">Not assigned</span>,
            sortable: true,
        },
        {
            name: 'CRITICALITY',
            selector: row => getCriticalityStatus(row.tags["Criticality"]),
            sortable: true,
        },
        {
            name: 'TYPE',
            selector: row => <span className="table-span">{row.original_type}</span>,
            sortable: true,
        },

    ]
  

    const getRemovedResourceIDs = ()=>{
        let tempResourceIds = [];
        for(const item of confirmTableData){
            tempResourceIds.push(item.ci_id)
        }
        
        return tempResourceIds
    }


    const createResourceList = (removedIds = [])=>{
        let applicationsList = []
        // console.log({selectAllResources, removedResourceIds, selectedResourceIds})
        // console.log({gen:removedIds})
        resourceList.map((item, index)=>{
            // let checkApp = false;
            // let checkApp = true 
            // if(selectAllResources){
                 
            // }else{
            //         checkApp = !selectedResourceIds.includes(item.ci_id)
            // }
            let checkApp = false
            if(selectAllResources){
                 checkApp = !removedResourceIds.includes(item.ci_id)
            }else{
                checkApp = selectedResourceIds.includes(item.ci_id)
            }
           
            // console.log({checkApp, item})
            applicationsList.push(
                {
                    id:item.ci_id,
                    data: item,
                    selected: item.selected,
                    render:<>
                        <div className="service-modify-respource-row">
                            <div className="respource-checkbox">
                               <Checkbox 
                               checked={checkApp}
                               onChange={(e)=>onApplicationSelect(e, item.ci_id, item)} 
                               />
                            </div>
                            <div className="resource-name-div">
                                <span><RiDatabase2Line color="#2DA077" size={20} /> </span>
                                <span className="resource-name">{item.name}</span>
                            </div>
                            <div className="resource-type-name-div">
                                Type : <span className="resource-type-name">{item.original_type}</span>
                            </div>
                        </div>
                    </>
                }
                
            )
        })
        setServiceResources(applicationsList)
    }

    const onSelectAllResources = (e)=>{
        setSelectAllResources(e.target.checked)

        let selectedIds = JSON.parse(JSON.stringify(selectedResourceIds));
        let removedIds =  JSON.parse(JSON.stringify(removedResourceIds));
        let removedData = JSON.parse(JSON.stringify(removedResourceData)); 

        if(e.target.checked){
            resourceList.forEach((item) => {
                selectedIds.push(item.ci_id)
            });
            setSeletedResourceIds(selectedIds)
            setRemovedResourceIds([])
            setRemovedResourceData([])
            setConfirmTableData([])
            createResourceList([])
        }else{
            
            resourceList.forEach((item) => {
                removedIds.push(item.ci_id)
                removedData.push(item)
            });

            setSeletedResourceIds([])
            setRemovedResourceIds(removedIds)
            setRemovedResourceData(removedData)
            setConfirmTableData(removedData)
            createResourceList(removedIds)
        }
       
    }

    useEffect(()=>{
        createResourceList(removedResourceIds)
    },[removedResourceIds, selectedResourceIds])


    const onApplicationSelect = (element, id, data)=>{
        let rawItems = JSON.parse(JSON.stringify(selectedResourceIds));
        let removedItems =  JSON.parse(JSON.stringify(removedResourceIds));
        let removeData = JSON.parse(JSON.stringify(removedResourceData)); 
        
        if(element.target.checked == true && rawItems.includes(id) == false){
            rawItems.push(id)
            let itemIndex = removedItems.indexOf(id);
            removedItems.splice(itemIndex,1)
            removeData.map((item, index)=>{
                if(item.ci_id == id){
                    removeData.splice(index,1)
                }
            })
           
        }else{
            let itemIndex = rawItems.indexOf(id);
            rawItems.splice(itemIndex,1)
            removedItems.push(id)
            removeData.push(data)
           
        }

        setSeletedResourceIds(rawItems)
        setRemovedResourceIds(removedItems)
        setRemovedResourceData(removeData)
        setConfirmTableData(removeData)
    
        
    }

    const getServiceDetails = async ()=>{
        let users = await getUserListForOption()
        setUserList(users)
        GetService(API_URL + `/api/v1/service/${serviceId}/get`).then(response=>{
            let data = response.data.data.service
            setServiceDetails(data)
            setServiceUpdateDetails({
                name: data.name,
                owner_name: `${data?.owner?.first_name} ${data?.owner?.last_name}`,
                owner_id : data?.owner?.user_id,
                criticality: getCriticalityStringByNumber(data.priority),
                budget: parseFloat(data.budget).toFixed(2) ?? 0.00
            })
            setUpdatedServiceName(data.name)
            getServiceResources()
        })

    }


    const getServiceResources = (page = 1, search = "")=>{
        GetService(API_URL + `/api/v1/service/${serviceId}/resources?current_page=${page}`).then(response=>{
            let data = response.data.data
            setResourceList(data.items)
            setServiceResourceTableInfo({...serviceResourceTableInfo, ...{loading: false, totalRows: data.total_count, currentPage:page }})
        })
    }

    const getNonSelectedResources = (page = 1, search = "")=>{
        let removedIDs  = getRemovedResourceIDs()
        let mode = 1;
        if(!selectAllResources){
            mode = 2;
            removedIDs = selectedResourceIds
        }

        console.log({mode, removedIDs})
        PostService(API_URL + `/api/v1/service/${serviceId}/modify/resources?current_page=${page}&mode=${mode}`,{
            removed_resources: removedIDs
        }).then(response=>{
            let data = response.data.data
            setNonSelectedResourceList(data.items)
            setServiceNonSelectedResourceTableInfo({...serviceResourceTableInfo, ...{loading: false, totalRows: data.total_count, currentPage:page }})
            setStage(2)
        })
    }


    const  onResourceChangePage = (page)=>{
        getServiceResources(page)
    }
    const  onNonSelectedResourceChangePage = (page)=>{
        getNonSelectedResources(page)
    }



    //mode 1, 2
    // 1 selected all and unselected resource
    // 2 deletedall and selected



    const onConfirmData = async ()=>{
        //setStage(2)
        if(serviceUpdateDetails.name == ""){
            showNotification("Please fill service name", "error")
            return
        }

        if(displayResource){
            if(updatedServiceName != serviceUpdateDetails.name){
                let response = await PostService(API_URL + `/api/v1/service/name/check?service_name=${serviceUpdateDetails.name}`,{}, {},true, true, false,)  
                if(response.data.success == true){
                    getNonSelectedResources()
                }else{
                    showNotification("Service name not valid", "error")
                }
            }else{
                getNonSelectedResources()
            }
        }else{
            onUpdateService()
        }
    }

    const onUpdateService = ()=>{
        let removedIDs  = getRemovedResourceIDs()
        let propogate = 0;
        if (displayResource && selectAllResources){
            propogate = 1;
        }
        if (displayResource && !selectAllResources){
            propogate = 2
            removedIDs = selectedResourceIds
        } 

        let JSONbody = {
            removed_resources: removedIDs,
        }
        PostService(API_URL + `/api/v1/service/${serviceId}/modify?service_name=${serviceUpdateDetails.name}&owner_id=${serviceUpdateDetails.owner_id}&criticality=${serviceUpdateDetails.criticality}&propogate=${propogate}&budget=${ parseFloat(serviceUpdateDetails.budget).toFixed(2)}`, JSONbody).then(response=>{
           if(response.data.success){
            showNotification("Updated Successfully")
            setStage(3)
           }else{
            showNotification(response.data.description, "error")
           }
        })
    }

    useEffect(()=>{
        //console.log({removedResourceIdsForCard})
        createResourceList(removedResourceIds)
    },[resourceList])

    useEffect(()=>{
        getServiceDetails()
    }, [])


    return(
        <>
            <Breadcrumbs section={[{"name": "Services", "path": "/services"}, { "name":"Modify Service", "path": ""}]} />
            <DashboardBody>

                <ResolvedUnresolvedTimeLine
                    stage1="Modify Service"
                    isStage2Completed={stage == 2 || stage == 3  ? true: false} 
                    isStage2Successful={stage == 3 ? true: false}
                />
           
                
            { stage == 1 && <>
            <div style={{marginTop: "20px"}}>
                <Card
                    title={<span><GrConfigure color={"#6B53AE"} style={{marginRight: "20px"}} size={19} />Modify Service</span>}
                    type={"full"}

                >
                    <Card.CardBody>
                        <div>
                            <Row style={{marginBottom: "20px"}}>
                                <Col>
                                    <span className="service-title" >Edit Service Name</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4">
                                    <InputComponent label="Service Name" defaultValue={serviceDetails.name} onChange={onServiceNameChange}  />
                                    <span style={{display: "block", marginTop: "8px", fontSize: "12px", marginLeft: "4px", color: "var(--danger-3)"}}>The change in service name will have an effect on all resources.</span>
                                </Col>
                            </Row>
                            
                            
                        </div>
                    </Card.CardBody>
                    <div className="card-divider"></div>
                    <Card.CardBody>
                            <Row style={{marginBottom: "20px"}}>
                                <Col>
                                    <span className="service-title" >Select Owner</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4">
                                    <Select  label={"Owner Name"} placeholder={`${serviceUpdateDetails.owner_name != " "? serviceUpdateDetails.owner_name: "select user" }`} options={userList} onChange={onOwnerUpdate} style={{width: "410px"}}/>
                                </Col>
                            </Row>
                    </Card.CardBody>
                    <div className="card-divider"></div>
                    <Card.CardBody>
                            <Row style={{marginBottom: "20px"}}>
                                <Col>
                                    <span className="service-title" >Select Criticality</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4">
                                    <Select label={"Select Criticality"} placeholder={serviceUpdateDetails.criticality} options={CRITICALITIES_FOR_OPTION} onChange={onCriticalityUpdate} style={{width: "410px"}}/>
                                </Col>
                            </Row>
                    </Card.CardBody>
                    <div className="card-divider"></div>
                    <Card.CardBody>
                            <Row style={{marginBottom: "20px"}}>
                                <Col>
                                    <span className="service-title" >Sevice Budget</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4">
                                    <InputComponent label="Enter Budget for service" beforeIcon={ <span className="service-dollar-symbol">$</span>} className="service-budget-input" defaultValue={serviceDetails.budget} onChange={onBudgetUpdate} onKeyDown={inputDecimalOnly}/>
                                </Col>
                            </Row>
                    </Card.CardBody>
                </Card>
            </div>

            <div className="">
                <Card>

                    <Card.CardBody>
                        <Row >
                            <Col>
                                <span className="service-title" >Select Resources</span>
                                <div style={{marginTop: "20px", marginLeft: "6px"}}>
                                    <Checkbox label={"Selecting this checkbox will apply Owner and Criticality changes to the chosen underlying resources of this service"} onChange={(e)=>setDisplayResource(!displayResource)} />
                                </div>
                            </Col>
                        </Row>
                        <div style={{display: displayResource ? "block": "none", marginTop: "10px" }}>
                            <Row>
                                <Col>
                                    
                                        <div>
                                            <div className="service-resource-select-deselect">
                                                <div>
                                                    <Checkbox label={"Select All"} checked={selectAllResources} onChange={onSelectAllResources} />
                                                </div>
                                            </div>
                                        </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <CardList 
                                        controls={false} 
                                        pagination={true} 
                                        paginationProps= {{rowCount: serviceResourceTableInfo.totalRows, rowsPerPage: 10, currentPage:serviceResourceTableInfo.currentPage, onChangePage: onResourceChangePage}}
                                        data={serviceResources} 
                                        />
                                </Col>
                            </Row>
                        </div>
                    </Card.CardBody>
                </Card>
            </div>

            </> }

            {stage == 2 && <>
                <div style={{marginTop: "20px"}}>
                    
                    <Table
                         columns={reviewColumns}
                         data={nonSelectedResourceList}
                         loading={serviceNonSelectedResourceTableInfo.loading}
                         totalRows={serviceNonSelectedResourceTableInfo.totalRows}
                         currentPage={serviceNonSelectedResourceTableInfo.currentPage}
                         onPageChage={onNonSelectedResourceChangePage}
                         pagination={true}
                        />
                </div>
            </>}

            {stage == 3 && <>
            
                <div style={{marginTop: "20px"}}>
                    <Table
                        columns={reviewColumns}
                        data={[]}
                        loading={false}
                        pagination={true}
                        noDataComponent={<SucessfullySolvedComponent heading={"Service is changed"} subheading={"Changes made will be reflected in a few minutes."}  buttonText={"Back to service list"} link={"/services"} />}
                    />
                </div>
        </>}

           {( stage == 1|| stage == 2) && <> <div style={{marginTop: "20px"}}>
                <Card type={"full"}>
                    <Card.CardBody>
                        <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                            <HowToLink/>
                        </div>
                        <div className="">
                            <Button type={"danger"} ButtonStyle="transparent" onClick={onCancel}>Cancel</Button>
                            { stage == 2 && <Button ButtonStyle="transparent" onClick={()=>setStage(1)}> Previous</Button> }
                            { stage == 1 && <Button onClick={onConfirmData}> {displayResource ? "Next": "Save"} </Button> }
                            { stage == 2 && <Button onClick={onUpdateService}>Save</Button>}
                        </div>
                    </div>
                    </Card.CardBody>
                </Card>
            </div>
            </>}

            </DashboardBody>
        </>
    )

}

export default ServiceFix