import Tag  from  "components/Elements/Tag/Tag"
import AzureIcon from "assets/icons/connectors/new-azure-icon.svg"
import NewRelicIcon from "assets/icons/connectors/new-relic.png"
import AWSIcon from "assets/icons/connectors/aws.png"
import GitHubIcon from "assets/icons/connectors/github.png"
import BitBucketIcon from "assets/icons/connectors/bitbucket.png"

const platformTypeInfo = {
    "azure": {
        name: "Azure",
        title: "Onepane Azure",
        icon: AzureIcon
    },
    "aws": {
        name: "AWS",
        title: "Onepane AWS",
        icon: AWSIcon
    },
    "github": {
        name: "AWS",
        title: "Onepane GitHub",
        icon: GitHubIcon
    },
    "bitbucket": {
        name: "BitBucket",
        title: "Onepane BitBucket",
        icon: BitBucketIcon
    },
    "apm": {
        name: "NewRelic",
        title: "Onepane NewRelic",
        icon: NewRelicIcon
    },
};


const getPlatformInfo = (platformTypeId, data)=>{  
    console.log({platformTypeId, data})
    if(platformTypeId == 1){
        return[
            { name: "Name", value: data.name },
            { name: "Azure Tenant ID", value: <Tag>{data.config.tenant_id}</Tag> },
            { name: "Azure Client ID", value: <Tag>{data.config.client_id}</Tag> },
            { name: "Azure Subscription ID", value: <Tag>{data.config.subscription_id}</Tag> },
            { name: "Azure Client Secret", value: <span style={{ color: "#6B53AE" }}>{"*".repeat(data.config.client_secret.length)}</span> }

        ]
    } 

    if(platformTypeId == 2){
        return[
            { name: "APM Name", value: data.name },
            { name: "NR Account ID", value: <Tag>{data.config.account_id}</Tag>  },
            { name: "NR Token", value: <span style={{color: "#6B53AE"}}>{"*".repeat(data.config.api_key.length)}</span> },
            { name: "NR License", value: <Tag>{data.config.ingest_license}</Tag> },
            
        ]  
    } 

    if(platformTypeId == 7){
        return[
            { name: "GitHub Name", value: data.name },
            { name: "Organization", value: <Tag>{data.config.gh_organization}</Tag>  },
            { name: "Username", value: <Tag>{data.config.gh_username}</Tag> },
            { name: "Token", value: <span style={{color: "#6B53AE"}}>{"*".repeat(data.config.gh_token.length)}</span> },
            
            
        ]
    }

    if(platformTypeId == 8){
        return[
            { name: "BitBucket Name", value: data.name },
            { name: "Workspace", value: <Tag>{data.config.bb_workspace}</Tag>  },
            { name: "Username", value: <Tag>{data.config.bb_username}</Tag> },
            { name: "Token", value: <span style={{color: "#6B53AE"}}>{"*".repeat(data.config.bb_apppassword.length)}</span> },
            
            
        ]
    }

    if(platformTypeId == 10){

        return([
            { name: "Name", value: data.name },
            { name: "AWS Access ID", value: <Tag>{data.config.aws_access_id}</Tag> },
            { name: "AWS Access ID", value: <Tag>{data.config.aws_region}</Tag> },
            { name: "AWS Secret Access Key", value: <span style={{ color: "#6B53AE" }}>{"*".repeat(data.config.aws_secret_access_key.length)}</span> }
        ])
       
    }

    return []
}



 

export { getPlatformInfo, platformTypeInfo }