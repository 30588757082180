import './App.css';
import React,{useEffect} from "react";
import MainRoute from "./routes/MainRoute";
import {setUpNotifications} from 'reapop'
import { Toaster } from 'react-hot-toast';

const App = () => {
  
  useEffect(() => {
      // this should run before any action ..
      setUpNotifications({
        defaultProps: {
            position: 'top-right',
            dismissible: true
        } 
    })
  }, []);

  
  return (
      <div className="App">
          
            <MainRoute />
            <Toaster 
              position="top-right"
              reverseOrder={false}
              containerClassName="op-notification"
              toastOptions={{
                className: 'op-notification-toast',
                style: {
                  border: "1px solid rgb(66 66 67",
                  padding: "3px 18px",
                  color: "rgb(249 246 255)",
                  borderRadius: "4px",
                  backgroundColor: "rgb(66 66 67)",
                  fontSize: "16px",
                },
              }}
            />
      </div>
    
  );
}

export default App;

