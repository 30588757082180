import Tag from "components/Elements/Tag/Tag";
import { API_URL } from "config/config";
import { CRITICALITY_STATUS, ENV_TYPE, POLICY_STATUS } from "config/constants";
import moment from "moment";
import GetService from "services/GetServices";


const DATEOPTIONS =  {
    hour: "numeric",
    second: "numeric",
    minute: "numeric",
    year: "numeric",
    month: "short",
    day: "numeric",
};

export function JSONFlatten(data) {
    var result = {};

    function recurse(cur, prop) {
        if (Object(cur) !== cur) {
            result[prop] = cur;
        } else if (Array.isArray(cur)) {
            for (var i = 0, l = cur.length; i < l; i++)
                recurse(cur[i], prop + "[" + i + "]");
            if (l == 0)
                result[prop] = [];
        } else {
            var isEmpty = true;
            for (var p in cur) {
                isEmpty = false;
                recurse(cur[p], prop ? prop + "." + p : p);
            }
            if (isEmpty && prop)
                result[prop] = {};
        }
    }
    recurse(data, "");
    return result;
}
  

export const textEllipsis = (text, length, sliceLength = 0) => {
    sliceLength = sliceLength == 0 ? length : sliceLength
    text = text ?? 'Unknown';
    return text.length > length ? text.slice(0, sliceLength) + "..." : text
}


const loaderState = (show, lightLoad = true)=>{
   var loaderDiv =  document.querySelector(".dashboardBodyLoader")
//    console.log({lightLoad})
    if(show == true){
        loaderDiv?.classList?.add("show")
        if(lightLoad == true){
            loaderDiv?.classList?.add("ligth-loader")
        }
        
    }else{
        loaderDiv?.classList?.remove("show")
        loaderDiv?.classList?.remove("ligth-loader")
    }
}

export const showPageLoader = (lightLoad = true)=>{
    // console.log({"showL":lightLoad })
    loaderState(true, lightLoad)
}


export const hidePageLoader = ()=>{
    loaderState(false)
}

export const dateFormat = (timestamp, dateOptions = DATEOPTIONS ) =>{
    return new Date(timestamp).toLocaleString("en-GB", dateOptions)
}


export const validateEmail = (email) => {
    let regex = new RegExp(/[^\s@]+@[^\s@]+\.[^\s@]+/, "gi");
    return regex.test(email)
   
};

export const validateBussinessEmail = (email)=> {
    //return validateEmail(email)
    let regex = new RegExp('^(?!.+@(gmail|google|yahoo|hotmail|aol|live|outlook|msn)\..+)(.+@.+\..+)$');
    return regex.test(email)
}

export const validatePassword = (password) => {
    let re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(password)
};


export const  getRandomColor = ()=> {
    let color = "#";
    do {
      const randomValue = Math.floor(Math.random() * 256).toString(16).padStart(2, "0");
      color += randomValue;
    } while (parseInt(color.substr(1), 16) < 0x333333); 
    
    return color;
  }



export const  getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export const  CapitalizeEachWord = (string = "") => {
    const words = string?.split(" ") ?? [];

    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

   return words.join(" ");
}


export const dashboardFade = (fade = true)=>{
    let dashboard = document.querySelector("#dashboardFade");
    if(fade){
        dashboard.style.display = "block"
    }else{
        dashboard.style.display = "none"
    }
   
}




export const  debounce = (func, timeout = 600)=>{
    let timer;
    return (...args) => {
      clearTimeout(timer);
      console.log("sdddd")
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

// export const ccFormat = (value) =>{
//     var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
//     var matches = v.match(/\d{4,16}/g);
//     var match = matches && matches[0] || ''
//     var parts = []

//     for (i=0, len=match.length; i<len; i+=4) {
//         parts.push(match.substring(i, i+4))
//     }

//     if (parts.length) {
//         return parts.join(' ')
//     } else {
//         return value
//     }
// }



export const getServiceStatus = (alertCount = 0)=>{
   switch (true) {
    case alertCount == 0:
        return <Tag type={"success"}>Good</Tag>
    case alertCount < 5:
        return <Tag type={"warning"}>Degraded</Tag>
    default:
        return <Tag type={"danger"}>Critical</Tag>
   }
}

export const getHealthStatus = (count = 0)=>{
    switch (true) {
     case count == 0:
         return <Tag type={"success"}>Good</Tag>
     case count < 5:
         return <Tag type={"warning"}>Degraded</Tag>
     default:
         return <Tag type={"danger"}>Critical</Tag>
    }
 }



export const getENVStatus = (env)=>{
    if(env){
        return <Tag type={ENV_TYPE[env] ?? 'danger'}>{env}</Tag> 
    }
    return <span className="no-value-span">no-env</span>

}

export const getCriticalityStatus = (criticality)=>{
    if(criticality){
        return <Tag type={CRITICALITY_STATUS[criticality] ?? 'danger'}>{criticality}</Tag> 
    }
    return <span className="no-value-span">no-criticality</span>

}

export const getPolicyStatus = (status = 1)=>{
    return <Tag type={POLICY_STATUS[status].status ?? 'danger'}>{POLICY_STATUS[status].label}</Tag> 
}   

export const getCriticalityByNumber = (count)=>{
    switch (true) {
        case count == 0:
            return <Tag type={"success"}>Low</Tag>
        case count < 5:
            return <Tag type={"warning"}>Medium</Tag>
        case count < 8:
            return <Tag type={"warning"}>High</Tag>
        default:
            return <Tag type={"danger"}>Critical</Tag>
    }

}

export const getCriticalityStringByNumber = (count)=>{
    switch (true) {
        case count == 0:
            return "Low"
        case count < 5:
            return "Medium"
        case count < 8:
            return "High"
        default:
            return "Critical"
    }

}



export const getUserListForOption = async () => {
    let response = await GetService(API_URL + `/api/v1/base/organisation/user/list`)
    let users = response.data?.data?.items
    let tempUsers = [];

    users.map((item)=>{
        tempUsers.push({
            label: item.first_name + " " + item.last_name,
            value: item.user_id
        })
    })

    return tempUsers;
}

export const getErrorClass = (errors, touchedFields , field)=>{
    if(errors[field]){
        return "ops-input-text-invalid"
    }
    if (!errors[field] && touchedFields[field]){
        return "ops-input-text-valid"
    }
}

export const numberOnly = (e, length = 2)=>{
    if(!(e.keyCode >= 48 && e.keyCode <= 57) && ![8,9].includes(e.keyCode)){
        e.preventDefault()
    }
    if (e.target.value.length >= length && ![8,9].includes(e.keyCode)){
        e.preventDefault()
    }  
}

export  const expDateValidate = (month, year)=> {
    let shortYear = new Date().getFullYear() - 2000
    if (Number(year) <= shortYear) {
      return 'Expiry Date Year cannot be less than ' + shortYear.toString();
    }
    return;
}

export const getDateInterval = (dateTimeInterval = 24)=>{
   
    const fromDate = new Date(new Date().getTime() - (dateTimeInterval * 60 * 60 * 1000))
    // const fromDay = fromDate.date();
    // const fromMonth = fromDate.month() + 1;
    // const fromYear = fromDate.year();
    // const fromHour = fromDate.hour()
    // const fromMinute = fromDate.minute()

    const currentDate =  new Date();
    // const currentDay = currentDate.date();
    // const currentMonth = currentDate.month() + 1;
    // const currentYear = currentDate.year();
    // const currentHour = currentDate.hour()
    // const currentMinute = currentDate.minute()

    
    // let fDate = `${(fromDay < 10 ? "0"+fromDay : fromDay)}-${fromMonth < 10 ? "0"+fromMonth : fromMonth}-${fromYear} ${fromHour <10 ? "0"+fromHour : fromHour}:${fromMinute <10 ? "0"+fromMinute : fromMinute}`
    // let tDate = `${(currentDay < 10 ? "0"+currentDay : currentDay)}-${currentMonth < 10 ? "0"+currentMonth : currentMonth}-${currentYear} ${currentHour <10 ? "0"+currentHour : currentHour}:${currentMinute <10 ? "0"+currentMinute : currentMinute}`

    let fromTimeStamp = fromDate.getTime();
    let toTimeStamp = currentDate.getTime();
   return({
        fromDate,
        currentDate,
        fromTimeStamp,
        toTimeStamp
    })
}


export const getAlternativeName = (tags = {}, platformCategory = 1)=>{
    switch(platformCategory){
        case 1 : return tags["hidden-title"] ?? "";
        default : return ""
    }
}


