
import style from "./Dashboard.module.css"
import notficationIcon from "../../assets/icons/dashboard-layout/notification.svg"
import chatIcon from "../../assets/icons/dashboard-layout/chat.svg"
import Avatar from 'react-avatar';
import OPAvater from "components/Avatar/Avatar"
import { RiCustomerService2Fill, RiMailLine, RiBuilding2Line, RiArrowDropDownLine, RiArrowDropRightLine } from "react-icons/ri"
import { useEffect, useRef, useState } from "react"
import GetService from '../../services/GetServices'
import PostService from '../../services/PostServices'
import TooltipView from "../../components/Tooltip/Tooltip";
import { API_URL } from '../../config/config'
import logOutIcon from "../../assets/icons/dashboard-layout/log-out.svg"
import Button from "../../components/Elements/Button/Button";
import noNotification from "../../assets/icons/profile/no-notification.svg"
import newnotification from "../../../src/assets/icons/message/newnotification.svg"
import withNotification from "../../assets/icons/profile/with-notification.svg"
import { Row, Col } from 'react-bootstrap'
import { Link } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";
import ConfirmDialog from "utils/ConfirmDialog";
import { useNotifications } from "reapop";
import UserAccessStore from "stores/NotificationSystem/UserAccessStore";
import { CapitalizeEachWord } from "utils/util";
import RBACWrapper from "components/RBACWrapper/RBACWrapper";

const Profile = ({ user, org, orgList, enableOrganisation = true }) => {


    const [showOrg, setShowOrg] = useState(false)
    const [showProfileDrop, setShowProfileDrop] = useState(false)
    const [shownewnotification, setShownewnotification] = useState(false)
    const [totalNotificationCount, setTotalNotificationCount] = useState(0)
    const [noficationSortOrder, setNoficationSortOrder] = useState(1)
    const [showNotificationLoader, setShowNotificationLoader] = useState(false)

    const [profileNotification, setprofileNotification] = useState([])
    const [userInvitations, setUserInvitations] = useState([])

    const notificationContainerRef = useRef(null)

    const { notify } = useNotifications()


    const userDenyStore = UserAccessStore(state=>state.deny)


    const showNotification = (text, type = "success") => {
        notify(text, type, {
            dismissAfter: 1500
        })
    }

    const selectOrg = (e) => {
        GetService(API_URL + `/api/v1/base/organisation/${e}/select`, {}, true).then(response => {
            window.location.href = "/overview"
        })
    }


    const acceptInvite = (InviteId) => {
        ConfirmDialog("Do you want to accept this invitation?", "Accept", () => {
            PostService(API_URL + `/api/v1/base/organisation/user/invite/${InviteId}/accept`).then(() => {
                window.location.href = "/overview";
            });
        });
    };



    const rejectInvite = (InviteId) => {
        ConfirmDialog("Do you want to reject this invitation?", "Reject", () => {
            PostService(API_URL + `/api/v1/base/organisation/user/invite/${InviteId}/reject`).then(() => {
                // document.querySelector(`.invitation-container-${InviteId}`).remove()
                //getUserInvitations();
                window.location.href = "/overview"
            })
        }, {confirmButton:{className:`${style.invitationRejectBtn}`}, cancelButton:{className:`${style.invitationRejectCancelBtn}`, label: "Cancel"} })


    }

    const getNotification = (sort = 1) => {
        setShowNotificationLoader(true)
        GetService(API_URL + `/api/v1/notifications?sort=${JSON.stringify([{ key: 'created_at', order: sort == 0 ? 'asc' : 'desc' }])}`,
            {}, false, false, false
        ).then(response => {
            let data = response.data.data
            let currentNotifications = JSON.parse(JSON.stringify(profileNotification))
            currentNotifications = [...currentNotifications, ...data.items]
            setprofileNotification(data.items)
            setTotalNotificationCount(data.total_count)
            setNoficationSortOrder(sort == 0 ? 1 : 0)
            setShowNotificationLoader(false)
        }).catch(() => {
            setShowNotificationLoader(false)
        })
    }

    const getProfileImage = (name) => {
        if (name?.toLowerCase().includes("ashmil")) {
            return "/images/profile/ashmil.png"
        }
        if (name?.toLowerCase().includes("gideon")) {
            return "/images/profile/gideon.png"
        }

        return name

    }

    const getUserInvitations = () => {
        GetService(API_URL + `/api/v1/base/user/info`, {}, false, false, false).then(response => {
            setUserInvitations(response.data.data.invites)
        })
    }

    const getMoreNotification = () => {

    }


    useEffect(() => {
        getNotification()
        getUserInvitations()
    }, [])





    return (


        <>
            <div className={style.dashboardHeaderInfo} >
                <div className={style.dashboardHeaderLeft}>




                    <div style={{ borderRight: "1px solid #EDE9F7", paddingRight: "22px" }}>
                        <TooltipView trigger={"click"} position={"bottom"} style={{ borderRadius: "10px" }} offset={50} distance={26} body={
                            <div style={{ backgroundColor: "white" }} >
                                <div className={`${style.profileDropDownContainer}`} >
                                    <div className={style.profileDropDownHeader}>
                                        <div>
                                            <Avatar src={getProfileImage(user?.first_name)} name={`${user?.first_name} ${user?.last_name}`} size="50" round={true} />
                                        </div>
                                        <div>
                                            <h6 className={style.profileDropDownUsername}>{CapitalizeEachWord(`${user?.first_name} ${user?.last_name}`)}</h6>
                                            <span className={style.profileDropDownEmail}> <RiMailLine /> {user?.user_email}</span>
                                        </div>
                                    </div>

                <div className={style.profileDropDivider}></div>
                {(RBACWrapper.hasPermission("PROFILE_ORGANISATION") && enableOrganisation == true ) && <div className={style.profileDropOrgDiv}>
                    <h6 className={style.profileDropOrgTitle}>ORGANIZATIONS</h6>
                    {orgList?.length > 0 && (
                        <>
                            <div className={style.profileDropCurrentOrg} onClick={() => setShowOrg(!showOrg)}>
                                <div>
                                    <RiBuilding2Line size={24} />
                                </div>
                                <div style={{ flexGrow: "1" }}>
                                    <span className={style.profileDropCurrentOrgName}>{org?.name}</span>
                                    {/* <span className={style.profileDropCurrentOrgEmail}>onepane@user.ai</span> */}
                                </div>
                                <div>
                                    <RiArrowDropDownLine size={20} color={"#8D98AF"} />
                                </div>
                            </div>
                            <div className={`${style.profileDropOrgList} ${showOrg ? style.profileDropOrgListShow : ''}`}>

                                                    {orgList?.map(item => {
                                                        return (
                                                            <div className={style.profileDropOrg} onClick={() => selectOrg(item.org_id)}>
                                                                <div>
                                                                    <RiBuilding2Line size={24} />
                                                                </div>
                                                                <div style={{ flexGrow: "1" }}>
                                                                    <span className={style.profileDropCurrentOrgName}>{item.name}</span>
                                                                    {/* <span className={style.profileDropCurrentOrgEmail}>onepane@user.ai</span> */}
                                                                </div>
                                                            </div>
                                                        )
                                                    })}

                            </div>
                        </>)}
                    <div className={style.profileDropOrgControl}>
                        <div>
                            <a href={"/organisations"}>
                                <Button ButtonStyle="transparent" style={{ width: "100%" }}> Organizations</Button>
                            </a>
                        </div>
                        <div>
                            <a href={"/create/organisation"}>
                                <Button style={{ width: "100%" }}>Add New</Button>
                            </a>
                        </div>
                    </div>
                
                </div>}




                                    <div className={style.profileDropDivider}></div>
                                    <div className={style.profileDropAccount}>
                                        <h6 className={style.profileDropOrgTitle}>ACCOUNT</h6>
                                        <div className={style.profileDropAccountAction}>
                                            <div>
                                                <RiCustomerService2Fill color={"#8D98AF"} size={24} />
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                <a href="https://onepane.help.center" target="_blank" className="link-decoration-none">
                                                    <span className={style.profileDropAccountActionText}>Help & Support</span>
                                                </a>
                                            </div>
                                            <div>
                                                <RiArrowDropRightLine color={"#8D98AF"} size={20} />
                                            </div>
                                        </div>
                                        <div className={style.profileDropAccountAction} onClick={() => {
                                            window.location = "/iam/wso2/logout"
                                        }}>
                                            <div>
                                                <img src={logOutIcon} />
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                <span className={style.profileDropAccountActionText} style={{ color: "#EA344C" }}>Logout</span>
                                            </div>
                                            <div>
                                                <RiArrowDropRightLine color={"#EA344C"} size={20} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }>
                            <div style={{ paddingLeft: "18px" }} onClick={() => setShowProfileDrop()} >
                                <div className={style.dashboardHeaderUserInfo}>
                                    <div className={style.dashboardHeaderUserImage}>
                                        <Avatar src={getProfileImage(user?.first_name)} name={user?.first_name} size="35" round={true} />
                                    </div>
                                    <div className={style.dashboardHeaderUserDetails}>
                                        <div className={style.headerUserName}> {CapitalizeEachWord(`${user?.first_name} ${user?.last_name}`)}</div>
                                        <div className={style.headerUserOrg}>{org?.name}</div>
                                    </div>
                                </div>
                            </div>
                        </TooltipView>
                    </div>
                    <div style={{ borderRight: "1px solid #EDE9F7", paddingRight: "22px" }}>
                        <TooltipView trigger={"click"} position={"bottom"} style={{ padding: "0px", width: "330px", border: "1px solid rgba(126, 89, 250, 0.20)" }} offset={50} distance={26} body={
                            <div style={{ backgroundColor: "white" }} >
                                <div className={`${style.scrollableNoti}`}>  <div className={`${style.NotificationHeader}`}>
                                    <Row style={{ padding: "15px", borderBottom: "1px solid rgba(126, 89, 250, 0.20)" }}>
                                        <Col style={{ textAlign: "initial", color: "rgba(6, 18, 55, 0.80)", fontSize: "14px", fontWeight: 700 }} lg={6}>Notifications</Col>
                                        <Col style={{ textAlign: "end", color: "#6B53AE", fontWeight: "12px", fontWeight: 600, cursor: "pointer" }} lg={6}><span className="ops-common-button-hover p-1" onClick={() => getNotification(noficationSortOrder)} style={{ fontSize: "12px" }}>{noficationSortOrder == 0 ? 'show oldest' : 'show latest'}</span></Col>
                                    </Row>
                                </div>
                                    <div ref={notificationContainerRef} onScroll={getMoreNotification}>


                                        <div className={`${style.acceptnotificationRow}`}>
                                            {userInvitations?.map((item, index) => {

                                                return (

                                                    <Row className={`${style.notificationRow} invitation-container-${item.invite_id}`}>
                                                        <Col lg={2} style={{ textAlign: "center", paddingTop: "5px" }}>
                                                            <Avatar name={item.inviter?.first_name} round={true} size="30" />
                                                        </Col>
                                                        <Col lg={10} style={{ marginBottom: "10px" }}>
                                                            <Row>
                                                                <Col lg={12}>
                                                                    <span style={{ fontSize: "14px", fontWeight: 400 }}>Accept invitation by <b style={{ fontWeight: '700' }}>{`${item.inviter?.first_name} ${item.inviter?.last_name}`}</b> </span>
                                                                </Col>
                                                                <Col lg={12}>
                                                                    <div className={style.acceptReject}>
                                                                        <div>
                                                                            <Button onClick={() => { acceptInvite(item.invite_id) }}>Accept</Button>
                                                                        </div>
                                                                        <div>
                                                                            <Button onClick={() => { rejectInvite(item.invite_id) }} style={{ backgroundColor: "#D94F4F", color: "#FCFCFD", borderColor: "#D94F4F" }}>
                                                                                Reject
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>

                                                )
                                            })}

                                        </div>

                                        <div>
                                            <div style={{ position: "absolute", width: "100%", height: "100%", backgroundColor: "rgb(246 242 242)", top: "-2px", opacity: ".7", display: (showNotificationLoader ? 'block' : 'none') }}></div>
                                            {
                                                profileNotification.map((notifications) => {
                                                    return (
                                                        <Row className={style.notificationRow}>
                                                            <Col lg={2} className={style.notificationRowIcon}>
                                                                <img src={newnotification} style={{ height: "30px", width: "30px" }} />
                                                            </Col>
                                                            <Col lg={10}>
                                                                <span style={{ fontSize: "14px", fontWeight: 400 }}>{notifications.notification_title}</span>
                                                                <span className={style.notificationRowTime}>{moment(notifications.created_at).fromNow()}</span>
                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }>
                            <img src={withNotification} />
                        </TooltipView>
                    </div>
                </div>
            </div>



        </>

    )

}


export default Profile