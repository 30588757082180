

import InputSearchComponent from "../form/InputSearchComponent/InputSearchComponent"
import TableButton from "./TableButton"
import refreshIcon from "./assets/restart.svg"
import filterIcon from "./assets/filter.svg"
import style from "./Table.module.css"
import { useState } from "react"
import TableFilter from "./TableFilter"

const TableControl = ({customActionPlace, customAction, search, onSearch, refresh, onRefresh, filter, filterData, onFilterApply})=>{

    const [showFilter, setShowFilter] = useState(false)
    const [filteredData, setFilteredData] = useState({})
    const [temp, setTemp] = useState({})
    
    let timeout = null;
    

    const onClear = ()=>{
        setShowFilter(false)
        onFilterApply({})
        setFilteredData({})
    }

    const onApplyClick = ()=>{
        setShowFilter(false)
        setFilteredData(JSON.parse(JSON.stringify(filteredData)))
        onFilterApply(filteredData)
    }

    const onCheckBoxClick = (e, filterVal)=>{
        let tempFilter = JSON.parse(JSON.stringify(filteredData))
       
        console.log({filterVal})
        if(e.target.checked){
            if(!tempFilter[filterVal.key]){
                tempFilter[filterVal.key] = []
            }
            tempFilter[filterVal.key].push(filterVal.value) 
        }else{
            let dataIndex = tempFilter[filterVal.key].indexOf(filterVal.value)
            tempFilter[filterVal.key].splice(dataIndex, 1)
        }
       
        if(tempFilter[filterVal.key].length == 0){
            delete tempFilter[filterVal.key]
        }
        setFilteredData(tempFilter)
        // setTemp(JSON.stringify(tempFilter))
    }
  

    const onSearchChange = (e)=>{
        console.log({timeout})
        clearTimeout(timeout)
        timeout = setTimeout(()=>{
           onSearch(e.target.value)
        },1000)
    }


    return(

        <div className={style.opTableContainerHeader}>
            <div>
                <div>
                {search && <InputSearchComponent onChange={onSearchChange} size={6} />}
                </div>  
                
            </div>
            <div className={style.opTableAction}> 
                {customActionPlace == "left" && customAction }
                {refresh &&  <TableButton onClick={onRefresh} icon={<img src={refreshIcon}/>}>Refresh</TableButton>}
                {/* <TableButton icon={<img src={sortIcon}/>}>Sort</TableButton> */}
                {filter &&  <>
                    <div className={style.opTableActionFilterContainer}>
                        <TableButton icon={<img src={filterIcon} style={{marginTop: "-3px"}}/>} className={showFilter == true ? style.opTableFilterSelect: ''} onClick={()=>setShowFilter(!showFilter)}>Filter</TableButton>
                        {showFilter && <TableFilter filteredData={filteredData} filterData={filterData} onChecked={onCheckBoxClick} onClear={onClear} onApply={onApplyClick} />}
                    </div>
                </>}

                {customActionPlace == "right" && customAction }
            </div>
        </div>

    )

}

TableControl.defaultProps = {
    customActionPlace: "left",
    customAction:<></>,
    search: true,
    onSearch: ()=>{},
    refresh:false,
    onRefresh: ()=>{},
    filter:false,
    filterData:[],
    onFilterApply: ()=>{},
}


export default TableControl