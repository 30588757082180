import { useEffect, useState } from "react";
// import {  Modal } from "react-bootstrap";


import errorNotification from "../../../assets/images/errorNotification.svg"

import Modal from "../Modal/Modal";
import Button from "../../Elements/Button/Button";
import './IntegrationCreation.css';
import HowToLink from "../../V1/HowToLink/HowToLink";
import { RiCheckboxCircleLine } from "react-icons/ri";

const IntegrationCreation = ({ show, info, healthCheckStatus, onHealthCheck, className, ...props }) => {
  const [custominput, setCustominput] = useState({})
  const [name, setNames] = useState("")
  const [formErrors, setFormErrors] = useState({})
  const [showHealthCheck, setShowHealthCheck] = useState(0)



  useEffect(() => {
    let temp = {}
    let errorObj = {}
    // console.log({ k: props.configs, c: props.configval })
    props.configs.map((obj) => {
      if (props.configval.hasOwnProperty(obj.config_key + "")) {
        try {
          temp[obj.config_key + ""] = JSON.parse(props.configval[obj.config_key])
        } catch (e) {
          temp[obj.config_key + ""] = props.configval[obj.config_key]
        }

      } else {
        if (obj.config_type == 1) {
          temp[obj.config_key + ""] = ""
          errorObj[obj.config_key + ""] = false
        } else if (obj.config_type == 2) {
          if (props.extraData[obj.config_key + ""]) {
            // console.log("has value")
            temp[obj.config_key + ""] = props.extraData[obj.config_key][0]["value"] ?? ""
            errorObj[obj.config_key + ""] = false
          }
          else {
            temp[obj.config_key + ""] = ""
            errorObj[obj.config_key + ""] = false
          }
        }
      }
    })

    if (props.configval.hasOwnProperty("name") && props.mode == "update") {
      setNames(props.configval["name"])
    } else {
      setNames("")
    }
    setCustominput(temp)

    props.configs.sort((a, b) => {
      if (a.config_order && b.config_order) {
        return a.config_order > b.config_order ? 1 : -1
      }
      return 0

    })

  }, [props.configs])

  useEffect(()=>{
    setShowHealthCheck(healthCheckStatus)
  },[healthCheckStatus])

  const getFormData = ()=>{
    let mod = custominput
    let hasError = false;

    let inputNameEle = document.querySelector(`.custominput-name`);
    inputNameEle.classList.remove("hasError")
    if (inputNameEle.value == "") {
      inputNameEle.classList.add("hasError")
      return { hasError: true, data:{}}
    }

    Object.entries(mod).map(([key, value]) => {

      let inputEle = document.querySelector(`.custominput-${key}`)
      inputEle.classList.remove("hasError")
      if (typeof value === "object") {
        mod[key] = JSON.stringify(value)
      } else {
        mod[key] = value?.toString()
      }
      if (inputEle.value == "") {
        inputEle.classList.add("hasError")
        hasError = true;
      }

    })





    let temp = {}
    temp["platform_id"] = info.platform_id
    temp["platform_name"] = name
    temp["custominput"] = mod


    return { hasError, data: temp}

   
  }

  const handleChange = (e) => {
    setCustominput({ ...custominput, [e.target.name]: e.target.value })
    setShowHealthCheck(0)
    console.log("llllllll")
  }


  

  const healthCheckClick = ()=>{
      const {hasError, data} = getFormData()
      if(hasError == false){
        onHealthCheck(data)
      }
  }

  let submitForm = (mode) => {
    const {hasError, data} = getFormData()
    data["mode"] = mode
    if (hasError == false) {
      props.action(data)
    }

  }


  if (props.show === false) {
    return null;
  }
  return (
    <>
      <Modal show={show} style={{ width: "490px" }}>
        <Modal.Title>
          <div className="content-padding">
            <div className="headingDiv">
              <div className='logo'>
                <img src={info.logo} alt="Azure Logo" width={54} />
              </div>
              <div className='header'>
                <span className="mainHeading">
                  {info.title}
                </span>
                <span className="subHeading">
                  {info.description}
                </span>
              </div>
            </div>
          </div>
        </Modal.Title>
        <Modal.Body>
          <div>
            <div className="Content">
              <div className="miniTitle">
                {info.basic_details}
              </div>
              <div className="subTitle">
                {info.basic_decscription}
              </div>

              <div className="formControls">
                <label htmlFor="lname">{info.label}</label><span className="hint">{info.hint}</span><br />
                <input type="text" id="lname" className="custominput-name" name="lname" onChange={(e) => setNames(e.target.value)} defaultValue={props.configval["name"]} />
              </div>

              <div className="lineII">
                <hr color="#EBE7F5" />
              </div>

              <div className="miniTitle">
                {info.client_configuration_text}
              </div>
              <div className="subTitle">
                {info.client_configuration_decscription}
              </div>


            </div>

            {props.configs.map((config) => {
              return (
                <div className="formControls">
                  <label >{config.config_name}</label><span className="hint">{config.hint}</span><br />
                  <input type="text" className={`custominput-${config.config_key}`} name={config.config_key} defaultValue={custominput[config.config_key]} onChange={handleChange} />
                </div>
              )
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* --------------------------------Verify Credentials start------------------------------------- */}
            <div className="HealthCheckContainer">
              <div className="HealthCheckStatusContainer">
                { showHealthCheck ==  1 && (<div className="verifyStatusOK">
                    <span className="messagesVerifyOK">
                      <RiCheckboxCircleLine size={20} color="#2DA077" /> Test Succesfull
                    </span>
                </div>)}
          
                { showHealthCheck ==  2 && (<div className="">
                    <span className="messagesVerify">
                      <img src={errorNotification} style={{marginTop: "-3px"}}/> There is an error while testing
                    </span>
                </div>)}
              </div>
              <div className="HealthCheckButtonContainer">
              {(showHealthCheck ==  0 || showHealthCheck ==  2 )  &&  (<div className="verifyButton">
                <button type="button" onClick={healthCheckClick}>Test Credentials</button>
              </div>)}
              </div>
            </div>
          {/* --------------------------------Verify Credentials End------------------------------------- */}

          <hr style={{ marginTop: "22px", marginLeft: "20px", marginRight: "20px" }}></hr>
          <div className="op-integrtion-action-container">
            <div className="flex-grow-1">
              <HowToLink link={info.how_to_link} />
            </div>
            <div>
              <Button type="danger" ButtonStyle="transparent" className="mx-3" onClick={() => props.onHide()}>Cancel</Button>
              {(props.mode === "create") && <Button onClick={() => submitForm("create")} disabled={showHealthCheck == 1 ? false : true}>Create</Button>}
              {(props.mode === "update") && <Button onClick={() => submitForm("Update")} disabled={showHealthCheck == 1 ? false : true}>Update</Button>}
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default IntegrationCreation