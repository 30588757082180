import { Route, Routes, Navigate } from "react-router-dom";
import SignUpPage from "../pages/signup/SignUpPage";
import PasswordReset from "../pages/password-reset/PasswordReset";
import VerificationSucces from "../pages/account-verify/VerificationSucces";
import VerficationFailed from "../pages/account-verify/VerificationFailed";
import SignupSuccess from "../pages/signup/SignUpSucces";
import GeneralLayout from "../pages/general-layout/GeneralLayout";
import DashboardLayout from "../pages/newlayout/Dashboard";


const MainRoute=()=>{

    return (
        <Routes>
            <Route exact key={"index"} path="/" element={<Navigate replace to="/overview" />} />
            {/* {process.env.REACT_APP_SAAS_MODE == 1  && <> */}
                {/* <Route exact key={"signup"} path="/signup" element={<SignUpPage/>} />
                <Route exact key={"signup-success"} path="/signup-success" element={<SignupSuccess />} />
                <Route exact key={"forgot-password"} path="/forgot-password" element={<PasswordReset />} />
                <Route exact key={"account-verify"} path="/verification-success" element={<VerificationSucces />}/>
                <Route exact key={"account-verify"} path="/verification-failed" element={<VerficationFailed />}/>
                <Route exact key={"layout"} path="/layout" element={<GeneralLayout />}/> */}
            {/* </>} */}
            <Route exact key={"other"} path="*" element={<DashboardLayout />}/>
        </Routes>
    )

}


export default MainRoute;