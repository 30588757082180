
import { useEffect, useState } from "react"
import style from "./Modal.module.css"

const Modal = ({show, children, ...otherProps})=>{

    const [showModal, setShowModal] = useState(false)


    useEffect(()=>{
        setShowModal(show)
    },[show])

    if(!showModal){
        return ""
    }


    return(
        <>
            <div className={`${style.opModalContainer}`} {...otherProps}>
                <div className="op-modal">
                    {children}
                </div>
            </div>
            
        </>
    )

}


const Title = ({children})=>{
    return(
        <>
            <div className={`${style.opModalTitle}`}>
                {children}
            </div>
        </>
    )
}

const Body = ({children})=>{
    return(
        <>
            <div className={`${style.opModalBody}`}>
                {children}
            </div>
        </>
    )
}


const Footer = ({children})=>{
    return(
        <>
            <div className={`${style.opModalFooter}`}>
                {children}
            </div>
        </>
    )
}


Modal.defaultProps = {
    show: false
}

Modal.Title = Title
Modal.Body = Body
Modal.Footer = Footer



export default Modal