import { useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import './ChangesTable.css';
import sortIcon from "../../assets/icons/util/table-sort.svg";
import Moment from 'react-moment';
import { AiOutlineClockCircle, AiOutlineCalendar } from "react-icons/ai";

import TablePagination from '../Table/TablePagination';
import TableEmpty from 'components/Table/TableEmpty';
import { useNavigate } from 'react-router-dom';
import TableControl from 'components/Table/TableControl';
import SimpleToolTip from 'components/Tooltip/SimpleToolTip';

const ChangesTable = (props) => {


    const [activeIndex, setActiveIndex] = useState(-1);
    const [sortInfo, setSortInfo] = useState({'key':'incident_id', direction: 'asc'})


    const navigate = useNavigate()

    const onSort = (key)=>{
        let direction = 'asc'
        if(sortInfo.key == key){
            direction = sortInfo.direction == 'asc' ? 'desc': 'asc'
        }
        setSortInfo({key: key, direction: direction});
        props.onSort(key, direction)
    }


    function shortenString(text, maxLength) {
        if (text.length > maxLength) {
          return text.slice(0, maxLength) + '...';
        }
        return text;
      }



    // Declare a new state variable, which we'll call "count"
    return (
        <div>
            <Container className='change-table'>


                 <Row className='change-table-control'>
                    <TableControl onSearch={props.onSearch} refresh={true} onRefresh={props.onRefresh} filter={true} filterData={props.filterData} onFilterApply={props.onFilterApply} />
                </Row>



                <Row className="change-header" >
                    <Col md={1} className='p-0'>
                        <span className="change-header-title" onClick={()=>{onSort('event_id')}}>EVENT ID <img src={sortIcon} /></span>
                    </Col>
                    <Col md={7}>
                        <span className="change-header-title" onClick={()=>{onSort('message')}} >CHANGE <img src={sortIcon} /> </span>
                    </Col>
                    <Col md={3}>
                        <span className="change-header-title" onClick={()=>{onSort('message')}} >RESOURCE <img src={sortIcon} /></span>
                    </Col>
                   
                    <Col md={1}>
                        <span className="change-header-title" onClick={()=>{onSort('event_type')}} >TYPE <img src={sortIcon} /></span>
                    </Col>


                </Row>
            </Container>
            <Container style={{ backgroundColor: "#FFF", paddingBottom: "4px", paddingLeft: "4px", paddingRight: "4px" }}>
                {props.data?.map((item, index) => {
                    return (
                        <Row key={index} >
                            <Col>
                                <div className={'change-container'} style={{ marginBottom: "0px", marginTop: "16px" }}>
                                    <Row className="align-items-center">
                                        <Col md={1} >
                                            <span style={{ fontWeight: '900', color: '#9BA0AF', fontSize: "12px"}} className="change-table-event-id">
                                                #OPEVT- {item.event_id}
                                            </span>

                                        </Col>
                                        <Col md={7}>
                                            <div className="change-title-container">
                                                <div className="">
                                                    <img src={`/images/connector-images/${item.platform?.platform_type?.icon}`} className="table-main-source br-img-rounded" />
                                                </div>
                                                <div>
                                                    <span className="change-title">
                                                      {  shortenString(item.message, 100)}
                                                    </span>
                                                    <div className="change-sub-text">
                                                        <span><AiOutlineClockCircle style={{ marginRight: "5px", marginTop: "-2px" }} />
                                                            <Moment format="HH:MM:SS">{item.timestamp}</Moment>
                                                        </span>
                                                        <span style={{ marginLeft: "25px" }}><AiOutlineCalendar style={{ marginRight: "8px", marginTop: "-2px" }} />
                                                            <Moment format="YYYY-MM-DD">{item.timestamp}</Moment>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>


                                        <Col md={3} >
                                            <span className="change-table-source">
                                                {item.config_item.name}
                                            </span>
                                        </Col>
                                        <Col md={1}>
                                            <SimpleToolTip tooltip={item.event_type}>
                                                <span className="change-table-source span-ellipsis">{item.event_type}</span>
                                            </SimpleToolTip>
                                        </Col>

                                    </Row>
                                   
                                </div>
                            </Col>
                        </Row>
                    )
                })
                }
                {props.data?.length == 0 && <TableEmpty />}
                <Row style={{ marginTop: "20px" }}>
                    <TablePagination rowCount={props.totalRows} rowsPerPage={props.perPage} currentPage={props.currentPage} onChangePage={props.onChangePage} />
                </Row>

            </Container>

        </div>
    );
}
export default ChangesTable;