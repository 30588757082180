
import style from "./Table.module.css"
import TablePagination from "./TablePagination"
import tableColummnSortIcon from "./assets/sort-icon-3.svg"
import DataTable from "react-data-table-component"

import "./DataTable.css"
import TableEmpty from "./TableEmpty"

import { useState } from "react"
import TableControl from "./TableControl"

    const Table = ({title,icon,search,refresh, footer, filter, filterData, onFilterApply, control, border, columns, data, customAction, customActionPlace,totalRows, pagination, onPageChage,loading, onSearch, noDataComponent, dataTableProps, containerStyle, tableClass, paginationPerPage, paginationServer, onRefresh, tableStyle,  ...otherProps})=>{
        const customStyles = {
            cells: {
              style: {
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '24px',
              },
            },
          };
        
        const [showFilter, setShowFilter] = useState(false)
        const [filteredData, setFilteredData] = useState({})


      
        
 
    return(
        <>
            <div className={`${style.opTableContainer} ${border == false? style.opTableNoBorder:""}`} {...otherProps}>
            {title !== "" && (
                <div className={style.opTableContainerTitle}>
                {icon && <span className={style.icon}>{icon}</span>} {/* Render the icon */}
                {title} {/* Render the title */}
                </div>
            )}
            <div className={`${style.opTableDataTableContainer} ${title != ""? style.opHasTableTitle : ""}`} style={containerStyle}>
                { control && <TableControl 
                        search={search} 
                        onSearch={onSearch}
                        customAction={customAction}
                        customActionPlace={customActionPlace}  
                        refresh={refresh}
                        onRefresh={onRefresh}
                        filter={filter}
                        filterData={filterData}
                        onFilterApply={onFilterApply}

                /> }
                
                {/* { control && (
                        <div className={style.opTableContainerHeader}>
                            <div>
                                <div>
                                {search && <InputSearchComponent onChange={(e) => onSearch(e.target.value)} size={6} />}
                                </div>  
                            </div>
                            <div className={style.opTableAction}> 
                                {customActionPlace == "left" && customAction }
                                {refresh &&  <TableButton onClick={onRefresh} icon={<img src={refreshIcon}/>}>Refresh</TableButton>}
                                {filter &&  <>
                                    <div className={style.opTableActionFilterContainer}>
                                        <TableButton icon={<img src={filterIcon} style={{marginTop: "-3px"}}/>} className={showFilter == true ? style.opTableFilterSelect: ''} onClick={()=>setShowFilter(!showFilter)}>Filter</TableButton>
                                        {showFilter && <TableFilter filteredData={filteredData} filterData={filterData} onChecked={onCheckBoxClick} onClear={onClear} onApply={onApplyClick} />}
                                    </div>
                                </>}

                                {customActionPlace == "right" && customAction }
                            </div>
                        </div>
                )} */}
                <div className={`customDTStyle ${!pagination && footer != ""  ? "":"no-pagination"} ${footer != "" ? "has-footer": ""} ${tableClass}`} style={tableStyle}>
                    <DataTable
                        columns={columns}
                        data={data}
                        customStyles={customStyles}
                        persistTableHead ={true}
                        pagination = {pagination}
                        paginationServer = {paginationServer}
                        paginationTotalRows=  {totalRows}
                        paginationPerPage= {paginationPerPage}
                        onChangePage={onPageChage}
                        progressPending={loading}
                        noDataComponent={noDataComponent}
                        paginationComponent={TablePagination}
                        sortIcon={<img className={style.opTableColumnSort} src={tableColummnSortIcon}/>}
                        {...dataTableProps}
                    />
                   
                        {footer != "" && footer}
                        
                    
                    
                </div>
            </div>
            </div>
        </>
    )
}

Table.defaultProps = {
    border: true,
    title : "",
    columns : [],
    data: [],
    customAction:<></>,
    customActionPlace: "left",
    totalRows: 0,
    paginationPerPage: 10,
    pagination:false,
    paginationServer:true,
    onPageChage: ()=>{},
    loading:true,
    dataTableProps: {},
    onSearch: ()=>{},
    onRefresh: ()=>{},
    control: true,
    search:true,
    refresh:true,
    filter:true,
    icon:"",
    containerStyle:{},
    tableClass: "",
    tableStyle: {},
    footer: "",
    noDataComponent:<TableEmpty/>,
    filterData:[],
    onFilterApply: ()=>{}


}

export default Table