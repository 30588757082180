import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs"
import Tag from "../../../components/Elements/Tag/Tag"
import DashboardBody from "../../../pages/newlayout/DashboardBody"
import IntegrationInfo from "../Common/IntegrationInfo"
import apmIcon from "../../../assets/icons/connectors/new-relic.png"
import AddApp from "./AddApp"

import EditApp from "./EditApp"
import './APM.css'
import { useEffect, useRef, useState } from "react"
import GetService from "services/GetServices"
import { API_URL } from "config/config"
import { useParams } from "react-router-dom"
import { getPlatformInfo } from "../common"



const APM = ()=> {


    const { id } = useParams()
    const [platform, setPlatform] = useState({})
    const [info, setInfo] = useState([])
    const [resources, setResources] = useState([])
    const [selectedResources, selectedSelectedResources] = useState([])
    const dashboardBodyRef = useRef(null)
    const [enableRefresh, setEnableRefresh] = useState(true)

    
    
   

   


    
   
  


    const getInitialData = (loader)=>{
        GetService(API_URL + `/api/v1/platform/${id}/step/init`,
        {}, false, false, loader).then(response=>{
            let data = response.data.data;
            setResources(data.resources)
        })
    }

    const getServiceData = (loader)=>{
        GetService(API_URL + `/api/v1/platform/${id}/step/final`,
        {}, false, false, loader).then(response=>{
            let data = response.data.data;
            selectedSelectedResources(data.resources)
        })
    }



    const getIntegrationInfo = (loader = true)=>{
        GetService(API_URL + `/api/v1/platform/${id}/get`,
        {}, false, false, loader).then(response=>{
            let data = response.data.data.platform;
            setPlatform(data)

            let platformInfo = getPlatformInfo(data.platform_type.platform_type_id, data)
            setInfo(platformInfo)     

            if(data.status >= 2){
                getInitialData(loader);
            }
            if (data.status >= 3){
                getServiceData(loader)
            }
            
        })
    }

    const onExpandPanel = (value)=>{
        console.log(value)
        setEnableRefresh(!value)
    }

    useEffect(()=>{
        getIntegrationInfo()
    },[])



    useEffect(()=>{
        let refreshIntegrationInfo = null
        clearInterval(refreshIntegrationInfo)
        refreshIntegrationInfo = setInterval(() => {
            if(enableRefresh){
                getIntegrationInfo(false)
            }
          }, 10000);
        return () => clearInterval(refreshIntegrationInfo);
    },[enableRefresh])


    return(
        <>
            <Breadcrumbs section={[ {name: "Integrations", path: "/integrations"} ]}/>
            <DashboardBody ref={dashboardBodyRef}>
                <IntegrationInfo icon={apmIcon} title={"Onepane NewRelic"} info={info} platform={platform}  />
                { ![7,8,9,10].includes(platform.status) && <>
                    <AddApp platformCategory={platform.platform_type?.category} platformId={id} resources ={resources} status={platform.status} onExpand={onExpandPanel} />
                    <EditApp platformCategory={platform.platform_type?.category} platformId={id} resources ={selectedResources} status={platform.status} onExpand={onExpandPanel} /> 
                </>}

            </DashboardBody>
        </>
    )
}


export default APM
