import Axios from 'axios';
import { hidePageLoader, showPageLoader } from '../utils/util';
import { toast } from 'react-hot-toast';

export default function GetService(API, params = {}, lightLoad = false, showToast = false, loader = true) {
    
    if(loader){
        showPageLoader(lightLoad)
    }
    let response = Axios.get(API, { params, withCredentials: false,headers: { "Content-Type": "application/x-www-form-urlencoded" } })
    response.then(()=>{
         if(showToast){
            if (response.data.success == false){
                if(response.data.validation == null){
                    toast.error(response.data.description)
                }else{
                    toast.error(response.data.validation[0].Message)
                }
                throw new Error("canceled")
            }else{
                toast.success(response.data.description)
            }
        }


        hidePageLoader()
        // toast("success")
    }).catch(err => {
        hidePageLoader()
        console.log(err)
        if (err.response && (err.response.status == 401 || err.response.status == 502)){
            window.location.replace("/login");
        }
        
    })
    return response
}