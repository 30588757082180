import { API_URL } from "config/config"
import GetService from "services/GetServices"


export const loadResources = (page = 1, search = "", sort=[], filter={}, url = '/api/v1/resource/list')=>{

    let apiSearch = []

    if(filter["tags->>'Criticality'"]){
        apiSearch.push({key:"tags->>'Criticality'", condition:'in', value: filter["tags->>'Criticality'"]})
        delete filter["tags->>'Criticality'"];
    }

    if(filter["tags->>'Env'"]){
        apiSearch.push({key:"tags->>'Env'", condition:'in', value: filter["tags->>'Env'"]})
        delete filter["tags->>'Env'"];
    }

    if(filter["service_id"]){
        filter["config_items.service_id"] = filter["service_id"]
        delete filter["service_id"];
    }

    apiSearch.push({key:"name", value:search})
    let searchJSON = JSON.stringify(apiSearch)
    let sortJSON = JSON.stringify(sort)
    let filterJSON = JSON.stringify(filter)
    console.log({filter})
    return GetService(API_URL + `${url}?current_page=${page}&search=${searchJSON}&sort=${sortJSON}&filter=${filterJSON}`)
}


export const  getResourceBaseData = async ()=>{
   let response = await GetService(API_URL + `/api/v1/filter/resources/init`,{}, false, false, false)
   let data = response.data.data
        
    let criticality = []
    data?.criticality?.map(item=>{
        if(item.env != null){
            criticality.push({
                label: item.env ,
                value: item.env 
            })
        }
    })

    let envs = []
    data?.env?.map(item=>{
        if(item.key != null){
            envs.push({
                label: item.key ,
                value: item.key 
            })
        }
    })

    let platforms = []
    data?.platforms?.map(item=>{
        platforms.push({
            label: item.name ,
            value: item.platform_id
        })
    })

    let services = []
    data?.services?.map(item=>{
        services.push({
            label: item.name ,
            value: item.service_id
        })
    })

    let types = []
    data?.types?.map(item=>{
        types.push({
            label: item.original_type ,
            value: item.onepane_type
        })
    })


    return { criticality, envs, platforms, services, types}
}

export const parseDataForFilter = (data)=>{
    return ([{
            label: "Criticality",
            key: "tags->>'Criticality'",
            type: 'search',
            values: data.criticality
        },{
            label: "ENV",
            key: "tags->>'Env'",
            type: 'search',
            values: data.envs
        },
        {
            label: "Platform",
            key: "platform_id",
            type: 'filter',
            values: data.platforms
        },
        {
            label: "Service",
            key:   "service_id",
            type: 'filter',
            values: data.services
        },
        {
            label: "Type",
            key: "onepane_type",
            type: 'filter',
            values: data.types
        }
    ])
}