import React, { useState } from 'react';
import { AiOutlineEyeInvisible, AiOutlineEye, AiOutlineClose, AiOutlineCheck, AiOutlineInfoCircle} from "react-icons/ai";
import {validateEmail, validatePassword} from "../../../utils/util"
import './InputComponent.css';
import ReactTip from '@jswork/react-tip'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const InputComponent = React.forwardRef((props, ref) => {

    const [inputType, setInputType] = useState("password")
    
    const charLength = (str, length)=> {
        return str?.length >= length ? true: false;
    }

    const containsUppercase = (str)=> {
        return /[A-Z]/.test(str);
    }

    const containsLowercase = (str)=> {
        return /[a-z]/.test(str);
    }

    const containsNumberic = (str)=> {
        return /\d/.test(str);
    }

    const containsSpecial = (str)=> {
        return  /[!@#$%^&*]/.test(str);
    }

    const showPassword = (e)=>{
        console.log(inputType)
        setInputType(inputType == "password" ? "text" : "password")
    }
  return (
        <>
            <label className="input-label">
                {props.label} 
                
                {props.hint != "" && <>
                    <ReactTip title={props.hint} placement={'up-right'}>
                        <AiOutlineInfoCircle color='rgba(255, 153, 0, 0.40)' style={{marginLeft: "5px", cursor:"pointer"}} />
                    </ReactTip>
                   
                </> }
            </label>
            
            { (props.type != "password" && props.type != "confirm-password") && <>
                <div style={{position: "relative"}}>
                    <span className="op-input-before-icon">{props.beforeIcon}</span>
                    <input id={props.id} ref={ref} {...props} type={props.type} style={props.style} className={`col-12 ops-input-text ${props.beforeIcon != "" ? "op-input-has-before-icon" : ""} ${props.afterIcon != "" ? "op-input-has-after-icon" : ""} ${props.className} `} data-error={props.error}  /> 
                    <span className="op-input-after-icon">{props.afterIcon}</span>
                    {props.showErrorMessage && <div className="error-popover">
                        <p>{props.error}</p>
                    </div>}
                </div>
                </>
            }
            
            
            { (props.showpassword == "false" && ( props.type == "password" || props.type == "confirm-password")) && (<>
                <div style={{position: "relative"}}>
                    <input id={props.id} ref={ref} type={props.type == "confirm-password" ? "password" : props.type} {...props}  style={props.style} className={`${props.className} ops-input-text col-12`}  data-error={props.error}  />
                    <span className="op-error-icon"  aria-label={props.error}></span>
                    <div className="error-popover">
                        <p>{props.error}</p>
                    </div>
                </div>
                
            </>)}
            
            { (props.type == "password" || props.type == "confirm-password") && props.showpassword == "true" && (
                <div style={{position: "relative"}}>
                    <input id={props.id} ref={ref}  {...props} type={inputType}  style={props.style} className={`ops-input-text col-12 ${props.className}`} data-error={props.error} />
                    <span className="op-input-password-span" onMouseEnter={()=>setInputType('text')} onMouseLeave={()=>setInputType("password")}>
                        { inputType == "password" ? <AiOutlineEyeInvisible color='#7E59FA' /> : <AiOutlineEye color='#7E59FA' /> } 
                    </span>
                    <span className="op-password-error-icon"></span>
                    <div className='error-popover'>
                        <p>
                            <span className='password-hind-title'>Password must includes</span>
                        <ul>
                            <li className={``}> { charLength(props.value, 8) ? <AiOutlineCheck  color='#00BF71'/>: <AiOutlineClose color='#F08392'/>} 8 characters</li>
                            <li className={``}> { containsUppercase(props.value) ? <AiOutlineCheck color='#00BF71'/>: <AiOutlineClose color='#F08392'/>} Uppercase</li>
                            <li className={``}> { containsLowercase(props.value) ? <AiOutlineCheck color='#00BF71'/>: <AiOutlineClose color='#F08392'/>} Lowercase</li>
                            <li className={``}> { containsNumberic(props.value) ? <AiOutlineCheck color='#00BF71'/>: <AiOutlineClose color='#F08392'/>} Numeric</li>
                            <li className={``}> { containsSpecial(props.value) ? <AiOutlineCheck color='#00BF71'/>: <AiOutlineClose color='#F08392'/>} Special Characters</li>
                        </ul>
                        </p>
                        <div className="popover__nub"></div>
                    </div>
                </div>
            )}
            {/* <div className='op-input-span-error' style={{marginTop: "4px"}}>{props.error}</div> */}
        
        </>
    );
})

InputComponent.defaultProps = {
    id: "",
    type: "text",
    required: false,
    regex: "",
    style: {},
    className: "",
    error: "",
    showpassword: "false",
    beforeIcon: "",
    afterIcon: "",
    showErrorIcon:true,
    showErrorMessage:true,
    hint:""
    // onLeave: ()=>{}
}


export default InputComponent;