import { create } from 'zustand'

const UserAccessStore  = create((set)=>({
    routes:[],
    deny:[],
    updateRules:(data)=>{
        set({deny: data})
    }
}))

export default UserAccessStore