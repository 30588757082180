import { useEffect, useState } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import Table from "components/Table/Table";
import SimpleToolTip from "components/Tooltip/SimpleToolTip";
import { loadChanges } from "sections/Changes/ChangesUtil";
import { getChangeBaseData, parseDataForFilter } from "sections/Changes/ChangesUtil";


const Changes = ({startTime, endTime}) => {
  const { resourcesId } = useParams();
  const [resourceEvents, setResourceEvents] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([])
  const [tableInfo, setTableInfo] = useState({
    totalRows: 0,
    currentPage: 1,
    loading: true,
    search: "",
  });


  const parseFilterData = async ()=>{
    let data = await getChangeBaseData()
    setTableFilterData(parseDataForFilter(data))
  }


  const getResourceEvents = (page = 1, search = "", sort = [], filter = {}) => {



    loadChanges(page, search, sort, filter, `/api/v1/resource/${resourcesId}/events`, `startTime=${startTime}&endTime=${endTime}` ).then(response=>{
      const data = response.data.data;
      setResourceEvents(data.items);
      setTableInfo({ ...tableInfo, loading: false, totalRows: data.total_count, currentPage: page, search: search, sort: sort, filter: filter  });
      
    })

  };


  const onPageChange = (page) => {
    setTableInfo({ ...tableInfo, loading: true, currentPage: page });
    getResourceEvents(page, tableInfo.search, tableInfo.sort, tableInfo.filter);
};

const onRefresh = ()=>{
  setTableInfo({ currentPage: 1, totalRows:0, perPage: 10, loading: true , search: "", sort: [], filter:{}})
  getResourceEvents();
}

const onFilterApply = (data)=>{
  setTableInfo({...tableInfo, ...{filter: data}})
  getResourceEvents(1, tableInfo.search, tableInfo.sort, data)
}

const onSearch = (keyword)=>{
getResourceEvents(1, keyword, tableInfo.sort, tableInfo.filter)
}


const onSort = (selectedColumn, sortDirection, sortedRows) =>{
  setTableInfo({...tableInfo, ...{sort: [{key: selectedColumn.sortField, order:sortDirection}]}})
  getResourceEvents(tableInfo.currentPage, tableInfo.search, [{key: selectedColumn.sortField, order:sortDirection}], tableInfo.filter)
}




  useEffect(() => {
    parseFilterData();
  }, []);

  useEffect(() => {
    getResourceEvents();
  }, [startTime, endTime]);

  const columns = [
    {
      name: "TITLE",
      selector: (row) => row.message,
      sortable: true,
      sortField: "message",
    },
    {
      name: "RESOURCE",
      selector: (row) => row.config_item.name,
      sortable: true,
      width: "250px",
      sortField: "config_item.name",
    },
    {
      name: "PROVIDER",
      selector: (row) => (
        <SimpleToolTip tooltip={row.platform?.name}>
          <span className="">
            <img
              src={`/images/connector-images/${row.platform.platform_type.icon}`}
              className="br-img-rounded"
              style={{ width: "20px", height: "20px" }}
            />
          </span>
        </SimpleToolTip>
      ),
      center: true,
      width: "180px",
    },
    {
      name: "TIME",
      selector: (row) => (
        <span className="">{moment(row.timestamp).format("YYYY-MM-DD HH:mm:SS")}</span>
      ),
      sortable: true,
      width: "250px",
      sortField: "timestamp",
    },
  ];



  return (
    <>
      <Table
        columns={columns}
        data={resourceEvents}
        loading={tableInfo.loading}
        containerStyle={{ border: "0px" }}
        pagination={true}
        totalRows={tableInfo.totalRows}
        onPageChange={onPageChange}   
        onRefresh={onRefresh}
        onSearch={onSearch} 
        filterData={tableFilterData}
        onFilterApply={onFilterApply}
        dataTableProps={{sortServer: true, onSort: onSort}}
      />
    </>
  );
};

export default Changes;
