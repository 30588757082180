import React from 'react';
import { useState } from 'react';
import { Col, Container, Row } from "react-bootstrap"
import './IncidentTable.css';
import TooltipView from '../Tooltip/Tooltip';
import sortIcon from "../../assets/icons/util/table-sort.svg"
import Moment from 'react-moment';
import { AiOutlineClockCircle, AiOutlineCalendar } from "react-icons/ai";
import { MdOutlineLaunch } from "react-icons/md";

import Tag from "../../components/Elements/Tag/Tag"
import TablePagination from '../Table/TablePagination';
import TableEmpty from 'components/Table/TableEmpty';
import { Link, useNavigate } from 'react-router-dom';
import { connectorRoute } from 'config/constants';
import incidentOpen from "assets/icons/alerts/alert-open-arrow.svg"
import incidentClose from "assets/icons/alerts/alert-close-arrow.svg"
import { getOnepaneTypeCIIcon } from 'utils/OnepaneType';
import Button from 'components/Elements/Button/Button';
import moment from 'moment/moment';
import InputSearchComponent from 'components/form/InputSearchComponent/InputSearchComponent';
import SearchControl from 'components/SearchControl/SearchControl';
import TableControl from 'components/Table/TableControl';
import SimpleToolTip from 'components/Tooltip/SimpleToolTip';

const IncidentTable = (props) => {


    const [activeIndex, setActiveIndex] = useState(-1);
    const [sortInfo, setSortInfo] = useState({'key':'incident_id', direction: 'asc'})

  

    const getSeverity = (severity)=>{
        switch (severity) {
            case severity >= 8:
                return <Tag type={"danger"}>critial</Tag>;
            case severity >= 6:
                return <Tag type={"danger"}>danger</Tag>;
            case severity >= 4:
                return <Tag type={"warning"}>warning</Tag>;
            default:
                return <Tag type={"primary"}>normal</Tag>;
        }
    }


    const handleClick = (index) => {
        if(index == activeIndex){
            setActiveIndex(-1);
        }else{
            setActiveIndex(index)
        }
        
    };

    const onSort = (key)=>{
        let direction = 'asc'
        if(sortInfo.key == key){
            direction = sortInfo.direction == 'asc' ? 'desc': 'asc'
        }
        setSortInfo({key: key, direction: direction});
        props.onSort(key, direction)
    }


  

    // Declare a new state variable, which we'll call "count"
    return (
        <div>
                
            <Container fluid className='alert-table'>

                <Row className='alert-table-control'>
                    <TableControl onSearch={props.onSearch} refresh={true} onRefresh={props.onRefresh} filter={true} filterData={props.filterData} onFilterApply={props.onFilterApply} />
                </Row>
               

                <Row className="alert-header" >
                    <Col md={5}>
                        <span className="alert-header-title" onClick={()=>{onSort('incident_title')}}>ALERTS <img src={sortIcon} /> </span>
                    </Col>
                    <Col md={2}>
                        <span className="alert-header-title" onClick={()=>{onSort('incident_id')}} >ALERT ID <img src={sortIcon} /></span>
                    </Col>
                    
                    <Col md={1}>
                        <span className="alert-header-title" onClick={()=>{onSort('platform_status')}}  >STATUS <img src={sortIcon} /></span>
                    </Col>
                    <Col md={2}>
                        <span className="alert-header-title">APP </span>
                    </Col>
                    <Col md={2} className='p-0'>
                        <span className="alert-header-title" onClick={()=>{onSort('severity')}}  >SEVERITY <img src={sortIcon} /></span>
                    </Col>
                    {/* <Col md={2}>
                        <span className="alert-header-title"></span>
                    </Col> */}
                </Row>
            </Container>
            <Container style={{ backgroundColor: "#FFF", paddingBottom: "4px", paddingLeft: "4px", paddingRight: "4px"}} fluid>
            {props.data?.map((item, index) => {
                    return (
                        <Row key={index} >
                            <Col>
                                <div className={`${index === activeIndex ? 'alert-container-active': 'alert-container'}`} style={{marginBottom: "0px", marginTop:"16px"}}>
                                    <Row className="align-items-center">
                                       
                                        <Col md={5}>
                                            <div className="alert-title-container">
                                                <div className="">
                                                    <img src={`/images/connector-images/${item.platform?.platform_type?.icon}`} className="table-main-source br-img-rounded" />
                                                </div>
                                                <div>
                                                    <span className="alert-title">
                                                        {item.incident_title}
                                                    </span>
                                                    <div className="alert-sub-text">
                                                        <span><AiOutlineClockCircle style={{ marginRight: "5px", marginTop: "-2px" }} />
                                                            <Moment format="HH:MM:SS">
                                                                {item.open_time}
                                                            </Moment>
                                                        </span>
                                                        <span style={{ marginLeft: "25px" }}><AiOutlineCalendar style={{ marginRight: "8px", marginTop: "-2px" }} />
                                                            <Moment format="YYYY-MM-DD">
                                                                {item.open_time}
                                                            </Moment>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={2}>
                                            <span className="alert-table-source">#OP{item?.incident_id}</span>
                                        </Col>
                                        <Col md={1} >
                                            <span className="alert-table-source ">{<Tag type={item.platform_status == 1 ? "success": "danger"}>{item.platform_status == 1 ? "Closed": "Open"}</Tag>}</span>
                                        </Col>
                                        <Col md={2}>
                                            <span className="alert-table-source">{item.config_item?.service?.name}</span>
                                        </Col>
                                        <Col md={2} >
                                            <span className="alert-table-source">
                                                {getSeverity(item.severity)}
                                            </span>

                                            <div className='action-container' onClick={()=> handleClick(index)}> 
                                                { ((index  === activeIndex)) ? (
                                                    <Button ButtonStyle="transparent">
                                                        <img src={incidentOpen} className="table-toggle-icon" /> See less
                                                    </Button>
                                                   
                                                ):(
                                                    <Button >
                                                        <img src={incidentClose} className="table-toggle-icon" /> See more
                                                    </Button>
                                                    
                                                )}
                                            </div>

                                        </Col>
                                        {/* <Col md={2} className='text-right'>
                                            
                                        </Col> */}
                                    </Row>
                                    <div style={{ marginTop: "10px" , borderTop: "1px solid #F5F2FF", marginBottom: "20px", paddingTop:"20px"}} className={`table-alert-description ${ index == activeIndex ? 'd-block' : 'd-none' }`}>
                                        <Row>
                                            <Col lg={4}><span className="alert-details-heading-col">AFFECTED CI</span></Col>
                                            <Col lg={2}><span className="alert-details-heading-col">OPEN TIME</span></Col>
                                            <Col lg={2} ><span className="alert-details-heading-col">CLOSE TIME</span></Col>
                                            <Col lg={2}><span className="alert-details-heading-col">LAST OCCURED</span></Col>
                                            <Col lg={2} className='text-center'><span className="alert-details-heading-col ">ACTION</span></Col>
                                            {/* <Col lg={1} className='text-center'><span className="alert-details-heading-col ">ACTION</span></Col> */}
                                        </Row>
                                        <div style={{ marginTop: "5px"}}>
                                            <Row className={""}>
                                                <Col lg={4} className='d-flex'>
                                                    {getOnepaneTypeCIIcon(item.config_item?.onepane_type)}
                                                    <SimpleToolTip tooltip={item.config_item?.name}>
                                                        <span className="alert-details-col table-alert-title span-ellipsis">
                                                            {item.config_item?.name}
                                                        </span>
                                                    </SimpleToolTip>
                                                    <Link to={`/resources/${item.config_item?.ci_id}/details`}><MdOutlineLaunch color='#6a53af' style={{marginLeft: "5px"}} /></Link>
                                                </Col>
                                                <Col lg={2}>
                                                    <span className="alert-details-col">
                                                            <Moment format="MMM-DD HH:mm:ss">
                                                                {item.open_time}
                                                            </Moment>
                                                    </span>
                                                </Col>
                                                <Col lg={2}>
                                                    <span className="alert-details-col">
                                                        {/* moment(item.closed_time).format("MMM-DD HH:MM:SS") */}
                                                        {item.closed_time == 0 && <span className="no-value-span">not yet closed</span> }
                                                        {item.closed_time != 0 && <Moment format="MMM-DD HH:mm:ss">{item.closed_time}</Moment> }
                                                    </span>
                                                </Col>
                                                <Col lg={2}>
                                                    <span className="alert-details-col">
                                                                {item.last_occured?.open_time == 0 && <Moment format="MMM-DD HH:mm:ss">{item.open_time}</Moment>}
                                                                {item.last_occured?.open_time != 0 && <Moment format="MMM-DD HH:mm:ss">{item.last_occured?.open_time}</Moment>}
                                                    </span>
                                                </Col>
                                                <Col lg={2} className='text-center'>

                                                    {/* {
                                                         item.config_item?.config_mapping?.map((config, index) => {
                                                            return (
                                                                
                                                                <TooltipView position={"top"} padding={"0px"} key={index} body={
                                                                    <div className='table-platform-wrapper'>
                                                                        <Row className='table-row-wrapper'>
                                                                            <Col lg={1}>
                                                                                <img src={`/images/connector-images/${config?.platform?.platform_type?.icon}`} style={{display: "block"}} className="table-description-img br-img-rounded" /> 
                                                                            </Col>
                                                                            <Col lg={7}>
                                                                                <span className='span-ellipsis'>{config?.platform?.name}</span>
                                                                            </Col>
                                                                            <Col lg={1}>
                                                                                <MdOutlineLaunch onClick={()=>gotoResource(config?.config_item_id)} />
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                }>
                                                                    <img src={`/images/connector-images/${config?.platform?.platform_type?.icon}`} style={{marginRight: "5px"}} className="table-description-img br-img-rounded" /> 
                                                                </TooltipView>

                                                            )
                                                        })
                                                    } */}
                                                    <span className="alert-details-col ops-common-button-hover p-1">
                                                        <a href={item.incident_link} target="#"  className='alert-details-col'>
                                                            <MdOutlineLaunch />
                                                        </a>
                                                    </span>

                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    )
                })
                }
                {props.data?.length == 0 && <TableEmpty />}
            <Row style={{marginTop: "20px"}}>
                <TablePagination rowCount={props.totalRows} rowsPerPage={props.perPage} currentPage={props.currentPage} onChangePage={props.onChangePage}/>
            </Row>
            
            </Container>
            
        </div>
    );
}
export default IncidentTable;