import { useEffect, useState } from 'react';

import { RiDeleteBin7Line, RiAlbumLine, RiAddCircleLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { API_URL } from '../../config/config';
import GetService from '../../services/GetServices';
import DashboardBody from '../../pages/newlayout/DashboardBody';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Sources from './Sources';
import Button from '../../components/Elements/Button/Button';
import './Sources.css';
import RBACWrapper from 'components/RBACWrapper/RBACWrapper';

const Index = () =>{

    const [selectTab, setSelectTab] = useState(1)
    const [itemCounts, setItemCount] = useState({ source:0, destination:0, processors:0 })


    useEffect(()=>{
        GetService(API_URL + "/api/v1/platform/overview").then((response)=>{
            let respData = response.data.data
            setItemCount({
                source:respData.source_count,
                destination:respData.destination_count,
                processors:0
            })
        })
        
    },[])

    
   
    let action  = <>
        <RBACWrapper action={"INTEGRATION_ADD"}>
            <Link to={"/integrations/new/sources"}>
                <Button className='integration-add-button'> <RiAddCircleLine style={{marginRight: "11px", marginTop: "-4px"}} size={"18px"}/> Add Integration</Button>
            </Link>
        </RBACWrapper>
    </>
                
    return (

        <>
        
            <Breadcrumbs section={["Integrations"]} actions={action}
            
            />
            <DashboardBody style={{background: "#F4F6FC"}}>
            <div className='source-containter'>
                <div className='integration-tab'>
                    <div className='integration-tabs-container'>
                        <div className={`integration-tab-menu`}>
                            <div className={`integration-tab ${selectTab == 1 ? 'active':''}`} onClick={()=>setSelectTab(1)}>
                                
                                <div className='tab-text'>
                                    <RiDeleteBin7Line size={18} style={{marginTop: "-3px", marginRight: "2px"}}/> Source</div>
                                <div className='tab-extra'>
                                    <div className='integration-count'>{itemCounts.source}</div>
                                </div>
                            </div>
                        </div>
                        <div className='integration-tab-menu'>
                            <div className={`integration-tab disabled`}>
                                <div className='tab-text'>
                                    <RiDeleteBin7Line size={20}  style={{marginTop: "-3px", marginRight: "2px"}}/> Processors</div>
                                <div className='tab-extra'>
                                <div className='integration-count'>{itemCounts.processors}</div>
                                </div>
                            </div>
                        </div>
                        <div className='integration-tab-menu'>
                            <div className={`integration-tab disabled`}>
                                <div className='tab-text'>
                                    <RiAlbumLine size={20}  style={{marginTop: "-3px", marginRight: "2px"}} />  Destination</div>
                                <div className='tab-extra'>
                                    <div className='integration-count'>{itemCounts.destination}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='integration-tab-panel'>
                    {selectTab == 1 && <Sources/> }
                </div>
            </div>
            

            </DashboardBody>
        </>
    )
}

export default Index;