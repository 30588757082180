
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs"
import DashboardBody from "../../../pages/newlayout/DashboardBody"
import AzureIcon from "../../../assets/icons/connectors/new-azure-icon.svg"
import IntegrationInfo from "../Common/IntegrationInfo"
import filterIcon from "../../../assets/icons/controls/filter.svg"
import "./Azure.css"
import { useNotifications } from "reapop"
import Tag from "../../../components/Elements/Tag/Tag"
import Card from "../../../components/Card/Card"
import { RiAddCircleLine } from 'react-icons/ri'

import { GrConfigure } from "react-icons/gr"
import Select from "../../../components/V1/Select/Select"
import SelectList from "../../../components/V1/SelectList/SelectList"
import { Col, Row } from "react-bootstrap"
import Button from "../../../components/Elements/Button/Button"
import { RiUserSettingsLine } from "react-icons/ri"
import HowToLink from "../../../components/V1/HowToLink/HowToLink"
import { useEffect, useRef, useState } from "react"
import { IoMdRemoveCircleOutline } from "react-icons/io"
import NewService from "../../../components/modals/NewService/NewService"
import { RiCloseCircleLine } from "react-icons/ri"
import { HiPencil } from "react-icons/hi"
import { useParams } from "react-router-dom"
import GetService from "services/GetServices"
import { API_URL } from "config/config"
import PostService from "services/PostServices"
import { Tooltip } from "react-tippy"
import { FiTrash2 } from "react-icons/fi"
import ConfirmModel from "../Common/ConfirmModel"
import { getPolicyStatus } from "utils/util"
import { getPlatformInfo } from "../common"
import RBACWrapper from "components/RBACWrapper/RBACWrapper"

const Azure = () => {


    const { id } = useParams()
    const [platform, setPlatform] = useState({})
    const [info, setInfo] = useState([])

    const [resources, setResources] = useState([])
    const [removedResourcesForSelectList, setRemovedResourcesForSelectList] = useState([])
    const [policies, setPolicies] = useState([]);
    const [enableRefresh, setEnableRefresh] = useState(true);
    const [policyValues, setPolicyValues] = useState([])
    const [serviceTags, setServiceTags] = useState([])
    const [selectedServiceForSelectList, setSelectedServiceForSelectList] = useState([])
    const [removedServiceForSelectList, setRemovedServiceForSelectList] = useState([])
    const dashboardBodyRef = useRef(null)
    const [policyDeleteModalInfo, setPolicyDeleteModalInfo] = useState({})
    const [showPolicyDeleteModal, setShowPolicyDeleteModal] = useState(false)

    const [selectedPolicies, setSelectedPolicies] = useState([])
    const [selectedPolicy, setSelectPolicy] = useState({ name: "select policy", action: "Audit", id: 0, value: "Audit" })
    const [selectedTag, setSelectedTag] = useState("")
    const [removedResources, setRemovedResources] = useState([])
    const [allServices, setAllServices] = useState([])
    const [selectedServices, setSelectedService] = useState([])
    const [removedServices, setRemovedService] = useState([])
    const [resourceFilterData, setResourceFilterData] = useState([])

    const [showModal, setShowModal] = useState(false)
    const [expandService, setExpandService] = useState(false)
    const [expandConfig, setExpandConfig] = useState(false)




    const { notify } = useNotifications()
    const confirmInput = useRef(null)


    const deleteSelectPolicy = (data, index) => {
        //console.log({data})

        if (data.saved == true) {

            setPolicyDeleteModalInfo({
                action: "delete",
                iconClass: "integration-icon-cover-delete",
                icon: <FiTrash2 />,
                heading: "Delete",
                subheading: "Completely delete the policy, data will also be lost.",
                message: `To confirm, type "delete"`,
                actionButtonText: "Delete",
                actionSuccessMessage: "Deleted Successfully",
                onAction: async () => {
                    if (confirmInput.current.value == "delete") {
                        await GetService(API_URL + `/api/v1/policy/applied/${data.id}/delete`)
                        showNotification("Policy Deleted Successfuly");
                        setShowPolicyDeleteModal(false)
                        let temp = JSON.stringify(selectedPolicies)
                        temp = JSON.parse(temp)
                        temp[index].status = 2
                        setSelectedPolicies(temp)
                    } else {
                        showNotification("Please type delete", "error");
                    }

                }
            })
            setShowPolicyDeleteModal(true)


        } else {
            let temp = JSON.stringify(selectedPolicies)
            temp = JSON.parse(temp)
            temp.splice(index, 1)
            setSelectedPolicies(temp)
        }




    }



    const addSelectedPolicy = async () => {

        let hasPolicy = await selectedPolicies.filter(item => item.platform_type_policy_id == selectedPolicy.id)
        console.log({ selectedPolicies, selectedPolicy })
        if (hasPolicy.length == 0) {
            console.log({ selectedPolicy })
            if ((selectedPolicy.name !== "select policy")) {
                setSelectedPolicies([...selectedPolicies, ...[{ name: selectedPolicy.name, id: selectedPolicy.id, value: selectedPolicy.value, action: selectedPolicy.action, status: 3, platform_type_policy_id: selectedPolicy.id, saved: false }]])
                setSelectPolicy({ name: "select policy", action: "Deny", id: 0, value: "Deny" })
            }
        } else {
            showNotification("Policy already added", "error")
        }


    }



    const showNotification = (text, type = "success") => {
        notify(text, type, {
            dismissAfter: 1500
        })
    }

    const onSelectPolicyName = (item) => {
        let temp = { ...selectedPolicy, ...{ name: item.label, id: item.value } }
        setSelectPolicy(temp)
    }
    const onSelectPolicyAction = (item) => {
        let temp = { ...selectedPolicy, ...{ action: item.label, value: item.value } }
        setSelectPolicy(temp)
    }

    const onResourceSelected = (selectedItem, removedItems) => {
        let tempRemovedResources = []
        removedItems?.map(item => {
            if (item.value instanceof Array) {
                item.value?.map(rItem => {
                    tempRemovedResources.push(rItem.value)
                })
            }
        })
        //console.log({tempRemovedResources})
        setRemovedResources(tempRemovedResources)
    }

    const onServiceSelected = (selectItem, removedItems) => {

        let tempRemovedServices = []
        let temService = [];

        for (const item of removedItems) {
            tempRemovedServices.push(item.value)
        }

        for (const item of selectItem) {
            temService.push(item.value)
        }


        setRemovedService(tempRemovedServices)
        setSelectedService(temService)


    }

    const onServiceRemoved = (selectItem, removedItems) => {


        let tempRemovedServices = []
        let temService = [];

        for (const item of removedItems) {
            tempRemovedServices.push(item.value)
        }

        for (const item of selectItem) {
            temService.push(item.value)
        }

        setRemovedService(tempRemovedServices)
        setSelectedService(temService)
    }

    

    const onExpandClick = (platformState, value) => {
        setEnableRefresh(!value)
        platformState(value)
        setTimeout(() => {
           if(dashboardBodyRef.current){
            dashboardBodyRef.current.scrollTop = 330
           }
        }, 10)

    }


    const expandButton = (platformState, stateFun, disable, message) => {
        return (
            <>
                {platformState == true && <>
                    <Button type="danger" ButtonStyle={"transparent"} size={"small"} onClick={() => onExpandClick(stateFun, false)}>
                        <RiCloseCircleLine size={"16"} style={{ marginRight: "3px", marginTop: "-3px" }} /> Cancel
                    </Button>
                </>
                }
                {platformState == false && <>
                    <Tooltip disabled={!disable} html={<><span style={{ padding: "0px 10px" }}>{message}</span></>}>
                        <Button ButtonStyle={"transparent"} disabled={disable} size={"small"}  onClick={() => onExpandClick(stateFun, true)}>
                            <HiPencil size={"16"} style={{ marginRight: "3px", marginTop: "-3px" }} /> Edit
                        </Button>
                    </Tooltip>

                </>
                }

            </>
        )
    }


    


    const parseResources = (data) => {
        let tempResources = {};
        let tempResourceFilterData = [] // to save Resource type filter data
        data?.map(item => {
           
            if (tempResources[item.original_type]) {
                tempResources[item.original_type].value.push({
                    label: item.name,
                    value: item.ci_id,
                    selected: item.selected
                })
            } else {
                tempResourceFilterData.push({
                    label: item.original_type,
                    values: item.original_type,
                    key: item.original_type
                })
                tempResources[item.original_type] = {
                    value: [{
                            label: item.name,
                            value: item.ci_id,
                            selected: item.selected
                    }]
                }
            }
        })

        let cleanedResources = [];
        let cleanedRemovedResources = []
        for (const item in tempResources) {
            let tempRes = []
            let tempRemRes = []
            tempResources[item].value?.map(res => {
                if (res.selected == false) {
                    tempRemRes.push(res)
                } else {
                    tempRes.push(res)
                }
            })
            cleanedResources.push({ label: item, value: tempRes })
            cleanedRemovedResources.push({ label: item, value: tempRemRes })

        }
        setResources(cleanedResources)
        setRemovedResourcesForSelectList(cleanedRemovedResources)
        setResourceFilterData(tempResourceFilterData)

    }

    const parsePolicies = (data, selectedPolicies = []) => {
        let tempPolicies = [];
        let tempSelectPolicies = []
        data?.map(item => {
            tempPolicies.push({
                label: item.policy_mapping_name,
                value: item.policy_platform_type_mapping_id,
                modes: item.policy_config.modes
            })
        })
        setPolicies(tempPolicies)
        setPolicyValues([{ label: "Deny", value: "Deny" }, { label: "Audit", value: "Audit" }])
        selectedPolicies.map(item => {
            let policy = data.find(policy => policy.policy_platform_type_mapping_id == item.platform_type_policy_id)
            tempSelectPolicies.push(
                {   name: policy?.policy_mapping_name, 
                    id: item?.policy_platform_id,
                    value: item?.policy_configuration?.modes, 
                    action: item?.policy_configuration?.modes, 
                    status: item.policy_status, 
                    platform_type_policy_id: item.platform_type_policy_id, 
                    saved: true 
                }
            )
        })
        setSelectedPolicies(tempSelectPolicies)
    }

    const parseServiceTags = (data) => {
        let tempTags = [];
        data?.map(item => {
            tempTags.push({
                label: item,
                value: item,
            })
        })
        setServiceTags(tempTags)
    }


    const getInitialData = async (loader) => {
        let response = await GetService(API_URL + `/api/v1/platform/${id}/step/init`, {}, false, false, loader)
        let data = response.data.data;
        parseResources(data.resources)
        parsePolicies(data.policies, data.applied_policies)
        parseServiceTags(data.tags)
        setSelectedTag(data.selected_tag != "" ? data.selected_tag : "Select Service Tag")
    }

    const getServiceData = async (loader) => {
        let response = await GetService(API_URL + `/api/v1/platform/${id}/step/final`, {}, false, false, loader)
        let data = response.data.data;
        let services = data?.services;
        let apiRemovedServices = Array.from(new Set(data?.removed_services))
        let apiSelectedServices = Array.from(new Set(data?.selected_services))
        // console.log({data, services, removedServices, selectedServices})

        let tempSelectedService = []
        let tempRemovedServices = []
        let tempSelectedServiceForList = []
        let tempRemovedServiceForList = []

        apiSelectedServices.map((item) => {
            tempSelectedService.push(item)
        })

        apiRemovedServices.map((item) => {
            tempRemovedServices.push(item)
        })

        services.map(item => {
            if (tempRemovedServices.includes(item)) {
                tempRemovedServiceForList.push({
                    label: item,
                    value: item
                })
            } else {
                tempSelectedServiceForList.push({
                    label: item,
                    value: item
                })
                if (!tempSelectedService.includes(item)) {
                    tempSelectedService.push(item)
                }
            }
        })


        setSelectedServiceForSelectList(tempSelectedServiceForList)
        setRemovedServiceForSelectList(tempRemovedServiceForList)

        setAllServices(services)
        setRemovedService(tempRemovedServices)
        setSelectedService(tempSelectedService)

    }


    const getIntegrationInfo = async (loader = true) => {
        let response = await GetService(API_URL + `/api/v1/platform/${id}/get`, {}, false, false, loader)
        let data = response.data.data.platform;
        let platformStatus = platform.status
        setPlatform(data)
        
        let platformInfo = getPlatformInfo(data.platform_type.platform_type_id, data)
        setInfo(platformInfo)   
       
        if (data.status >= 2 && data.status != platformStatus) {
            await getInitialData(loader);
        }
        if (data.status >= 4 && data.status != platformStatus) {
            await getServiceData(loader)
        }
    }


    const addNewService = (serviceName, user) => {
        if (serviceName == "") {
            showNotification("Please enter service name", "error");
            return
        }

        let tempSelectedServices = selectedServiceForSelectList?.map(item => item.value)

        if (tempSelectedServices.includes(serviceName)) {
            showNotification("Service name already exist", "error");
            return
        }

        let tempServiceForSelectList = JSON.parse(JSON.stringify(selectedServiceForSelectList))
        tempServiceForSelectList.push({ label: serviceName, value: serviceName })
        setSelectedServiceForSelectList(tempServiceForSelectList)




        showNotification("Saved Successfuly")
        setShowModal(false);
    }

    const saveConfig = () => {

        if (selectedTag == "") {
            showNotification("Please select Service Tag", "error");
            return
        }

        let parseSelectedPolicy = selectedPolicies?.map(item => {
            return {
                policy_id: parseInt(item.id),
                policy_config: {
                    modes: item.value,
                }

            }
        })


        PostService(API_URL + `/api/v1/platform/${id}/step/init`, {
            removed_resources: removedResources,
            policies: parseSelectedPolicy,
            selected_tag: selectedTag

        }).then(response => {
            showNotification("Config Saved Successfully");
            setExpandConfig(false)
        })

    }

    const saveService = () => {
        console.log({ selectedServices, removedServices })
        if (selectedServices.length == 0) {
            showNotification("Please select alteast one servcie", "error");
            return
        }
        PostService(API_URL + `/api/v1/platform/${id}/step/final`, {
            selected_services: selectedServices,
            removed_services: removedServices
        }).then(response => {
            showNotification("Servce Saved Successfully");
            setExpandService(false)
        })

    }

    useEffect(() => {
        getIntegrationInfo()
    }, [])


    useEffect(() => {
        let refreshIntegrationInfo = null
        clearInterval(refreshIntegrationInfo)
        refreshIntegrationInfo = setInterval(() => {
            if (enableRefresh) {
                getIntegrationInfo(false)
            }
        }, 10000);
        return () => clearInterval(refreshIntegrationInfo);
    }, [platform, enableRefresh])



    const ConfigCardTitle = <>
    
        <div className="d-flex align-items-center">
            <span className="flex-grow-1">
                <GrConfigure style={{ marginRight: "8.2px" }} /> Configuration
            </span>
            <span>
                {expandButton(expandConfig, setExpandConfig,  RBACWrapper.hasPermission("INTEGRATION_EDIT") ? platform.status < 2 ? true: false: true , "Please wait to delploy the connector")}
            </span>
        </div>

    </>

    const ServiceCardTitle = <>
        <div className="d-flex align-items-center">
            <span className="flex-grow-1">
                <span> <RiUserSettingsLine style={{ marginRight: "8.2px" }} /> Service Overview </span>
            </span>
            <span>
                <span>
                    {expandButton(expandService, setExpandService,  RBACWrapper.hasPermission("INTEGRATION_EDIT") ? platform.status < 4 ? true : false : true , "this will only enable after configure")}
                </span>
            </span>
        </div>

    </>



   


    return (
        <>

            <Breadcrumbs section={[{ name: "Integrations", path: "/integrations" }]} />
            <DashboardBody ref={dashboardBodyRef}>
                <IntegrationInfo icon={AzureIcon} title={"Onepane Azure"} info={info} platform={platform} />
                { ![7,8,9,10].includes(platform.status) && <>
                <Card title={ConfigCardTitle} type="full" style={{ marginTop: "20px" }} expand={expandConfig}>

                    <Card.CardBody>
                        <div>
                            <span className="integration-section-title">Select Resources</span>
                            <span className="azureTagHeading">By default, Onepane tracks all resources. If there are any resources you wish to exclude from tracking, remove them to create exceptions.</span>
                            <SelectList
                                selectDescription="All of the resources below are cataloged and monitored within Onepane"
                                removeDescription="Ensure that the resources not listed in Onepane are not essential to
                                    your business operations"
                                selectedOptions={resources}
                                removedOptions={removedResourcesForSelectList}
                                onItemRemoved={onResourceSelected}
                                onItemSelected={onResourceSelected}
                                selectedFilterData={resourceFilterData}
                                removedFilterData={resourceFilterData}
                            />
                        </div>
                    </Card.CardBody>

                    {policies.length > 0 && <>
                    <div className="card-divider"></div>
                    
                    <Card.CardBody>
                        <div>
                            <span className="integration-section-title">Standard and Policy Enforcement</span>
                            <span className="azureTagHeading">Select the policies you want to apply within Azure. You have the flexibility to make changes later.</span>

                            <div>
                                <div className="policy-table">
                                    <div className="policy-table-header">
                                        <div>POLICY NAME</div>
                                        <div>STATUS</div>
                                        <div>ACTION</div>
                                    </div>
                                    <div className="policy-table-body">
                                        {
                                            selectedPolicies.map((item, index) => {
                                                return (
                                                    <>
                                                        <div className="policy-row">
                                                            <div>{item.name}</div>
                                                            <div>{getPolicyStatus(item.status)}</div>
                                                            <div>
                                                                <div style={{ display: "flex" }}>
                                                                    {item.status != 2 && <>
                                                                        <div style={{ flexGrow: "1", marginRight: "10px" }}>
                                                                            <Select defaultValue={item.value} label={""} placeholder={item.value} options={[
                                                                                { label: "Deny", value: "Deny" },
                                                                                { label: "Audit", value: "Audit" }
                                                                            ]} style={{ width: "100%" }} />
                                                                        </div>
                                                                        <div>
                                                                            <IoMdRemoveCircleOutline color="#D94F4F" size={24} onClick={() => deleteSelectPolicy(item, index)} style={{ marginTop: "12px", cursor: "pointer" }} />
                                                                        </div>
                                                                    </>}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </>

                                                )
                                            })
                                        }

                                    </div>
                                </div>

                                <Row style={{ marginTop: "17.7px" }} >
                                    <Col md={4}>
                                        <Select placeholder={selectedPolicy.name} defaultValue={selectedPolicy.name} label={""} options={policies} style={{ width: "100%" }} onChange={onSelectPolicyName} />
                                    </Col>
                                    <Col md={4}>
                                        <Select defaultValue={selectedPolicy.action} label={""} placeholder={selectedPolicy.action} options={policyValues} style={{ width: "100%" }} onChange={onSelectPolicyAction} />
                                    </Col>
                                    <Col md={4}>
                                        <Button ButtonStyle="transparent" onClick={addSelectedPolicy} style={{ marginTop: "8px" }}> <RiAddCircleLine style={{ marginRight: "6px", marginTop: "-4px" }} size={"18px"} /> Add</Button>
                                    </Col>

                                </Row>



                            </div>

                        </div>
                    </Card.CardBody>
                    </>}
                    <div className="card-divider"></div>

                    <Card.CardBody>
                        <div className="">
                            <span className="integration-section-title">Select Service Tag<span style={{ color: "red" }}>*</span></span>
                            <div>
                                <span className="azureTagHeading">Choose a tag name to represent your business application.</span>                                </div>
                            <Select defaultValue={selectedTag} label={"Service Tag Name"} placeholder={selectedTag} options={serviceTags} onChange={(item) => setSelectedTag(item.value)} style={{ width: "375px" }} />
                        </div>

                        <div className="d-flex policy-action">
                            <div className="flex-grow-1">
                                <HowToLink />
                            </div>
                            <div>
                                <Button type="danger" ButtonStyle="transparent" onClick={() => setExpandConfig(false)}>Cancel</Button>
                                <Button onClick={saveConfig}>Save</Button>
                            </div>
                        </div>
                    </Card.CardBody>


                </Card>


                <Card title={ServiceCardTitle} type="full" style={{ marginTop: "20px" }} expand={expandService}>
                    <div style={{marginLeft:'20px',paddingTop:'18px'}}>
                        <span className="integration-section-title">Select Services</span>
                        <span className="azureTagHeading" style={{paddingBottom:0}}>Onepane automatically monitors all services. Remove a specific service from the list to not be tracked by Onepane. You can change this configuration later.</span>
                    </div>
                    <Card.CardBody>
                        <div>
                            <SelectList
                                selctedFilter={false}
                                selectDescription="Exclude any unwanted service from consideration"
                                removeDescription="Ensure that the following items do not result in any business impact"
                                selectedOptions={selectedServiceForSelectList}
                                removedOptions={removedServiceForSelectList}
                                selectedActionComponent={<>
                                    <Button style={{ marginRight: "20px" }} onClick={() => setShowModal(true)}>Add Service</Button>
                                    <img src={filterIcon} style={{ marginRight: "2px", marginBottom: "3px" }} /> <span className={"custom-filter-text"}>Filter</span>
                                </>}
                                onItemRemoved={onServiceRemoved}
                                onItemSelected={onServiceSelected}
                            />
                        </div>
                        <div>
                            <div className="d-flex policy-action">
                                <div className="flex-grow-1">
                                    <HowToLink />
                                </div>
                                <div>
                                    <Button type="danger" ButtonStyle="transparent" onClick={() => setExpandService(false)}>Cancel</Button>
                                    <Button onClick={saveService}>Save</Button>
                                </div>
                            </div>
                        </div>
                    </Card.CardBody>
                </Card>
                <Card >

                </Card>
                </>}
                <NewService show={showModal} title={"Add Service"} description={"Include a fresh service and owner to consolidate resources within a single service, allowing you to conveniently monitor them as a group."} users={[]} onHide={() => setShowModal(false)} onSave={addNewService} />
                <ConfirmModel show={showPolicyDeleteModal} modalInfo={policyDeleteModalInfo} inputRef={confirmInput} onCancel={() => setShowPolicyDeleteModal(false)} />
            
            </DashboardBody>
        </>
    )

}


export default Azure