import Profile from "../newlayout/Profile"
import style from "./style.module.css"


const Header = ({showProfile, user, org, orgList, enableOrganisation = true})=>{
    


    return(
        <>
            <div className={`px-5 py-3 ${style.generalLayoutHeader}`}>
                <div className="flex-grow-1">
                    <img src="/images/icons/onepane-full.png" style={{height: "26px"}}/> 
                </div>
                <div>
                   {showProfile && <Profile user={user} org={org} orgList={orgList} enableOrganisation={enableOrganisation} /> }
                </div>
            </div>
        </>
    )
}

export default Header