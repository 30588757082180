
import Card from "../../../components/Card/Card"
import Tag from "../../../components/Elements/Tag/Tag"
import SearchControl from "../../../components/SearchControl/SearchControl"
import CardList from "../../../components/CardList/CardList"
import { RiBookMarkLine,RiDatabase2Line } from "react-icons/ri";
import { useEffect, useState } from "react";
import PostService from "services/PostServices";
import { API_URL } from "config/config";
import { useNotifications } from "reapop";
import Checkbox from "components/V1/Checkbox/Checkbox";
import Button from "components/Elements/Button/Button"
import { RiCloseCircleLine } from "react-icons/ri";
import { HiPencil} from "react-icons/hi";
import { Tooltip } from "react-tippy";
import RBACWrapper from "components/RBACWrapper/RBACWrapper";

const  AddApp = ({platformCategory, platformId, status, resources, onExpand})=>{

   
    const [expandAppliction, setExpandAppliction] = useState(false)
    const [apmApplictions, setApmApplications] = useState([])
    const [selectedApplicationIdsState, setSelectedApplicationIdsState] = useState([]);
   
    
    const {notify} = useNotifications()
   
    var selectedApplicationIds = []
    var removedApplicationIds = []



    const ApplicationCardTitle =  <>
            <div className="d-flex align-items-center">
            <span className="flex-grow-1"> 
                <RiDatabase2Line color="#6B53AE" size={18} style={{marginRight: "8px"}} /> 
                {platformCategory == 2 ? ' Application List ' : 'Repository List'}
            </span>
            <span>
                    <Tooltip disabled={!(status < 2)} html={<><span style={{padding: "0px 10px"}}>Please wait to delploy the connector</span></>}>
                        <Button ButtonStyle={"transparent"}  type={expandAppliction ? "danger" : "primary"} size={"small"} disabled={ RBACWrapper.hasPermission("INTEGRATION_EDIT") ?  status < 2 ? true : false : false}  onClick={()=>ExpandPanel(!expandAppliction)}>
                            { expandAppliction == true ? <><RiCloseCircleLine size={"16"} style={{marginRight: "3px", marginTop: "-3px"}} /> Cancel</> : <><HiPencil size={"16"} style={{marginRight: "3px", marginTop: "-3px"}} /> Edit</> }
                        </Button>
                    </Tooltip>
                
            </span>
        </div>
    </>




    const showNotification = (text, status="success")=>{
        notify( text, status,{
            dismissAfter: 1500
        })
    }

    const ExpandPanel = (value)=>{
        setExpandAppliction(value)
        onExpand(value)
    }
    

    const onApplicationSelect = (element, id)=>{

        let rawItems = JSON.parse(JSON.stringify(selectedApplicationIds));
        let removedItems =  JSON.parse(JSON.stringify(removedApplicationIds));
        
        console.log({
            selectedApplicationIds,
            removedApplicationIds,
            rawItems,
            removedItems,
            result : element.target.checked == true && rawItems.includes(id) == false,
            checked: element.target.checked,
            checked2: (rawItems.includes(id) == false),
            id: id
        })

         if(element.target.checked == true && rawItems.includes(id) == false){
            rawItems.push(id)
            let itemIndex = removedItems.indexOf(id);
            removedItems.splice(itemIndex,1)
        }else{
            let itemIndex = rawItems.indexOf(id);
            rawItems.splice(itemIndex,1)
            removedItems.push(id)
        }

        selectedApplicationIds = rawItems;
        removedApplicationIds = removedItems;
        setSelectedApplicationIdsState(selectedApplicationIds)
        console.log({rawItems, removedItems})
      
        
        // setSelectedApplicationIds(rawItems)
        // setRemovedApplicationIds(removedItems)
        createResourceList(rawItems)
    }


    const createResourceList = (selectIds = [])=>{
        let applicationsList = []
        console.log({selectIds})
        resources.map((item, index)=>{
            applicationsList.push(
                {
                    id:item.ci_id,
                    data: item,
                    selected: item.selected,
                    render:<>
                        <div className="application-row">
                            <div className="apm-checkbox checkBoxmargin">
                               <Checkbox 
                               checked={selectIds.includes(item.ci_id)}
                               onChange={(e)=>onApplicationSelect(e, item.ci_id)} />
                            </div>
                            <div className={`${platformCategory == 3 ? "git-repo": "apm-app "}`}>
                                <span><RiDatabase2Line color="#2DA077" size={20} /></span>
                                <span className="apm-app-name">{item.name}</span>
                                {platformCategory == 3 && <> 
                                    <Tag type={item.tags?.["repository.visibility"] == "private" ? "warning" : "primary"}>{item.tags?.["repository.visibility"]}</Tag>
                                </>}
                            </div>
                            {platformCategory == 3 && <>
                                <div className="git-lang">
                                    <span className="">
                                        <div className="git-lang-indicator"></div>
                                    </span>
                                    <span className="git-lang-label">{item.tags?.["repository.language"] == "" ? <span className="no-value-span">Not Detected</span> : item.tags?.["repository.language"]}</span>
                                </div>
                            </>}

                            <div className={`${platformCategory == 3 ? "git-type": "apm-type apm-type-div"}`} >
                                {platformCategory == 2 && <> Type : <span className="apm-type-value">{item.original_type}</span></>}
                                {platformCategory == 3 && <> Type : <span className="git-type-value">Code</span></>}
                            </div>

                            {platformCategory == 2 && <><div className="apm-type apm-service-div">
                                <span style={{flexFlow: 1}}>Resource ID : </span><span className="apm-type-value span-ellipsis apm-type-ID" style={{display: "inline-block",width: "77%"}}>{item.identifier}</span>
                            </div>
                            </>}
                            {platformCategory == 3 && <><div className="git-type">
                                Branch : <span className="git-type-value">{item.tags?.["repository.branch"]}</span>
                            </div>
                            </>}
                        </div>
                    </>
                }
                
            )
        })
        setApmApplications(applicationsList)
    }

   
    const onSave = ()=>{
        PostService(API_URL + `/api/v1/platform/${platformId}/step/init`,{
            selected_resources: selectedApplicationIdsState
        }).then(response=>{
            showNotification("Save Successfully")
            setExpandAppliction(false)
        })
    }


    useEffect(()=>{
        
        let tempSelectApplicationIds = []
        let tempRemovedApplicationIds =[]
     
        resources.map((item)=>{
            if(item.selected == true){
                tempSelectApplicationIds.push(item.ci_id)
            }else{
                tempRemovedApplicationIds.push(item.ci_id)
            }
            
        })

        selectedApplicationIds = tempSelectApplicationIds;
        removedApplicationIds = tempRemovedApplicationIds
        setSelectedApplicationIdsState(tempSelectApplicationIds)
        createResourceList(tempSelectApplicationIds)
    },[resources])



    

    






    return(
    


        <div style={{marginTop: "20px"}} >
                    <Card 
                        title={ApplicationCardTitle}
                        type="full"
                        expand={expandAppliction}
                    >
                      
                            <Card.CardBody>
                                <SearchControl />
                                <CardList onCancel={()=>ExpandPanel(false)} onSave={onSave} data={apmApplictions} style={{marginTop:"20px"}}/>
                            </Card.CardBody>
                    
                    </Card>
                </div>

    )

}


AddApp.defualtProps = {
    resources:[]
}

export default AddApp