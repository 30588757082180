import { useEffect, useRef, useState } from "react";

import IconTag from "../../../components/IconTag/IconTag";
import { FaRegDotCircle } from "react-icons/fa";
import { HiPencil } from "react-icons/hi";
import { RiCheckboxCircleLine } from "react-icons/ri";
import { useNotifications } from "reapop";
import { BiErrorCircle } from "react-icons/bi";
import Card from "../../../components/Card/Card";
import KeyValueLabel from "../../../components/KeyValueLabel/KeyValueLabel";
import "./IntegrationInfo.css";
import IntegrationCreation from "../../../components/modals/IntegrationCreation/IntegrationCreation";
import kebab from "../../../assets/icons/util/kebab.svg";
import { MdOutlineDoNotDisturbOn } from "react-icons/md";
import { FiTrash2 } from "react-icons/fi";
import PostService from "services/PostServices";
import { API_URL } from "config/config";
import ConfirmModel from "./ConfirmModel";
import deploying from "assets/gif/deploying.gif";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { useLocation, useNavigate, useOutlet, useParams } from "react-router-dom";
import { platformTypeInfo } from "../common";
import GetService from "services/GetServices";
import RBACWrapper from "components/RBACWrapper/RBACWrapper";
import { dashboardFade } from "utils/util";

const IntegrationInfo = ({icon, title, platform, info, onModify})=>{

    
    
    const [editInfo, setEditInfo] = useState({})
    const [config, setConfig] = useState([])
    const [configVal, setConfigVal] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [showDropDown, setShowDropDown] = useState(false)
    const [showActionModal, setShowActionModal] = useState(false)
    const confirmInput = useRef(null)

    const [actionModalInfo, setActionModalInfo] = useState({})
    
    const CONNECTOR_STATUS = ["Deploying", "Error", "Ready to Configure", "Configuring", "Initial Configuration Done", "Scanning", "Running", "Deleting", "Deleted", "Changing State", "Disabled"]
    const CONNECTOR_STATUS_STYLE = {backgroundColor: "#D3EAE2", color: "#2DA077 !important"}
    const CONNECTOR_STATUS_ERROR_STYLE = {backgroundColor: "##f8ebec", color: "#2DA077 !important"}
    const CONNECTOR_STATUS_WARNING_STYLE = {backgroundColor: "#fbf1ec", color: "#2DA077 !important"}

    const pageLocation = useLocation()
    const routeSegment = pageLocation.pathname.split("/");
    const integrationInfo = platformTypeInfo[routeSegment[2]]
    const [healthCheckStatus, setHealthCheckStatus] = useState(0)
   
    const {notify} = useNotifications()
    const outlet = useOutlet();
    const { id, action } = useParams()


    const navigate = useNavigate()
    const showNotification = (text, type="success")=>{
        notify( text, type,{
            dismissAfter: 1500
        })
    }

    const onHealthCheck = (data)=>{
       
        let postData = data.custominput
        console.log({platform})
        PostService(API_URL+`/api/v1/base/platformtype/${platform.type}/test`,postData).then((response)=>{
            if(response.data.success == true){
                setHealthCheckStatus(1)
            }else{
                setHealthCheckStatus(2)
            }
            console.log({response})
        })
    }

    const onIntegrationClick = ()=> {
        
        setEditInfo({
                title: platform.name,
                logo: "/images/connector-images/"+platform.platform_type.icon,
                description: platform.platform_type.description,
                basic_details: "Basic details",
                basic_decscription: platform.platform_type.basic_description,
                label: "Name",
                client_configuration_text: "Client configurations",
                client_configuration_decscription: platform.platform_type.client_config_description,
                hint: <BiErrorCircle color="rgba(255, 153, 0, 0.40)" fontSize={14}/>
            }
        )
        setConfigVal({name: platform.name,...platform.config})
        setConfig(platform.platform_type.configs)
        navigate(`action/edit`)
        setShowModal(true)
        setShowDropDown(false)
    }

    const onUpdate = (data)=>{
        
        PostService(API_URL+`/api/v1/platform/${platform.platform_id}/update`,{
            platform_name: data.platform_name,
            platform_config: data.custominput
        }).then(()=>{
            setShowModal(false)
        })
    }

    const onDisableClick = (platform)=>{
        console.log({platform})
        setActionModalInfo({
            action: "disable",
            iconClass: "integration-icon-cover-disable",
            icon: <MdOutlineDoNotDisturbOn style={{ transform: "rotate(45deg)"}} />,
            heading:"Disable",
            subheading: "Disabling the connector will not remove the data.",
            message:`To confirm, type "disable"`,
            actionButtonText:"Disable",
            actionSuccessMessage:"Disabled Successfully",
            onAction: ()=>{
                if(confirmInput.current.value == "disable"){
                    PostService(API_URL + `/api/v1/platform/${platform.platform_id}/disable`).then(response=>{
                        showNotification("Connector Disabled Successfuly");
                        setShowActionModal(false)
                        confirmInput.current.value = ""
                    })
                }else{
                    showNotification("Please type disable", "error");
                }
               
            }
        });
        setShowActionModal(true)
        navigate(`action/disable`)
        setShowDropDown(false)
        
    }

    const onEnableClick = (platform)=>{
        setActionModalInfo({
            action: "enable",
            iconClass: "integration-icon-cover-disable",
            icon: <RiCheckboxCircleLine />,
            heading:"Enable",
            subheading: "Enabling data collection by re-activating the connector.",
            message:`To confirm, type "enable"`,
            actionButtonText:"Enable",
            actionSuccessMessage:"Enable Successfully",
            actionButtonType:"primary",
            onAction: ()=>{
                if(confirmInput.current.value == "enable"){
                    PostService(API_URL + `/api/v1/platform/${platform.platform_id}/enable`).then(response=>{
                        showNotification("Connector Enabled Successfuly");
                        setShowActionModal(false)
                        confirmInput.current.value = ""
                    })
                }else{
                    showNotification("Please type enable", "error");
                }
               
            }
        });
        navigate(`action/enable`)
        setShowActionModal(true)
        setShowDropDown(false)
    }

    const onDeleteClick = (platform)=>{
        setActionModalInfo({
            action: "delete",
            iconClass: "integration-icon-cover-delete",
            icon: <FiTrash2/>,
            heading:"Delete",
            subheading: "Completely delete the connector, data will also be lost.",
            message:`To confirm, type "delete"`,
            actionButtonText:"Delete",
            actionSuccessMessage:"Deleted Successfully",
            onAction: ()=>{
                if(confirmInput.current.value == "delete"){

                    if(platform.platform_type.category == 1){
                        GetService(API_URL + `/api/v1/platform/active/sources?filter=${JSON.stringify({"platform_type_id":[2,3,4,5,6,7,8,9]})}`).then(platformRes=>{
                            let hasOtherConnetor = platformRes.data?.data?.total_count > 1
                            if(hasOtherConnetor){
                                showNotification("Delete all connectors that are not cloud-based", "error");
                                setShowDropDown(false)
                                return
                            }

                            PostService(API_URL + `/api/v1/platform/${platform.platform_id}/delete`).then(response=>{
                                showNotification("Connector Deleted Successfuly");
                                setShowActionModal(false)
                                confirmInput.current.value = ""
                                navigate("/integrations")
        
                            })
            
                        })
                    }

                   
                }else{
                    showNotification("Please type delete", "error");
                }
               
            }
        })
        navigate(`action/delete`)
        setShowActionModal(true)
        setShowDropDown(false)
    }
    
    const closeActionModal = ()=>{
        confirmInput.current.value = ""
        setShowActionModal(false)
        resetURL()

    }

    const resetURL = ()=>{
        let pSegment = pageLocation.pathname.split("/")
        let pageUrl = pSegment.slice(0, pSegment.length - 2).join("/")
        console.log({pageUrl})
        navigate(pageUrl)
        dashboardFade(false)
    }

    const closeModal = ()=>{
        setShowModal(false)
        resetURL()
    }

    useEffect(()=>{
        console.log({id, action})
        if(action == "edit"){
            onIntegrationClick()
        }
        if(action == "delete"){
            onDeleteClick()
        }
        if(action == "disable"){
            onDisableClick()
        }
        if(action == "enable"){
            onEnableClick()
        }
    },[])
    


    const CartTitle = <>
            <div className="d-flex align-items-center integration-card-title">
                <div>
                    <img src={integrationInfo.icon} className="integration-connector-icon"/>
                </div>
                <div className="integration-connector-title">
                   {integrationInfo.title}
                </div>
                <div className="flex-grow-1 ml-3">
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                            {[2,3,4,5,7,9,10].includes(platform.status) && <IconTag icon={<FaRegDotCircle color={"#FCFCFD"} />} iconBgcolor={"#FDBC3E"} textColor={"#FDBC3E"}  text={CONNECTOR_STATUS[platform.status]} style={CONNECTOR_STATUS_WARNING_STYLE} /> }
                            { platform.status == 0 && <IconTag icon={<img src={deploying} style={{width:"30px"}}/>} iconBgcolor={"#FDBC3E"} textColor={"#FDBC3E"}  text={CONNECTOR_STATUS[platform.status]} style={ CONNECTOR_STATUS_WARNING_STYLE} /> }
                            { platform.status == 1 && <IconTag icon={<FaRegDotCircle color={"#FCFCFD"} />} iconBgcolor={"#D95050"} textColor={"#D95050"}  text={CONNECTOR_STATUS[platform.status]} style={ CONNECTOR_STATUS_ERROR_STYLE} /> }
                            { platform.status == 6 && <IconTag icon={<FaRegDotCircle color={"#FCFCFD"} />} iconBgcolor={"#2DA077"} textColor={"#2DA077"}  text={CONNECTOR_STATUS[platform.status]} style={ CONNECTOR_STATUS_STYLE} /> }
                            { platform.status == 8 && <IconTag icon={<FaRegDotCircle color={"#FCFCFD"} />} iconBgcolor={"#D95050"} textColor={"#D95050"}  text={CONNECTOR_STATUS[platform.status]} style={ CONNECTOR_STATUS_ERROR_STYLE} /> }
                        </div>
                        <RBACWrapper action={"INTEGRATION_EDIT"}>

                            <div>
                                {(platform.status != 7 && platform.status != 9) && <>
                                    <img src={kebab} style={{cursor: "pointer"}} onClick={()=>setShowDropDown(!showDropDown)} />
                                    {showDropDown && <>
                                        <div className="">
                                            <div className="integration-dropdown" >
                                                <div onClick={()=>onIntegrationClick(platform)}>Modify <HiPencil  style={{marginLeft: "40px"}}/></div>
                                                { platform.status != 10 && <div onClick={()=>onDisableClick(platform)}>Disable <MdOutlineDoNotDisturbOn style={{marginLeft: "40px"}} /></div>}
                                                { platform.status == 10 && <div onClick={()=>onEnableClick(platform)}>Enable <RiCheckboxCircleLine  style={{marginLeft: "44px"}} /></div> }
                                                <div onClick={()=>onDeleteClick(platform)}>Delete <FiTrash2 color={"#D94F4F"} style={{marginLeft: "43px"}} /> </div>
                                            </div>
                                        </div>
                                    </>} 
                                </>}
                            </div>

                        </RBACWrapper>
                        
                    </div>
                </div>
            </div>
        </>



    return(
        <>

            <Card title={CartTitle} type="full">
                <Card.CardBody className="p-0">
                    <div>
                        
                        {platform.status == 1 && <>
                            <div className="integration-connector-error-message"> 
                                <span><AiOutlineExclamationCircle color={"#D95050"} size={20} style={{marginRight: "8px"}} /> </span>
                                <span>Error while adding connector : {platform.error_message}</span>
                            </div>
                        </>}
                    </div>
                    <div style={{padding: "8px 21px 21px 21px"}}>
                        {info.map((item, index)=>{
                            return(
                            <   KeyValueLabel key={index} label={item.name} value={item.value} />
                            )
                        })}  
                    </div> 
                </Card.CardBody> 
            </Card>
            { outlet && action == "edit" && <IntegrationCreation show={showModal} info={editInfo} configs={config} configval={configVal} onHide={closeModal} mode={"update"} action={onUpdate} healthCheckStatus={healthCheckStatus} onHealthCheck={onHealthCheck} />}
            { outlet && (action == "delete" || action == "disable" || action == "enable" ) && <ConfirmModel show={showActionModal} modalInfo={actionModalInfo} actionButtonType={actionModalInfo.actionButtonType} inputRef={confirmInput} onCancel={closeActionModal}  /> }
            
           
            
        </>
    )
}

IntegrationInfo.defaultProps = {
    info: []
}

export default IntegrationInfo