import onepaneTypes from "config/onepaneType"



export const getOnepaneTypeCIName = (onepaneType = 1)=>{
    if(onepaneTypes[onepaneType?.toString()]){
        return onepaneTypes[onepaneType].name 
    }
    return onepaneTypes["default"].name 
}

export const getOnepaneTypeCIIcon = (onepaneType = 1)=>{
    if(onepaneTypes[onepaneType?.toString()]){
        return <img className="resource-table-icon" src={ onepaneTypes[onepaneType].icon }/> 
    }
    return onepaneTypes["default"].icon 
}