import {validateEmail} from "../../utils/util"
import moment from "moment/moment"


const OrganisationName = {
    required: {
        value: true,
        message: "organisation name required"
    },
    pattern: {
        value: /^[a-zA-Z ]+$/,
        message: "organisation name should not contain special characters "
    },
    minLength: {
        value: 3,
        message: "organisation name must be mininum 3 characters"
    }
}

const FullName = {
    required: {
        value: true,
        message: "fullname required"
    },
    pattern: {
        value: /^[a-zA-Z ]+$/,
        message: "fullname should not contain special character "
    },
    minLength: {
        value: 3,
        message: "fullname must be mininum 3 character"
    }
}


const email = {
    required: {
        value: true,
        message: "email required"
    },
    validate: async val=>{
        if(!validateEmail(val)){
            return "invalid email address"
        }
        
       
        
    },
}



const phone = {
    valueAsNumber: true,
    required: {
        value: true,
        message: "phone  required"
    },
    validate: (val)=> {
        console.log({val})
        return (val.toString().length > 6 && val.toString().length < 11) ? true : "invalid phone number";
    }
}

const address1 = {
    required: {
        value: true,
        message: "address  required"
    }
}

const country = {
    valueAsNumber: true,
    required: {
        value: true,
        message: "country  required"
    }
}

const state = {
    valueAsNumber: true,
    required: {
        value: true,
        message: "state  required"
    }
}

const zipCode = {
    valueAsNumber: true,
    required: {
        value: true,
        message: "zip code  required"
    },
    min: {
        value: 4999,
        message: "zip code name must be mininum 5 "
    }
}



const CardHolderName = {
    required: {
        value: true,
        message: "card holder name required"
    },
    pattern: {
        value: /^[a-zA-Z ]+$/,
        message: "cardholder name should not contain special character "
    },
    minLength: {
        value: 3,
        message: "cardholder name must be mininum 3 character"
    }
}

const CardNumber = {
    valueAsNumber: true,
    required: {
        value: true,
        message: "card number required"
    }
}

const ExpiryDate = {
    valueAsNumber: true,
    required: {
        value: true,
        message: "exiry date required"
    },
   
}

const month = {
    valueAsNumber: true,
    required: {
        value: true,
        message: "month required"
    },
    min:{
        value:1,
        message: "invalid month"
    },
    max:{
        value:12,
        message: "invalid month"
    }
}

const year = {
    valueAsNumber: true,
    required: {
        value: true,
        message: "yesr required"
    },
    min:{
        value: moment().format("YY"),
        message: "invalid year"
    },
    max:{
        value: parseInt(moment().format("YY")) + 20,
        message: "invalid year"
    }
}

const CVV = {
    // valueAsNumber: true,
    required: {
        value: true,
        message: "CVV required"
    },
    minLength: {
        value:3,
        message: "CVV should be in 3 numbers"
    },
}



export default { OrganisationName, FullName, email, phone, address1, country, state, zipCode, CardHolderName, CardNumber, ExpiryDate, month, year, CVV}
