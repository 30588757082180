import React, { useEffect, useRef, useState } from 'react';
import "./Overviewchart.css";
import { AiOutlineClockCircle } from 'react-icons/ai';
import { HiOutlineChartPie } from "react-icons/hi"
import launch from '../../../src/assets/icons/arrows/expand-icon.svg';
import * as echarts from 'echarts';
import { Link } from 'react-router-dom';
import noData from "./no-data-icon.svg"

function Overviewchart(props) {
  const chartRef = useRef(null);
  const [totalCount, setTotalCount] = useState(0)

  useEffect(() => {
    let tempTotalCount = 0
    props.data?.map(item => {
      tempTotalCount = tempTotalCount + item.value
    })
    setTotalCount(tempTotalCount)
  }, [props.data])


  useEffect(() => {

    if (chartRef.current) {
      var myChart = echarts.init(chartRef.current);
      var option = {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '',
            label: {
              show: false
            },
            type: 'pie',
            radius: '93%',
            data: props.data,
          }
        ]
      };
      myChart.setOption(option);
    }


  }, [totalCount])



  return (
    
    <div className="overview">

      <div className="col-12">
        <div className="row align-items-center">
          <div className="col-1">
            {props.icon}
          </div>
          <div className="col-10 pe-0 pb-0 pt-0 ps-0">
            <h3 className='overview-headings'>{props.title}</h3>
          </div>
        </div>
        <div className="row" >
          {props.description}
          <div className="col-12" style={{ marginBottom: "25px" }}>
            {totalCount != 0 && <div className="chartdiv" ref={chartRef}></div>}
          </div>


          <div className={`${props.alignment === 'vertical' ? "col-12 op-pl-24" : "col-12"}`}>
            <div className="row">
              {totalCount !== 0 && props.data.map((segment, index) => (
                <div className={`col-${props.alignment === 'vertical' ? '4' : '12'} ${props.alignment === "vertical" ? 'op-mb-11' : ''}`}>
                  <div className="row align-items-center ops-common-button-hover">
                    <div className={`col-1 ${props.alignment === 'vertical' ? "p-0" : null}`}>
                      <div className={`indicationcolor`} style={{ backgroundColor: segment.itemStyle.color, borderRadius: props.shouldRound ? "50%" : "0%" }}></div>
                    </div>
                    <div className={`col-${props.alignment === 'vertical' ? '11' : '9'}`}>
                      <p className={props.alignment == 'vertical' ? 'perecentagetitleSmall' : 'perecentagetitle'} style={{ color: "#222127" }}>
                        {segment.name}  {props.showPercentage ? null : `- ${parseFloat((segment.value / totalCount) * 100).toFixed(2)}%`}
                      </p>
                    </div>
                    <div className={`col-${props.alignment === 'vertical' ? null : '1'} text-right`}>
                      <div className="icon1">
                        {props.launch ? (<Link to={props.link}><img src={launch} width={14} alt="Launch icon" /> </Link>) : (null)}
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            </div>
          </div>

          {(props.data?.length == 0 || totalCount == 0) && <div className='chart-no-data'>
            <img src={noData} /> <br />
            <span className='chart-no-data-text'>no data available</span>
          </div>}
        </div>
      </div>
    </div>
  );
}

Overviewchart.defaultProps = {
  icon: <HiOutlineChartPie fontSize={24} color='#6B53AE' />,
  title: 'Tagged Percentage',
  description: "",
  data: [],
  
};

export default Overviewchart;
