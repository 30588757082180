import axios from 'axios';
import { hidePageLoader, showPageLoader } from '../utils/util';
import { toast } from 'react-hot-toast';

// import { Notification } from 'reapop';

export default function PostService(API, data, customConfig = {}, lightLoad = true, loader=true, showToast=true) {   
    let axiosHeader = { ...customConfig, ...{ headers: { 
        'Accept': 'application/json'
    }} }   
 
    // const { notify }  = useNotifications()

    let response = axios.post(API,data, axiosHeader);
    if(loader){
        showPageLoader(lightLoad)
    }
    response.then((response)=>{
        hidePageLoader()
       
        if(showToast){
            if (response.data.success == false){
                if(response.data.validation == null){
                    toast.error(response.data.description,)
                }else{
                    toast.error(response.data.validation[0].Message)
                }
                return new Promise.reject("Error")
            }else{
                toast.success(response.data.description)
            }
        }

    }).catch(err => {
        hidePageLoader()
        console.log({err})
        if (err.response && (err.response.status == 401 || err.response.status == 502)){
            window.location.replace("/login");
        }
    })
    return response
}