// import {  Modal } from "react-bootstrap";
import Modal from "../Modal/Modal";
import Button from "../../Elements/Button/Button";
import { BiErrorCircle, BiPencil } from "react-icons/bi";
import Select from "../../V1/Select/Select";
import { useEffect } from "react";
import SearchControl from "components/SearchControl/SearchControl";
import SelectwithSearch from "components/V1/SelectwithSearch/Select";


const EditResource = ({show,title, logo, description, className, resourceData, baseData, mapping, resources, onResourceSearch, ...props }) => {


    let serviceEditedData = {}

    useEffect(() => {
        serviceEditedData["alternate_name"] = resourceData.name;
        serviceEditedData["env"] = resourceData.tags?.Env;
        serviceEditedData["criticality"] = resourceData?.tags?.Criticality;
        serviceEditedData["owner"] = resourceData?.tags?.Owner;
        serviceEditedData["service_name"] = resourceData?.service?.name;
    }, [resourceData])

    const onSave = () => {
        props.onSave(serviceEditedData)
    }

    return (
        <>
            <Modal show={show} style={{ width: "514px" }}>
                <Modal.Title>
                    <div className="content-padding">

                        <div className="headingDiv">
                            <div className='logoos'>
                                <BiPencil />
                            </div>
                            <div className='header'>
                                <span className="mainHeading">
                                    {title}
                                </span>
                                <span className="subHeading">
                                    {description}
                                </span>
                            </div>
                        </div>
                    </div>
                </Modal.Title>
                <Modal.Body>
                    <div>
                        <div className="Content">


                            <div className="formControls">
                                <label htmlFor="lname">Resource Name</label><span className="hint"><BiErrorCircle color="rgba(255, 153, 0, 0.40)"/></span><br />
                                <input type="text" id="lname" name="lname" disabled={mapping} defaultValue={resourceData.name} onChange={(e)=>{serviceEditedData["alternate_name"] = e.target.value}} />
                            </div>

                            <div className="formControls" style={{marginTop:'20px'}}>
                                <Select disabled={mapping} label={"Environment"} placeholder={resourceData.tags?.Env} options={baseData.envs} hint={false} onChange={(i)=>serviceEditedData["env"] = i.value} />
                            </div>
                          
                            <div className="formControls" style={{marginTop:'20px'}}>
                                <Select disabled={mapping} label={"Owner"} placeholder={resourceData?.tags?.Owner} options={baseData.users} hint={false} onChange={(i)=>serviceEditedData["owner"] = i.label}/>
                            </div>
                            <div className="formControls" style={{marginTop:'20px'}}>
                                <Select disabled={mapping} label={"Service name"} name="service_name" placeholder={resourceData?.service?.name} options={baseData.services} hint={false} onChange={(i)=>serviceEditedData["service_name"] = i.label}/>
                            </div>
                            <div className="formControls" style={{marginTop:'20px'}}>
                                <Select disabled={mapping} label={"Criticality"}  name="criticality" placeholder={resourceData?.tags?.Criticality} options={baseData.criticality} onChange={(i)=>serviceEditedData["criticality"] = i.value} />
                            </div>
                            {mapping && <>
                                <div className="formControlsx" style={{marginTop:'20px'}}>
                                    <SelectwithSearch width={505} showKey={'label'} data={baseData.resources} serverSideSearch={true} onServerSideSearch={onResourceSearch} onChange={(i)=>serviceEditedData["mapped_resource"] = i.value} />
                                </div>
                            </> }
                            
                         
                        </div>
                        <div style={{ float: "right", marginTop: "40px" }}>
                            <Button type="danger" ButtonStyle="transparent" className="mx-3" onClick={() => props.onHide()}>Cancel</Button>
                            <Button onClick={onSave} >Save</Button>
                        </div>


                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

EditResource.defaultProps = {
    mapping: false,
    onResourceSearch: ()=>{}
}

export default EditResource;