

import { useRef } from "react"
import style from "./Table.module.css"

const TablePagination = (props)=> {

    const gotoInputRef = useRef(null)

    let totalButtons = Math.floor(props.rowCount/props.rowsPerPage) + 1 ?? 0
  
    let starting = (props.currentPage - 1) * props.rowsPerPage
    let ending = props.rowCount

    let buttonStartFrom = totalButtons > 10 ? parseInt(props.currentPage) : 1;

    if (buttonStartFrom > (totalButtons - 10) && totalButtons > 10){
        buttonStartFrom = totalButtons - 9
    }


    const gotoPage = ()=>{
        let number = gotoInputRef.current.value
        if(number <= totalButtons){
            props.onChangePage(number)
        }
        
    }

    const onPageInputChange = (e)=>{
        if(isNaN(e.key) && e.keyCode != 8){
            e.preventDefault()
        }
    }

    return(
        <>  
            <div style={{padding: "15px 23px"}}>
                <div className={style.opTablePaginationInfoContainer}>
                    <div className={style.opTablePaginationInfo}> Showing {starting} to {ending}  of {totalButtons} pages</div>
                
                    <div className={style.opPaginationButtonContainer}>

                        { totalButtons > 10  && (<>
                                <div className={`${style.opPaginationGoOptionContainer}`}>
                                    <input ref={gotoInputRef} className={`${style.opPaginationGoInput}`} onKeyDown={onPageInputChange} />
                                    <button className={`${style.opPaginationGoButton} `} style={{width: "auto"}} onClick={gotoPage}>Go</button>
                                </div>
                            </>
                         )}


                           { totalButtons > 10  && (<>
                                    <button className={`${style.opPaginationButton} `}  style={{width: "auto"}} onClick={()=>props.onChangePage(1)}>First</button>
                                    <button className={`${style.opPaginationButton} `} disabled={props.currentPage == 1} style={{width: "auto"}} onClick={()=>props.onChangePage( props.currentPage - 1)}>Previous</button>
                            </>)} 
                            
                            {
                                 new Array(totalButtons > 10 ? 10 : totalButtons).fill(0).map((item,index)=>{
                                    return(
                                        <button key={index} 
                                        className={`${style.opPaginationButton}  ${(buttonStartFrom + index) == props.currentPage ? style.opPaginationButtonSelected:""}`} 
                                        onClick={()=>props.onChangePage(buttonStartFrom + index)}>{buttonStartFrom  + index}</button>)
                                    
                                })
                            } 

                           { totalButtons > 10 && (<>
                                <button className={`${style.opPaginationButton} `} disabled={props.currentPage == totalButtons} style={{width: "auto"}} onClick={()=>props.onChangePage( props.currentPage + 1)}>Next</button>
                                <button className={`${style.opPaginationButton} `}  style={{width: "auto"}} onClick={()=>props.onChangePage(totalButtons)}>Last</button>
                           </>) }
                        
                           
                       
                    </div>
                </div>
            </div>
        </>
    )
}

TablePagination.defaultProps = {
    rowCount: 0,
    rowsPerPage: 10,
    currentPage: 1,
    onChangePage: ()=>{}
}

export default TablePagination