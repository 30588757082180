// import {  Modal } from "react-bootstrap";
import { useNotifications } from "reapop";
// import './EditBillDetails.css';
import style from './EditBillDetails.module.css'
import { MdOutlineLaunch } from "react-icons/md";
import Modal from "../Modal/Modal";
import Button from "../../Elements/Button/Button";
import { BiErrorCircle, BiPencil } from "react-icons/bi";
import Select from "../../V1/Select/Select";
import { getCookie, getErrorClass } from "utils/util";
import PostService from "services/PostServices";
import { API_URL } from "config/config";
import { useState } from "react";
import { useForm } from "react-hook-form";
import BillingRules from "sections/Organisation/BillingValidator";
import countries from "sections/Organisation/countries.json"
import InputComponent from "components/form/InputComponent/InputComponent";
import TelComponent from "components/form/TelComponent/TelComponent";
import SelectComponent from "components/form/SelectComponent/SelectComponent";
import { Col, Row } from "react-bootstrap";

const EditBillDetails = ({ data ={}, show, title, logo, description, className, ...props }) => {


    const [formSubmiting, setFormSubmiting] = useState(false)
    const { register, handleSubmit, watch, formState } = useForm({
        mode: "onTouched",
    });

    const { errors, isValid, isSubmitting, touchedFields, submitCount } = formState


   
    const [states, setStates] = useState([])
    const [selectedState, setSelectedState] = useState(null)
    const [selectedCountry, setSelectedCountry] = useState(null)
    const [selectedCountryCode, setSelectedCountryCode] = useState(null)


    const onCountryChange = (e)=>{
        setSelectedCountry(e)
        let tempStates = countries.find(item=>{
            if(item.country == e){
                return item.states
            }
            return false
        })
        let stateList = tempStates?.states?.map(item=>{
            console.log(item)
            return { name:item, value:item }
        })
        setStates(stateList)
    }

    const onStateChange = (e)=>{
        console.log({state:e})
        setSelectedState(e)
    }

    const onCodeChange = (code)=>{
        setSelectedCountryCode(code)
    }

    

    let countryList = countries.map(item=>{
        return { name:item.country, value:item.country }
    })
    let stateList = countries[0].states?.map(item=>{
        return { name:item, value:item }
    })




    const defaultValues = {
        name: data?.billing_name || "",
        address1: data?.billing_primary_address || "",
        address2: data?.billing_secondary_address || "",
        phone: data?.billing_phone || "",
        email: data?.billing_email || "",
        zipcode: data?.billing_zip_code || "",
        country: data?.billing_country || "",
        state: data?.billing_state || "",
        phone_code: data?.billing_phone? data.billing_phone.split("-")[0] : ""
        
    };



    const onFormSubmit = (data) => {
        data["country"] = selectedState ?? defaultValues?.country
        data["state"] =  selectedCountry ?? defaultValues?.state
        data["phone"] = (selectedCountryCode ?? defaultValues?.phone_code) + "-" + data["phone"]
        console.log({data})
        
        props.onSave(data)

    }
    //console.log({props})
    return (
        <>
            <Modal show={show} style={{ width: "514px" }}>
                <Modal.Title>
                    <div className="">

                        <div className={style.headingDiv}>
                            <div className={style.logoos}>
                                <BiPencil />
                            </div>
                            <div className={style.header}>
                                <span className={style.mainHeading}>
                                    {title}
                                </span>
                                <span className={style.subHeading}>
                                    {description}
                                </span>
                            </div>
                        </div>
                    </div>
                </Modal.Title>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onFormSubmit)}>
                        <div>
                            <div className={style.Content}>
                                
                                <InputComponent label="Name" defaultValue={defaultValues.name} className={getErrorClass(errors, touchedFields, "name")} error={errors.fullname?.message} {...register("full_name", BillingRules.FullName)}  />
                                <div className="form-control-seperator"></div>
                                <InputComponent label="Email" defaultValue={defaultValues.email} className={getErrorClass(errors, touchedFields, "email")} error={errors.email?.message} {...register("email", BillingRules.email)} />
                                <div className="form-control-seperator"></div>
                                <TelComponent  id="phone"  label="Phone" defaultValue={defaultValues.phone} onCodeChange={onCodeChange} className={getErrorClass(errors,touchedFields, "phone")}  error={errors.phone?.message} rules={{...register("phone", BillingRules.phone)}}  />
                                <div className="form-control-seperator"></div>
                                <InputComponent label="Address 1" defaultValue={defaultValues.address1} className={getErrorClass(errors, touchedFields, "address1")} error={errors.address1?.message} {...register("address1", BillingRules.address1)} />
                                <div className="form-control-seperator"></div>
                                <InputComponent label="Address 2" defaultValue={defaultValues.address2} className={getErrorClass(errors, touchedFields, "address2")} error={errors.address2?.message} {...register("address2", {})} />
                                <div className="form-control-seperator"></div>
                                <SelectComponent label={"Country"} search={true} options={countryList}  onChange={onCountryChange} defaultValue={(selectedCountry ?? defaultValues.country )} hint=""   />
                                <div className="form-control-seperator"></div>
                                <Row>
                                    <Col>
                                        
                                        <SelectComponent label={"State"} search={true} options={stateList}  onChange={onStateChange} defaultValue={( selectedState ?? defaultValues.states )}  hint="" />
                                    </Col>
                                    <Col>
                                       <InputComponent label={"Zip Code"} defaultValue={( defaultValues.zipcode )} className={getErrorClass(errors, touchedFields, "zipcode")} error={errors.zipcode?.message} {...register("zipcode", BillingRules.zipCode)}  />
                                    </Col>
                                </Row>

                              

                               



                            </div>
                            <div style={{ float: "right", marginTop: "40px" }}>
                                <Button type="danger" ButtonStyle="transparent" className={"mx-3"} onClick={() => props.onHide()}>Cancel</Button>
                                <Button>Save</Button>
                            </div>


                        </div>   </form>
                </Modal.Body>
            </Modal>
        </>
    )
}


EditBillDetails.defaultProps = {
    title: "Edit Bill Details    ",
    description: "Azure connector gathers Azure resources, configurations, usage, and size data for streamlined management"
}

export default EditBillDetails;