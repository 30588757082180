
import TableEmptySVG from "./assets/table-empty.svg"

const TableEmpty = ()=>{

    return(
        <div style={{textAlign: "center", padding: "40px 0px"}}>
            <img src={TableEmptySVG} /><br/>
            <span style={{display: "block", marginTop: "20px"}}>no data to display</span>
        </div>
    )
}

export default TableEmpty