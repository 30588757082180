
import style from "./Checkbox.module.css"

function Checkbox({label, ...props}) {
    return (
        <div className={`${style.opcheckbox}`}>
            <input type="checkbox" {...props} />
            {label != "" && <label className={`${style.opcheckboxLabel}`}>{label}</label>} 
        </div>
    );
}

export default Checkbox