import React from 'react'
import "./TableEmptySuccess.css"
import trickImg from "../../../src/assets/icons/resolved-unreolved/sucessTick.svg"
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { Link } from 'react-router-dom'

function TableEmptySuccess({heading, subheading,buttonText, link}) {
    return (
        <>
            <div className="SuccessfullyComponentDiv">
                <div className="trickImg">
                    <img src={trickImg} />
                </div>
                <div className="title">
                    <span>{heading}</span>
                </div>
                <div className="subtitle">
                    <span>{subheading}</span>
                </div>

                <div>
                    <Link to={link} className={{textDecoration:"none"}}>
                        <button className='backButton'>
                            <span><AiOutlineArrowLeft /> </span>{buttonText}<span></span>
                        </button>
                    </Link>
                </div>
            </div>

        </>
    )
}

TableEmptySuccess.defaultProps = {
    heading: "Unresolved is changed",
    subheading: "Changes made will be reflected in few minutes.",
    buttonText: "Back to resource list",
    link: "",
}

export default TableEmptySuccess