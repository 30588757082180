import React from 'react';
import './ResolvedUnresolvedComponent.css';
import ServiceIcon from '../../../src/assets/icons/resolved-unreolved/unreolved-azure.svg';
import Expand from '../../../src/assets/icons/arrows/expand-icon.svg';
import { Link } from 'react-router-dom';

function ResolvedUnresolvedComponent(props) {
    return (
        <>
            <div className={`unresolvedresolvedDiv ${props.disabled == true ? "disabled": ""}`}>
                <div className="logo">
                    <img src={props.icon} />
                </div>

                <div className="content">
                    <div className='titlecontent'>
                        <span className='title'>{props.title}</span>
                        <span className='health'>{props.health}</span>
                        <div className="extend">
                               {props.disabled == false  && <Link to={props.link}> <img src={Expand}/></Link>} 
                        </div>
                    </div>
                    <div className='subtitle pt-2'>
                       id : {props.subtitle}
                    </div>

                    <div className="resolvedunresolved">
                        <div className='Resolved'>
                            <div>
                                <div className="circle">
                                    <span>{props.resolved}</span>
                                </div>
                            </div>
                            <div>
                                <div className="resolvedText">
                                    Resolved
                                </div>
                            </div>
                        </div>
                        <div className='Resolved' style={{marginLeft:'20px'}}>
                            <div>
                                <div className="circle">
                                    <span>{props.unresolved}</span>
                                </div>
                            </div>
                            <div>
                                <div className="resolvedText">
                                Unresolved
                                </div>
                            </div>
                        </div>
                
                    </div>
                </div>
            </div>
        </>
    );
}

ResolvedUnresolvedComponent.defaultProps = {
    disabled: false,
    icon: ServiceIcon,
    title: 'Onepane Microsoft Azure',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur',
    resolved: 0,
    unresolved: 0,
    health: 'Good',
    link: "",
};

export default ResolvedUnresolvedComponent;
