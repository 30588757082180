import React from 'react';
import './NotificationMessage.css';
import Moment  from 'react-moment';
import IconImage from '../../../assets/icons/message/newnotification.svg';

function NotificationMessage(props) {
  return (
    <>
      <div className="row" style={{ paddingTop: '16px' }}>
        <div className="col-2">
          <div className="messageicon">
            <img src={props.messageicon} width={18} alt="Message Icon" />
          </div>
        </div>
        <div className="col-10 p-0">
          <div className="message">
            <p>{props.message}</p>
          </div>
          <div className="seen">
            <p> 
              <Moment toNow>{props.seen}</Moment>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

NotificationMessage.defaultProps = {
  messageicon: IconImage,
  message: 'You have a bug that needs to be fixed.',
  seen: 'Just now',
};

export default NotificationMessage;
