import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import HorizontalTab from "components/Tab/HorizontalTab";
import DashboardBody from "pages/newlayout/DashboardBody";
import jobTimer from "assets/icons/resources/job-timer.svg"
import { RiDatabase2Line, RiBarChart2Line, RiRefreshLine, RiErrorWarningLine } from "react-icons/ri";
import Overview from "./Tab/Overview";
import Alerts from "./Tab/Alerts";
import { useNotifications } from "reapop";
import Changes from "./Tab/Changes";
import Card from "components/Card/Card";
import "./ResourceDetails.css";
import { useNavigate, useOutlet, useParams } from "react-router-dom";
import { BiCoinStack } from "react-icons/bi";
import EditResource from "components/modals/EditResource/EditResource";
import Button from "components/Elements/Button/Button";
import { useEffect, useState } from "react";
import GetService from "services/GetServices";
import { API_URL } from "config/config";
import { getResourceBaseData } from "../ResourceUtil";
import PostService from "services/PostServices";
import { dashboardFade, getDateInterval, getUserListForOption } from "utils/util";
import Calendar from "components/Calender/Calender";
import IconTag from "components/IconTag/IconTag";
import TooltipView from "components/Tooltip/Tooltip";
import SimpleToolTip from "components/Tooltip/SimpleToolTip";
import { IoMdClose } from "react-icons/io";
import AppSettingStore from "stores/NotificationSystem/AppSettingStore";
import RBACWrapper from "components/RBACWrapper/RBACWrapper";
const ResourceDetails = () => {

    const [showEditModal, setShowEditModal] = useState(false)
    const [resourceDetails, setResourceDetails] = useState({})
    const [resourceEditBaseData, setResourceEditBaseData] = useState({users:"",envs:[],services:[],criticality:[],})
    const [editingPlatform, setEditingPlatform] = useState(0)
    const [enabledMapping, setEnabledMapping] = useState(false)
    const [dateTimeRange, setDateTimeRange] = useState(getDateInterval())
    const [jobStatus, setJobStatus] = useState({running:{count: 0, jobDetails:[]}, success: {count: 0, jobDetails:[]}, failed:{count: 0, jobDetails:[]}, total: 0})
    const [jobModal, setJobModal] = useState({show: false, title: "", count: 0, jobs: []})
    const { resourcesId } = useParams()


    const outlet = useOutlet()
    const navigate = useNavigate()
    const appSettings = AppSettingStore()
    

    const { notify, dismissNotification } = useNotifications()


    const openResourceEditModal = ()=>{
        navigate(`/resources/${resourcesId}/details/edit`)
    }
    const closeResourceEditModal = ()=>{
        navigate(`/resources/${resourcesId}/details`)
    }

    const showNotification = (text, status="success")=>{
        notify( text, status,{
            dismissAfter: 1500
        }) 

    }


    const loadBaseData = async ()=>{
        let filterData = await getResourceBaseData()
        let users = await getUserListForOption()
        setResourceEditBaseData({
                envs: filterData.envs,
                services: filterData.services,
                criticality: filterData.criticality,
                users: users
        })
    }

    const getResourceDetails = ()=>{
        GetService(API_URL + `/api/v1/resource/${resourcesId}/get`).then(response=>{
            let data = response.data.data

            let runningJobs = {count: 0, jobDetails:[]};
            let failedJobs = {count: 0, jobDetails:[]};
            let successJobs ={count: 0, jobDetails:[]};
            let totalJobs = 0;
            for (const job of data.jobs) {
                totalJobs = totalJobs + 1
                switch(job.job_status){
                    case 0: runningJobs.count = runningJobs.count + 1; 
                            runningJobs.jobDetails.push({jobId: job.job_json?.info?.batch_id, message: job.job_message})
                            break;
                    case 1: successJobs.count = successJobs.count + 1; 
                            successJobs.jobDetails.push({jobId: job.job_json?.info?.batch_id, message: job.job_message})
                            break;
                    case 2: failedJobs.count = failedJobs.count + 1;
                            failedJobs.jobDetails.push({jobId: job.job_json?.info?.batch_id, message: job.job_message})
                            break;
                }
            }
            setJobStatus({running: runningJobs, success: successJobs, failed:failedJobs, total: totalJobs})
            console.log({l: {running: runningJobs, success: successJobs, failed:failedJobs, total: totalJobs}})
            setResourceDetails(data.resource)
        })
        loadBaseData()
    }


    const updateResource = (data)=>{
        console.log({data})
        if(enabledMapping == false){
            PostService(API_URL + `/api/v1/resource/${resourcesId}/modify`,data).then(response=>{
                setShowEditModal(false)
                showNotification("Resource edited. it will take some time to reflect")
            })
        }else{
            PostService(API_URL + `/api/v1/resource/mapping/single/fix?resource_id=${resourcesId}&platform_id=${editingPlatform}&raw_resource_id=${data["mapped_resource"]}`,data).then(response=>{
                setShowEditModal(false)
                showNotification("Plaftform Mapping Resource Update . it will take some time to reflect")
            })
        }
    }

    const onSearchResource = (platform_id, search = "") => {
        let searchJSON = JSON.stringify([{key:"name", value:search}])
        GetService(API_URL + `/api/v1/resource/mapping/single/init?platform_id=${platform_id}&search=${searchJSON}`).then(response=>{
            setEnabledMapping(true)
            setShowEditModal(true)
            let data = response.data?.data?.items
            let resources = []
            data?.map(item=>{
                resources.push({
                    label: item.name,
                    value: item.ci_id
                })
            })
            setResourceEditBaseData({
                resources: resources
            })
        })
    }
  
    const onPlatformMappingClick = (platform_id)=>{
        setEditingPlatform(platform_id)
        onSearchResource(platform_id)
    }

    const OnResourceCrossLaunchClick = (platform_type, identifier)=>{
        let postForm = new FormData()
        postForm.append("resource_id", identifier)
        PostService(API_URL + `/api/v1/platform/${platform_type}/cross-launch`, postForm).then(response=>{
            if(response.data?.data?.url){
                window.open(response.data?.data?.url,'_blank');
            }else{
                showNotification("Resource URL not found", "error")
            }
        })

       
    }

    const onDateChange = (fromDate, toDate, fromTimestamp, toTimestamp)=>{
        appSettings.setCalender(fromDate, toDate, fromTimestamp, toTimestamp)
    }

    const showJobModal = (title, className, job)=>{
        setJobModal({
            show:true,
            title: title, 
            countClass: className, 
            count:job.count, 
            jobs:job.jobDetails}) ;
        dashboardFade(true)
    }

    const hideJobModal = ()=>{
        setJobModal({jobModal,...{show:false}})
        dashboardFade(false)
    }

    useEffect(()=>{
        getResourceDetails()
    }, [])

    let tabs = [
        {
            title: "Overview",
            children: <Overview resourceData={resourceDetails} onEditPlatform={onPlatformMappingClick} OnResourceCrossLaunchClick={OnResourceCrossLaunchClick}  />

        },
        {
            title: "Run",
            children: "",
            disable: true
        },
        {
            title: "Audit Log",
            children: "",
            disable: true
        },
        {
            title: "Scorecards",
            children: "",
            disable: true
        }
    ]



    const CardTitle = () => {
        return (
            <>
                <div className="titles">
                    <div className="resourceDetailsPageTitle">
                        <div>
                            <span><BiCoinStack size={26} color="#6B53AE" /></span><span style={{ paddingLeft: '8px', paddingTop: '16px', paddingRight: "10px" }}>Resource Details</span>
                            
                           
                        </div>
                        <div>
                            <TooltipView body={<>
                                <div style={{width: "180px", padding: "0px", textAlign: "left", color: "#4D4C52", fontFamily: "Lato", fontSize: "14px", fontStyle: "normal", fontWeight: "600", lineHeight: "16px"}} >
                                    <div className="resource-job-count-container">
                                        <div onClick={()=>showJobModal("Job Running", "job-running", jobStatus.running)}><span className="resource-job-count job-running">{jobStatus.running.count}</span>Running</div>
                                        <div onClick={()=>showJobModal("Job Completed", "job-success", jobStatus.success)} ><span className="resource-job-count job-success">{jobStatus.success.count}</span>Completed</div>
                                        <div onClick={()=>showJobModal("Job Failed", "job-failed", jobStatus.failed)}><span className="resource-job-count job-failed">{jobStatus.failed.count}</span>Failed</div>
                                    </div>
                                    
                                </div>
                            </>}>
                              {jobStatus.total > 0 && <>
                                    <IconTag 
                                        iconBgcolor={"#79787D"} 
                                        iconStyle={{width: "28px", height: "28px"}}
                                        icon={<img src={jobTimer} />} 
                                        text={`${jobStatus.failed.count}/${jobStatus.total} jobs failed`} 
                                        style={{ backgroundColor: "#D0CFD4", display: "inline-flex", marginRight: "35px"}} 
                                    />
                                </> }
                            </TooltipView>
                            <RBACWrapper action={"RESOURECE_EDIT"}>
                                <SimpleToolTip tooltip={"Unable to modify the resource due to ongoing tasks."} disabled={jobStatus.running.count == 0}>
                                    <Button disabled={jobStatus.running.count != 0} onClick={openResourceEditModal} ButtonStyle="transparent">Modify</Button> 
                                </SimpleToolTip>
                            </RBACWrapper>
                           
                        </div>
                    </div>
                </div>

            </>
        )
    }

    const CompaintStatus = ({complaintsData})=>{
        
        let complaints = complaintsData["policy_config_item_compliances"] && complaintsData["policy_config_item_compliances"]?.filter(item=>item.compliance_status == false)
                    
        return(
            <>  
                             
                { complaints?.length > 0 && <>
                    <div className="resource-warning">
                        <p className="resource-warning-title">
                            <RiErrorWarningLine color={"#FDBC3E"} size={24} style={{marginRight: "6px", marginTop: "-3px"}} />
                            This resource is non-compliant due to following policies
                        </p>
                    <div style={{paddingLeft: "33px"}}>
                    { complaints?.map(item=>{
                        let message = item.compliance_json?.failed_reason ? item.compliance_json?.failed_reason : item.compliance_json?.complianceState
                        let policyName = item.plaform?.plaform_policy?.policy_mapping_name
                        return(
                            <div >
                                <span className="resource-warning-bullet">•</span>
                                <span className="resource-warning-text  resource-warning-policy-name">{policyName} </span> - <span className="resource-warning-text ">{message}</span>
                                
                            </div>
                        )
                        })}
                    </div>
                    </div>
                </>}
                
            </>
        )
    }

    return (
        <>
            <Breadcrumbs section={[{ name: "Resources", path: "/resources" }, "Resources Details"]} />
            <DashboardBody>
                <div className='overViewCalender'>
                   <div>
                        <Calendar onClick={onDateChange} fromDate={appSettings.calender.fromDate} toDate={appSettings.calender.toDate} />
                   </div>
                </div>
                <div>

                    <Card
                        title={<CardTitle />}
                        type={"full"}
                    >   
                        <CompaintStatus complaintsData={resourceDetails} />
                      
                        
                        <div>
                            <HorizontalTab tabs={tabs} tabStyle="line" tabClass={"resource-details-tab"} panelClass={"resource-details-tab-panel"} />
                        </div>
                        <div className="card-divider"></div>
                        {/* <div className="d-flex align-items-center" style={{margin: "20px 20px"}}>
                            <div className="flex-grow-1">
                                
                            </div>
                            <div>
                                <Button type={"danger"} ButtonStyle="transparent">Cancel</Button>
                                <Button>Save</Button>
                            </div>
                        </div> */}
                    </Card>




                </div>


                <div style={{ marginTop: "20px" }}>

                    <Card
                        title={<span> <RiDatabase2Line color="#6B53AE" size={18} style={{ marginRight: "8px" }} /> Latest Alerts & Changes </span>}
                        type={"full"}
                    >

                        <HorizontalTab
                            tabClass={"resource-alter-details-tab"} panelClass={"resource-details-tab-panel"}
                            tabs={[
                                {
                                    title: <span><RiBarChart2Line color="#6B53AE" size={20} style={{ marginTop: "-4px", marginRight: "8px" }} />Alerts </span>,
                                    children: <Alerts startTime={appSettings.calender.fromTimestamp} endTime={appSettings.calender.toTimestamp} />

                                },
                                {
                                    title: <span><RiRefreshLine color="#6B53AE" size={20} style={{ marginTop: "-4px", marginRight: "8px" }} />Changes </span>,
                                    children: <Changes startTime={appSettings.calender.fromTimestamp} endTime={appSettings.calender.toTimestamp} />,
                                },
                            ]}

                            tabStyle="line" />
                       {outlet &&  <EditResource 
                            title={"Edit"} 
                            description={"Modifying these parameters will result in adjustments or establishment of Tags within your cloud tenant"} 
                            show={true} 
                            onHide={closeResourceEditModal}  
                            onSave={updateResource} 
                            baseData={resourceEditBaseData}
                            resourceData={resourceDetails}
                            mapping={enabledMapping}
                            onResourceSearch={(keyword)=>{onSearchResource(editingPlatform, keyword)}}
                        /> }
                            
                            
                    </Card>

                </div>

               {jobModal.show &&  <div className="resource-job-details-modal"> 
                        <div className="resource-job-details-modal-title">
                            <p>
                                {jobModal.title} 
                                <span className={`resource-job-count ${jobModal.countClass}`}>{jobModal.count}</span>
                            </p>
                            <IoMdClose size={20} style={{float: "right", cursor: "pointer"}} onClick={hideJobModal} />
                        </div>
                        <div className="resource-job-details-modal-content">
                            {jobModal?.jobs.map((item, index)=>{
                                return(
                                    <div key={index} className="resource-job-details-message">
                                        {item.jobId} - {item.message}
                                    </div>
                                )
                            })}
                        </div>
                </div>
            }   
            </DashboardBody>
        </>
    )
}

export default ResourceDetails