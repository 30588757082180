import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import HorizontalTab from "components/Tab/HorizontalTab";
import DashboardBody from "pages/newlayout/DashboardBody";
import jobTimer from "assets/icons/resources/job-timer.svg"
import { useNavigate } from "react-router-dom";
import { RiDatabase2Line, RiBarChart2Line, RiRefreshLine } from "react-icons/ri";
import Overview from "./Tab/Overview";
import serviceNameIcon from "assets/icons/services/dummy-service.svg";

import "./ServiceDetails.css";
import Table from "components/Table/Table";
import Alerts from "./Tab/Alerts";
import Changes from "./Tab/Changes";
import { Link, useParams } from "react-router-dom";
import pencilIcon from "assets/icons/services/pencil.svg"
import catalogueIcon from "assets/icons/services/catalogue.svg"
import serviceMap from "assets/icons/services/service-map.svg"
import Avatar from "components/Avatar/Avatar";
import launchIcon from "assets/icons/util/launch.svg";
import { useEffect, useState } from "react";
import Distribution from "./Tab/Distribution";
import GetService from "services/GetServices";
import { API_URL } from "config/config";
import SimpleToolTip from "components/Tooltip/SimpleToolTip";
import { getDateInterval, getENVStatus, getRandomColor, getServiceStatus } from "utils/util";
import { getOnepaneTypeCIName } from "utils/OnepaneType";
import { getResourceBaseData, loadResources, parseDataForFilter } from "sections/Resource/ResourceUtil";
import Tag from "components/Elements/Tag/Tag";
import Calendar from "components/Calender/Calender";
import Cost from "./Tab/Cost";
import { CHART_COLORS } from "config/constants";
import IconTag from "components/IconTag/IconTag";
import AppSettingStore from "stores/NotificationSystem/AppSettingStore";
import RBACWrapper from "components/RBACWrapper/RBACWrapper";

const ServiceDetails = () => {

    const {serviceId } = useParams()
    const navigate = useNavigate();

    
    const [serviceDetails, setServiceDetails] = useState({})
    const [serviceResources, setServiceResources] = useState([])
    const [serviceResourceTableInfo, setServiceResourceTableInfo] = useState({totalRows: 0 , currentPage: 1, loading: true, search: ""})
    const [resourceFilterData, setResourceFilterData] = useState([]) 
    const [dateTimeRange, setDateTimeRange] = useState(getDateInterval())

    const [incidentChart, setincidentChart] = useState([])
    const [resourceChart, setresourceChart] = useState([])
    const [eventChart, seteventChart] = useState([])
    const [allowEdit, setAllowEdit] = useState(false)
    const [jobStatus, setJobStatus] = useState({running:0, success: 0, failed:0, total: 0})

    const appSettings = AppSettingStore()

    let timeout;

    const gotoResource = (id) => {
        navigate("/resources/"+id+"/details")
    }


    const parseFilterData = async ()=>{
        let filterData = await getResourceBaseData()
        setResourceFilterData(parseDataForFilter(filterData))
    }

    const getResources = (page = 1, search = "", sort=[], filter={})=>{


        loadResources(page, search, sort, filter, API_URL + `/api/v1/service/${serviceId}/resources`).then(response=>{
            let data = response.data.data
            setServiceResources(data.items)
            setServiceResourceTableInfo({...serviceResourceTableInfo, ...{loading: false, search: search, filter: filter, sort:sort, totalRows: data.total_count, currentPage:page }})

        })
        parseFilterData()

    }

    const getDistribution = async () => {
        try {
            
          const response = await GetService(API_URL + `/api/v1/service/${serviceId}/distribution`);
          const data = response.data.data;
          
          const tempIncidentData = data.alert_distribution_on_env.map((item) => ({
            value: item.value,
            name: item.key,
            itemStyle: {
              color: getRandomColor(CHART_COLORS[0]),
            },
            link: '/resources',
          }));

          const tempEventData = data.resource_distribution_on_env.map((item) => ({
            value: item.value,
            name: item.key,
            itemStyle: {
              color: getRandomColor(CHART_COLORS[1]),
            },
            link: '/resources',
          }));

          const tempResourceData = data.resource_distribution_on_env.map((item) => ({
            value: item.value,
            name: item.key,
            itemStyle: {
              color: getRandomColor(CHART_COLORS[2]),
            },
            link: '/resources',
          }));
    
          setincidentChart(tempIncidentData);
          setresourceChart(tempResourceData);
          seteventChart(tempEventData)

        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };

  

    const getServiceDetails = async ()=>{
       
        GetService(API_URL + `/api/v1/service/${serviceId}/get`).then(response=>{
            let data = response.data.data
           
            let runningJobs = 0
            let totalJobs = 0
            let successJobs = 0
            let failedJobs = 0
            for (const job of data.jobs) { 
                runningJobs =  runningJobs + job.running
                successJobs =  successJobs + job.success
                failedJobs =  failedJobs + job.failed
                totalJobs =  totalJobs + job.total_count
            }
            setJobStatus({running:runningJobs, success:successJobs, failed:failedJobs, total: totalJobs})
            setAllowEdit(runningJobs == 0)
            setServiceDetails(data.service)
            getResources()
        })
    }

    const onResourcePageChage = (page)=>{
        setServiceResourceTableInfo({...serviceResourceTableInfo, ...{loading: true, currentPage: page}})
        getResources(page, serviceResourceTableInfo.search, serviceResourceTableInfo.sort, serviceResourceTableInfo.filter)
    }


    const onResourceSearch = (keyword)=>{
        setServiceResourceTableInfo({...serviceResourceTableInfo, ...{search: keyword}})
        getResources(1, keyword, serviceResourceTableInfo.sort, serviceResourceTableInfo.filter)
    }

    const onResourceSortTable = (selectedColumn, sortDirection, sortedRows)=>{
        getResources(1, serviceResourceTableInfo.search, [{key: selectedColumn.sortField, order: sortDirection}], serviceResourceTableInfo.filter)
        setServiceResourceTableInfo({...serviceResourceTableInfo, ...{sort: [{key: selectedColumn.sortField, order: sortDirection}]}})
    }

    const onrRefreshResource = ()=>{
        getResources()
    }

    const onResourceFilter = (data)=>{
        setServiceResourceTableInfo({...serviceResourceTableInfo, ...{filter: data.value}})
        getResources(1,serviceResourceTableInfo.search, serviceResourceTableInfo.sort, data)
    }


   

    const onDateChange = (fromDate, toDate, fromTimestamp, toTimestamp)=>{
        appSettings.setCalender(fromDate, toDate, fromTimestamp, toTimestamp)
    }


    const serviceTitleInfo = <>
    
        {getServiceStatus(serviceDetails?.active)}
                               
        <div className="service-overall-count-container">
            <div title={`${serviceDetails?.changes} changes`}>
                
                <IconTag 
                    iconBgcolor={"#3EDBFD"} 
                    iconStyle={{width: "28px", height: "28px"}}
                    icon={<RiRefreshLine color="#FCFCFD" size={18} />} 
                    text={`${serviceDetails?.changes} changes`} 
                    style={{ backgroundColor: "#E8F7FF", fontWeight: '700' }} 
                />
            </div>
            <div title={`${serviceDetails?.active} active issues`} >
                <IconTag 
                    iconBgcolor={"#FDBC3E"} 
                    iconStyle={{width: "28px", height: "28px"}}
                    icon={<RiBarChart2Line color="#FCFCFD" size={18} />} 
                    text={`${serviceDetails?.active} alerts`} 
                    style={{ backgroundColor: "#FBF4EC", fontWeight: '700' }} 
                />
            </div>
            <div title={`${serviceDetails?.active} resources`} >
                <IconTag 
                    iconBgcolor={"#4E79E5"} 
                    iconStyle={{width: "28px", height: "28px"}}
                    icon={<RiDatabase2Line color="#FCFCFD" size={18} />} 
                    text={`${serviceDetails?.ci_count} resources`} 
                    style={{ backgroundColor: "#EAEDFC", fontWeight: '700' }} 
                />
            </div>
        </div>
    
    </>

    const serviceTitleMenu = <div className="d-flex">
        <div>
           
               
                {jobStatus.total > 0 && <>
                    <IconTag 
                        iconBgcolor={"#DA478D"} 
                        iconStyle={{width: "28px", height: "28px"}}
                        icon={<img src={jobTimer} />} 
                        text={`${jobStatus.running}/${jobStatus.total} jobs pending`} 
                        style={{ backgroundColor: "#F5D8E7", fontWeight: '700' }} 
                    />
                </> }
        </div>
        <div className="service-header-menu ops-common-button-hover">
                 <RBACWrapper action={"SERVICE_EDIT"}>
                    { allowEdit == true &&  <Link to={ allowEdit == true ? `/services/${serviceDetails?.service_id}/fix` : ""} style={{ textDecoration: "none", color: "#212529" }}>
                            <img src={pencilIcon} className="service-side-menu-icon" /> <span className="service-side-menu">Modify</span>
                    </Link> }
                    { allowEdit == false && <SimpleToolTip tooltip={"Unable to modify the services due to ongoing tasks."} >
                            <img src={pencilIcon} className="service-side-menu-icon" /> <span className="service-side-menu">Modify</span>
                    </SimpleToolTip>}
                 </RBACWrapper>

        </div>
        <div className="service-header-menu" aria-disabled="true">
            <img src={catalogueIcon} className="service-side-menu-icon" /> <span className="service-side-menu">Catalogue</span>
        </div>
        <div className="service-header-menu" aria-disabled="true">
            <img src={serviceMap} className="service-side-menu-icon" /> <span className="service-side-menu">Service Map</span>
        </div>
                    
    </div>


    useEffect(()=>{
        getServiceDetails()
        getDistribution()
    },[])
   


    let tabs = [
        {
            title: "Overview",
            children: <Overview  titleInfo={serviceTitleInfo} serviceTitleMenu={serviceTitleMenu} serviceData={serviceDetails} resourceChart={resourceChart} />

        },
        {
            title: "Cost",
            children: <Cost   titleInfo={serviceTitleInfo} serviceTitleMenu={serviceTitleMenu} serviceData={serviceDetails} />
        },
        {
            title: "Distribution",
            children: <Distribution titleInfo={serviceTitleInfo} serviceTitleMenu={serviceTitleMenu} incidentChart={incidentChart} eventChart={eventChart}/>
        },
        {
            title: "Application",
            children: "",
            disable: true
        }
    ]

    const resourceColumns = [
        {
            name: 'RESOURCE NAME',
            selector: row => {
                return ( 
                    <>
                        <span onClick={()=>{gotoResource(row.ci_id)}} className="table-span ops-common-hover span-ellipsis "><img src={serviceNameIcon} style={{marginRight: "4px", marginTop: "-2px"}}/>{row.name}</span>
                    </>
                )
            },
            width: "380px",
            sortable: true,
            sortField: "name",
        },
        {
            name: 'TENANT',
            selector: row => (
                <SimpleToolTip tooltip={row?.plaform?.name}>
                    <span>
                        <img src={`/images/connector-images/${row.plaform.platform_type.icon}`} style={{width:"30px", height:"30px"}} className="br-img-rounded"/>
                    </span>
                </SimpleToolTip>
            ),
            width: "100px",
            center: true,
            width:"100px"
        },
        {
            name: 'ENV',
            selector: row =>( <>{ getENVStatus(row.tags?.Env)} </>),
            width: "100px",
            sortField: "tags->>'Env'",
        },
        
         {
            name: 'TYPE',
            selector: row => <span className="table-span">{getOnepaneTypeCIName(row.onepane_type)}</span>,
            sortable: true,
            width: "250px",
            sortField: 'onepane_type'
        },

        {
            name: 'COST',
            selector: row => <>{row.pricing_details && row.pricing_details?.[0]?.total_cost ?  <><span style={{color: "#2DA077",fontWeight: "700"}}>$</span><span style={{color: "#4D4C52"}}> {parseFloat(row.pricing_details?.[0].total_cost).toFixed(2)} </span></>: <span className="no-value-span">Not Estimated</span>   }</>,
            // width: "200px",
            sortable: true,
            sortField: "original_type",
        },

        {
            name: 'COMPLIANCE',
            selector: row => {
                return(
                    <>
                        { row.status == "compliant" && <Tag type={"success"}>Compliant</Tag> }
                        { row.status == "non_compliant" &&  <Tag type={"danger"}>Non Compliant</Tag> }
                        { row.status == "undetected" && <Tag>Undetected</Tag> } 
                    </>
                )
            },
            sortable: true,
            center: true,
            width: "160px",
        },

        {
            name: 'OWNER',
            selector: row =>   row.tags?.Owner ? <Avatar username={row.tags?.Owner} imageUrl="" customText="Not assigned" /> : <span className="no-value-span">Not assigned</span> ,
            sortable: true,
            width: "200px",
            sortField: "tags->>'Owner'",
        },

        {
            name: 'ACTION',
            selector: row => <Link to={`/resources/${row.id}/details`}>
                                <div className="ops-common-button-hover px-1">
                                    <img src={launchIcon}/>
                                </div>
                            </Link>,
            sortable: true,
            width: "120px",
            center: true,
            width:"120px"
        },
    ]

    



    return(
        <>
            <Breadcrumbs section={[{"name":"Services", "path": "/services"},{"name": "Services Details","path": ""}]} />
            <DashboardBody>

                <div className='overViewCalender'>
                   <div>
                        <Calendar onClick={onDateChange} fromDate={appSettings.calender.fromDate} toDate={appSettings.calender.toDate} />
                        
                   </div>
                </div>

                <div>
                    <HorizontalTab tabs={tabs} tabStyle="bubble" />
                </div>


                <div style={{marginTop: "20px"}}>

                    <HorizontalTab  
                        tabs={[
                            {
                                title: <span><RiBarChart2Line color="#6B53AE" size={20} style={{marginTop: "-4px", marginRight:"8px"}} />Latest Alerts </span>,
                                children: <Alerts startTime={appSettings.calender.fromTimestamp} endTime={appSettings.calender.toTimestamp} />
                    
                            },
                            {
                                title: <span><RiRefreshLine color="#6B53AE" size={20} style={{marginTop: "-4px", marginRight:"8px"}} />Latest Changes </span>,
                                children: <Changes startTime={appSettings.calender.fromTimestamp} endTime={appSettings.calender.toTimestamp} />,
                            },
                        ]} 
                    
                        tabStyle="line" />
                </div>

                <div style={{marginTop: "20px"}}>
                    <Table
                        title={ <span style={{ color:"#6B53AE"}}> <RiDatabase2Line size={18} style={{marginRight: "8px"}} /> Resource List </span>}
                        columns={resourceColumns}
                        data={serviceResources}
                        loading={serviceResourceTableInfo.loading}  
                        pagination={true}
                        totalRows={serviceResourceTableInfo.totalRows}
                        onPageChage={onResourcePageChage}
                        onSearch={onResourceSearch}
                        onRefresh={onrRefreshResource}
                        dataTableProps={{sortServer: true, onSort: onResourceSortTable}}
                        filterData={resourceFilterData}
                        onFilterApply={onResourceFilter}
                    />
                    
                </div>
                
                    

            </DashboardBody>
        </>
    )
}

export default ServiceDetails