import serviceIcon from "assets/icons/services/service-overview.svg"
import pencilIcon from "assets/icons/services/pencil.svg"
import catalogueIcon from "assets/icons/services/catalogue.svg"
import serviceMap from "assets/icons/services/service-map.svg"
import { Link, useParams } from "react-router-dom"
import { Row, Col } from "react-bootstrap"
import Overviewchart from "components/OverviewChart/Overviewchart"
import GetService from "services/GetServices"
import { useEffect, useState } from "react"
import { API_URL } from "config/config"

const Distribution = ({titleInfo, serviceTitleMenu, incidentChart, eventChart }) => {


    return (
        <>
            <div className="service-overview">
                <div className="service-title-container">
                    <div style={{ flexGrow: "1" }}>
                        <div className="service-overview-title">
                            <div>
                            <img src={serviceIcon} style={{ marginTop: "-4px" }} /> <span className="service-title">Distribution Details</span>
                            </div>
                            <div className="service-overview-title-more-info">
                               {titleInfo}
                            </div>
                        </div>
                    </div>
                    <div>
                        {serviceTitleMenu}
                    </div>
                </div>
                <div>
                    <div className="service-details">
                        <Row>
                            <Col md={4}>
                                <Overviewchart title="Incidents per Environment" data={incidentChart} launch={true} />
                            </Col>
                            <Col md={4}>
                                <Overviewchart title="Changes per Environment" data={eventChart} launch={true}/>
                            </Col>
                            
                        </Row>
                    </div>
                </div>
            </div>

        </>
    )

}

export default Distribution