import { Row,Col } from 'react-bootstrap'
import TooltipView from 'components/Tooltip/Tooltip';
import {MdOutlineLaunch} from 'react-icons/md'
import Moment from 'react-moment';
import SimpleToolTip from 'components/Tooltip/SimpleToolTip';


let SERVICE_RESOURCES_OPTIONS = {
    tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    series: [
        {
            name: 'Resources per Service',
            type: 'pie',
            radius: ['58%', '93%'],
            avoidLabelOverlap: true,
            label: {
                show: false,
            },
            emphasis: {
                label: {
                    show: false,
                },
            },
            data: []
        },
    ],
};


const TAGGING_POLICY_CHART = [
    {
        value: 88,
        name: "Tagged",
        itemStyle: {
            color: '#3FC796'
        },
        link: "/reports"
    },
    {
        value: 12,
        name: "Untagged",
        itemStyle: {
            color: '#457CF6'
        },
        link: "/reports"
    },
];
const NAMING_POLICY_CHART = [
    {
        value: 88,
        name: "Compliant",
        itemStyle: {
            color: '#3FC796'
        },
        link: "/reports"
    },
    {
        value: 12,
        name: "Non Compliant",
        itemStyle: {
            color: '#457CF6'
        },
        link: "/reports"
    },

];




const INCIDENT_TABLE_COLUMN = [
    {
        name: 'TITLE',
        selector: (row) => (
            <TooltipView body={
                <div className='incidentPopover'>
                    <div className='incidentTitle'>{row.incident_title}</div>
                    <div className='incidentLauchIcon'><a href={row.incident_link} style={{textDecoration: "none", color: "#222127"}} target='#' ><MdOutlineLaunch /></a> </div>
                </div>
               
            } position={"top"} style={{fontSize: "13px" , padding: "5px 8px" }}>

                 <span className='ops-common-hover span-ellipsis' style={{width: "250px"}}>{row.incident_title}</span>
            </TooltipView>
               
        ),
    },
    {
        name: 'RESOURCE',
        selector: 'resource',
        selector: (row) => (
                <SimpleToolTip tooltip={row.config_item?.name}>
                    <a href={`resources/${row.config_item?.ci_id}/details`}  className='link-decoration-none'>
                        <span className='table-span ops-common-hover'>{row.config_item?.name}</span>
                    </a>
                </SimpleToolTip>
        ),
        width: '170px',
    },
    {
        name: 'PROVIDER',
        selector: row => {
            return (
                <SimpleToolTip tooltip={row.platform?.name}>
                    <img className='br-img-rounded' src={`/images/connector-images/${row?.platform?.platform_type?.icon}`} style={{width: "30px", height: "30px"}} />
                </SimpleToolTip>
            )
        },
        center: true,
        width: '130px',
    },
    {
        name: 'TYPE',
        selector: 'type',
        sortable: true,
        selector: (row) => (
            <span style={{ fontFamily: 'Lato', color: '#222127', fontSize: '14px', fontStyle: 'normal', fontWeight: '600' }}>
                {row.config_item.original_type}
            </span>
        ),
        width: '120px',
    },
    {
        name: 'STATUS',
        selector: 'status',
        selector: (row) => (
            <div>
                {row.platform_status == 1 ? (
                    <span className="badge-closed">Closed</span>
                ) : (
                    <span className="badge-open">Open</span>
                )}
            </div>
        ),
        width: '100px',
    },
    {
        name: 'TIME',
        selector: 'time',
        width: '170px',
        selector: (row) => (
            <Moment format="YYYY-MM-DD HH:MM:SS">
                {row.open_time}
            </Moment>
        )
    },
];



const CHANGE_TABLE_COLUMN = [
    {
        name: 'TITLE',
        selector: row=> <SimpleToolTip tooltip={ row.message?.length > 170 ? row.message?.slice(0,170) + "..." : row.message } ><span className='span-ellipsis ' style={{width: "400px"}}>{row.message}</span></SimpleToolTip> ,
       
    },
    {
        name: 'RESOURCE',
        selector: 'resource',
        width: "150px",
        selector: (row) => (
                <SimpleToolTip tooltip={row.config_item.name}>
                    <a href={`resources/${row.config_item?.ci_id}/details`} className='link-decoration-none'>
                        <span style={{ color: '#222127', fontSize: '14px', fontStyle: 'normal', fontWeight: '600' }} className='ops-common-hover'>
                            {row.config_item.name}
                        </span>
                    </a>
                </SimpleToolTip>
        ),
    },
    {
        name: 'PROVIDER',
        selector: row => {
            return (
                <SimpleToolTip tooltip={row.platform?.name}>
                    <img className='br-img-rounded' src={`/images/connector-images/${row.platform?.platform_type?.icon}`} style={{width: "30px", height: "30px"}} />
                </SimpleToolTip>
            )
        },
        width: "150px",
        center: true,
    },
    {
        name: 'TIME',
        selector: (row) => (
            <Moment format="YYYY-MM-DD HH:MM:SS">
                {row.timestamp}
            </Moment>
        ),
        width: "200px",
    },
];


export { SERVICE_RESOURCES_OPTIONS, INCIDENT_TABLE_COLUMN , CHANGE_TABLE_COLUMN, NAMING_POLICY_CHART, TAGGING_POLICY_CHART}