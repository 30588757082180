import { Col, Row } from 'react-bootstrap';
import { BsArrowDownUp } from 'react-icons/bs';
import Card from "../../../../components/Card/Card";
import KeyValueLabel, { Value } from "../../../../components/KeyValueLabel/KeyValueLabel";
import masterCardIcon from "../../../../assets/icons/credit-card/masterCard.svg";
import { RiBankCardLine, RiMapPinUserLine, RiPencilLine } from 'react-icons/ri';
import { useEffect } from 'react';
import { API_URL } from 'config/config';
import { useState } from 'react';
import EditCardDetails from 'components/modals/EditCardDetails/EditCardDetails';
import PostService from 'services/PostServices';
import { useNotifications } from 'reapop';
import EditBillDetails from 'components/modals/EditBillDetails/EditBillDetails';
import Moment from 'react-moment';

const Billing = ({billingDetails = {}, addressDetails = {}}) => {

       
        const [showEditModal, setShowEditModal] = useState(false)
        const [showAddressEditModal, setShowAddressEditModal] = useState(false)




        const { notify, dismissNotification } = useNotifications()

        const showNotification = (text, status = "success") => {
                notify(text, status, {
                        dismissAfter: 1500
                })

        }

        // const getBillingDetails = () => {
        //         GetService(API_URL + `/api/v1/base/active/organisation`).then(response => {
        //                 let data = response?.data?.data?.organisation?.billing?.billing_card[0]
        //                 let addressdata = response?.data?.data?.organisation?.billing?.billing_address[0]
        //                 setBillingData(data)
        //                 setAddressData(addressdata)
        //         })
        // }



        const updateCardDetails = (data) => {
                let billJsonData = {
                        card_expiry:  data.month + "/" + data.year,
                        card_cvv: data.cvv,
                        card_number:  data.cardnumber.replaceAll(" ",""),
                        card_holder_name: data.name,


                }
                PostService(API_URL + `/api/v1/base/billing/card/${billingDetails.billing_card_id}/update`, billJsonData).then(response => {
                        setShowEditModal(false)
                        showNotification("BillDetails edited. it will take some time to reflect")
                })
        }

        const updateAddressDetails = (data) => {

                console.log({kk:data})

                let jsonBody = {
                        full_name: data.full_name,
                        email: data.email,
                        phone: data.phone,
                        primary_address: data.address1,
                        secondary_address: data.address2,
                        country: data.country,
                        state: data.state,
                        zip_code: data.zipcode.toString()

                }

                PostService(API_URL + `/api/v1/base/billing/address/${addressDetails.address_id}/update`, jsonBody).then(response => {
                        setShowEditModal(false)
                        showNotification("BillDetails edited. it will take some time to reflect")
                })
        }

      
        useEffect(() => {
                // getBillingDetails()
        }, [])



        return (
                <>
                        <div className="org-basic-panel">
                                {/* <div className='padding_X'>
                                        <div className={'change-plan-container'}>
                                                <div className="d-block m-auto w-content">
                                                        <div>
                                                                <span>Want to change your current plan ?</span>
                                                                <Button className="change-plan-button">Change Plan</Button>
                                                        </div>
                                                </div>
                                        </div>
                                </div> */}


                                <Card title={
                                        <div className='billingHeader padding_X disabled-plan'>
                                                <div className='TitleDiv'>
                                                        <span><RiBankCardLine size={24} color='#6B53AE' /></span><span className='tileHeader'>Card Details</span>
                                                </div>
                                                <div className='ModifyatHeader'>
                                                        <button onClick={() => { setShowEditModal(true) }}>
                                                                <span><RiPencilLine size={18} color='#D0CFD4' /></span>
                                                                <span className='tileHeader'>Edit</span>
                                                        </button>
                                                </div>
                                        </div>}>

                                        <div className={'padding_X'}>
                                                <KeyValueLabel label={"Holder Name"} value={billingDetails?.card_holder_name} className={`Keyvaluecolor`} />
                                                <KeyValueLabel label={"Card Number"} value={
                                                        <>
                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                        <img src={masterCardIcon} alt="MasterCard Icon" />
                                                                        <Value style={{ color: "#0612374D", marginLeft: "10px" }}>{billingDetails?.card_number}</Value>
                                                                </div>
                                                        </>
                                                } />



                                                <KeyValueLabel label={"Expiry Date"} value={<Value style={{ color: "#0612374D" }}>{
                                                       <Moment format='MMM YY'>{ billingDetails?.card_expiry}</Moment>
                                                }</Value>} />
                                        </div>
                                        <EditCardDetails
                                                title={"Edit Card Details"}
                                                description={"We Ensure the Security of Your Credit Card Data with the Stripe Payment System"}
                                                show={showEditModal}
                                                data={billingDetails}
                                                onHide={() => setShowEditModal(false)}
                                                onSave={updateCardDetails}
                                        />

                                </Card>

                                <Card title={
                                        <div className='billingHeader padding_X'>
                                                <div className='TitleDiv'>
                                                        <span><RiMapPinUserLine size={24} color='#6B53AE' /></span><span className='tileHeader'>Billing Address</span>
                                                </div>
                                                <div className='ModifyatHeader'>
                                                        <button onClick={() => { setShowAddressEditModal(true) }}><span><RiPencilLine size={18} color='#D0CFD4' /></span><span className='tileHeader'>Edit</span></button>
                                                </div>
                                        </div>}>
                                        <div className={'padding_X'}>
                                                <KeyValueLabel label={"Full Name"} value={addressDetails?.billing_name} />
                                                <KeyValueLabel label={"Address 1"} value={addressDetails?.billing_primary_address} />
                                                <KeyValueLabel label={"Address 2"} value={addressDetails?.billing_secondary_address} />
                                                <KeyValueLabel label={"Zipcode"} value={addressDetails?.billing_zip_code} />
                                                <KeyValueLabel label={"Email"} value={<Value style={{ color: "#0612374D" }}>{addressDetails?.billing_email}</Value>} />
                                                <KeyValueLabel label={"Phone"} value={addressDetails?.billing_phone} />
                                                <KeyValueLabel label={"Country"} value={addressDetails?.billing_country} />
                                                <KeyValueLabel label={"State"} value={addressDetails?.billing_state} />
                                        </div>
                                        
                                        <EditBillDetails
                                                title={"Edit Billing Details"}
                                                description={"Please Provide the Company or Personal Information for Invoicing"}
                                                show={showAddressEditModal}
                                                data={addressDetails}
                                                onHide={() => setShowAddressEditModal(false)}
                                                onSave={updateAddressDetails}
                                        />
                                </Card>

                        </div>
                        <div className="org-basic-panel table-panel mt-20">
                                <div className='panel-title'>
                                        <span>Billing History</span>
                                </div>
                                <div>
                                        <Row>
                                                <Col lg={12} className={"pt-4"}>
                                                        <div className="br-8px ops-border-table px-4 table-header" >
                                                                <Row className="py-3 px-3 f-14px fw-bold table-header-text">
                                                                        <Col lg={4} className={"text-uppercase "}>Invoice <BsArrowDownUp className="f-12px ms-2" /></Col>
                                                                        <Col lg={2} className={"text-uppercase"}>Billing Date <BsArrowDownUp className="f-12px ms-2" /></Col>
                                                                        <Col lg={2} className={"text-uppercase"}>Amount <BsArrowDownUp className="f-12px ms-2" /></Col>
                                                                        <Col lg={2} className={"text-uppercase"}>Plan <BsArrowDownUp className="f-12px ms-2" /></Col>
                                                                        <Col lg={2} className={"text-uppercase"}>Action <BsArrowDownUp className="f-12px ms-2" /></Col>
                                                                </Row>
                                                        </div>
                                                </Col>
                                        </Row>
                                        <Row>
                                                <Col lg={12} className={"pt-4"}>
                                                        <div className=" bg-white px-4 text-center">
                                                                <span>No data available</span>
                                                                {/* <Row>
                                                                        <Col lg={4} className={"text-uppercase "}>Invoice 2452-June-2023 <Tag type="success">Paid</Tag></Col>
                                                                        <Col lg={2} className={"text-uppercase"}>12-06-2023</Col>
                                                                        <Col lg={2} className={"text-uppercase"}>0 </Col>
                                                                        <Col lg={2} className={"text-uppercase"}><Tag type="success">Free</Tag></Col>
                                                                        <Col lg={2} className={"text-uppercase"}><Button size="small" type="success">Download</Button> </Col>

                                                                </Row> */}


                                                        </div>
                                                </Col>
                                        </Row>
                                </div>
                        </div>


                </>
        )
}

Billing.defaultProps = {

}

export default Billing