import React from 'react'
import './ServiceCard.css'
import { BiPlusCircle } from 'react-icons/bi'

function AddServiceCard() {
  return (

<div className='NewServiceCard'>
<div className='addBox'>
     <div className='d-flex justify-content-center align-items-center' style={{height:'100%'}}>
        <button style={{border:'none',background:'transparent'}}><BiPlusCircle color='#6B53AE' fontSize={24}/></button>
        </div>
     </div>
</div>



  )
}

export default AddServiceCard