
import InputSearchComponent from "../../components/form/InputSearchComponent/InputSearchComponent"
import style from "./Dashboard.module.css"

import Profile from "./Profile"

const Header = ({user, org, orgList})=>{

    return(
        <>
            <div className={style.dashboardHeader}>
                <div className={style.dashboardHeaderSearch}>
                    <div style={{width: "280px"}}>
                        <InputSearchComponent  />
                    </div>
                </div>
                <Profile key={"profile"} user={user} org={org} orgList={orgList} />
            </div>
        </>
    )
}

export default Header 