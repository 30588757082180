
import Table from "components/Table/Table"
import moment from "moment"
import { useNavigate } from "react-router-dom"
import { useParams } from "react-router-dom"
import GetService from "services/GetServices"
import { API_URL } from "config/config"
import { useEffect, useState } from "react"
import SimpleToolTip from "components/Tooltip/SimpleToolTip"
import { getChangeBaseData, parseDataForFilter } from "sections/Changes/ChangesUtil"


const Changes = ({startTime, endTime})=> {

    const navigate = useNavigate();

    const gotoResource = (id) => {
            navigate("/resources/"+id+"/details")
    }

    const {serviceId } = useParams()

    const [serviceEvents, setServiceEvents] = useState([]);
    const [tableFilterData, setTableFilterData] = useState([])
    const [tableInfo, setTableInfo] = useState({
      totalRows: 0,
      currentPage: 1,
      loading: true,
      search: "",
      filter: {},
      sort: []
    });

    const parseFilterData = async ()=>{
      let data = await getChangeBaseData()
      setTableFilterData(parseDataForFilter(data))
    }


    const getResourceEvents = (page =1, search = "", sort = [], filter = {}) => {

      let apiSearch = [{key: "message", value :search}]


      let searchJSON = JSON.stringify(apiSearch)
      let filterJSON = JSON.stringify(filter)
      let sortJSON = JSON.stringify(sort)

      let urlParams = `current_page=${page}&search=${searchJSON}&filter=${filterJSON}&sort=${sortJSON}&startTime=${startTime}&endTime=${endTime}`
      


      GetService(API_URL +  `/api/v1/service/${serviceId}/events?${urlParams}`)
        .then((response) => {
          const data = response.data.data.items;
          const totalRows = response.data.data.totalRows;
          setServiceEvents(data);
          setTableInfo({ ...tableInfo, currentPage:page, search:search, sort:sort , filter: filter, loading: false, totalRows });
        })
    };
    
     

    const columns = [
        {
            name: 'TITLE',
            selector: row => <div style={{maxWidth: "600px"}}><span className="span-ellipsis" style={{fontWeight: "bold"}}>{row.message}</span></div>,
            sortable: true,
            sortField: 'message'
        },
        {
            name: 'RESOURCE',
            selector: row => <span className="ops-common-hover" onClick={()=>{gotoResource(row.config_item.ci_id)}}>{row.config_item.name}</span>,
            sortable: true,
            width: "250px",
            sortField: 'config_item.name'
        },
        {
            name: 'PROVIDER',
            selector: row => {
                return ( 
                    <SimpleToolTip tooltip={row.config_item?.plaform?.name}>
                      <span className="">
                          <img src={`/images/connector-images/${row.config_item.plaform.platform_type.icon}`} className="br-img-rounded" style={{width: "30px", height: "30px"}}/>
                      </span>
                    </SimpleToolTip>
                )
            },
            center: true,
            width: "180px"
        },
        {
            name: 'TIME',
            selector: row => <span style={{color: "#A4A3A9"}}>{moment(row.time).format("YYYY-MM-DD HH:mm:SS")}</span>,
            sortable: true,
            width: "250px",
            sortField: 'time'
        },
    ]

    const onPageChange = (page) => {
        setTableInfo({ ...tableInfo, loading: true, currentPage: page });
        getResourceEvents(page, tableInfo.search, tableInfo.sort, tableInfo.filter);
    };

    const onRefresh = ()=>{
      setTableInfo({ currentPage: 1, totalRows:0, perPage: 10, loading: true , search: "", sort: [], filter:{}})
      getResourceEvents();
  }

  const onFilterApply = (data)=>{
      setTableInfo({...tableInfo, ...{filter: data}})
      getResourceEvents(1, tableInfo.search, tableInfo.sort, data)
  }

  const onSearch = (keyword)=>{
    getResourceEvents(1, keyword, tableInfo.sort, tableInfo.filter)
  }


  const onSort = (selectedColumn, sortDirection, sortedRows) =>{
      setTableInfo({...tableInfo, ...{sort: [{key: selectedColumn.sortField, order:sortDirection}]}})
      getResourceEvents(tableInfo.currentPage, tableInfo.search, [{key: selectedColumn.sortField, order:sortDirection}], tableInfo.filter)
  }


  useEffect(() => {
    
    parseFilterData();
  }, []);

  useEffect(()=>{
    getResourceEvents();
  },[startTime, endTime])



    return(

        <>
            <Table
               columns={columns}
               data={serviceEvents}
               loading={tableInfo.loading}
               containerStyle={{ border: "0px" }}
               pagination={true}
               totalRows={tableInfo.totalRows}
               onPageChange={onPageChange}   
               onRefresh={onRefresh}
               onSearch={onSearch} 
               filterData={tableFilterData}
               onFilterApply={onFilterApply}
               dataTableProps={{sortServer: true, onSort: onSort}}
            />
            

        </>
    )
}


export default Changes