import { useEffect, useState } from 'react';
import { RiDeleteBin7Line, RiCpuLine, RiAlbumLine } from 'react-icons/ri';
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import DashboardBody from "../../../pages/newlayout/DashboardBody";
import InputSearchComponent from '../../../components/form/InputSearchComponent/InputSearchComponent';
import filterIcon from "../../../assets/icons/controls/filter.svg";
import "./NewIntegration.css";
import { BiErrorCircle } from "react-icons/bi";
import IntegrationCreation from '../../../components/modals/IntegrationCreation/IntegrationCreation';
import { useNotifications } from "reapop";
import TableEmptySuccess from '../../../components/TableEmptySuccess/TableEmptySuccess';

import GetService from 'services/GetServices';
import { API_URL } from 'config/config';
import PostService from 'services/PostServices';
import Tag from 'components/Elements/Tag/Tag';
import SimpleToolTip from 'components/Tooltip/SimpleToolTip';
import { useNavigate, useOutlet, useParams } from 'react-router-dom';

const NewIntegration = ()=>{

    const [info, setInfo] = useState({})
    const [config, setConfig] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [completeIntegrstion, setCompleteIntegrstion] = useState(false)
    const [platforms, setPlatforms] = useState([])
    const [healthCheckStatus, setHealthCheckStatus] = useState(0)

    const [selectTab, setSelectTab] = useState(1)
    const [itemCounts, setItemCount] = useState({ source: 8, destination:0, processors:0 })

    const searchKeywords = ["AWS", "Datadog", "AppDynamics"]
    const HOW_TO_LINKS  = { 
            "1": "https://onepane.help.center/article/5005-azure-prerequisites",
            "2": "https://onepane.help.center/article/5008-newrelic-prerequisites"
    }

    const navigate = useNavigate()
    const outlet = useOutlet();
    const {sourceId} = useParams()

    const {notify} = useNotifications()

 
    const onHealthCheck = (data)=>{
       
        let postData = data.custominput
        
        PostService(API_URL+`/api/v1/base/platformtype/${data.platform_id}/test`,postData).then((response)=>{
            if(response.data.success == true){
                setHealthCheckStatus(1)
            }else{
                setHealthCheckStatus(2)
            }
            console.log({response})
        })
    }
    
    const onSave = (data)=> {
        if(data.mode == "create"){
            PostService(API_URL+`/api/v1/platform/${data.platform_id}/create`,{
                platform_name: data.platform_name,
                platform_config: data.custominput
            }).then((response)=>{
                if(response?.data?.success){
                    setShowModal(false)
                    setCompleteIntegrstion(true)
                }
            })
        }
    }

    const onIntegrationClick = (platformId)=> {

        GetService(API_URL+`/api/v1/base/platformtype/${platformId}/details`).then(response=>{
            let platform = response.data.data.platform_type;
            navigate(`/integrations/new/sources/${platformId}/add`)
            setInfo({
                platform_id: platform.platform_type_id,
                title: platform.name,
                logo: "/images/connector-images/"+platform.icon,
                description: platform.description,
                basic_details: "Basic details",
                basic_decscription: platform.basic_description,
                label: "Name",
                client_configuration_text: "Client configurations",
                client_configuration_decscription: platform.config_description,
                hint: <BiErrorCircle color="rgba(255, 153, 0, 0.40)" fontSize={14}/>,
                how_to_link: HOW_TO_LINKS[platformId]
            }
        )
        setConfig(platform.configs)
        setShowModal(true)
        setHealthCheckStatus(0)
            
        })

    }

    const closeModal = ()=>{
        setShowModal(false)
        navigate("/integrations/new/sources/")
    }

    
    const groupPlatform = (data, hasCloudConnetor = true)=>{
        let tempPlatform = [];
        data?.map(item=>{
            item.disabled = false
            if( hasCloudConnetor == false && item.category != 1 ){
                item.disabled = true
            }
            if (tempPlatform[item.platform_category.platform_category_id]){
                tempPlatform[item.platform_category.platform_category_id].integrations.push(item)
            }else{
                tempPlatform[item.platform_category.platform_category_id] = {
                    id: item.platform_category.platform_category_id,
                    category: item.platform_category.category_name,
                    integrations: [item],
                }
            }
            

        })
        setPlatforms(tempPlatform)
    }

    const loadSource = ()=>{
        GetService(API_URL + `/api/v1/platform/active/sources?filter=${JSON.stringify({"status":[6],"platform_type_id":[1, 10]})}`).then(platformRes=>{
            let hasCloudConnetor = platformRes.data?.data?.total_count >= 1
            GetService(API_URL+`/api/v1/base/platform/list`).then(response=>{
                let data = response.data.data.platforms;
                groupPlatform(data, hasCloudConnetor)
            })
        })



        
    }
    
    useEffect(()=>{

        GetService(API_URL+`/api/v1/base/platform/overview`).then(response=>{
            let data = response.data.data
            setItemCount({ source: data.source_count, destination:0, processors:0 })
            loadSource()
        })

        if(sourceId){
            console.log({sourceId})
            onIntegrationClick(sourceId)
        }
    },[])
    
   
    

    return(
        <>

            <Breadcrumbs section={[ {name: "Integrations", path: "/integrations"} ]}/>
            <DashboardBody>
               
            <div className='source-containter'>

               {completeIntegrstion == true &&  <TableEmptySuccess heading={"Integration completed successfully"} subheading={"Integration onboarding completed "} buttonText={"list integrations"} link={"/integrations"} /> }

               {completeIntegrstion == false && <>
                    <div className='integration-tab'>
                        <div className='integration-tabs-container'>
                            <div className={`integration-tab-menu`}>
                                <div className={`integration-tab ${selectTab == 1 ? 'active':''}`} onClick={()=>setSelectTab(1)}>
                                    
                                    <div className='tab-text'>
                                        <RiDeleteBin7Line size={18} style={{marginTop: "-3px", marginRight: "2px"}}/> Source
                                    </div>
                                    <div className='tab-extra'>
                                        <div className='integration-count'>{itemCounts.source}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='integration-tab-menu'>
                                <div className={`integration-tab disabled`}>
                                    <div className='tab-text'>
                                        <RiCpuLine size={20}  style={{marginTop: "-3px", marginRight: "2px"}}/>  Processors</div>
                                    <div className='tab-extra'>
                                    <div className='integration-count'>{itemCounts.processors}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='integration-tab-menu'>
                                <div className={`integration-tab disabled`}>
                                <div className='tab-text'>
                                        <RiAlbumLine size={20}  style={{marginTop: "-3px", marginRight: "2px"}}/>  Destination</div>
                                    <div className='tab-extra'>
                                        <div className='integration-count'>{itemCounts.destination}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='integration-tab-panel overflow-scroll' style={{paddingBottom: "20px"}}>
                        <div className='integration-search-controler'>
                            <div className='integration-search'>
                                <div>
                                    <InputSearchComponent size={4} style={{width: "306px"}} />
                                </div>
                                <div style={{marginTop: "3px"}}>
                                    <span className='popular-search-terms'>Popular search terms:</span> 
                                    {
                                        searchKeywords.map((item, index)=>{
                                            return (<span className='search-keyword' key={index}>{item}</span>)
                                        })
                                    }
                                </div>
                            </div>
                            {/* <div className='integration-filter'>
                                <span className='search-filter-text'> <img src={filterIcon} className='search-filter-icon' /> Filter</span>
                            </div> */}
                        </div>


                        {
                            platforms.map((item, index)=>{
                                return(
                                    <div className='integration-list' key={index}>
                                        <div className='integration-category'>
                                            <div>
                                                <span className='integration-category-name'>{item.category}</span>
                                            </div>
                                            <div style={{flexGrow: 1}}>
                                                <div className='integration-category-line'></div>
                                            </div>
                                        </div>

                                       
                                        <div className='integration-card-container'>
                                            {
                                                item.integrations?.sort((item)=>item.status == 1 ? -1: 1)?.map((integration, i)=>{
                                                    return(
                                                        <>
                                                          
                                                            <SimpleToolTip tooltip={"Configure your cloud connector prior to integrating Monitoring and DevOps services."} disabled={!(integration.disabled == true && integration.status == 1)}>
                                                                <div className={`integration-card ${(integration.status == 2) ? "disabled" : ""} ${(integration.disabled == true) ? "blocked" : "active"}`} key={i} onClick={ (integration.status == 2 || integration.disabled == true) ? ()=>{} : ()=>onIntegrationClick(integration.platform_type_id)}>
                                                                    <div className={`integration-card-disable ${integration.status == 2 ? "show" : ""}`} >
                                                                        <Tag type={'warning'} className={'alpha-tag'}>alpha</Tag>
                                                                    </div>
                                                                    <div> <img src={`/images/connector-images/${integration.icon}`} className='integration-icon' /> </div>
                                                                    <div> <span className='integration-text'>{integration.name}</span></div>
                                                                </div>
                                                            </SimpleToolTip>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                        
                                    </div>
                                )
                            })
                        }

                        
                    </div>
                </>}
            </div>
            {outlet &&  <IntegrationCreation show={true} info={info} configs={config} configval={{}} healthCheckStatus={healthCheckStatus} onHide={closeModal} mode={"create"} action={onSave} onHealthCheck={onHealthCheck} /> }
            </DashboardBody>
        </>
    )

}


export default NewIntegration