
import "./KeyValueLabel.css"


const Key = ({className, children, ...props})=>{
    return(
        <label className={`op-key ${className}`} {...props}>{children}</label>
    )  
}

const Value = ({className, children, ...props})=>{
    return(
        <label className={`op-value ${className}`} {...props}>{children}</label>
    )  
}



const KeyValueLabel = ({label, value, className, ...props}) => {

    return(
        <div className={`op-key-value ${className}`} {...props}>
            <Key>{label}<span className="op-value-seperator">:</span></Key>
            <Value>{value}</Value>
        </div>
    )
}

KeyValueLabel.defaultProps = {
    className: '',
    key: '',
    value: ''
}


export default KeyValueLabel
export { Key, Value }