
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import ResolvedUnresolvedTimeLine from "components/ResolvedUnresolvedTimeLine/ResolvedUnresolvedTimeLine"
import DashboardBody from "pages/newlayout/DashboardBody"
import avatar from "assets/icons/util/avatar.svg"
import serviceNameIcon from "assets/icons/services/dummy-service.svg"
import Table from "components/Table/Table"
import SucessfullySolvedComponent from "components/TableEmptySuccess/TableEmptySuccess"
import Tag from "components/Elements/Tag/Tag"
import { useParams } from "react-router-dom"
import { services } from "assets/data"
import { resources } from "assets/data"

const ServiceSuccess = ()=> {


        const CRITICALITY =  ["High", "Medium", "Low"]
        const LABELS = ["danger", "warning", "success"]
        const {serviceId } = useParams()
        const serviceDetails = services[parseInt(serviceId) - 1]

        const resourcesData = resources.filter((data)=>{
            return serviceDetails["name"].toLowerCase().includes(data["service"].toLowerCase())
        })


        const columns = [
            {
                name: 'RESOURCE NAME',
                selector: row => {
                    return ( 
                        <>
                           <div className="git-repo" >
                                <span><img src={serviceNameIcon}/></span>
                                <span className="repo-name ops-common-hover">{row.name}</span>
                            </div>
                        </>
                    )
                },
                sortable: true,
            },
            {
                name: 'SERVICE',
                selector: row => <span className="table-span">{row.service}</span>,
                sortable: true,
            },
            {
                name: 'OWNER',
                selector: row => {
                    return ( 
                        <>
                           <div className="flex">
                                <span className="">
                                    <img src={avatar} style={{width: "20px", height: "20px", marginRight: "15px"}}/>
                                </span>
                                <span className="table-span">{row.source}</span>
                            </div>
                        </>
                    )
                },
                
                sortable: true,
            },
            {
                name: 'CRITICALITY',
                selector: row => <Tag type={LABELS[row.criticality-1]}>{CRITICALITY[row.criticality-1]}</Tag>,
                sortable: true,
            },
            {
                name: 'TYPE',
                selector: row => <span className="table-span">{row.type}</span>,
                sortable: true,
            },
    
        ]
    

    return(
        <>
            <Breadcrumbs section={[{"name": "Services", "path": "/services"}, { "name":"Modify Service", "path": ""}]} />
            <DashboardBody>

                <ResolvedUnresolvedTimeLine
                    stage1="Fix Services"
                    isStage2Successful={true}
                    isStage2Completed={true}
                />

            <div style={{marginTop: "20px"}}>

                
                    <Table
                        columns={columns}
                        data={[]}
                        search={false}
                        refresh={false}
                        filter={false}
                        loading={false}
                        noDataComponent={<SucessfullySolvedComponent heading="Service is changed" buttonText="Back to service list" link= "/services"/>}
                      />
            </div>


            </DashboardBody>
        </>
    )

}

export default ServiceSuccess