import { getDateInterval } from "utils/util";
import { create } from "zustand";


const {fromDate, currentDate, fromTimeStamp, toTimeStamp } = getDateInterval()


const AppSettingStore  = create((set)=>({
    user:{
        firstName: "",
        lastName: "",
        role: 1,
    },
    calender: {
        fromDate: fromDate,
        toDate: currentDate,
        fromTimestamp: fromTimeStamp,
        toTimestamp: toTimeStamp
    },
    setCalender: (fromDate, toDate, fromTimestamp, toTimestamp)=>{
        set({calender:{fromDate: fromDate, toDate: toDate, fromTimestamp: fromTimestamp, toTimestamp: toTimestamp}})
    },
    setCalenderDate: (fromDate, toDate )=>{
        set({calender:{fromDate: fromDate, toDate: toDate}})
    },
    setCalenderTimestamp: (fromTimestamp,toTimestamp )=>{
        set({calender:{fromTimestamp: fromTimestamp, toTimestamp: toTimestamp}})
    },

    setUser: (firstName, lastName, role)=>{
        set({user:{firstName: firstName, lastName: lastName, role:role}})
    },

}))


export default AppSettingStore;
