import { RiUserLine } from "react-icons/ri"
import UserAvatar  from "react-avatar"
import { CapitalizeEachWord } from "utils/util"


const Avatar = ({username, imageUrl = "/images/profile/ashmil.png", customText="No User", size="30"})=> {


    const nameFontStyle = {
        color: "#4D4C52",
        fontFamily: "Lato",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        marginLeft: "15px"
    }

    const dummyAvaterStyle = {
        display: "inline-block",
        backgroundColor: "#a7a3a3",
        borderRadius: "44px",
        width: "20px",
        height: '20px',
        textAlign: "center",
    }

    const avatarContainer = {
        display: "flex",
        alignItems: "center"
    }


    const getProfileImage = (name)=>{
        if(name?.toLowerCase().includes("ashmil")){
            return "/images/profile/ashmil.png"
        }
        if(name?.toLowerCase().includes("gideon")){
            return "/images/profile/gideon.png"
        }

        return name

    }

    return(
        <> 
            { (username?.replaceAll(" ", "") == "") && <> <div style={dummyAvaterStyle}> <RiUserLine size={14} style={{marginTop: "-6px"}} /> </div><span style={nameFontStyle}>{customText}</span> </> }
            { username?.replaceAll(" ", "") != "" && <> 
                <div style={avatarContainer}>
                    <UserAvatar name={username} src={getProfileImage(username)} size={size} round={true}  /> 
                    <span style={nameFontStyle}>{CapitalizeEachWord(username)}</span>
                </div>
                
            </> }
            
        </>
    )

}

export default Avatar