import Tab from "../../../components/Tab/Tab";
import General from "./Tabs/General";
import "./Settings.css";
import Billing from "./Tabs/Billing";
import { useEffect, useState } from 'react';
import { getCookie } from "../../../utils/util";
import GetService from "../../../services/GetServices";
import { API_URL } from "../../../config/config";
import Members from "./Tabs/Members";
import DashboardBody from "../../../pages/newlayout/DashboardBody";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import RBACWrapper from "components/RBACWrapper/RBACWrapper";

const Settings = ()=>{

    let orgId = getCookie("onepane_org")
    const [organisation, setOrganisations] = useState({})
    
   

    useEffect(()=>{

        GetService(API_URL + "/api/v1/base/active/organisation").then(response=>{
            let organisation = response?.data?.data?.organisation   
            setOrganisations(organisation)
        })


    },[])



    const tabs = [
        {
            "label": "General",
            "children": <General organisation={organisation}/>
        },
       
    ]


    if(RBACWrapper.hasPermission("SETTINGS_MEMBER")){
        tabs.push( {
            "label": "Members",
            "children": <Members/>,
           
        })
    }

    if(process.env.REACT_APP_SAAS_MODE == 1){

        if(RBACWrapper.hasPermission("SETTINGS_BILLING")){
            tabs.push( {
                "label": "Billing",
                "children": <Billing billingDetails={organisation.billing?.billing_card && organisation.billing?.billing_card[0]} addressDetails={organisation.billing?.billing_address && organisation.billing?.billing_address[0]}  />,
                "panelClass": "",
                "disabled": true,
            })
        }

        if(RBACWrapper.hasPermission("SETTINGS_USAGE")){
            tabs.push({
                "label": "Usage",
                "children": "Usage",
                "disabled": true,
            })
        }

      
    }



    return(
        <>
            <Breadcrumbs section={["Settings"]} />
            <DashboardBody style={{background: "#F4F6FC"}}>
                <Tab tabs={tabs} tabStyle={{height: "calc(100vh - 195px)"}}/>
           </DashboardBody>
        </>
    )


}

export default Settings