import { useEffect, useState } from 'react';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import DashboardBody from 'pages/newlayout/DashboardBody';
import SearchControl from 'components/SearchControl/SearchControl';
import ResolvedUnresolvedComponent from 'components/ResolvedUnresolvedComponent/ResolvedUnresolvedComponent';
import './Index.css';
import { Link } from 'react-router-dom';
import GetService from 'services/GetServices';
import { API_URL } from 'config/config';
import { connectorRoute } from 'config/constants';
import { getHealthStatus } from 'utils/util';
import SimpleToolTip from 'components/Tooltip/SimpleToolTip';
import TablePagination from 'components/Table/TablePagination';

function ResolvedIndex() {


    const searchItems = ["AWS", "Datadog", "AppDynamics", "Git"];
    const [integrations, setIntegrations] = useState([])
    const [pagination, setPagination] = useState({totalRows: 0 , currentPage: 1, loading: true, search: "", sort:[], filter :{}})
   

    const getIntegrations = (page = 1, search = "" ) => {
        let searchJSON = JSON.stringify([{key:"name", value:search}])
        GetService(API_URL + `/api/v1/platform/unresolved/list?current_page=${page}&search=${searchJSON}`).then(response=>{
            let data = response.data?.data
            setIntegrations(data.items);
            setPagination({pagination,...{totalRows:data.total_count, currentPage:page, search}})
        })
    }

    const onPageChange = (page)=>{
        setPagination({...pagination, ...{loading: true, currentPage: page}})
        getIntegrations(page, pagination.search, pagination.sort, pagination.filter)
    }
   
    const onSearch = (keyword)=>{
        setPagination({...pagination, ...{search: keyword}})
        getIntegrations(1, keyword, pagination.sort, pagination.filter)
    }


    useEffect(()=>{
        getIntegrations()
    },[])

    return (
        <>
            <Breadcrumbs section={[{
                "name": "Resources",
                "path": "/resources"
            },{
                "name": "Unresolved resources",
                "path": "/resources/resolve"
            }]} />
            <DashboardBody style={{ background: "#F4F6FC" }}>
                <div className='resolved-page'>
                    <SearchControl showFilter={false} showSort={false} onRefresh={getIntegrations}  onSearch={onSearch} />
                    <div style={{marginTop: "3px"}}>
                        <span className='popular-search-terms'>Popular search terms:</span> 
                        { searchItems.map(item=><span className='search-keyword'>{item}</span>) }
                    </div>
                    <div className='resolved-plaforms'>
                                {integrations?.map((item, index) => (
                                    <div className={`resolved-plaform-card`} key={index} style={{ marginTop: "19px" }}>
                                           <SimpleToolTip tooltip={"unresolved items will be automatically resolved for cloud connectors"} disabled={item.platform_type.category != 1}>
                                           <Link to={`${ item.platform_type.category != 1 ? `/resources/resolve/${item.platform_id}/details`: '#'}`} className='link-decoration-none'>
                                                <div>
                                                    <ResolvedUnresolvedComponent
                                                        disabled={item.platform_type.category == 1 ? true: false}
                                                        icon={`/images/connector-images/${item.platform_type.icon}`}
                                                        health={getHealthStatus(item.unresolved)}
                                                        title={item.name}
                                                        subtitle={`op-connector-${item.platform_id}`}
                                                        resolved={item.resolved}
                                                        unresolved={item.unresolved}
                                                        link={`${connectorRoute[item.platform_type.platform_type_id]}/${item.platform_id}/details`}
                                                    />
                                                </div>
                                            </Link>
                                        </SimpleToolTip>
                                    </div>
                                ))}
                    </div>
                    <TablePagination rowCount={pagination.totalRows} currentPage={pagination.currentPage}  onChangePage={onPageChange}/>
                </div>
                
            </DashboardBody>
        </>
    )
}

export default ResolvedIndex;
