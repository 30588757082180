import { Link } from "react-router-dom";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Table from "components/Table/Table";
import DashboardBody from "pages/newlayout/DashboardBody";
import Avatar from "components/Avatar/Avatar";
import serviceNameIcon from "assets/icons/services/dummy-service.svg";
import { RiDatabase2Fill } from "react-icons/ri";
import "./Service.css";

import { useEffect, useState } from "react";

import { API_URL } from "config/config";
import GetService from "services/GetServices";
import { getServiceStatus } from "utils/util";

const Service = ()=> {

    let timeout;

    // const tableData = services
    const [services, setServices] = useState([])
    const [tableInfo, setTableInfo] = useState({totalRows: 0 , currentPage: 1, loading: true, search: "", sort: [], filter: {}})

    const filterData = [{
            label: "Health",
            key: "active",
            values: [
                { label: "Good", value: 1 },
                { label: "Degraded", value: 2 },
                { label: "Critical", value: 3 }
            ]

        }]
    
    const columns = [
        {
            name: 'SERVICE NAME',
            selector: row => {
                return ( 
                    <>
                        <Link to={`/services/${row.service_id}/details`} className="table-link-title">
                            <img src={serviceNameIcon}/> 
                            <span className="table-span ops-common-hover" style={{marginLeft: "5px"}}>{row.name}</span>
                        </Link>
                    </>
                )
            },
            width: "350px",
            sortField: "name",
            sortable: true,
        },
        {
            name: 'OWNER',
            selector: row => {
                return ( 
                    <>  
                        {  row.owner?.first_name ? <Avatar username={`${row.owner?.first_name} ${row.owner?.last_name}`} imageUrl="" /> : <span className="no-value-span">Not assigned</span> }
                    </>
                )
            },
            width: "300px"
        },
        {
            name: 'HEALTH',
            selector: row => getServiceStatus(row.active),
            sortField: "active",
            sortable: true,
            // width: "120px"
        },
        {
            name: 'ACTIVE ALERTS',
            selector: row => <span className="table-span" >{row.active}</span>,
            sortField: "active",
            sortable: true,
            center: true,
        },
        {
            name: 'NO.OF RESOURCE',
            selector: row => <span className="table-span">{row.ci_count}</span>,
            sortField: "ci_count",
            sortable: true,
            center: true,
        },
        {
            name: 'ACTION',
            selector: row => {
                return ( 
                    <>
                        {/* <span className="ops-cursor" style={{marginLeft: "5px"}}><img src={serviceMap}/> </span> */}
                        <span className="ops-cursor" style={{marginRight: "15px"}}>
                            {/* <img src={catalogueIcon}/>  */}
                            <RiDatabase2Fill size={18} color="#A4A3A9" />
                        </span>
                    </>
                )
            },
            // width: "100px",
            center: true,
        },

    ];

    const getServices = (page = 1, search = "", sort =[], filter = {})=>{
        let apiSearch = [{key:"name", value:search}]
        if(filter["active"]){
            if(filter["active"].includes(1)){
                apiSearch.push({key:"active",condition: '=', value:0})
            }
            if(filter["active"].includes(2)){
                apiSearch.push({key:"active",condition: '<', value:5})
            }
            if(filter["active"].includes(3)){
                apiSearch.push({key:"active",condition: '>', value:6})
            }
            delete filter["active"];
        }

        let searchJSON = JSON.stringify(apiSearch)
        let sortJSON = JSON.stringify(sort)
        let filterJSON = JSON.stringify(filter)

        GetService(API_URL + `/api/v1/service/list?current_page=${page}&search=${searchJSON}&sort=${sortJSON}&filter=${filterJSON}`).then(response=>{
            let data = response.data.data
            setServices(data.items)
            setTableInfo({...tableInfo, ...{loading: false, totalRows: data.total_count }})
        })
    }

    const onPageChange = (page)=>{
        setTableInfo({...tableInfo, ...{loading: true, currentPage: page}})
        getServices(page, tableInfo.search, tableInfo.sort, tableInfo.filter)
    }

    const onSearch = (keyword)=>{
        setTableInfo({...tableInfo, ...{search: keyword}})
        getServices(1, keyword, tableInfo.sort, tableInfo.filter)
        
    }



    const sortTable = (selectedColumn, sortDirection, sortedRows)=>{
        getServices(1, tableInfo.search, [{key: selectedColumn.sortField, order: sortDirection}])
        setTableInfo({...tableInfo, ...{sort: [{key: selectedColumn.sortField, order: sortDirection}]}})
    }

    const refreshServices = ()=>{
        getServices()
    }

    const onFilter = (data)=>{
        setTableInfo({...tableInfo, ...{filter: data}})
        getServices(1, tableInfo.search, tableInfo.sort, data)
    }

    useEffect(()=>{
        getServices()
     
    },[])

    


    return(
        <>
            <Breadcrumbs section={["Services"]} />
            <DashboardBody>
                <Table 
                     columns={columns} 
                     data={services} 
                     loading={tableInfo.loading}
                     tableStyle={{border: "0px"}}
                     pagination={true}
                     totalRows={tableInfo.totalRows}
                     onPageChage={onPageChange}
                     onSearch={onSearch}
                     onRefresh={refreshServices}
                     dataTableProps={{sortServer: true, onSort: sortTable}}
                     onFilterApply={onFilter}
                     filterData={filterData}
                    />
            </DashboardBody>
        </>
    )
}

export default Service