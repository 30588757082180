
import Table from "components/Table/Table"
import moment from "moment"
import { Link } from "react-router-dom"
import launchIcon from "assets/icons/util/launch.svg"
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import GetService from "services/GetServices"
import { API_URL } from "config/config"
import { useEffect, useState } from "react"
import SimpleToolTip from "components/Tooltip/SimpleToolTip"
import { getAlertBaseData, parseDataForFilter } from "sections/Alerts/AlterUtil"

const Alerts = ({startTime, endTime})=> {


    const [servicesalert, setServiceAlert] = useState([])
    const [tableInfo, setTableInfo] = useState({ currentPage: 1, totalRows:0, perPage: 10, loading: true , search: "", sort: [], filter:{}})
    const [tableFilterData, setTableFilterData] = useState([])

    const SEVERITY_VALUES = [[10,9,8],[7,6],[5,4],[3,2,1]]

    const navigate = useNavigate();

    const { serviceId } = useParams()

    const gotoResource = (id) => {
            navigate("/resources/"+id+"/details")
    }

    const parseFilterData = async ()=>{
        let data = await getAlertBaseData()
        setTableFilterData(parseDataForFilter(data))
    }


    const getServicesAlert = (page = 1, search = "", sort= [], filter = {})=>{
        
        let apiFilter = filter
        let apiSearch = [{key: "incident_title", value :search}]
      
    
        if(apiFilter['severity']){
            let values = [];

            apiFilter['severity']?.map(item=>{
                values = [...values, ...SEVERITY_VALUES[item]]
            })

            apiSearch.push({
                key: 'severity',
                condition: 'in',
                value: values
            })
            delete apiFilter['severity']
        }

        let searchJSON = JSON.stringify(apiSearch)
        let filterJSON = JSON.stringify(apiFilter)
        let sortJSON = JSON.stringify(sort)


        let urlParams = `current_page=${page}&search=${searchJSON}&filter=${filterJSON}&sort=${sortJSON}&startTime=${startTime}&endTime=${endTime}`
        GetService(API_URL + `/api/v1/service/${serviceId}/incidents?${urlParams}`).then(response=>{
            let data = response.data.data
            setServiceAlert(data.items)
            setTableInfo({...tableInfo, ...{loading: false, totalRows: data.total_count, currentPage: page, search: search, sort: sort, filter: filter }})
        })
    }

    const onPageChange = (page)=>{
        setTableInfo({...tableInfo, ...{loading: true}})
        getServicesAlert(page, tableInfo.search,  tableInfo.sort, tableInfo.filter)
    }



    const onRefresh = ()=>{
        setTableInfo({ currentPage: 1, totalRows:0, perPage: 10, loading: true , search: "", sort: [], filter:{}})
        getServicesAlert();
    }
    const onFilterApply = (data)=>{
        setTableInfo({...tableInfo, ...{filter: data}})
        getServicesAlert(1, "", [], data)
    }


    const onSearch = (keyword)=>{
        getServicesAlert(1, keyword, tableInfo.sort, tableInfo.filter)
    }
    

    const onSort = (selectedColumn, sortDirection, sortedRows) =>{
        setTableInfo({...tableInfo, ...{sort: [{key: selectedColumn.sortField, order:sortDirection}]}})
        getServicesAlert(tableInfo.currentPage, tableInfo.search, [{key: selectedColumn.sortField, order:sortDirection}], tableInfo.filter)
    }

        

    useEffect(()=>{
        parseFilterData()
    },[])

    useEffect(()=>{
        getServicesAlert();
    },[startTime, endTime])




    const columns = [
        {
            name: 'ALERT ID',
            selector: row => <span style={{ fontWeight: 700}} className="ops-common-hover"><a style={{color: "#9BA0AF" , textDecoration: "none"}} href={row.link}>#OPIN{row.incident_id}</a></span>,
            width: "160px",
            sortable: true,
            sortField: "incident_id",
        },
       
        {
            name: 'TITLE',
            selector: row => (<div style={{maxWidth: "392px"}} className="ops-common-hover p-1">
                        <span className="span-ellipsis" style={{fontWeight: "bold"}}>
                            <a style={{color: "black", textDecoration: "none"}} href={row.link}>{row.incident_title}</a>
                        </span>
                    </div>),
            sortable: true,
            sortField: "incident_title",
            
        },
        {
            name: 'TENANT',
            selector: row => {
                return ( 
                    <>
                       <div className="flex ops-common-hover">
                            <SimpleToolTip tooltip={row.config_item?.plaform?.name}>
                                <span className="">
                                    <img src={`/images/connector-images/${row.config_item?.plaform?.platform_type?.icon}`} style={{width: "30px", height: "30px", marginRight:"10px"}} className="br-img-rounded"/>
                                </span>
                            </SimpleToolTip>
                        </div>
                    </>
                )
            },
            width: "120px",
        },
       
        
        {
            name: 'RESOURCE',
            selector: row => <div className="ops-common-hover p-1" onClick={()=>{gotoResource(row.config_item?.ci_id)}}>{row.config_item?.name}</div>,
            width: "250px",
            sortable: true,
            sortField: "incident_title",
        },
       
        {
            name: 'TIME',
            selector: row => <span style={{color: "#9BA0AF"}}>{  moment(row.open_time).format("YYYY-MM-DD HH:mm:SS")} {}</span>,
            width: "180px",
            sortable: true,
            sortField: "open_time",
        },

        {
            name: 'ACTION',
            selector: row => <Link to={"/alerts"} ><div className="ops-common-button-hover px-1"> <img src={launchIcon}/></div> </Link> ,
            width: "180px",
            center: true,
        },
        

    ]




    return(

        <>
        
            <Table
                columns={columns}
                data={servicesalert}
                loading={tableInfo.loading}
                containerStyle={{border: "0px"}}
                pagination={true}
                totalRows={tableInfo.totalRows}
                onPageChage={onPageChange}
                onSearch={onSearch}
                onRefresh={onRefresh}
                dataTableProps={{sortServer: true, onSort: onSort}}
                filterData={tableFilterData}
                onFilterApply={onFilterApply}
            />
            

        </>
    )
}


export default Alerts