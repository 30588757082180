
import { API_URL } from "config/config"
import GetService from "services/GetServices"


const SEVERITY_VALUES = [[10,9,8],[7,6],[5,4],[3,2,1]]


export const loadAlerts = (page = 1, search = "", sort= [], filter = {}, url = "/api/v1/incidents", params = "")=>{
        let apiFilter = filter
        let apiSearch = [{key: "incident_title", value :search}]
        // let service_id = searchParams.get("service")
        // if(searchParams.get("active") == 1){
        //     apiFilter["platform_status"] = [0]
        // }

        // if(searchParams.get("active") == 0){
        //     apiFilter["platform_status"] = [1]
        // }

        // if(service_id != null){
        //     apiFilter["config_items.service_id"] = [service_id]
        // }

    
        if(apiFilter['severity']){
            let values = [];

            apiFilter['severity']?.map(item=>{
                values = [...values, ...SEVERITY_VALUES[item]]
            })

            apiSearch.push({
                key: 'severity',
                condition: 'in',
                value: values
            })
            delete apiFilter['severity']
        }

        let searchJSON = JSON.stringify(apiSearch)
        let filterJSON = JSON.stringify(apiFilter)
        let sortJSON = JSON.stringify(sort)

        let urlParams = `?current_page=${page}&search=${searchJSON}&filter=${filterJSON}&sort=${sortJSON}&${params}`
        return GetService(`${API_URL}${url}${urlParams}`)
}



export const  getAlertBaseData = async ()=>{
    let response = await GetService(API_URL + `/api/v1/filter/alerts/init`,{}, false, false, false)
    let data = response.data.data
         
    
 
     let platforms = []
     data?.platforms?.map(item=>{
         platforms.push({
             label: item.name ,
             value: item.platform_id
         })
     })
 
     let services = []
     data?.services?.map(item=>{
         services.push({
             label: item.name ,
             value: item.service_id
         })
     })
 

     return { platforms, services }
 }
 
 export const parseDataForFilter = (data)=>{


    return [
        {
            label: "Status",
            key: "platform_status",
            values:[ { label: "Opened", value: 0}, { label: "Closed", value: 1}]
        },
        {
            label: "Service",
            key: "config_items.service_id",
            values: data.services
        },
        {
            label: "Severity",
            key: "severity",
            values:[ 
                { label: "Critial", value: 0},
                { label: "Danger", value: 1},
                { label: "Warning", value: 2},
                { label: "Normal", value: 3}
            ]
        },
        {
            label: "Platform",
            key: "incidents.platform_id",
            values: data.platforms
        }
    ]



 }