import { useState } from "react"
import moment from "moment"
import Card from "../../../../components/Card/Card"
import Button from "../../../../components/Elements/Button/Button"
import Tag, { TAG_TYPE } from "../../../../components/Elements/Tag/Tag"
import KeyValueLabel, { Value } from "../../../../components/KeyValueLabel/KeyValueLabel"
import { getCookie } from "../../../../utils/util"
import GetService from "../../../../services/GetServices"
import { API_URL } from "../../../../config/config"
import ConfirmDialog, { MessageBox } from "../../../../utils/ConfirmDialog"
import PostService from "../../../../services/PostServices"
import { RiArrowLeftRightLine, RiArticleLine, RiContactsLine } from "react-icons/ri"
import { useNotifications } from "reapop"
import { useNavigate } from "react-router-dom"
import Moment from "react-moment"
import RBACWrapper from "components/RBACWrapper/RBACWrapper"


const General = ({ organisation }) => {


    const { notify, dismissNotification } = useNotifications()

    const [showBasicDetailModal, setBasicDetailModal] = useState(false)

    const navigate = useNavigate();


    const deleteOrg = () => {
        ConfirmDialog("Do you want to delete this organisation ?", "Delete", () => {
            PostService(API_URL + `/api/v1/base/organisation/delete`).then(response=>{
                window.location = "/overview"
            })
        })
    }

    const deleteAccount = () => {
         ConfirmDialog("Do you want to delete your account ?", "Delete", () => {

            GetService(API_URL + "/api/v1/base/organisation/owned/list").then(response=>{
                let data = response.data.data;
                if (data?.organisations?.length > 0 ){
                    MessageBox(()=>{
                        return(
                            <div>
                                <div className="org-delete-title">
                                    <p>Please delete the following organizations in order to delete your account</p>
                                </div>
                                <div>
                                    {data?.organisations.map(item=>{
                                        return (
                                            <>
                                                <div className="delete-org-name-container">
                                                    <span className="delete-org-bullet">•</span>
                                                    <span className="delete-org-name">{item.name }</span>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                                
                            </div>
                        )
                    }, "Okay")
                }else{
                    PostService(API_URL + `/api/v1/base/user/delete`).then(response=>{
                        window.location = "/iam/wso2/logout"
                    })
                }
               
               
            })


                
        })


        
        
       
    }

    

    const pauseOrg = () => {
        let orgId = getCookie("onepane_org")
        ConfirmDialog("Do you want to pause this organisation ?", "Pause", () => {
            PostService(API_URL + `/api/v1/base/organisation/${orgId}/delete`)
        })
    }


    const showNotification = (text, status = "success") => {
        notify(text, status, {
            dismissAfter: 1500
        })

    }


    const ourPlans = () => {
        // setModalData({
        //     title: "Invite User",
        //     subtitle: "Invite user to this organisation",
        //     action: 1,
        //     data: {}
        // })
        // setShowInviteModal(true)

    }


    return (
        <>
            <div className="org-basic-panel">
                <Card title={<div className='billingHeader padding_X' style={{ marginTop: "0px", borderTop: "none", paddingTop: "0px" }}>
                    <div className='TitleDiv'>
                        <span><RiContactsLine size={18} color='#6B53AE' /></span><span className='tileHeader'>Basic Details</span>
                    </div>
                    {/* <div className='ModifyatHeader'>
                        <button onClick={() => { setBasicDetailModal(true) }}>
                            <RiPencilLine size={18} color='#D0CFD4' />
                            <span className='tileHeader'>Edit</span>
                        </button>
                    </div> */}
                </div>} className={'settings-cards Cardpadding'}>
                    <div className={'padding_X'}>
                        <KeyValueLabel label={"ID"} value={organisation.org_id} />
                        <KeyValueLabel label={"Name"} value={organisation.name} />
                        <KeyValueLabel label={"Created On"} value={moment(new Date(organisation.created_at)).format("DD-MM-YYYY")} />
                        <KeyValueLabel label={"Plan"} value={<Tag>{"Free"}</Tag>} />
                    </div>
                   
                </Card>

                <Card title={
                    <div className='billingHeader padding_X' style={{ marginTop: "0px" }}>
                        <div className='TitleDiv'>
                            <span><RiArticleLine size={18} color='#6B53AE' /></span><span className='tileHeader'>Plan Details</span>
                        </div>
                        <div className='ModifyatHeader'>
                            {/* <button><span><RiPencilLine size={18} color='#D0CFD4' /></span><span className='tileHeader'>Edit</span></button> */}
                            <a href="/changeyourplan">
                                <Button onClick={ourPlans} disabled><RiArrowLeftRightLine size={18} style={{ marginRight: "2px", marginBottom: "3px" }} /> Change Plan</Button>
                            </a>
                        </div>
                    </div>
                } className={'settings-cards'}>
                    <div className={'padding_X'}>
                        <KeyValueLabel label={"Plan Type"} value={<Tag>{organisation.billing?.plan_details?.name ?? 'Free'}</Tag>}/>
                        <KeyValueLabel label={"Started On"} value={<Value style={{ color: "#0612374D" }}>{<Moment format="DD-MM-YYYY">{organisation.billing?.created_at}</Moment>}</Value>} />
                        <KeyValueLabel label={"Status"} value={<>
                            <Tag type={TAG_TYPE.SUCCESS}>Active</Tag>
                            {/* {organisation.billing?.plan_details?.is_active == true && <Tag type={TAG_TYPE.SUCCESS}>Active</Tag> }
                            {organisation.billing?.plan_details?.is_active == false && <Tag type={TAG_TYPE.DANGER}>Inactive</Tag> } */}
                            
                        </>}/>
                        {/* <KeyValueLabel label={"Storage"} value={<Value style={{ color: "#0612374D" }}>100GB</Value>} /> */}
                        {/* <KeyValueLabel label={"Users"} value={<Value style={{ color: "#0612374D" }}>1/2</Value>} /> */}
                    </div>
                </Card>

            </div>

            <RBACWrapper action={"SETTINGS_DELETE_ORGANISATION"}>
                <div className="org-basic-panel mt-20">
                    <Card title={
                        <div className='billingHeader padding_X' style={{ marginTop: "0px", borderTop: "none", paddingTop: "0px" }}>
                            <div className='TitleDiv'>
                                <span><RiArticleLine size={18} color='#6B53AE' /></span><span className='tileHeader'>Delete Organization</span>
                            </div>
                        </div>
                    } className={'settings-cards '}>
                        <div className={'padding_X'}>
                            {/* <div className="setting-action-container org-pause-panel">
                                <div className="setting-info">
                                    <span className="action-title">Pause your organisation account</span>
                                    <span className="action-sub-title">Pause your organisation for a while</span>
                                </div>
                                <div className="setting-action">
                                    <Button type="reverse-primary" disabled onClick={pauseOrg}>Pause</Button>
                                </div>
                            </div> */}
                            <div className="setting-action-container">
                                <div className="setting-info org-delete-pane">
                                    <span className="action-title">Delete your organization account</span>
                                    <span className="action-sub-title">Deleting your organization will erase all organization-related data.</span>
                                </div>
                                <div className="setting-action">
                                    <Button type="reverse-danger" onClick={deleteOrg} >Delete Organization</Button>
                                </div>
                            </div>
                        </div>

                    </Card>     
                </div>
            
            </RBACWrapper>

                <RBACWrapper action={"SETTINGS_DELETE_USER"}>
                    <div className="org-basic-panel mt-20">
                        <Card title={
                            <div className='billingHeader padding_X' style={{ marginTop: "0px", borderTop: "none", paddingTop: "0px" }}>
                                <div className='TitleDiv'>
                                    <span><RiArticleLine size={18} color='#6B53AE' /></span><span className='tileHeader'>Delete Account</span>
                                </div>
                            </div>
                        } className={'settings-cards '}>
                            <div className={'padding_X'}>
                                <div className="setting-action-container">
                                    <div className="setting-info org-delete-pane">
                                        <span className="action-title">Delete your account</span>
                                        <span className="action-sub-title">Deleting your user account will erase all stored data.</span>
                                    </div>
                                    <div className="setting-action">
                                        <Button type="reverse-danger" onClick={deleteAccount} >Delete Account</Button>
                                    </div>
                                </div>
                            </div>

                        </Card>     
                    </div>
                </RBACWrapper>

        </>
    )
}

General.defaultProps = {

}

export default General