import plan from "./ChooseplanBannerComponent.module.css"


function ChooseplanBannerComponent(Heading) {
  return (
    <>
    <div className={`${plan.choosePlan}`}>
         <span className={`${plan.title}`}>Chose a plan that fits your needs</span>
    </div>
    </>
  )
}

export default ChooseplanBannerComponent