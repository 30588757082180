import apmIcon from "assets/icons/config-items/apm.svg"
import vmIcon from "assets/icons/config-items/virtual-machine.svg"
import k8sClusterIcon from "assets/icons/config-items/kubernetes.svg"
import containerIcon from "assets/icons/config-items/container.svg"
import k8sControlIcon from "assets/icons/config-items/kubernetes-controller.svg"
import k8sPodIcon from "assets/icons/config-items/kubernetes-pod.svg"
import k8sServiceIcon from "assets/icons/config-items/kubernetes-service.svg"
import firewallIcon from "assets/icons/config-items/firewall.svg"
import diskIcon from "assets/icons/config-items/disk.svg"
import appGatewayIcon from "assets/icons/config-items/api-gateway.svg"
import lbIcon from "assets/icons/config-items/load-balancer.svg"
import nicIcon from "assets/icons/config-items/nic.svg"
import ipGroupIcon from "assets/icons/config-items/ip-group.svg"
import ipAddressIcon from "assets/icons/config-items/ip-address.svg"
import routeTableIcon from "assets/icons/config-items/route-table.svg"
import vnIcon from "assets/icons/config-items/virtual-network.svg"
import endpointIcon from "assets/icons/config-items/endpoint.svg"
import scalesetIcon from "assets/icons/config-items/scalesets.svg"
import dnsZone from "assets/icons/config-items/dns.svg"
import nsgIcon from "assets/icons/config-items/network-security-groups.svg"
import KeyvaultIcon from "assets/icons/config-items/key-vault.svg"
import containerRegistryIcon from "assets/icons/config-items/container-registries.svg"
import networkWatcherIcon from "assets/icons/config-items/network-watcher .svg"
import workspaceIcon from "assets/icons/config-items/workspace.svg"
import cacheIcon from "assets/icons/config-items/cache.svg"
import DBIcon from "assets/icons/config-items/database.svg"
import cloudStorageIcon from "assets/icons/config-items/cloud-storge.svg"
import siteIcon from "assets/icons/config-items/site.svg"
import defaultIcon from "assets/icons/config-items/placeholder.svg"


const onepaneTypes = {
  "default": {
    name: "no onepane type",
    icon: defaultIcon
  },
  "1": {
    name: "APM application",
    icon: apmIcon
  },
  "2": {
    name: "Virtual machine",
    icon: vmIcon
  },
  "3": {
    name: "Container",
    icon: containerIcon
  },
  "4": {
    name: "Kubernetes Cluster",
    icon: k8sClusterIcon
  },
  "5": {
    name: "Kubernetes Pod",
    icon: k8sPodIcon
  },
  "6": {
    name: "Kubernetes Controller",
    icon: k8sControlIcon
  },
  "7": {
    name: "Kubernetes Service",
    icon: k8sServiceIcon
  },
  "8": {
    name: "Firewall",
    icon: firewallIcon
  },
  "9": {
    name: "Disk",
    icon: diskIcon
  },
  "10": {
    name: "Application Gateway",
    icon: appGatewayIcon
  },
  "11": {
    name: "Load Balancer",
    icon: lbIcon
  },
  "12": {
    name: "NIC",
    icon: nicIcon
  },
  "13": {
    name: "IP groups",
    icon: ipGroupIcon
  },
  "14": {
    name: "IP Address",
    icon: ipAddressIcon
  },
  "15": {
    name: "Route Table",
    icon: routeTableIcon
  },
  "16": {
    name: "Virtual Network",
    icon: vnIcon
  },
  "17": {
    name: "Endpoint",
    icon: endpointIcon
  },
  "18": {
    name: "Scaleset",
    icon: scalesetIcon
  },
  "19": {
    name: "DNS Zone",
    icon: dnsZone
  },
  "20": {
    name: "Network Security Group",
    icon: nsgIcon
  },
  "21": {
    name: "Keyvault",
    icon: KeyvaultIcon
  },
  "22": {
    name: "Container Registry",
    icon: containerRegistryIcon
  },
  "23": {
    name: "Network watcher",
    icon: networkWatcherIcon
  },
  "24": {
    name: "Workspaces",
    icon: workspaceIcon
  },
  "25": {
    name: "Cache",
    icon: cacheIcon
  },
  "26": {
    name: "Database",
    icon: DBIcon
  },
  "27": {
    name: "Cloud storage",
    icon: cloudStorageIcon
  },
  "28": {
    name: "Site",
    icon: siteIcon
  },
};

export default onepaneTypes;