
import filterDownArrow from "./assets/filter-down-arrow.svg"
import Checkbox from "components/V1/Checkbox/Checkbox"
import style from "./Table.module.css"
import Button from "components/Elements/Button/Button"
const TableFilter = ({hasGroup, filteredData, filterData, onChecked, onClear, onApply})=> {


    const expandFilter = (e)=>{
        let ele = e.target
        let parentEle = ele.parentElement;
        if(parentEle.classList.contains(style.opTableFilterOptionHeader)){
            parentEle = ele.parentElement.parentElement
        }
        parentEle.classList.toggle(style.opTableFilterOptionParentOpen)
        
    }

    return(

        <>
                <div className={style.opTableFilterContainer}>
                    <div className={style.opTableFilterHeader}>
                        <div style={{flexGrow: "1", textAlign: "left"}}>
                            <span className={style.opTableFilterHeaderText}>Filter</span>
                        </div>
                        <div>
                            <span className={style.opTableFilterHeaderClearText} onClick={onClear}>Clear All</span>
                        </div>
                    </div>
                    <div className={`${style.opTableFilterOptionContainer} ${hasGroup ? "" :  `${style.opTableFilterNoCatOptionContainer}`}`}>
                    
                        {filterData?.map((item, index)=>{
                                
                                if(!Array.isArray(item.values)){
                                    return(
                                        <>
                                            <div key={index} className={style.opTableFilterSingleOptionValue}>
                                                <div className={style.opTableFilterOptionValueCheckbox}>
                                                    <Checkbox id={`filter-checkbox-${index}`} checked={filteredData[item.key] && filteredData[item.key].includes(item.values)}   onChange={(e)=>onChecked(e, {key: item.key, value:item.values })} />
                                                </div>
                                                <label className={style.opTableFilterOptionValueText} htmlFor={`filter-checkbox-${index}-${index}`}>{item.label}</label>
                                            </div>
                                        </>
                                    )
                                }
                                return(
                                        <div key={index} className={style.opTableFilterOptionParent}>
                                        <div onClick={expandFilter} className={style.opTableFilterOptionHeader}>
                                            <div className={style.opTableFilterOptionText}>{item.label}</div>
                                            <div className={style.opTableFilterOptionImg}><img src={filterDownArrow}/></div>
                                        </div>
                                        <div className={style.opTableFilterOptionValueContainer}>
                                        {
                                            item.values?.map((vItem, vIndex)=>{
                                                
                                                return(
                                                    <>
                                                        <div key={vIndex} className={style.opTableFilterOptionValue}>
                                                            <div className={style.opTableFilterOptionValueCheckbox}><Checkbox id={`filter-checkbox-${index}-${vIndex}`} checked={filteredData[item.key] && filteredData[item.key].includes(vItem.value)}   onChange={(e)=>onChecked(e, {key: item.key, value:vItem.value })} /></div>
                                                            <label className={style.opTableFilterOptionValueText} htmlFor={`filter-checkbox-${index}-${vIndex}`}>{vItem.label}</label>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                        </div>
                                    </div>
                                )
                        })}
                    </div>
                    <div className={style.opTableFilterActionContainer}>
                        <Button className={style.opTableFilterApplyButton} onClick={onApply}>Apply</Button>
                    </div>
                </div>
        
        
        </>

    )
}

TableFilter.defaultProps = {
    hasGroup : true,
    filteredData: {},
    filterData: [],
    onChecked : ()=>{},
    onClear: ()=>{}, 
    onApply: ()=>{}
}

export default TableFilter