// import {  Modal } from "react-bootstrap";
import './EditGithub.css';
import Modal from "../Modal/Modal";
import Button from "../../Elements/Button/Button";
import { BiErrorCircle, BiPencil } from "react-icons/bi";
import Select from "../../V1/Select/Select";

const EditGithub = ({ show, title, logo, description, repoType, branch, resources, className, ...props }) => {



    //console.log({props})
    return (
        <>
            <Modal show={show} style={{ width: "514px" }}>
                <Modal.Title>
                    <div className="content-padding">

                        <div className="headingDiv">
                            <div className='logoos'>
                                <BiPencil />
                            </div>
                            <div className='header'>
                                <span className="mainHeading">
                                    {title}
                                </span>
                                <span className="subHeading">
                                    {description}
                                </span>
                            </div>
                        </div>
                    </div>
                </Modal.Title>
                <Modal.Body>
                    <div>
                        <div className="Content">


                            <div className="formControls">
                                <label htmlFor="lname">Respository</label><span className="hint"><BiErrorCircle color="rgba(255, 153, 0, 0.40)" /></span><br />
                                <input type="text" id="lname" name="lname" defaultValue={props.resourceName} disabled />
                            </div>
                            <div className="formControls">
                                <label htmlFor="lname">Language</label><span className="hint"><BiErrorCircle color="rgba(255, 153, 0, 0.40)" /></span><br />
                                <input type="text" id="lname" name="lname" defaultValue={props.language} disabled />
                            </div>

                            <div className="formControls" style={{ marginTop: '20px' }}>
                                <Select label={"Type"} placeholder={"code"} options={repoType} disabled  hint={true} />
                            </div>
                            <div className="formControls" style={{ marginTop: '20px' }}>
                                <Select label={"Branch"} placeholder={"main"} options={branch} disabled hint={true} />
                            </div>
                            <div className="formControls" style={{ marginTop: '20px' }}>
                                <Select label={"Resource"} placeholder={"vm-onepane-demo-eastus-001"} options={resources}  hint={true} />
                            </div>

                        </div>
                        <div style={{ float: "right", marginTop: "40px" }}>
                            <Button type="danger" ButtonStyle="transparent" className="mx-3" onClick={() => props.onHide()}>Cancel</Button>
                            <Button onClick={() => props.onSave()} >Save</Button>

                        </div>


                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

EditGithub.defaultProps = {
    repoType:[],
    branch: [],
    resources:[]
}

export default EditGithub;