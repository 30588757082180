
import React, {  } from 'react';
import { ButtonToolbar, Col, FormControl, InputGroup } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import './InputSearchComponent.css';

const InputSearchComponent= (props) => {
    
  return (
        <>
            <Col lg={props.size} className={"ops-input-search-square mx-1"}>
                <ButtonToolbar className='b-0' >
                    <InputGroup className='ops-search-custom fc-font-mild-grey w-100'>
                        <InputGroup.Text>
                            <BsSearch />
                        </InputGroup.Text>
                        <FormControl
                            type="text"
                            placeholder={ props.placeholder }
                            aria-label={ props.placeholder }
                            className='f-12px ops-search-input'
                            onKeyUp={props.onKeyUp}
                            style={props.style}
                            {...props}
                        />
                    </InputGroup>
                </ButtonToolbar>
            </Col>
        </>
  );
}

InputSearchComponent.defaultProps = {
    placeholder: "Search",
    style:{},
    onKeyUp: ()=>{}
}

export default InputSearchComponent;


                        