

import "./PriceBadge.css"
import checkCircle from "./assets/check-circle.svg"

const Feature = ({children})=>{
    return(
        <>
            <div className="plan-feature">
                <div><img src={checkCircle}/></div>
                <div><p className="plan-feature-text">{children}</p></div>
            </div>
        </>
    )
}



const PriceBadge = ({id,name,vendor, type, description, price, active, disabled, freatures, onChooseClick, ...props})=> {

    const planData = {id, name, vendor, type, description, price,active, freatures}

    const chooseBtnClick = ()=>{
        onChooseClick(planData)
    }
    return(
        <>
            <div className={`price-badge ${active == true ? 'active': ""} ${type}`} {...props}>
                <div className="topper-section">
                    <div className="plan">
                        <div className="plan-icon">
                            <div className="icon-outer">
                                <div className="icon-inner"></div>
                            </div>
                        </div>
                        <div style={{marginLeft: '10px'}}>
                            <span className="plan-for">{vendor}</span>
                            <span className="plan-name">{name}</span>
                        </div>
                    </div>
                    <div className="plan-description">
                        <p>
                            {description}
                        </p>
                    </div>
                    <div className="plan-price">
                        <p>{disabled == true ? <span style={{fontSize: "20px", marginRight: "2px", display: "inline-block"}}>NA</span> : price}<span className="plan-period">/monthly</span></p>
                    </div>
                </div>
                <div className="bottom-section">
                    <div className="section">
                        <div className="plan-features">
                            <span className="plan-feature-heading">What's included</span>
                            {
                                freatures.map((item, index)=>{
                                    return(<Feature key={index}>{item}</Feature>)
                                })
                            }
                            <div className="plan-action">
                                <button className={`plan-choose ${disabled == true ? "disabled": ""}`} 
                                    onClick={ disabled == true ? ()=>{} : chooseBtnClick}>
                                    {disabled == true ? "Coming Soon": "Choose this Plan"} 
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default PriceBadge