import { useEffect, useState } from 'react';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import DashboardBody from '../../../pages/newlayout/DashboardBody';
import { RiTodoLine } from 'react-icons/ri';
import refreshIcon from "assets/icons/report/restart.svg"
import './ReportList.css';
import Card from '../../../components/Card/Card';
import Avatar from '../../../components/Avatar/Avatar';
import Table from '../../../components/Table/Table';
import { BiDownload, BiPrinter } from 'react-icons/bi';
import { useNotifications } from "reapop";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Button from '../../../components/Elements/Button/Button';
import { services } from '../../../assets/data';
import { MdOutlineLaunch } from 'react-icons/md';
import Moment from 'react-moment';
import GetService from 'services/GetServices';
import { API_URL } from 'config/config';
import SimpleToolTip from 'components/Tooltip/SimpleToolTip';
import { FiRefreshCcw } from "react-icons/fi";
import PostService from 'services/PostServices';


function ReportList() {



    const [policyDetails, setPolicyDetails] = useState({})
    const [policyResources, setPolicyResource] = useState([])
    const [policyResourcesTableInfo, setPolicyResourcesTableInfo] = useState({totalRows: 0 , currentPage: 1, loading: true, search: "", sort:[], filter :{}})

    
    const {reportId } = useParams()
    const {notify} = useNotifications()
    
    const showNotification = (text, status="success")=>{
        notify( text, status,{
            dismissAfter: 1500
        })
    }

   
    
    const getPolicyDetails = ()=>{
        GetService(API_URL + `/api/v1/policy/applied/${reportId}/get`).then(response=>{
            setPolicyDetails(response.data?.data?.policy)
        })
    }

    const getPolicyResource = (page = 1, search = "", sort = [], filter = {})=>{
        let searchJSON = JSON.stringify([{key:"name", value:search}])
        let sortJSON = JSON.stringify(sort)
        let filterJSON = JSON.stringify(filter)

        GetService(API_URL + `/api/v1/policy/applied/${reportId}/resources?current_page=${page}&search=${searchJSON}&sort=${sortJSON}&filter=${filterJSON}`).then(response=>{
            let data = response.data.data
            setPolicyResource(data.items)
            setPolicyResourcesTableInfo({...policyResourcesTableInfo, ...{loading: false, totalRows: data.total_count, currentPage:page}})
        })
    }
    

    const onPageChage = (page)=>{
        getPolicyResource(page)
    }

    const rescanPolicy = ()=>{
        ///policy/applied/:policyId/rescan
        PostService(API_URL + `/api/v1/policy/applied/${reportId}/rescan`).then(response=>{
            showNotification("Policy Rescan Started")
        })
    }

    useEffect(()=>{
        getPolicyDetails();
        getPolicyResource();

    },[])



    const columns = [
        {
            name: 'RESOURCE',
            sortable: true,
            selector: (row) => (
                <SimpleToolTip tooltip={row.name}>
                    <Link to={`/resources/${row.ci_id}/details`} className='link-decoration-none'>
                        <span className='ops-common-hover table-span'>{row.name}</span>
                    </Link>
                </SimpleToolTip>
                
            ),
            sortable: true,
            sortField: 'name'
        },
        {
            name: 'OWNER',
            selector: row=>{
                return(
                    row.tags?.Owner ? <Avatar username={row.tags?.Owner} imageUrl="" customText="Not assigned" /> : <span className="no-value-span">Not assigned</span>
                )
            },
            width: "250px",
        },
        {
            name: 'SERVICE',
            selector: (row) => (
                <SimpleToolTip tooltip={row.service.name}>
                     <Link to={`/services/${row.service_id}/details`} className='link-decoration-none'>
                    <div className='ops-common-hover table-span'>{row.service.name}</div>
                    </Link>
                </SimpleToolTip>
            ),
             width: "250px",
        },
       
        {
            name: 'DESCRIPTION',
            selector: (row) => {
                let data = row.policy_config_item_compliances;
                let message = ""
                if(data.length > 0){
                    message = data[0].compliance_json?.failed_reason ? data[0].compliance_json?.failed_reason : data[0].compliance_json?.complianceState
                }
                return(
                        <>
                        {data.length > 0 && (<>
                            <SimpleToolTip tooltip={message}>
                                {message} 
                            </SimpleToolTip>
                        </>)}
                        {data.length == 0 &&  <span className="no-value-span">not evaluated</span>  }
                        </>
                            
                )
                            
            },
            width: "200px",
        },
        {
            name: 'ACTION',
            selector: (row) => ( <div className='table-span'>
                 <Link to={`/resources/${row.ci_id}/details`} className='link-decoration-none'>
                    <MdOutlineLaunch fontSize={"16px"} color='#6a53af'  className='ops-common-button-hover'/>
                </Link>
            </div>),
            width: "100px",
            center: true,
        },
    ];


   

    const resourceTableTitle =  <>
                                <Row>
                                    <Col lg={8}>
                                        <div className='report-title py-3'> 
                                            <RiTodoLine color='#6B53AE' fontSize={24}  style={{ marginRight: "10px" }}/>
                                            Non Compliant Resource List
                                            <span style={{paddingLeft: "10px"}} className='report-date'><Moment format='DD-MMM-YYYY HH:MM' /></span> 
                                        </div>
                                        <div className='report-date pt-2'>List to Non Compliant Resource against the policy </div>
                                    </Col>
                                    <Col lg={4} style={{textAlign: "end"}} className='my-auto'>
                                        <Button onClick={rescanPolicy}  className="reportRefreshButton" > <img src={refreshIcon} style={{marginRight: "5px", marginTop: "-1px"}} /> Rescan Policy</Button> 
                                        
                                        <Link to={"/"}>
                                            <Button ButtonStyle="solid success" className="mx-3" disabled >
                                                <BiDownload fontSize={"16px"} style={{marginRight: "4px"}}/>
                                                Download
                                            </Button>
                                        </Link>
                                        <Link to={"/"}>
                                            <Button ButtonStyle="transparent" disabled> 
                                                <BiPrinter  fontSize={"16px"} style={{marginRight: "4px"}}/>
                                                Print
                                            </Button>
                                        </Link>
                                    </Col>
                                </Row>      
    </>

   
    const breadcrumbsAction = <>
        {/* <div className="">
            <Button onClick={rescanPolicy}><FiRefreshCcw color='' /> Rescan Policy</Button> 
        </div> */}
    </>


    return (
        <>
            <Breadcrumbs section={[{
                "name": "Policies",
                "path": "/reports"
            },{
                "name": "Report",
                "path": "/"
            }
            ]} actions={breadcrumbsAction} />


            <DashboardBody style={{ background: "#F4F6FC" }} >
                
                <Card type={"full"}  style={{marginTop:"20px", border: "0px"}}>
                        <Table 
                           
                            tableStyle={{marginTop: "0px"}} 
                            columns={columns} 
                            loading={policyResourcesTableInfo.loading} 
                            data={policyResources} 
                            control={false}
                            border={false}
                            title={resourceTableTitle}
                            currentPage={policyResourcesTableInfo.currentPage}
                            totalRows={policyResourcesTableInfo.totalRows}
                            onPageChage={onPageChage}
                            pagination={true}/>
                   
                </Card>
            </DashboardBody>
        </>
    );
}

export default ReportList;
