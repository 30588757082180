
import serviceIcon from "assets/icons/services/service-overview.svg"

import KeyValueLabel from "components/KeyValueLabel/KeyValueLabel"
import { useState, useEffect } from "react"

import ReactECharts from 'echarts-for-react'
import launch from "assets/icons/util/launch.svg"
import UserServiceAssign from "components/modals/UserServiceAssign/UserServiceAssign"
import moment from "moment"
import { Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import Avatar from "components/Avatar/Avatar"
import { getCriticalityByNumber, getServiceStatus } from "utils/util"
import { HiOutlineChartPie } from "react-icons/hi"


const Overview = ({ titleInfo, serviceTitleMenu, allowEdit, serviceData, resourceChart }) => {

    const [service, setService] = useState({})
    const [showUserAssignModal, setShowUserAssignModal] = useState(false)
    const [chartOpt, setChartOpt] = useState({})
  
   

   const serviceResourcesOpt = {
    tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    series: [
        {
            name: 'Resources per Service',
            type: 'pie',
            radius: ['80%'],
            avoidLabelOverlap: true,
            label: {
                show: false,
            },
            emphasis: {
                label: {
                    show: false,
                },
            },
        },
    ],
   } 

   

    useEffect(() => {
        //console.log({ json3: serviceData })
        setService(serviceData)
        serviceResourcesOpt.series[0].data = resourceChart;
        
        setChartOpt(serviceResourcesOpt)

    }, [serviceData, resourceChart])




    return (
        <>
            <div className="service-overview">
                <div className="service-title-container">
                    <div style={{ flexGrow: "1" }}>
                        <div className="service-overview-title">
                            <div>
                                <img src={serviceIcon} style={{ marginTop: "-4px" }} /> <span className="service-title">Service Details</span>
                            </div>
                            <div className="service-overview-title-more-info">
                               {titleInfo}
                            </div>
                        </div>
                        
                        
                    </div>
                    <div>
                        {serviceTitleMenu}
                    </div>
                    
                </div>
                <div>
                    <div className="service-details">

                        <Row>
                            <Col md={4} className="service-overview-pt-20">
                                <div>
                                    <KeyValueLabel label={"Service Name"} value={<span style={{ color: "#222127" }}>{service?.name}</span>} />
                                    <KeyValueLabel label={"Owner"} value={<>
                                        {service?.owner?.first_name ? <Avatar username={`${service?.owner?.first_name} ${service?.owner?.last_name }`} imageUrl="" /> : <span className="no-value-span">Not assigned</span> }
                                    </>} />
                                    <KeyValueLabel label={"Last Update"} value={<span style={{ color: "#A4A3A9" }}>{moment(service?.updated_at).fromNow()}</span>} />


                                    {/* <KeyValueLabel label={"Onepane Score"} value={<span style={{color: "#2DA077"}}>{service?.service_score}%</span>}/> */}
                                    <KeyValueLabel label={"Criticality"} value={ getCriticalityByNumber(service?.priority) }/>
                                    <KeyValueLabel label={"Last Change"} value={<span style={{ color: "#A4A3A9" }}>{ service?.last_change == 0 ? <span className="no-value-span">no last detected</span>: moment(service?.updated_at).fromNow()}</span>} />
                                    
                                </div>
                            </Col>
                            <Col md={4} className="service-overview-pt-20">
                                <div>
                                    <KeyValueLabel label={"Last Change"} value={<span style={{ color: "#A4A3A9" }}>{ service?.last_change == 0 ? <span className="no-value-span">Not Detected</span>: moment(service?.updated_at).fromNow()}</span>} />
                                    <KeyValueLabel label={"Health"} value={<>
                                         {getServiceStatus(service?.active)}
                                    </>} />
                                    <KeyValueLabel label={"Total Alerts"} value={<span style={{ color: "#222127" }}>{service?.total ?? 0}</span>} />
                                    <KeyValueLabel label={"Total Resources"} value={<span style={{ color: "#222127" }}>{service?.ci_count ?? 0}</span>} />

                                    <KeyValueLabel label={"Budget"} value={<span style={{ color: "#222127" }}>{parseFloat(service?.budget ?? 0).toFixed(2) ?? 0.00}</span>} />
                                    <KeyValueLabel label={"Cost"} value={<span style={{ color: "#222127" }}>{parseFloat(service?.total_cost ?? 0).toFixed(2) ?? 0.00}</span>} />
                                    {/* <KeyValueLabel label={"MTTD"} value={<span style={{color: "#222127"}}>{service?.avg_mttd ?? 0}</span>}/>
                                    <KeyValueLabel label={"MTTR"} value={<span style={{color: "#222127"}}>{service?.avg_mttr ?? 0}</span>}/> */}
                                </div>
                            </Col>
                            <Col className="" style={{borderLeft: "1px solid #edeaf7"}}>
                                
                                <Row className="service-overview-pt-20">
                                        <div>
                                            <span className='resource-chart-container-title'> <HiOutlineChartPie fontSize={24} color='#6B53AE' style={{marginRight: "3px"}} /> Resources per Environment</span>
                                        </div>
                                    <Col className="">
                                        
                                            <ReactECharts
                                                option={chartOpt}
                                                style={{ height: '240px' }}
                                            />
                                        </Col>
                                        <Col className="" style={{paddingTop: "80px"}}>
                                            <div style={{height: "100%", display: "flex",flexDirection: "column"}}>
                                                { chartOpt?.series && chartOpt?.series[0]?.data?.map((item, key)=>{
                                                    return (
                                                        <div className='resource-chart-legend'>
                                                            <span className='resource-chart-color-span' style={{background: item.itemStyle?.color}}></span>
                                                            <span className='resource-chart-legend-label'>{item.name}</span>
                                                            <span className=''>
                                                               <Link to={"/resources"}> <img src={launch} /> </Link> 
                                                            </span>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </Col>
                                </Row>
                            </Col>
                        </Row>

                    </div>
                </div>
            </div>



            <UserServiceAssign show={showUserAssignModal} serviceId={service?.service_id} onHide={() => setShowUserAssignModal(false)} />
        </>


    )


}

export default Overview