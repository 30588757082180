import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import DashboardBody from "../../pages/newlayout/DashboardBody";
import ChooseplanBannerComponent from '../../components/ChooseplanBannerComponent/ChooseplanBannerComponent';
import { Card } from 'react-bootstrap';
import Plancard from '../../components/Plancard/Plancard';
import Button from '../../components/Elements/Button/Button';
import HowToLink from '../../components/V1/HowToLink/HowToLink';
import "./SettingsPlan.css";


function SettingsPlan() {
    return (
        <>
            <Breadcrumbs section={["Settings"]} />
            <DashboardBody style={{ background: "#F4F6FC" }}>

                <div className="chooseplan">
                    <ChooseplanBannerComponent Heading={"Chose a plan that fits your needs"} />
                </div>

                <Card title={""} type={"full"} style={{ marginTop: '20px',minHeight:'762px' }}>
                    <div className="row" style={{ margin: "80px 61px 62px 61px" }}>
                        <div className="col-12 ">
                            <div className="row">
                                <div className="col-md-3">
                                    <Plancard choosePlanButton={true} features={[{ name: "For Startups", id: 1 }, { name: "Upto 5/Month Cloud spent", id: 2 }, { id: 3, name: "Get Started Free" }, { id: 4, name: "No Credit Card Required" }]} isMonthly={true} planPrice={0} planFor={"For Startups"} plan={"Startup"} description={"Free plan helps startups streamline operations with cloud and standards"} />
                                </div>
                                <div className="col-md-3">
                                    <Plancard choosePlanButton={true} features={[{ name: "For Small companies   ", id: 1 }, { name: "Upto 25/Month Cloud spent", id: 2 }, { id: 3, name: "30 Days Trial" }, { id: 4, name: "No Credit Card Required" }]} isMonthly={true} planPrice={200} planFor={"For Small Companies"} plan={"Growth"} description={"For Growth companies with additional features like Incident correlation"} />
                                </div>
                                <div className="col-md-3">
                                    <Plancard choosePlanButton={true} features={[{ name: "For Medium scale companies", id: 1 }, { name: "Upto 250K Cloud spent", id: 2 }, { id: 3, name: "30 Days Trial" }, { id: 4, name: "No Credit Card Required" }]} isMonthly={true} planPrice={1000} planFor={"For Medium Scale"} plan={"Professional"} description={"For Companies with more cloud usage and complex stack to be monitored"} />
                                </div>
                                <div className="col-md-3">
                                    <Plancard deployLink={true} contactusButton={true} choosePlanButton={false} features={[{ name: "Custom pricing", id: 1 }, { name: "Enhanced support", id: 2 }]} isMonthly={false} planPrice={false} planFor={"For Enterprise"} plan={"Enterprise"} description={"For Enterprises to use it large scale multi-tanant cloud setup"} />
                                </div>
                            </div>
                        </div>
                    </div>

                </Card>
                <Card type={"full"} style={{ marginTop: "20px" }}>
                    <Card.Body>
                        <div className="knowmore">
                            <div>
                                <HowToLink text={"Know More"} />
                            </div>
                            <div className="">
                                <Button type={"danger"} ButtonStyle="transparent">Cancel</Button>

                                <Button>Confirm</Button>
                            </div>
                        </div>
                    </Card.Body>

                </Card>
           
            </DashboardBody>

        </>
    )
}

export default SettingsPlan