


import { Col, Container, Row } from "react-bootstrap"
import onepaneLogo from "../../assets/logo/onepane-logo.png"
import InputComponent from "../../components/form/InputComponent/InputComponent"
import countries from "./countries.json"
import { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import BillingRules from "./BillingValidator"
import { API_URL } from "../../config/config"
import PostService from "../../services/PostServices"
import { useNotifications } from "reapop"



const getClass = (errors, touchedFields , field)=>{
    if(errors[field]){
        return "ops-input-text-invalid"
    }
    if (!errors[field] && touchedFields[field]){
        return "ops-input-text-valid"
    }
}

const SectionHeader = ({stage, subHeading})=> {
    return(
        <div className="create-organisation-status-header">
            <img className="logo" src={onepaneLogo} />
            <h2 className="heading">Create Organisation</h2>
            {/* <Timeline timelines={["Choose a plan", "Billing Form", "Payment"]} stage={stage} style={{margin: "auto", marginTop: "50px", width: "380px"}} /> */}
            {subHeading && <h3 className="sub-heading">{subHeading}</h3>}
        </div>
    )
}


const BillingAddress = ({formData, onPeviousClick, onNextClick} )=>{

    
    const [formSubmiting, setFormSubmiting] = useState(false)
    const { register, handleSubmit, watch, formState } = useForm({
        mode: "all",
    });

    const { errors, isValid, isSubmitting, touchedFields, submitCount } = formState


   
    const [states, setStates] = useState([])
    const [selectedState, setSelectedState] = useState(null)
    const [selectedCountry, setSelectedCountry] = useState(null)

    let countryList = countries.map(item=>{
        return { name:item.country, value:item.country }
    })
    let stateList = countries[0].states?.map(item=>{
        return { name:item, value:item }
    })
    
    const onCountryChange = (e)=>{
        setSelectedCountry(e)
        let tempStates = countries.find(item=>{
            if(item.country == e){
                return item.states
            }
            return false
        })
        let stateList = tempStates?.states?.map(item=>{
            console.log(item)
            return { name:item, value:item }
        })
        setStates(stateList)
    }

    const onStateChange = (e)=>{
        console.log({state:e})
        setSelectedState(e)
    }

    const onFormSubmit = (data)=>{
        setFormSubmiting(true)
        data["country"] = selectedState
        data["state"] = selectedCountry
        console.log({data})
        onNextClick(data, ()=>setFormSubmiting(false))
    }

    // console.log({formState})

    useEffect(()=>{
        setStates(stateList)
        setSelectedCountry(countryList[0].value)
        setSelectedState(stateList[0].value)
    },[])


    return(
        <div className="billing-section">
            <Container fluid className="create-org-container" >
                <Row>
                    
                    <Col lg={6} className="full-height offset-3" style={{paddingTop: "40px"}}>
                        <SectionHeader stage={2}/>
                        <div  style={{margin: "50px 120px 50px 120px"}}>
                            <form onSubmit={handleSubmit(onFormSubmit)} className={`op-form ${formState.isSubmitted === true ? "form-validated": ""}`}>
                                <Row>
                                    <Col>
                                        <InputComponent label="Organisation Name" defaultValue={formData.organisationName} className={getClass(errors, touchedFields, "organisationName")} error={errors.organisationName?.message} {...register("organisationName", BillingRules.OrganisationName)}  />
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col>
                                        <InputComponent label="Full Name" defaultValue={formData.fullname} className={getClass(errors, touchedFields, "fullname")} error={errors.fullname?.message} {...register("fullname", BillingRules.FullName)}  />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <InputComponent label="Email" defaultValue={formData.email} className={getClass(errors, touchedFields, "email")} error={errors.email?.message} {...register("email", BillingRules.email)} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <TelComponent  id="phone"  label="Phone" defaultValue={formData.phone} className={getClass(errors,touchedFields, "phone")}  error={errors.phone?.message} rules={{...register("phone", BillingRules.phone)}}  />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                       <InputComponent label={"Address 1"} defaultValue={formData.address1} className={getClass(errors, touchedFields, "address1")} error={errors.address1?.message} {...register("address1", BillingRules.address1)}  />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                       <InputComponent label={"Address 2"} defaultValue={formData.address2} className={getClass(errors,touchedFields, "address2")}  error={errors.address2?.message} {...register("address2", {})}  />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        
                                        <SelectComponent label={"Country"} search={true} options={countryList}  onChange={onCountryChange} defaultValue={(selectedCountry ?? formData.country )}   />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <SelectComponent label={"State"} search={true} options={states}  onChange={onStateChange} defaultValue={( selectedState ?? formData.state )}  />
                                    </Col>
                                    <Col>
                                       <InputComponent label={"Zip Code"} defaultValue={formData.zipcode} className={getClass(errors, touchedFields, "zipcode")} error={errors.zipcode?.message} {...register("zipcode", BillingRules.zipCode)}  />
                                    </Col>
                                </Row> */}
                                <Row style={{marginTop: '31px'}}>
                                   
                                    <Col lg={6} className="offset-2">
                                        <button className={`ops-btn ops-btn-main  ops-singup-btn ${(isValid == false || formSubmiting == true) ? 'ops-singup-btn-disabled' :""}`} style={{width: "140px", padding: "10px 0px", float: "right"}}  >
                                            Create 
                                        </button>

                                    </Col>
                                </Row>
                            </form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}



const CreateHostOrganisationLandscape = (props)=>{

   
    const [billingDetails, setBillingDetails] = useState({})
    // const [cardDetails, setCardDetails] = useState({})
    
    const { notify, dismissNotification } = useNotifications()

   
    const onProceedClick = (formData, callback = ()=>{})=>{
     
        console.log({formData})
        let reqBody = {
            // plan_id:    selectedPlan.id,
            org_name:  formData.organisationName,
            // full_name: billingDetails.fullname,
            // email: billingDetails.email,
            // phone: billingDetails.phone,
            // primary_address: billingDetails.address1,
            // secondary_address: billingDetails.address2,
            // country: billingDetails.country,
            // state: billingDetails.state,
            // zip_code: billingDetails.zipcode,
            // card_expiry: cardDetails.month + "/" + cardDetails.year,
            // card_cvv: cardDetails.cvv,
            // card_number: cardDetails.cardnumber.replaceAll(" ",""),
            // card_holder_name: cardDetails.cardHolderName,
        }
        PostService(API_URL + "/api/v1/base/organisation/create", reqBody).then(response=>{
            let resData = response.data;
            if(resData.success == false){
                if(resData.validation?.length > 0){
                    notify(resData.validation[0].Message?.replaceAll("_", " "),{dismissAfter: 2500 })
                }else{
                    notify(resData.description,{dismissAfter: 2500 })
                }
            }else{
                callback()
                props.navDisableUpdate(1, true)
            }


           
        })
    }



    return(
        <>
            <div className="organisation-creation--landscape">
                <BillingAddress selectedPlan={{}} formData={billingDetails}  onNextClick={onProceedClick} />
            </div>
        </>
        
    )


}



export default CreateHostOrganisationLandscape