var services = [
    {
        service_id : 1,
        name: "Online store",
        owner: {
            first_name: "Ashmil Hussain"
        },
        criticality: 1,
        health: 1,
        active_alerts: 2,
        total_alerts: 2,
        total_changes: 5,
        no_of_resources:8,
        created: "2023-09-10 15:21:01.774027+05:30",
        last_updated: "1 min ago"
    },
    {
        service_id : 2,
        name: "Payment service",
        owner: {
            first_name: "Ashmil Hussain"
        },
        criticality: 2,
        health: 1,
        active_alerts: 2,
        total_changes: 0,
        total_alerts: 2,
        no_of_resources:3,
        created: "2023-09-10 15:21:01.774027+05:30",
        last_updated: "1 min ago",
    },
    {
        service_id : 3,
        name: "Cart service",
        owner: {
            first_name: "Ashmil Hussain"
        },
        health: 1,
        criticality: 3,
        total_changes: 0,
        active_alerts: 2,
        total_alerts: 3,
        no_of_resources:3,
        created: "2023-09-10 15:21:01.774027+05:30",
        last_updated: "1 min ago"
    },
    {
        service_id : 4,
        name: "Email service",
        owner: {
            first_name: "Ashmil Hussain"
        },
        health: 1,
        criticality: 3,
        active_alerts: 2,
        total_changes: 0,
        total_alerts: 2,
        no_of_resources:2,
        created: "2023-09-10 15:21:01.774027+05:30",
        last_updated: "1 min ago"
    }]
    
    
    
    var resources = [
    {
        "id": 0,
        "name": "emailservice-hxnaz-assdsd87",
        "icon": "azure.png",
        "compliance": 1,
        "environment": "Prod",
        "owner": {
            first_name: "Ashmil Hussain"
        },
        "service": "Email Service",
        "criticality": 3,
        "total_alerts": 1,
        "last_update": "1 minute ago",
        "health": 1,
        "cloud_service": "Kubernetes pod",
        "cloud_tenant": [{ 
            "name": "EmailService",
            "provider": 1,
         }],
        "apm": [{ 
            "name": "EmailService",
            "provider": 2,
         }],
         "type": "Pod",
         "monitoring": [{ 
            "name": "EmailService",
            "provider": 1,
         },{ 
            "name": "EmailService",
            "provider": 2
         }],
         "repository": [{ 
            "name": "onepaneai/emailservice",
            "provider": 3,
         }]
    
    },
    {
        "id": 1,
        "name": "vm-onepane-demo-001",
        "icon": "azure.png",
        "environment": "Prod",
        "compliance": 1,
        "owner": {
            first_name: "Ashmil Hussain"
        },
        "service": "Payment Service",
        "criticality": 3,
        "total_alerts": 1,
        "last_update": "1 minute ago",
        "health": 1,
        "cloud_service": "Virutal machine",
        "cloud_tenant": [{ 
            "name": "vm-onepane-demo-001",
            "provider": 1,
         }],
        "apm": [],
        "type": "Virtual machine",
         "monitoring": [{ 
            "name": "vm-onepane-demo-001",
            "provider": 1,
         }],
         "repository": []
    
    },
    {
        "id": 2,
        "name": "vm-onepane-demo-002",
        "icon": "azure.png",
        "environment": "Prod",
        "compliance": 1,
        "owner": {
            first_name: "Ashmil Hussain"
        },
        "service": "Cart Service",
        "criticality": 3,
        "total_alerts": 1,
        "last_update": "1 minute ago",
        "health": 1,
        "cloud_service": "Virutal machine",
        "cloud_tenant": [{ 
            "name": "vm-onepane-demo-002",
            "provider": 1,
         }],
        "apm": [],
         "type": "Virtual machine",
         "monitoring": [{ 
            "name": "vm-onepane-demo-002",
            "provider": 1,
         }],
         "repository": []
    
    },
    {
        "id": 3,
        "name": "vm-onepane-demo-n-003",
        "icon": "azure.png",
        "compliance": 0,
        "compliance_json": {
            "message": "Naming compliance failed for the resource. Please change name of the resource adhering to policy",
            "fields": {
                "name": {"message": "Name doesn't follow the naming conventions", policy: 1}
            },
            "policies": [1],
        },
        "environment": "Prod",
        "owner": {
            first_name: "Ashmil Hussain"
        },
        "service": "Online Store",
        "criticality": 3,
        "total_alerts": 1,
        "last_update": "1 minute ago",
        "health": 1,
        "cloud_service": "Virutal machine",
        "cloud_tenant": [{ 
            "name": "vm-onepane-demo-003",
            "provider": 1,
         }],
        "apm": [],
         "type": "Virtual machine",
         "monitoring": [{ 
            "name": "vm-onepane-demo-003",
            "provider": 1,
         }],
         "repository": []
    
    },
    {
        "id": 4,
        "name": "Emailservice",
        "compliance": 1,
        "icon": "newrelic.png",
        "environment": "Prod",
        "owner": {
            first_name: "Ashmil Hussain"
        },
        "service": "Email Service",
        "criticality": 3,
        "total_alerts": 1,
        "last_update": "1 minute ago",
        "health": 1,
        "cloud_service": "Kubernetes deployment",
        "cloud_tenant": [{ 
            "name": "Emailservice",
            "provider": 1,
         }],
        "apm": [{ 
            "name": "Emailservice",
            "provider": 2,
         }],
         "type": "Controller",
         "monitoring": [{ 
            "name": "Emailservice",
            "provider": 1,
         },{ 
            "name": "Emailservice",
            "provider": 2
         }],
         "repository": []
    },
    {
        "id": 5,
        "name": "paymentservice-sadcacsa-asxsdsd878",
        "icon": "newrelic.png",
        "compliance": 1,
        "environment": "Prod",
        "owner": {
            first_name: "Ashmil Hussain"
        },
        "service": "Payment Service",
        "criticality": 3,
        "total_alerts": 1,
        "last_update": "1 minute ago",
        "health": 1,
        "cloud_service": "Kubernetes pod",
        "cloud_tenant": [{ 
            "name": "paymentservice-sadcacsa-asxsdsd878",
            "provider": 1,
         }],
        "apm": [{ 
            "name": "paymentservice-sadcacsa-asxsdsd878",
            "provider": 2,
         }],
         "type": "Pod",
         "monitoring": [{ 
            "name": "paymentservice-sadcacsa-asxsdsd878",
            "provider": 1,
         },{ 
            "name": "paymentservice-sadcacsa-asxsdsd878",
            "provider": 2
         }],
         "repository": [{ 
            "name": "onepaneai/paymentservice",
            "provider": 3,
         }]
    
    },
    {
        "id": 6,
        "name": "Paymentservice",
        "icon": "newrelic.png",
        "compliance": 1,
        "environment": "Prod",
        "owner": {
            first_name: "Ashmil Hussain"
        },
        "service": "Payment service",
        "criticality": 3,
        "total_alerts": 1,
        "last_update": "1 minute ago",
        "health": 1,
        "cloud_service": "Kubernetes deployment",
        "cloud_tenant": [{ 
            "name": "Paymentservice",
            "provider": 1,
         }],
        "apm": [{ 
            "name": "Paymentservice",
            "provider": 2,
         }],
         "type": "Controller",
         "monitoring": [{ 
            "name": "Paymentservice",
            "provider": 1,
         },{ 
            "name": "Paymentservice",
            "provider": 2
         }],
         "repository": []
    },
    {
        "id": 7,
        "name": "cartservice-adsds-sdssdc12",
        "icon": "azure.png",
        "environment": "Prod",
        "compliance": 1,
        "owner": {
            first_name: "Ashmil Hussain"
        },
        "service": "Cart Service",
        "criticality": 3,
        "total_alerts": 1,
        "last_update": "1 minute ago",
        "health": 1,
        "cloud_service": "Kubernetes pod",
        "cloud_tenant": [{ 
            "name": "cartservice-adsds-sdssdc12",
            "provider": 1,
         }],
        "apm": [{ 
            "name": "cartservice-adsds-sdssdc12",
            "provider": 2,
         }],
         "type": "Pod",
         "monitoring": [{ 
            "name": "cartservice-adsds-sdssdc12",
            "provider": 1,
         },{ 
            "name": "cartservice-adsds-sdssdc12",
            "provider": 2
         }],
         "repository": [{ 
            "name": "onepaneai/cartservice",
            "provider": 3,
         }]
    
    },
    {
        "id": 8,
        "name": "Cartservice",
        "icon": "newrelic.png",
        "environment": "Prod",
        "compliance": 1,
        "owner": {
            first_name: "Ashmil Hussain"
        },
        "service": "Cart Service",
        "criticality": 3,
        "total_alerts": 1,
        "last_update": "1 minute ago",
        "health": 1,
        "cloud_service": "Kubernetes deployment",
        "cloud_tenant": [{ 
            "name": "Cartservice",
            "provider": 1,
         }],
        "apm": [{ 
            "name": "Cartservice",
            "provider": 2,
         }],
         "type": "Controller",
         "monitoring": [{ 
            "name": "Cartservice",
            "provider": 1,
         },{ 
            "name": "Cartservice",
            "provider": 2
         }],
         "repository": []
    },
    {
        "id": 9,
        "name": "frontend-sdsdsdds-assdsd87",
        "icon": "azure.png",
        "environment": "Prod",
        "compliance": 1,
        "owner": {
            first_name: "Ashmil Hussain"
        },
        "service": "Online Store",
        "criticality": 3,
        "total_alerts": 1,
        "last_update": "1 minute ago",
        "health": 1,
        "cloud_service": "Kubernetes pod",
        "cloud_tenant": [{ 
            "name": "frontend-sdsdsdds-assdsd87",
            "provider": 1,
         }],
        "apm": [{ 
            "name": "frontend-sdsdsdds-assdsd87",
            "provider": 2,
         }],
         "type": "Pod",
         "monitoring": [{ 
            "name": "frontend-sdsdsdds-assdsd87",
            "provider": 1,
         },{ 
            "name": "frontend-sdsdsdds-assdsd87",
            "provider": 2
         }],
         "repository": [{ 
            "name": "onepaneai/frontend",
            "provider": 3,
         }]
    
    },
    {
        "id": 10,
        "name": "Frontend",
        "icon": "newrelic.png",
        "environment": "Prod",
        "compliance": 1,
        "owner": {
            first_name: "Ashmil Hussain"
        },
        "service": "Online Store",
        "criticality": 3,
        "total_alerts": 1,
        "last_update": "1 minute ago",
        "health": 1,
        "cloud_service": "Kubernetes deployment",
        "cloud_tenant": [{ 
            "name": "Frontend",
            "provider": 1,
         }],
        "apm": [{ 
            "name": "Frontend",
            "provider": 2,
         }],
         "type": "Controller",
         "monitoring": [{ 
            "name": "Frontend",
            "provider": 1,
         },{ 
            "name": "Frontend",
            "provider": 2
         }],
         "repository": []
    },
    {
        "id": 11,
        "name": "Frontend",
        "environment": "Dev",
        "compliance": 1,
        "owner": {
            first_name: "Ashmil Hussain"
        },
        "service": "Online Store",
        "criticality": 3,
        "total_alerts": 0,
        "last_update": "1 minute ago",
        "health": 1,
        "cloud_service": "Kubernetes deployment",
        "cloud_tenant": [{ 
            "name": "Frontend",
            "provider": 1,
         }],
        "apm": [{ 
            "name": "Frontend",
            "provider": 2,
         }],
         "type": "Controller",
         "monitoring": [{ 
            "name": "Frontend",
            "provider": 1,
         },{ 
            "name": "Frontend",
            "provider": 2
         }],
         "repository": []
    
    },
    {
        "id": 12,
        "name": "frontend-hxnaz-assdsd87",
        "environment": "Dev",
        "compliance": 1,
        "owner": {
            first_name: "Ashmil Hussain"
        },
        "service": "Online Store",
        "criticality": 3,
        "total_alerts": 0,
        "last_update": "1 minute ago",
        "health": 1,
        "cloud_service": "Kubernetes pod",
        "cloud_tenant": [{ 
            "name": "frontend-hxnaz-assdsd87",
            "provider": 1,
         }],
        "apm": [{ 
            "name": "frontend-hxnaz-assdsd87",
            "provider": 2,
         }],
         "type": "Pod",
         "monitoring": [{ 
            "name": "frontend-hxnaz-assdsd87",
            "provider": 1,
         },{ 
            "name": "frontend-hxnaz-assdsd87",
            "provider": 2
         }],
         "repository": [{ 
            "name": "onepaneai/frontend",
            "provider": 3,
         }]
    },
    {
        "id": 13,
        "name": "Backend",
        "environment": "Debug",
        "compliance": 0,
        "compliance_json": {
            "message": "Tagging compliance failed for the resource. Please change the tag according to the convention.",
            "fields": {
                "environment": {"message": "Please use a tag that is allowed in the convention", policy: 2}
            },
            "policies": [2],
        },
        "owner": {
            first_name: "Ashmil Hussain"
        },
        "service": "Online Store",
        "criticality": 3,
        "total_alerts": 0,
        "last_update": "1 minute ago",
        "health": 1,
        "cloud_service": "Kubernetes deployment",
        "cloud_tenant": [{ 
            "name": "Backend",
            "provider": 1,
         }],
        "apm": [{ 
            "name": "Backend",
            "provider": 2,
         }],
         "type": "Controller",
         "monitoring": [{ 
            "name": "Backend",
            "provider": 1,
         },{ 
            "name": "Backend",
            "provider": 2
         }],
         "repository": []
    
    },
    {
        "id": 14,
        "name": "backend-adsdd-assdsd87",
        "environment": "Prod",
        "compliance": 1,
        "owner": {
            first_name: "Ashmil Hussain"
        },
        "service": "Online Store",
        "criticality": 3,
        "total_alerts": 0,
        "last_update": "1 minute ago",
        "health": 1,
        "cloud_service": "Kubernetes pod",
        "cloud_tenant": [{ 
            "name": "backend-adsdd-assdsd87",
            "provider": 1,
         }],
        "apm": [{ 
            "name": "backend-adsdd-assdsd87",
            "provider": 2,
         }],
         "type": "Pod",
         "monitoring": [{ 
            "name": "backend-adsdd-assdsd87",
            "provider": 1,
         },{ 
            "name": "backend-adsdd-assdsd87",
            "provider": 2
         }],
         "repository": [{ 
            "name": "onepaneai/backend",
            "provider": 3,
         }]
    },
    {
        "id": 15,
        "name": "Online boutique",
        "environment": "Debug",
        "compliance": 0,
        "compliance_json": {
            "message": "Naming & Tagging compliance failed for the resource. Please change name of the resource adhering to policy.",
            "fields": {
                "name": {"message": "Name doesn't follow the naming conventions", policy: 1},
                "environment": {"message": "Please use a tag that is allowed in the convention", policy: 2},
            },
            "policies": [1,2],
        },
        "owner": {
            first_name: "Ashmil Hussain"
        },
        "service": "Online Store",
        "criticality": 3,
        "total_alerts": 0,
        "last_update": "1 minute ago",
        "health": 1,
        "cloud_service": "Kubernetes cluster",
        "cloud_tenant": [{ 
            "name": "Online boutique",
            "provider": 1,
         }],
        "apm": [{ 
            "name": "Online boutique",
            "provider": 2,
         }],
         "type": "Cluster",
         "monitoring": [{ 
            "name": "Online boutique",
            "provider": 1,
         },{ 
            "name": "Online boutique",
            "provider": 2
         }],
         "repository": []
    }

]
    
    
var  alerts =[
    
    {
        id: "OP-IN-00001",
        title: 'CPU usage is high',
        resource: 'emailservice-hxnaz-assdsd87',
        type: 'Pod',
        provider: "azure.png",
        link: "https://portal.azure.com/#resource/subscriptions/fec00d93-2543-4aac-b35d-5581d1c5e053/resourceGroups/azureapp-auto-alerts-745a3b-ashmil_onepane_ai/providers/microsoft.insights/activityLogAlerts/Service%20Health%20issue%20in%20'Microsoft%20Azure%20Sponsorship'",
        provider_name: "Onepane Azure",
        service_id: 4,
        status: 'Open',
        time: '2023-09-11 15:21:01.774027+05:30',
    },
    {
        id: "OP-IN-00002",
        title: 'Deployment is not having enough enough pods',
        resource: 'EmailService',
        service_id: 4,
        type: 'Controller',
        provider: "newrelic.png",
        link: "https://portal.azure.com/#resource/subscriptions/fec00d93-2543-4aac-b35d-5581d1c5e053/resourceGroups/azureapp-auto-alerts-745a3b-ashmil_onepane_ai/providers/microsoft.insights/activityLogAlerts/Service%20Health%20issue%20in%20'Microsoft%20Azure%20Sponsorship'",
        provider_name: "Onepane NewRelic",
        status: 'Open',
        time: '2023-09-11 15:20:01.774027+05:30',
    },
    {
        id: "OP-IN-00003",
        title: 'CPU usage is high',
        resource: 'cartservice-adsds-sdssdc12',
        type: 'Pod',
        provider: "newrelic.png",
        link: "https://portal.azure.com/#resource/subscriptions/fec00d93-2543-4aac-b35d-5581d1c5e053/resourceGroups/azureapp-auto-alerts-745a3b-ashmil_onepane_ai/providers/microsoft.insights/activityLogAlerts/Service%20Health%20issue%20in%20'Microsoft%20Azure%20Sponsorship'",
        provider_name: "Onepane NewRelic",
        service_id: 3,
        status: 'Open',
        time: '2023-09-11 15:21:01.774027+05:30',
    },
    {
        id: "OP-IN-00004",
        title: 'Deployment is not having enough enough pods',
        resource: 'CartService',
        service_id: 3,
        type: 'Controller',
        provider: "azure.png",
        provider_name: "Onepane Azure",
        link: "https://portal.azure.com/#resource/subscriptions/fec00d93-2543-4aac-b35d-5581d1c5e053/resourceGroups/azureapp-auto-alerts-745a3b-ashmil_onepane_ai/providers/microsoft.insights/activityLogAlerts/Service%20Health%20issue%20in%20'Microsoft%20Azure%20Sponsorship'",
        status: 'Open',
        time: '2023-09-11 15:20:01.774027+05:30',
    },
    {
        id: "OP-IN-00005",
        title: 'Number of pods in ready phase is less than required',
        resource: 'Paymentservice',
        type: 'Controller',
        provider: "azure.png",
        provider_name: "Onepane Azure",
        service_id: 2,
        link: "https://portal.azure.com/#resource/subscriptions/fec00d93-2543-4aac-b35d-5581d1c5e053/resourceGroups/azureapp-auto-alerts-745a3b-ashmil_onepane_ai/providers/microsoft.insights/activityLogAlerts/Service%20Health%20issue%20in%20'Microsoft%20Azure%20Sponsorship'",
        status: 'Open',
        time: '2023-09-11 15:21:01.774027+05:30',
    },
    {
        id: "OP-IN-00006",
        title: 'Pod is not in healthy state',
        resource: 'paymentservice-sadcacsa-asxsdsd878',
        type: 'Pod',
        provider: "newrelic.png",
        provider_name: "Onepane NewRelic",
        service_id: 2,
        link: "https://portal.azure.com/#resource/subscriptions/fec00d93-2543-4aac-b35d-5581d1c5e053/resourceGroups/azureapp-auto-alerts-745a3b-ashmil_onepane_ai/providers/microsoft.insights/activityLogAlerts/Service%20Health%20issue%20in%20'Microsoft%20Azure%20Sponsorship'",
        status: 'Open',
        time: '2023-09-11 15:21:01.774027+05:30',
    },
    {
        id: "OP-IN-00007",
        title: 'Response time anomaly detected in frontend serice',
        resource: 'Frontend',
        type: 'Deployment',
        provider: "newrelic.png",
        provider_name: "Onepane NewRelic",
        link: "https://portal.azure.com/#resource/subscriptions/fec00d93-2543-4aac-b35d-5581d1c5e053/resourceGroups/azureapp-auto-alerts-745a3b-ashmil_onepane_ai/providers/microsoft.insights/activityLogAlerts/Service%20Health%20issue%20in%20'Microsoft%20Azure%20Sponsorship'",
        status: 'Open',
        time: '2023-09-11 15:22:01.774027+05:30',
    },
    {
        id: "OP-IN-00008",
        title: 'Number of pods in ready phase is less than required',
        resource: 'Frontend',
        type: 'Controller',
        provider: "newrelic.png",
        provider_name: "Onepane NewRelic",
        link: "https://portal.azure.com/#resource/subscriptions/fec00d93-2543-4aac-b35d-5581d1c5e053/resourceGroups/azureapp-auto-alerts-745a3b-ashmil_onepane_ai/providers/microsoft.insights/activityLogAlerts/Service%20Health%20issue%20in%20'Microsoft%20Azure%20Sponsorship'",
        status: 'Open',
        time: '2022-09-22 04:30:51',
    },
    {
        id: "OP-IN-00009",
        title: 'Memory pressure is high',
        resource: 'cartservice-adsds-sdssdc12',
        link: "https://portal.azure.com/#resource/subscriptions/fec00d93-2543-4aac-b35d-5581d1c5e053/resourceGroups/azureapp-auto-alerts-745a3b-ashmil_onepane_ai/providers/microsoft.insights/activityLogAlerts/Service%20Health%20issue%20in%20'Microsoft%20Azure%20Sponsorship'",
        type: 'Pod',
        provider: "azure.png",
        provider_name: "Onepane Azure",
        service_id: 3,
        status: 'Closed',
        time: '2023-09-11 15:21:01.774027+05:30',
    }]
    
    
    var  changes = [ 
    {
        title: 'Updated frontend with latest packges',
        resource: 'onepaneai/frontend',
        provider: 3,
        provider_name: "Onepane Github",
        icon: "github.png",
        time: '2023-09-11 15:21:01.774027+05:30',
    },
    {
        title: 'Fixed an issue in frontend where page was not loading',
        resource: 'onepaneai/frontend',
        provider: 3,
        icon: "github.png",
        time: '2023-09-11 15:21:01.774027+05:30',
    },
    {
        title: 'Updated Readme.Md',
        resource: 'onepaneai/frontend',
        provider: 3,
        icon: "github.png",
        time: '2023-09-11 15:21:01.774027+05:30',
    },
    {
        title: 'Fixed an issue in Backend where error was not catched',
        resource: 'onepaneai/backend',
        provider: 3,
        icon: "github.png",
        time: '2023-09-11 15:21:01.774027+05:30',
    },
    {
        title: 'Updated Backend code with database connection',
        resource: 'onepaneai/backend',
        provider: 3,
        icon: "github.png",
        time: '2023-09-11 15:21:01.774027+05:30',
    },
    {
        title: 'Updated Readme.Md',
        resource: 'onepaneai/backend',
        provider: 3,
        icon: "github.png",
        time: '2023-09-11 15:21:01.774027+05:30',
    },
    ]
    
    
    var repositories= [
        {
            repository:"onepaneai/paymentservice",
            language: "Go",
            type: "code",
            branch: "main",
            resource:"paymentservice-sadcacsa-asxsdsd878",
    
        },
        {
            repository:"onepaneai/cartservice",
            language: "Go",
            type: "code",
            branch: "main",
            resource: "cartservice-adsds-sdssdc12",
    
        },
        {
            repository:"onepaneai/backend",
            language: "golang",
            type: "code",
            branch: "main",
            resource: "backend-adsdd-assdsd87",
        },
        {
            repository:"onepaneai/frontend",
            language: "Javascript",
            type: "code",
            branch: "main",
            resource: "frontend-sdsdsdds-assdsd87",
        },
    ]



export { services, resources, alerts, changes, repositories }