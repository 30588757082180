import Footer from "./Footer"
import Header from "./Header"
import style from  "./style.module.css"

const GeneralLayout = (props)=>{


    return(
        <>
        <div className={`dashboardBodyLoader`}>
            <span className={`dashboardBodySpinner full-screen`}></span>
        </div>
        <div className={`${style.layoutFullHieght}  ${props.className}`}>
            {props.header && <Header showProfile={props.showProfile} user={props.user} org={props.org} orgList={props.orgList} enableOrganisation={props.enableOrganisation}/>}
            <div className={`
                ${(props.header && props.footer) ? `${style.layoutFixedHieght} op-scrollbar` : ''}  
                ${(props.header && !props.footer) ? style.headerOnlyLayout : ''} 
                ${(!props.header && props.footer) ? style.footerOnlyLayout : ''} 
                ${ props.showProfile ? style.layoutFixedHieghtWithProfile : ""}
            `} >
                {props.children}
            </div>
            {props.footer && <Footer/>}
        </div>
        </>
    )
}

GeneralLayout.defaultProps = {
    header: true,
    footer: true,
    fixedHieght: false,
    className: "",
    showProfile: false,
    enableOrganisation: true
}


export default GeneralLayout