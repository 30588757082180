
import OPFullLogo from "../../assets/logo/full-logo.svg"
import OPLogo from "../../assets/logo/onepane-logo.svg"

import style from "./Dashboard.module.css"
import { NavLink } from "react-router-dom"
import { SideBarMenu, SideBarBottomMenu } from "./SideBarRoute"
import { RiHeadphoneLine, RiLogoutCircleLine } from "react-icons/ri"


const Sidebar = ({ expand }) => {


    const onDisableMenuClick = (e) => {
        e.PreventDefault()
    }

    const showToolTip = (e) => {
        e.stopPropagation();
        let tooltipId = e.target.dataset.tooltip;
        if (tooltipId) {
            let toolTip = document.querySelector("." + tooltipId)
            toolTip.style.top = (e.clientY  - e.nativeEvent.offsetY + 8) + "px";
        }
    }

    return (
        <>
            <div className={`${style.sidebarContainer}`}>
                <img src={expand ? OPFullLogo : OPLogo} className={expand ? style.sidebarExpandLogo : style.sidebarCollapseLogo} />

            </div>
            <div className={`${style.sideMenuContainer} op-scrollbar`}>
                <div className={`${style.menuContainer} ${style.topMenu}`}>
                    <span className={style.menuTitle}>main menu</span>
                    <div className={style.menus}>
                        <div className={style.sidebarMenu}>
                            {
                                SideBarMenu.map((item, index) => {
                                    return (
                                        <>
                                            <NavLink to={item.url} key={index} onClick={(e) => item.disabled ? e.preventDefault() : ""}

                                                className={item.disabled ? style.disabledMenu : ""}>
                                                <div>

                                                    <div className={`${style.menuItem} `}>
                                                        <div className={style.menuIcon} data-tooltip={`menu-tooltip-${index}`} onMouseOver={showToolTip}>
                                                            {item.icon}
                                                        </div>
                                                        <div className={style.menuItemTitle}>
                                                            {item.title}
                                                        </div>
                                                        {expand == false && (<div className={`${style.menutooltip} menu-tooltip-${index}`}>{item.title}</div>)}

                                                    </div>


                                                </div>
                                            </NavLink>
                                        </>

                                    )
                                })
                            }
                        </div>
                    </div>
                </div>


                <div className={`${style.menuContainer} ${style.bottomMenu}`}>
                    <span className={style.menuTitle}>Support</span>
                    <div className={style.menus}>
                        {/* <RiHeadphoneLine {...sideMenuIconProps} /> */}
                        
                            
                       
                        <div className={style.sidebarMenu}>
                            <a id="onepane_help" href="mailto:HELP@YOUR-APP.COM">
                                <div className={style.menuItem}>
                                    <div className={style.menuIcon}>
                                        <RiHeadphoneLine  size="24px" style={{ color:"#79787D", size:"24px" }}/>
                                    </div>
                                    <div className={style.menuItemTitle}>
                                        {"Support"}
                                    </div>
                                </div>
                            </a>
                            {
                               
                                SideBarBottomMenu.map((item, index) => {
                                    return (
                                        <NavLink to={item.url} key={index} onClick={item.disabled ? onDisableMenuClick : item.onClick ? item.onClick : ""} className={item.disabled ? style.disabledMenu : ""}>
                                            <div className={style.menuItem}>
                                                <div className={style.menuIcon}>
                                                    {item.icon}
                                                </div>
                                                <div className={style.menuItemTitle}>
                                                    {item.title}
                                                </div>
                                            </div>
                                        </NavLink>

                                    )
                                })
                            }
                            <div>
                                <div style={{cursor: "pointer"}} className={style.menuItem} onClick={ () => {
                                                    window.location = "/iam/wso2/logout"
                                    }}>
                                    <div className={style.menuIcon}>
                                        <RiLogoutCircleLine  color="#6B53AE" size="24px"/>
                                    </div>
                                    <div className={style.menuItemTitle}>
                                        {"Support"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sidebar