
import { forwardRef } from "react"
import style from "./Dashboard.module.css"
import ConfirmDialog from "utils/ConfirmDialog"
import PostService from "services/PostServices"
import clearDataImg from "assets/icons/dashboard-layout/clear-data.svg"
import { useNotifications } from "reapop";
import { API_URL } from '../../config/config'
import Button from "components/Elements/Button/Button"
import RBACWrapper from "components/RBACWrapper/RBACWrapper"

const DashboardBody = forwardRef (({className, children, ...props}, ref ) => {

   
    const {notify} = useNotifications()

    const showNotification = (text, status="success")=>{
        notify( text, status,{
            dismissAfter: 1500
        })
    }

    const upgrade = ()=>{
        ConfirmDialog("Upgrading will create a new organization","Clear Data",()=>{
            
            PostService(API_URL + "api/v1/base/organisation/kind/upgrade").then(response=>{
                let data = response.data
                if(data.success == true){
                    window.location.href = "/overview"
                }else{
                    showNotification("Error while Creating Organization")
                }
            })
        })
       
    }


    return(
        <>
             

            <div className={`dashboardBodyLoader`}>
                <span className={`dashboardBodySpinner`}></span>
            </div>
            <div id='dashboardFade' className={`${style.dashboardFade}`}></div>
             
            <div ref={ref} id="dashboardBody" className={`${style.dashboardBody} ${className}`} {...props}>
                
                 <RBACWrapper action={"DEMO_USER"}>
                    <div className={style.dashboardDemoBanner}>
                        <p className={style.dashboardDemoBannerContent}>
                            Your demo organization will expire in 14 days. To continue with the free trial, please clear dummy data
                            <Button type="danger" onClick={upgrade} style={{marginLeft: "27px"}}> Clear Data <img src={clearDataImg} style={{marginTop:"-4px", marginLeft: "5px"}}/></Button> 
                        </p>
                    </div> 
                </RBACWrapper>
                <div style={{padding: "20px"}}>
                    {children}
                </div>
               
            </div>
        </>
    )

})

DashboardBody.defaultProps = {
    className: ""
}

export default DashboardBody