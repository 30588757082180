import { Link, useSearchParams } from "react-router-dom";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Table from "components/Table/Table";
import DashboardBody from "pages/newlayout/DashboardBody";
import Avatar from "components/Avatar/Avatar";
import { useNavigate } from "react-router-dom";
import "./Resources";
import Button from "components/Elements/Button/Button";
import settingsIcon from "assets/icons/util/white-settings.svg";
import { useEffect, useState } from "react";

import { getCriticalityStatus, getENVStatus } from "utils/util";
import SimpleToolTip from "components/Tooltip/SimpleToolTip";
import { getResourceBaseData, loadResources, parseDataForFilter } from "./ResourceUtil";
import { getOnepaneTypeCIIcon } from "utils/OnepaneType";
import RBACWrapper from "components/RBACWrapper/RBACWrapper";

const Resources = ()=> {

    const [resources, setResources] = useState([])
    const [tableInfo, setTableInfo] = useState({totalRows: 0 , currentPage: 1, loading: true, search: "", sort:[], filter :{}})
    const [filterData, setFilterData] = useState([])
    let timeout;
    
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const gotoService = (seriviceId, hasService) => {
            if(hasService){
                navigate("/services/"+seriviceId+"/details")
            }
    }

    const getLatestPrice = (priceList = [])=>{
       
        return priceList.sort((a, b)=> a.resource_mapping_id > b.resource_mapping_id)[0] ?? {};
    }

   

    const columns = [
        {
            name: 'RESOURCE NAME',
            selector: row => {
                return ( 
                    <>
                        <SimpleToolTip tooltip={row.name}>
                            <Link to={`/resources/${row.ci_id}/details`} className="table-link-title span-ellipsis" style={{ width: "350px", display: "block"}}>
                                {getOnepaneTypeCIIcon(row.onepane_type)}
                                <span className="table-span ops-common-hover " style={{marginLeft: "5px"}}>{row.name}</span>
                            </Link>
                        </SimpleToolTip>
                    </>
                )
            },
            sortable: true,
            sortField: "name",
        },
        {
            name: 'CLOUD',
            selector: row => {
                    return (
                        <SimpleToolTip tooltip={row.plaform?.name}>
                            <span><img src={`/images/connector-images/${row.plaform.platform_type.icon}`} style={{width:"30px", height:"30px"}} className="br-img-rounded"/></span>
                        </SimpleToolTip>
                    )
            },
            center: true,
            width: "150px"
        },
        {
            name: 'SERVICE',
            selector: (row)=>{
                let hasService = row.service.name != "untagged" ? true : false
                return(
                    <span 
                    className={`table-span  ${hasService ? "ops-common-hover": ""}`} 
                    onClick={()=>{gotoService(row.service.service_id, hasService)}}>
                       {hasService ? row.service.name : "No Service" }
                    </span> 
                )
            },
            width: "150px",
            // sortable: true,
            // sortField: 'services.name'
        },
        {
            name: 'ENV',
            selector: row =>(getENVStatus(row.tags?.Env)),
            width: "100px",
            sortable: true,
            sortField: "tags->>'Env'",
        },
        {
            name: 'OWNER',
            selector: row => {
                return ( 
                    <>  
                        {row.tags?.Owner ? <Avatar username={row.tags?.Owner} imageUrl="" customText="Not assigned" /> : <span className="no-value-span">Not assigned</span> }
                        {/* {  row.owner?.first_name ? <Avatar username={row.owner?.first_name} customText="Not assigned" /> : <span className="no-value-span">Not assigned</span> } */}
                    </>
                )
            },
            sortable: true,
            sortField: "tags->>'Owner'",
            width: "200px"
        },
        {
            name: 'CRITICALITY',
            selector: row => <>{getCriticalityStatus(row.tags?.Criticality)} </>,
            width: "130px",
            sortable: true,
            sortField: "tags->>'Criticality'",
            
        },
        {
            name: 'COST',
            selector: row => <>{row.total_cost != null ? <><span style={{color: "#2DA077",fontWeight: "700"}}>$</span><span style={{color: "#4D4C52"}}> {parseFloat(row.total_cost).toFixed(2)} </span></> : <span className="no-value-span">Not Estimated </span>}</>,
            width: "150px",
            sortable: true,
            sortField: "total_cost",
        },
        {
            name: 'TYPE',
            selector: row => <SimpleToolTip tooltip={row.original_type}>{row.original_type}</SimpleToolTip> ,
            width: "200px",
            sortable: true,
            sortField: "original_type",
        },
        
    ];


    const parseFilterData = async ()=>{
        let filterData = await getResourceBaseData()
        setFilterData(parseDataForFilter(filterData))
    }


    const getResources = (page = 1, search = "", sort=[], filter={})=>{
        let service_id = searchParams.get("service")
        if(service_id != null){
            filter["config_items.service_id"] = [service_id]
        }

        loadResources(page, search, sort, filter).then(response=>{
            let data = response.data.data
            setResources(data.items)
            setTableInfo({...tableInfo, ...{loading: false, totalRows: data.total_count, search, sort, filter }})
        })
        parseFilterData()
    }

    const onPageChange = (page)=>{
        setTableInfo({...tableInfo, ...{loading: true, currentPage: page}})
        getResources(page, tableInfo.search, tableInfo.sort, tableInfo.filter)
    }
   
    const onSearch = (keyword)=>{
        setTableInfo({...tableInfo, ...{search: keyword}})
        getResources(1, keyword, tableInfo.sort, tableInfo.filter)
    }

    const sortTable = (selectedColumn, sortDirection, sortedRows)=>{
        getResources(1, "", [{key: selectedColumn.sortField, order: sortDirection}], tableInfo.filter)
        setTableInfo({...tableInfo, ...{sort: [{key: selectedColumn.sortField, order: sortDirection}]}})
    }

    const refreshResource = ()=>{
        getResources()
    }

    const onFilter = (data)=>{
        // console.log({data})
        setTableInfo({...tableInfo, ...{filter: data.value}})
        getResources(1,"", tableInfo.sort, data)
    }


    const breadcrumbsAction = <>
        <RBACWrapper action={"RESOURECE_EDIT"}>
            <div className="">
                <Link to={"/resources/resolve"}><Button ButtonStyle={"transparent"}>Unresolved</Button></Link> 
                <Link to={"/resources/compliances/details"}><Button style={{padding: "4px 23px"}} > <img src={settingsIcon} /> Fix Compliance</Button></Link> 
            </div>
        </RBACWrapper>
       
    </>


    useEffect(()=>{
        getResources()
    },[])
    
    return(
        <>
            <Breadcrumbs section={["Resources"]} actions={breadcrumbsAction} />
            <DashboardBody>
                <Table 
                        columns={columns} 
                        data={resources} 
                        loading={tableInfo.loading}
                        tableStyle={{border: "0px"}}
                        pagination={true}
                        totalRows={tableInfo.totalRows}
                        onPageChage={onPageChange}
                        onSearch={onSearch}
                        onRefresh={refreshResource}
                        dataTableProps={{sortServer: true, onSort: sortTable}}
                        filterData={filterData}
                        onFilterApply={onFilter}
                        
                        
                    />
            </DashboardBody>
        </>
    )
}

export default Resources