import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Service.css";
const EmptySerivce = ({username})=> {


    return(
        <>
            <div className="waiting-list">
                <Row>
                    <Col lg={6} className={"offset-3"}>
                        <div className="content" style={{marginTop: "20vh"}}>
                           
                            <div className="welcome-message" >
                                <h1 className="user-welcome">Hi { username?.charAt(0)?.toUpperCase() + username?.slice(1)}</h1>
                                <p className="welcome-para">
                                    We are excited to get you started.
                                     Our <a href="https://onepane.help.center" target="_blank" className="op-link">quick start </a> guide help you to
                                    start add integration on onepane. </p>
                            </div>
                            <div className="v-timeline">
                                <div className="timeline">
                                    <div className="timeline-container">
                                        <div className="number">1</div>
                                        <div className="text">Click on add integration </div>
                                        <div className="line"></div>
                                    </div>
                                </div>
                                <div className="timeline">
                                    <div className="timeline-container">
                                        <div className="number">2</div>
                                        <div className="text">Select the integration method</div>
                                        <div className="line"></div>
                                    </div>
                                </div>
                                <div className="timeline">
                                    <div className="timeline-container">
                                        <div className="number">3</div>
                                        <div className="text">Fill the required details</div>
                                    </div>
                                </div>
                            </div>

                            <Link to={"/integrations/new/sources"} style={{textDecoration: "none"}} >
                                <button className={`ops-btn ops-btn-main  ops-singup-btn col-12 `}  style={{width: "350px", margin: "auto"}}  >Add Integration</button>
                            </Link>
                            {/*  <button className="btn btn-primary mt-4">Add new Service</button> </Link> */}
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default EmptySerivce