import { useEffect, useState } from 'react'
import ServiceCard from 'components/ServiceCard/ServiceCard'
import AddServiceCard from 'components/ServiceCard/AddServiceCard'
import "./Overview.css"
import ReactECharts from 'echarts-for-react'
import Button from "components/Elements/Button/Button"
import { AiFillRightCircle } from 'react-icons/ai'
import Overviewchart from 'components/OverviewChart/Overviewchart'
import Notification from 'components/Notifications/Notification'
import Table from 'components/Table/Table'
import graph from "assets/icons/resources/Graph.svg"
import { RiRefreshLine } from 'react-icons/ri'
import { HiOutlineChartPie } from "react-icons/hi"


import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import DashboardBody from 'pages/newlayout/DashboardBody'
import showAllIcon from "assets/icons/util/see-all-icon.svg"
import exportDashboardIcon from "assets/icons/util/export.svg"
import shareDashboardIcon from "assets/icons/util/share-white.svg"

import launch from "assets/icons/util/launch.svg"



import { Link } from 'react-router-dom'

import GetService from 'services/GetServices'
import { API_URL } from 'config/config'
import { CHART_COLORS } from 'config/constants'
import { CHANGE_TABLE_COLUMN, INCIDENT_TABLE_COLUMN, SERVICE_RESOURCES_OPTIONS, NAMING_POLICY_CHART, TAGGING_POLICY_CHART } from './ChartConst'
import moment from 'moment'
import Calendar from 'components/Calender/Calender'
import AppSettingStore from 'stores/NotificationSystem/AppSettingStore'



const  Overview = ()=>{


    const [overviewServices, setOverviewServices] = useState([])
    const [notifications, setNotifications] = useState([])
    const [serviceResourcesOpt, setServiceResourcesOpt] = useState({})
    const [namingPolicyOpt, setNamingPolicyOpt] = useState([])
    const [taggingPolicyOpt, setTaggingPolicyOpt] = useState([])
    const [incidentTableInfo, setIncidentTableInfo] = useState({data:[], loading:true})
    const [changeTableInfo, setChangeTableInfo] = useState({data:[], loading:true})
    
    const appSettings = AppSettingStore()
   
   

    const breadcrumbsActions = <>
                    <Link to={"/"}>
                        <Button ButtonStyle="transparent" className="mx-3" disabled={true} >
                        <img src={exportDashboardIcon} style={{marginRight: "11px", marginTop: "-2px"}}/>
                            Export
                        </Button>
                    </Link>
                    <Link to={"/"}>
                        <Button disabled={true}> 
                            <img src={shareDashboardIcon} style={{marginRight: "11px", marginTop: "-2px"}}/>
                            Share
                         </Button>
                    </Link>
                    
    </>


    const AlterTableFooter = ({url})=> <>
        <div className='text-right'>
            <Link to={url} style={{textDecoration: "none"}}>
                <Button
                    ButtonStyle="solid"
                    size="small"
                    style={{ fontWeight: 500, position: "relative", top: "11px" }}
                >
                    See all 
                    <img src={showAllIcon} style={{marginLeft: "10px",marginTop: -"2px"}}/>
                </Button>
            </Link>
        </div>
    </>


   

    const getOverViewDetails = ()=>{
        GetService(API_URL + "/api/v1/overview").then(response=>{
            let data = response.data.data;
            
            let tempServiceResources = []
            data.resources_svc_distribution?.map((item, index)=>{
                tempServiceResources.push({
                    value: item.resource_count, 
                    name: `${item.service_name}`,
                    serviceId: `${item.service_id}`,
                    itemStyle: {
                        color: CHART_COLORS[index]
                    },
                })
            })
            SERVICE_RESOURCES_OPTIONS.series[0].data = tempServiceResources
            console.log({SERVICE_RESOURCES_OPTIONS})
            setServiceResourcesOpt(SERVICE_RESOURCES_OPTIONS)

            NAMING_POLICY_CHART[0].value = data?.naming_policy_distribution.compliant
            NAMING_POLICY_CHART[1].value = data?.naming_policy_distribution.non_compliant
            console.log({NAMING_POLICY_CHART})
            setNamingPolicyOpt(NAMING_POLICY_CHART)

            TAGGING_POLICY_CHART[0].value = data?.tagging_policy_distribution.compliant
            TAGGING_POLICY_CHART[1].value = data?.tagging_policy_distribution.non_compliant
            console.log({TAGGING_POLICY_CHART})
            setTaggingPolicyOpt(TAGGING_POLICY_CHART)


            let tempNotification = [];
            data.notifications?.map(item=>{
                tempNotification.push({
                    message: item.notification_title,
                    type:item.type,
                    time: moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")
                })
            })
            setNotifications(tempNotification)
        })
    }

    const getServices = (fromDate, toDate)=>{
        GetService(API_URL + `/api/v1/service/list?startTime=${fromDate}&endTime=${toDate}`).then(response=>{
            let data = response.data.data;
            let services = data.items.slice(0,4)

            let tempServices = []
            services?.map(item=>{
                tempServices.push({
                    service_id: item.service_id,
                    name: item.name,
                    active_alerts: item.active,
                    resolve_alerts: item.resolved,
                    total_changes: item.changes ?? 0,
                    no_of_resources: item.ci_count,
                    link: `/services/${item.service_id}/details`,
                })
            })
            setOverviewServices(tempServices)
        })
    }


    const getIncidents = (fromDate, toDate)=>{
        GetService(API_URL + `/api/v1/incidents?per_page=5&startTime=${fromDate}&endTime=${toDate}`).then(response=>{
            let data = response.data.data;
            setIncidentTableInfo({data: data.items, loading: false});
            
        })
    }

    const getChanges = (fromDate, toDate)=>{
        GetService(API_URL + `/api/v1/events?per_page=5&startTime=${fromDate}&endTime=${toDate}&per`).then(response=>{
            let data = response.data.data;
            setChangeTableInfo({data: data.items, loading: false});
           
        })
    }

    

    const onDateChange = (fromDate, toDate, fromTimestamp, toTimestamp)=>{
        appSettings.setCalender(fromDate, toDate, fromTimestamp, toTimestamp)
        setChangeTableInfo({changeTableInfo, ...{loading: true}});
        setIncidentTableInfo({incidentTableInfo, ...{loading: true}});
        getIncidents(fromTimestamp, toTimestamp);
        getChanges(fromTimestamp, toTimestamp);
        getServices(appSettings.calender.fromTimestamp, appSettings.calender.toTimestamp);
    }
   


    useEffect(()=>{
        getOverViewDetails();
        getServices(appSettings.calender.fromTimestamp, appSettings.calender.toTimestamp);
        getIncidents(appSettings.calender.fromTimestamp, appSettings.calender.toTimestamp);
        getChanges(appSettings.calender.fromTimestamp, appSettings.calender.toTimestamp);
    },[])

    return (
   
        

            <>
            <Breadcrumbs section={[{ "name": "Overview", "path": "/overview"  }]} actions={breadcrumbsActions}  showHome={false} />
            <DashboardBody style={{background: "#F4F6FC"}}>
            <div className='overViewCalender'>
                   <div>
                      <Calendar onClick={onDateChange} fromDate={appSettings.calender.fromDate} toDate={appSettings.calender.toDate} />
                   </div>
                </div>
            <div className="col-12">
                {/* Service SECTION */}
                <section>
                    <div className="servicebox w-100">
                        <div className="row g-3">
                            {overviewServices.map((item, index)=> {
                                return (
                                    <div className="col-md-3 col-12">
                                        <ServiceCard  key={index} serviceId={item.service_id} link={item.link} servicename={item.name} active={item.active_alerts} resolve={ item.resolve_alerts } changes={item.total_changes} resources={item.no_of_resources} />
                                    </div>
                                )
                            })}
                           {overviewServices.length <= 3 && (
                            <div className="col-md-3 col-12">
                                <AddServiceCard />
                            </div>
                           )}
                        </div>
                        <div className=" position-relative">
                            <div className=" position-absolute seeAllButton">
                                <div>
                                    <Link to={"/services/"} style={{textDecoration: "none"}}>
                                        <Button size="small">
                                            See all <AiFillRightCircle fontSize={20} style={{marginLeft: "10px", marginTop: "-2px"}} /> 
                                        </Button>
                                    </Link> 
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <div className='row p-0' style={{marginTop: "35px"}}>
                        <div className="row">
                            <div className="col-md-3">
                                <Overviewchart data={taggingPolicyOpt} title={"Tagged Percentage"} launch={true} link={"/reports"} />
                            </div> 
                            <div className="col-md-3">
                                <Overviewchart data={namingPolicyOpt} title={"Naming Compliance"} launch={true} link={"/reports"} />
                            </div>
                            <div className="col-md-6">
                                <div className='resource-chart-container'>
                                    <div>
                                        <span className='resource-chart-container-title'> <HiOutlineChartPie fontSize={24} color='#6B53AE' style={{marginRight: "3px"}} /> Resources per Service</span>
                                    </div>
                                    <div className='row align-items-center' style={{marginTop: "35px", padding: "0px 28px", gap: "25px"}}>
                                        <div className='col'>
                                            <ReactECharts
                                                option={serviceResourcesOpt}
                                                style={{ height: '240px' }}
                                            />
                                        </div>
                                        <div className='col'>
                                            <div>
                                                { serviceResourcesOpt?.series && serviceResourcesOpt?.series[0]?.data?.map((item, key)=>{
                                                    return (
                                                        <div className='resource-chart-legend'>
                                                            <span className='resource-chart-color-span' style={{background: item.itemStyle?.color}}></span>
                                                            <span className='resource-chart-legend-label'>{item.name}</span>
                                                            <span className=''>
                                                               <Link to={`/services/${item.serviceId}/details`}> <img src={launch} /> </Link> 
                                                            </span>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                </div>


                <div className="row chartnotisection p-0">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-12 ">
                                <Table 
                                    control={false} 
                                    columns={INCIDENT_TABLE_COLUMN} 
                                    loading={incidentTableInfo.loading} 
                                    data={incidentTableInfo.data} 
                                    title={"Alert"} 
                                    footer={<AlterTableFooter url="/alerts"/>}
                                    icon={<img src={graph}  />} tableStyle={{marginTop: "0px", border: "0px"}} />
                                    
                            </div>

                        </div>
                        <div className="row" style={{ paddingTop:'25px'}}>
                            <div className="col-md-12" >
                                <Table  
                                    control={false} 
                                    columns={CHANGE_TABLE_COLUMN} 
                                    loading={changeTableInfo.loading} 
                                    data={changeTableInfo.data} 
                                    title={"Changes"} 
                                    footer={<AlterTableFooter url="/changes" />}
                                    icon={<RiRefreshLine color='#6B53AE'   />} 
                                    tableStyle={{marginTop: "0px", border: "0px"}} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className='bg-white h-100'>
                            <Notification title="Notifications" notifications={notifications}  />
                             {/* <hr />
                            <Notification messageicon={notificationavatar} title={"Activities"} icon={<RiFlag2Line fontSize={24} color='#6B53AE' />} messages={['created new service', 'edited new cart-service resource', 'create a new report' ]} /> */}
                        </div>
                    </div>
                </div>
            </div>

            </DashboardBody>
        </>



    )
}

export default Overview