import { useEffect, useState } from 'react';
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import DashboardBody from "pages/newlayout/DashboardBody";
import ResolvedUnresolvedTimeLine from 'components/ResolvedUnresolvedTimeLine/ResolvedUnresolvedTimeLine';
import Card from 'components/Card/Card';
import { BiCoinStack } from 'react-icons/bi';
import Table from 'components/Table/Table';
import SucessfullySolvedComponent from 'components/TableEmptySuccess/TableEmptySuccess';
import "./Unresolved.css";
import HowToLink from 'components/V1/HowToLink/HowToLink';
import Button from 'components/Elements/Button/Button';
import { useParams } from 'react-router-dom';
import SelectwithSearch from 'components/V1/SelectwithSearch/Select';
import { useNavigate } from 'react-router-dom';
import GetService from 'services/GetServices';
import { API_URL } from 'config/config';
import PostService from 'services/PostServices';
import { getOnepaneTypeCIName } from 'utils/OnepaneType';
import onepaneTypes from 'config/onepaneType';

function Unresolved() {

  const [state, setState] = useState(0)
  const [platformResources, setPlatformResources] = useState([])
  const [mappingResource, setMappingResource] = useState([])
  const [mappedPlatformResource, setMappedPlatformResource] = useState({})
  const [mappedConfirmTableData, setMappedConfirmTableData] = useState([])
  const [filterData, setFilterData] = useState([])
  const [tableInfo, setTableInfo] = useState({totalRows: 0 , currentPage: 1, loading: true, search: "", sort:[], filter :{}})
  const { platformId } = useParams()
 
  var timeout = null;
  
  const navigate = useNavigate()
  


  const onPrev = ()=>{
    // setMappingResource(resourceDataTemp)
    setState(0)
  }

  const onCancel = ()=>{
    navigate(`/resources/resolve`)
  }

  const setSelectedCi = (data, row) =>{
      
  }




  const columnsFirstStep = [
    {
      name: 'RESOURCE',
      selector: row=><span className='table-span'>{row.name}</span>,
      sortable: true,
      width: "350px",
  },
    {
      name: 'ORIGINAL TYPE',
      selector: row=>row.original_type,
      sortable: true,
      width: "250px",
    },
    {
      name: 'ONEPANE TYPE',
      selector: row=> getOnepaneTypeCIName(row.onepane_type),
      sortable: true,
      width: "200px",
    },
    {
      name: 'SERVICE',
      selector: row=> (state == 1 && row.service_name),
      sortable: true,
      width: "200px",
    },
    {
      name: 'RESOURCE MAPPING',
      selector: row=>{
        return(
          <>
            { state == 0 && <><SelectwithSearch 
                width={280} 
                data={mappingResource} 
                placeholder={"Map resource"} 
                defaultValue={ mappedPlatformResource[row.ci_id] ? mappedPlatformResource[row.ci_id]["mapped_resource_name"] : ""}
                showKey={"label"}
                serverSideSearch={true} 
                onServerSideSearch={loadBaseData}
                onChange={(data)=> onMapPlatformResource(row, 'mapped_resource_id', data)}
                />
            </>}
            {state == 1 && <span className="table-text">{row.mapped_resource_name}</span>}
          </>
        )
      },
      sortable: true,
    },
  ];


  const loadFilterData = ()=>{
    const tempFilterData = []
    for (const key in onepaneTypes) {
      tempFilterData.push({
          label: onepaneTypes[key].name,
          value: parseInt(key)
      })
    }
   
    

    setFilterData([
      {
        label: "Onepane Type",
        key: "onepane_type",
        values: tempFilterData
    },
  ])
  }

  const loadBaseData = (search = "", filter={})=>{
    let searchJSON = JSON.stringify([{key:"config_items.name",  value:search}])
   

    GetService(API_URL + `/api/v1/platform/unresolved/init?search=${searchJSON}`).then(response=>{
      let data = response.data.data
      let tempRespurces = []

      data.items?.map(item=>{
        tempRespurces.push({
          label: item.name,
          value: item.ci_id,
          data: item,
        })
      })
      setMappingResource(tempRespurces)
  });
  }

 

  const onMapPlatformResource = (data, key, selectedData)=>{
    console.log({selectedData})
    let ciId = data.ci_id
    let tempMappedPlatformResource = JSON.parse(JSON.stringify(mappedPlatformResource))
    if(!tempMappedPlatformResource[ciId]){
        tempMappedPlatformResource[ciId] = {}
        tempMappedPlatformResource[ciId]["ci_id"] = ciId;
        tempMappedPlatformResource[ciId]["name"] = data.name;
        tempMappedPlatformResource[ciId]["original_type"] = data.original_type;
        tempMappedPlatformResource[ciId]["onepane_type"] = data.onepane_type;
        
    }
    tempMappedPlatformResource[ciId]["service_name"] = selectedData?.service?.name
    tempMappedPlatformResource[ciId]["mapped_resource_name"] = selectedData.label
    tempMappedPlatformResource[ciId]["mapped_resource_id"] = selectedData.value
    setMappedPlatformResource(tempMappedPlatformResource)
  }


  const onConfirmMapping = ()=>{
    let tempConfirmTableData = []
    console.log({mappedPlatformResource})
    for (const key in mappedPlatformResource) {
        tempConfirmTableData.push(mappedPlatformResource[key])
    }
    setMappedConfirmTableData(tempConfirmTableData)
   
    setState(1)
}



const onSaveMappedData = ()=>{
  console.log({mappedConfirmTableData})
  let JSONBody = [];
  for (const data of mappedConfirmTableData) {
      JSONBody.push({
        raw_resource_id:  parseInt(data.ci_id),
        platform_id:  parseInt(platformId),
        resource_id: parseInt(data.mapped_resource_id)
      })
  }
  PostService(API_URL + `/api/v1/platform/unresolved/bulk/fix?platform_id=${platformId}`,{mappings: JSONBody}).then(response=>{
    setState(2)
  })
}

  const getPlatformResource = (page = 1, search = "", sort=[], filter={})=>{
    let searchJSON = JSON.stringify([{key:"name", value:search}])
    let sortJSON = JSON.stringify(sort)
    let filterJSON = JSON.stringify(filter)
    GetService(API_URL + `/api/v1/platform/unresolved/resources/list?platform_id=${platformId}&current_page=${page}&search=${searchJSON}&sort=${sortJSON}&filter=${filterJSON}`).then(response=>{
        let data = response.data.data
        setPlatformResources(data.items ?? [])
        setTableInfo({...tableInfo, ...{loading: false, totalRows: data.total_count }})
    });
  }


  const onPageChange = (page)=>{
    setTableInfo({...tableInfo, ...{loading: true, currentPage: page}})
    getPlatformResource(page, tableInfo.search, tableInfo.sort, tableInfo.filter)
  }

  const onSearch = (keyword)=>{
      setTableInfo({...tableInfo, ...{search: keyword}})
      getPlatformResource(1, keyword, tableInfo.sort, tableInfo.filter)
  }

  const sortTable = (selectedColumn, sortDirection, sortedRows)=>{
      getPlatformResource(1, tableInfo.search, [{key: selectedColumn.sortField, order: sortDirection}])
      setTableInfo({...tableInfo, ...{sort: [{key: selectedColumn.sortField, order: sortDirection}]}})
  }

  const refreshResource = ()=>{
    getPlatformResource()
  }

  const onFilter = (data)=>{
      setTableInfo({...tableInfo, ...{filter: data}})
      getPlatformResource(tableInfo.tableInfo, tableInfo.search, tableInfo.sort, data)
  }



  useEffect(()=>{
    getPlatformResource();
    loadBaseData();
    loadFilterData();
  },[])


  return (
    <>
      <Breadcrumbs section={[{name: "Resources", path:"/resources"}, {name: "Unresolved Resources", path: "/resources/resolve"}, {name: "Fix Unresolved", path: ""}]} />
      <DashboardBody style={{ background: "#F4F6FC" }}>
        <div>
          <ResolvedUnresolvedTimeLine isStage2Completed={state == 1 || state == 2  ? true: false} isStage2Successful={state == 2 ? true: false} />
        </div>
        <div>
        { state == 0  && (
          <Card type="full" style={{ marginTop: "20px", marginBottom:"20px" }}>
           <Table 
                  title={<span> <BiCoinStack fontSize={24} color='#6B53AE' style={{ marginRight: "8.2px" }} /> Resource List </span>}

                  border={false} 
                  columns={columnsFirstStep} 
                  loading={tableInfo.loading} 
                  totalRows={tableInfo.totalRows}
                  tableStyle={{border: "0px"}}
                  pagination={true}
                  data={platformResources}  
                  onPageChage={onPageChange}
                  onSearch={onSearch}
                  onRefresh={refreshResource}
                  dataTableProps={{sortServer: true, onSort: sortTable}}
                  filterData={filterData}
                  onFilterApply={onFilter}
                  // noDataComponent={<SucessfullySolvedComponent link={"/resources"} />}
                  />
          
          </Card>
          )} 
      { state == 1  && (
          <Card type="full" style={{ marginTop: "20px", marginBottom:"20px" }}>
                <Table 
                  title={<span> <BiCoinStack fontSize={24} color='#6B53AE' style={{ marginRight: "8.2px" }} /> Resource List </span>}
                  border={false} 
                  columns={columnsFirstStep} 
                  loading={false} 
                  tableStyle={{border: "0px"}}
                  pagination={true}
                  data={mappedConfirmTableData}  
                  paginationServer={false}
                  
                />
                  
          </Card>
      )}

      { state == 2  && (
          <Card type="full" style={{ marginTop: "20px", marginBottom:"20px" }}>
                <Table 
                  title={<span> <BiCoinStack fontSize={24} color='#6B53AE' style={{ marginRight: "8.2px" }} /> Resource List </span>}
                  border={false} 
                  columns={columnsFirstStep} 
                  loading={false} 
                  tableStyle={{border: "0px"}}
                  pagination={true}
                  data={[]}  
                  noDataComponent={<SucessfullySolvedComponent 
                    link={"/resources"} 
                  />}
                  />
                  
          </Card>
      )}
        
      </div>
      
        { (state == 0 || state == 1) && (<div style={{marginTop: "20px"}}>
                <Card type={"full"}>
                    <Card.CardBody>
                        <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                            <HowToLink />
                            
                        </div>
                        <div className="">
                            <Button type={"danger"} ButtonStyle="transparent" onClick={onCancel}>Cancel</Button>
                            {state == 1 && <Button  ButtonStyle="transparent" onClick={onPrev}>Previous</Button>} 
                            {state == 0 && <Button   onClick={onConfirmMapping}>Next</Button>} 
                            {state == 1 && <Button   onClick={onSaveMappedData}>Save</Button>} 
                            {/* <Link to={"/services/1/fix-confirm"}> <Button >Save</Button></Link> */}
                        </div>
                    </div>
                    </Card.CardBody>
                </Card>
            </div>)}
      </DashboardBody>

    </>
  )
}

export default Unresolved