import style from "./CardList.module.css";
import Button from '../Elements/Button/Button';
import HowToLink from 'components/V1/HowToLink/HowToLink';
import TablePagination from 'components/Table/TablePagination';


const Footer = ({onSave, onCancel})=>{
    return (
        <>
            <div className={style.footer}>
                <div style={{flexGrow: 1}}>
                    <HowToLink/>
                </div>
                <div className=''>
                   <Button ButtonStyle={"transparent"} onClick={onCancel}>Cancel</Button>
                   <Button onClick={onSave}>Save</Button>
                </div>
            </div>
        </>
    )
}

function CardList({onChecked, controls, pagination, paginationProps, data, selectedItems, checkbox, onSave, onCancel, ...props}) {

 
    return (
        <>
         <div {...props}>
            <div className={`${style.cardListContainer} ${controls == false? style.cardListContainerNoControl : ""}`}>
                {data?.map((item, index) => {
                    return(
                        <>
                            <div key={index} className={`d ${style.cardListbox}  `}>
                                {/* <List checkbox={checkbox} data={item} selectedItems={selectedItemList} onChecked={onItemChecked} /> */}
                                <div className={style.cardList}>
                                        {item.render}
                                 </div>
                            </div>
                        </>
                    )
                })}
            </div> 
            {pagination && <TablePagination {...paginationProps} /> } 
            {controls && <Footer onSave={onSave} onCancel={onCancel} />} 
            
         </div>
          
        </>
    );
}

CardList.defaultProps = {
    data: [],
    controls: true,
    pagination: false,
    paginationProps : {},
    onSave: ()=>{},
    onCancel: ()=>{}
    
}

export default CardList;
