
import { useEffect, useState } from "react"
import style from "./Select.module.css"
import arrowDown from "./assets/arrow-down.svg"
import arrowUp from "./assets/arrow-up.svg"
import arrowDisabled from "./assets/arrow-down-disabled.svg"
import { BiErrorCircle } from "react-icons/bi"
const Select = ({hint,label, options, placeholder, onChange, defaultValue, size, muliple, ...otherProps})=>{


    const [showDropdown, setShowDropdown] = useState(false)
    const [selectedItem, setSelectedItem] = useState({})
    const [defaultVal, setDefaultVal] = useState("")
    const [dropdownIcon, setDropdownIcon] = useState(arrowDown)

    const onItemSelect = (index)=>{
        setSelectedItem(options[index])
        setShowDropdown(false)
        onChange(options[index])
        setDefaultVal(options[index].value)
    }

    const onSelectClick =()=>{
        if(showDropdown){
            setShowDropdown(false);
            setDropdownIcon(arrowDown)
        }else{
            setShowDropdown(true);
            setDropdownIcon(arrowUp)
        }
        // dropdownIcon()
    }

    useEffect(()=>{
        setDefaultVal(defaultValue)
        if(otherProps.disabled && otherProps.disabled == true){
            setDropdownIcon(arrowDisabled)
        }
    },[defaultValue])

    // const outsideClick = (e)=>{
    //     console.log({l: e.target.classList})
    //     console.log({k: style.opSelectContainer})
        
    //     if (e.target.classList.contains(style.opSelectContainer) || e.target.classList.contains(style.opOptionList)){
    //         console.log("inside")
    //     }else{
    //         console.log("outside")
    //     }
       
    // }

    // useEffect(()=>{
    //     document.addEventListener("mousedown", outsideClick);
    //     return () => {
    //       // Unbind the event listener on clean up
    //       document.removeEventListener("mousedown", outsideClick);
    //     };
    // },[])

    return(
        <>
            <div className={style.opSelectContainer} {...otherProps}>
                <div style={{position: "relative"}}>
                    <span className={style.opSelectLabel}>{label}</span>{hint ? <span style={{marginLeft:"5px"}}><BiErrorCircle color="rgba(255, 153, 0, 0.40)"/></span> :""}
                    <div className={`${style.opSelect} ${otherProps.disabled ? style.opSelectDisabled : ""}  ${size == "small" ? style.opSelectSmall: ""}  `} onClick={otherProps.disabled ? ()=>{} : onSelectClick}>
                        <span className={`${style.opSelectText}`}>{selectedItem.label ?? placeholder} </span> <img src={dropdownIcon}/>
                    </div>
                    
                    <div className={`${style.opOptionList}  ${showDropdown == true ? style.opOptionListOpen : ""}`} >
                    
                        {options && options?.map((item, index)=>{
                            return(
                                <div key={index} className={`${style.opOption} ${size == "small" ? style.opSmallOption: ""} ${defaultVal == item.value ? style.selectedOption: ""}`} onClick={()=>onItemSelect(index)}>{item.label}</div>
                            )
                        })}
                        
                    </div>
                </div>
            </div>
        </>
    )
}


Select.defaultProps = {
    label:"Select",
    placeholder: "Select Item",
    muliple: false,
    defaultValue: "",
    size: "default",
    options: [],
    onChange: ()=>{},
    hint:false
}

export default Select