import "./Tag.css"

const TAG_TYPE = {
    "PRIMARY": "primary",
    "SUCCESS": "success",
    "WARNING": "warning",
    "DANGER": "danger"
    
}


const Tag = ({children, type, className, ...props})=>{
    return(
        <span className={`op-tag ${type} ${className}`} {...props}>{children}</span>
    )
}


Tag.defaultProps = {
    type: 'primary',
    className: ""
}


export default Tag;
export { TAG_TYPE }