


import { useEffect, useState } from "react";
import GetService from "services/GetServices";
import { API_URL } from "config/config";
import "./Organisation.css"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import DashboardBody from "pages/newlayout/DashboardBody"
import Table from "components/Table/Table";
import Avatar from "components/Avatar/Avatar";
import Tag from "components/Elements/Tag/Tag";
import SimpleToolTip from "components/Tooltip/SimpleToolTip";
import Button from "components/Elements/Button/Button";


const Organisation = ()=>{


    const [organisations, setOrganisations] = useState([])
    const [filterOrg, setFilterOrg] = useState([])
    const [orgTableInfo, setOrgTableInfo] = useState({totalRows: 0 , currentPage: 1, loading: false, search: "", sort:[], filter :{}})
    const [selectedOrg, setSelectOrg] = useState(0)
  

    const filterData = [{
        label: 'Status',
        key:"status",
        values: [ 
            { label: "Pending", value: 0 },
            { label: "Ready", value: 7 },
            { label: "Error", value: 1 },
            { label: "Approved", value: 2 },
            { label: "Deployment Started", value: 3 },
            { label: "Deploying", value: 4 },
            { label: "Disabled", value: 5 },
            { label: "Rejected", value: 6 },
           
         ]
    }]


    const onSwitchOrg = (orgId)=>{
        GetService(API_URL + `/api/v1/base/organisation/${orgId}/select`).then(()=>{
          window.location.href = "/overview"
        })
    }

    const getOrgStatus = (status, message = "")=>{
        switch(status){
            case 0: return <Tag type={"warning"}>Pending</Tag>
            case 1: return <SimpleToolTip tooltip={message}><Tag type={"danger"}>Error</Tag></SimpleToolTip> 
            case 2: return <Tag type={"primary"}>Approved</Tag>
            case 3: return <Tag type={"primary"}>Deployment Started</Tag>
            case 4: return <Tag type={"primary"}>Ready</Tag>
            case 5: return <Tag type={"danger"}>Disabled</Tag>
            case 6: return <Tag type={"danger"}>Rejected</Tag>
            // case 7: return <Tag type={"success"}>Ready</Tag>
        }
        
    }


    const loadOrganisation = ()=>{
        GetService(API_URL + "/api/v1/base/organisation/list").then((res)=>{
            setOrganisations(res.data.data?.organisations)
            setFilterOrg(res.data.data?.organisations)
        })
    }


    
    const onSearch = (search)=>{
        let tempOrg = organisations.filter(item=>{
            return item?.name?.toLowerCase().includes(search.toLowerCase())
        })
        
        setFilterOrg(tempOrg)
    }

    const onFilter =(data)=>{
        let tempOrg = organisations.filter(item=>{
            return data["status"]?.includes(item.status)
        })
        setFilterOrg(tempOrg)
    }


    const OrgColumn = [
        {
            name: 'ORG ID',
            selector: row => (row.org_id),
            width: "130px",
            sortable: true,
            sortField: "org_id",

        },
        {
            name: 'ORG NAME',
            selector: row=>row.name,
            
        },
        {
            name: 'CREATOR',
            selector: row=> <Avatar username={`${row.creator.first_name} ${row.creator.last_name}`}  /> ,
            width: "300px",
        },
        {
            name: 'STATUS',
            selector: row=> getOrgStatus(row.status),
            width: "150px",
        },
        {
            name: 'ACTION',
            selector: row => {
                return ( 
                    <>  
                       <Button onClick={()=>onSwitchOrg(row.org_id)}>Switch</Button>
                    </>
                )
            },
            width: "200px"
        },
       
    ];


    useEffect(()=>{
        loadOrganisation()
    },[])

    return(
        <>
           <>
            <Breadcrumbs section={["Organisations"]} />
            <DashboardBody>
                <Table 
                    columns={OrgColumn} 
                    data={filterOrg} 
                    loading={orgTableInfo.loading}
                    tableStyle={{border: "0px"}}
                    pagination={true}
                    totalRows={orgTableInfo.totalRows}
                    paginationServer={false}
                    onSearch={onSearch}
                    onRefresh={loadOrganisation}
                    filterData={filterData}
                    onFilterApply={onFilter}
                />
            </DashboardBody>
        </>
        </>
    )
}

export default Organisation