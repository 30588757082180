import { API_URL } from "config/config"
import GetService from "services/GetServices"


export const loadChanges = (page = 1, search = "", sort=[], filter = {}, url = '/api/v1/events', params = "")=>{

    let apiSearch = [{key: "message", value :search}]

    let searchJSON = JSON.stringify(apiSearch)
    let filterJSON = JSON.stringify(filter)
    let sortJSON = JSON.stringify(sort)

    let urlParams = `current_page=${page}&search=${searchJSON}&filter=${filterJSON}&sort=${sortJSON}&${params}`
    return GetService(API_URL + `${url}?${urlParams}`)
}


export const  getChangeBaseData = async ()=>{
    let response = await GetService(API_URL + `/api/v1/filter/alerts/init`,{}, false, false, false)
    let data = response.data.data
         
    
 
     let platforms = []
     data?.platforms?.map(item=>{
         platforms.push({
             label: item.name ,
             value: item.platform_id
         })
     })
 
     let services = []
     data?.services?.map(item=>{
         services.push({
             label: item.name ,
             value: item.service_id
         })
     })
 

     return { platforms, services }
 }
 
 export const parseDataForFilter = (data)=>{

    return [
        {
            label: "Platform",
            key: "events.platform_id",
            values: data.platforms
        },
        {
            label: "Service",
            key: "config_items.service_id",
            values: data.services
        }
    ]



 }