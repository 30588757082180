
import { useState } from "react"

const HorizontalTab = ({title, tabs, tabClass, panelClass, tabStyle, defaultTab})=>{

    const [selectedTab, setSelectedTab] = useState(0)
    return(
        <>
            <div className={`op-h-tab-container`}>
               
                <div className={`op-h-style ${tabStyle} ${tabClass}`}>
                    <div  className="op-h-tabs">
                        {
                            tabs.map((item, index)=>{
                                return(
                                    <div className={`op-h-tab-style ${tabStyle} ${item.disable == true ? 'disable' :''}  ${index == selectedTab ? 'active':''}`} onClick={()=> item.disable != true ? setSelectedTab(index) : ""}>
                                        <div key={index} className={`op-h-tab `} >
                                            {item.title}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className={`op-h-tab-panels ${panelClass}`}>
                        {tabs.map((item, index)=>{
                                return(
                                    <div key={index} className={`panel ${(index == selectedTab ) ? 'show': ''}`}>
                                        { item.children }
                                    </div>
                                )
                        })}
                    
                </div>
            </div>
        </>
    )
}

HorizontalTab.defaultProps = {
    title: "",
    tabs: [],
    defaultTab: 0,
    tabClass: "",
    panelClass: "",
    tabStyle: "bubble"
}

export default HorizontalTab