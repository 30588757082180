import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { BiErrorCircle, BiPencil } from "react-icons/bi";
import Button from "../../Elements/Button/Button";
import Modal from "../Modal/Modal";
import style from "./EditCardDetails.module.css";
import InputComponent from "components/form/InputComponent/InputComponent";
import { useCreditCardValidator , images} from "react-creditcard-validator";
import { expDateValidate, getErrorClass, numberOnly } from "utils/util";
import BillingRules from "sections/Organisation/BillingValidator";

const EditCardDetails = ({ data, show, title, description, ...props }) => {

    const [formSubmiting, setFormSubmiting] = useState(false)
    const { register, handleSubmit, watch, formState, getValues} = useForm({
        mode: "onTouched",
    });



    const { errors, isValid, isSubmitting, touchedFields, submitCount } = formState


    const {
        getCardNumberProps,
        getCardImageProps,
        meta: { erroredInputs }
      } = useCreditCardValidator({ expiryDateValidator: expDateValidate });


    const onFormSubmit = (formData) => {
        formData["month"] = formData.month < 10 ? "0" + formData.month : formData.month
        formData["cardnumber"] = getCardNumberProps().ref.current.value
        props.onSave(formData);
    };

    return (
        <Modal show={show} style={{ width: "514px" }}>
            <Modal.Title>
                <div className={style["content-padding"]}>
                    <div className={style.headingDiv}>
                        <div className={style.logoos}>
                            <BiPencil />
                        </div>
                        <div className={style.header}>
                            <span className={style.mainHeading}>{title}</span>
                            <span className={style.subHeading}>{description}</span>
                        </div>
                    </div>
                </div>
            </Modal.Title>
            <Modal.Body>
                <form onSubmit={handleSubmit(onFormSubmit)}>
                    <div className={style.Content}>
                        <InputComponent 
                            label="Name"
                            id="name"
                            name="name"
                            defaultValue={data?.card_holder_name}
                            className={ getErrorClass (errors, touchedFields, "name")}
                            error={errors.name?.message}
                            {...register("name", BillingRules.CardHolderName)}
                        />
                        <div className="form-control-seperator"/>
                        <InputComponent 

                            {...getCardNumberProps()} 
                            label="Card Number"
                            id="cardnumber"
                            name="cardnumber"
                            beforeIcon={<svg {...getCardImageProps({ images })} width={"23px"}  style={{marginTop: "-8px"}}/>} 
                            showErrorIcon={false}
                            className={erroredInputs["cardNumber"] ? "ops-input-text-invalid": "ops-input-text-valid"}
                     
                        />
                         <div className="form-control-seperator"/>
                        <div className={`${style.formMargin} ${style.cardSections}`}>
                            <div className={style.expriry}>
                                <InputComponent 
                                    label="Expiry Date"
                                    id="month"
                                    name="month"
                                    onKeyDown={numberOnly}
                                    className={getErrorClass(errors, touchedFields, "month")} 
                                    {...register("month",  BillingRules?.month)}
                                    error={errors.month?.message}
                                />
                                 </div> 
                            <div className={style.year}>
                                <InputComponent 
                                    id="year"
                                    name="year"
                                    defaultValue={data?.year}
                                    onKeyDown={numberOnly}
                                    className={getErrorClass(errors, touchedFields, "year")} 
                                    {...register("year",  BillingRules.year)}
                                    error={errors.year?.message}
                                />
                            </div>
                            <div className={style.cvv}>
                                <InputComponent 
                                    type="password"
                                    label="CVV"
                                    id="cvv"
                                    name="cvv"
                                    // onKeyDown={numberOnly}
                                    defaultValue={data?.year}
                                    className={getErrorClass(errors, touchedFields, "cvv")} 
                                    {...register("cvv", BillingRules.CVV)}
                                    error={errors.cvv?.message}
                                />
                            </div>
                            <div className={style.cvv}></div>
                        </div>
                        <div style={{ float: "right", marginTop: "40px" }}>
                            <Button
                                type="danger"
                                ButtonStyle="transparent"
                                className={style["mx-3"]}
                                onClick={() => props.onHide()}
                            >
                                Cancel
                            </Button>
                            <Button>Save</Button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

EditCardDetails.defaultProps = {
    title: "Edit Card Details",
    description:
        "Azure connector gathers Azure resources, configurations, usage, and size data for streamlined management",
};

export default EditCardDetails;
