import launch from "./launch.svg"

const HowToLink = ({link, text})=> {

    const linkStyle = {
        color: "#6B53AE",
        fontFamily: "Lato",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "24px",
        textDecoration: "none"
    }

    const iconStyle = {
        width: "16px",
        height: "16px",
        marginLeft: "14px",
        marginLeft: "7px",
        textDecoration: "none",
        marginTop: "-2px"
    }


    return(
        <a href={link} target="_blank" style={linkStyle}>{text}<img src={launch} style={iconStyle}/></a>
    )
}

HowToLink.defaultProps = {
    text: "How to link",
    link: "https://onepane.help.center"
}

export default HowToLink