
import { AiFillLinkedin, AiOutlineTwitter, AiFillGithub } from "react-icons/ai";
import { Col, Row } from "react-bootstrap";
import { COOKIE_POLICY_URL, DOC_URL, GITHUB_URL, HELP_CENTER_URL, LINKEDIN_URL, PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL, TWITTER_URL } from "config/constants";
import style from "./style.module.css"

const Footer = () => {

    return (
        <>
                            {/* -----------------------------------------------FOOTER MOBILE DEVICE-------------------------------------- */}

            <div className="footer-container ">
                <div className={` ${style.footerMobile} d-lg-none d-md-none`}>
                    <Row >
                        <Col>
                            <span className={`${style["style_FooterMenu"]}`}>
                                <a href={TERMS_OF_SERVICE_URL} target="_blank" >Terms of Service</a></span>
                        </Col>
                        <Col>
                            <span className={`${style["style_FooterMenu"]}`}>
                                <a href={COOKIE_POLICY_URL} target="_blank" >Cookie Policy</a>
                            </span>
                        </Col>
                        <Col>
                            <span className={`${style["style_FooterMenu"]}`}><a href={PRIVACY_POLICY_URL} target="_blank" >Privacy Policy</a>
                            </span>
                        </Col>


                    </Row>
                    <Row>
                        <Col lg="4" className="text-center" xs="12">
                            <span className={`${style["style_FooterMenu"]}`}>
                                <a href="">Copyright &copy; {new Date().getFullYear()} onepane.ai All right reserved</a>
                            </span>
                        </Col>
                    </Row>
                </div>
                <div className={`${style.generalLayoutFooter} py-2 d-none d-md-block d-lg-block`} >

                    {/* -----------------------------------------------FOOTER MOBILE DEVICE END-------------------------------------- */}

                    <Row>
                        <Col lg="6" xs="12" >
                            <div className="d-flex">
                                <span className={style.generalLayoutFooterMenu}>
                                    <a href={TERMS_OF_SERVICE_URL} target="_blank" >Terms of Service</a>
                                </span>
                                <span className={style.generalLayoutFooterMenu}>
                                    <a href={DOC_URL} target="_blank" >Docs</a>
                                </span>
                                <span className={style.generalLayoutFooterMenu}>
                                    <a href={COOKIE_POLICY_URL} target="_blank" >Cookie Policy</a>
                                </span>
                                <span className={style.generalLayoutFooterMenu}>
                                    <a href={HELP_CENTER_URL} target="_blank" >Help Center</a>
                                </span>
                                <span className={style.generalLayoutFooterMenu}>
                                    <a href={PRIVACY_POLICY_URL} target="_blank" >Privacy Policy</a>
                                </span>
                            </div>
                        </Col>
                        <Col lg="2" xs="12" className="d-none d-lg-block">
                            <div className={`${style.generalLayoutFooterMenu} mr-5`}>
                                <span className={style.socialIcon}>
                                    <a href={LINKEDIN_URL} target="_blank" >
                                        <AiFillLinkedin size={25} color="#FFFFFF" />
                                    </a>
                                </span>
                                <span className={style.socialIcon}>
                                    <a href={TWITTER_URL} target="_blank" >
                                        <AiOutlineTwitter size={25} color="#FFFFFF" />
                                    </a>
                                </span>
                                <span className={style.socialIcon}>
                                    <a href={GITHUB_URL} target="_blank" >
                                        <AiFillGithub size={25} color="#FFFFFF" />
                                    </a>
                                </span>
                            </div>

                        </Col>
                        <Col lg="4" className="text-right" xs="12">
                            <span className={style.generalLayoutFooterMenu}>
                                <a href="">Copyright &copy; {new Date().getFullYear()} onepane.ai All right reserved</a>
                            </span>
                        </Col>
                    </Row>
                </div>
            </div>

            <div>

            </div>

        </>
    )
}

export default Footer